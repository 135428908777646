import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator, LsModal } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';

type Props = {
    selectedWorkflowTemplate: Domain.Shared.WorkflowTemplateWithStates,
    isBusy: boolean,
    onConfirm: (selectedWorkflowId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to delete a workflow template.
 */
const WorkflowTemplateDeleteDialog: React.FC<Props> = (props) => {
    return (
        <LsModal
            id={`dialog-delete-workflow-template${props.selectedWorkflowTemplate.id ? `-${props.selectedWorkflowTemplate.id}` : ''}`}
            title="Verwijder workflow"
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Verwijder workflow',
                onRightButtonClick: () => props.onConfirm(props.selectedWorkflowTemplate.id),
                rightButtonDisabled: props.isBusy,
            }}
        >
            {props.isBusy
                ? <LoadIndicator variant={IndicatorSize.extralarge} />
                : <Text danger={true} value='Weet u zeker dat u het proces wilt verwijderen?' />
            }
        </LsModal>
    );
};

export { WorkflowTemplateDeleteDialog };