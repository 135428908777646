import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, Text } from '@liasincontrol/ui-basics';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    workflows: Domain.Shared.WorkflowTemplateWithStates[],
    currentName?: string,
    disabled?: boolean,
    onConfirm: (newWorkflowId: string, selectedName: string) => void,
    onCancel: () => void,
};

/** Represents a UI component that renders the dialog used to set/change the workflow for a budget journal kind. */
const SetWorkflowDialog: React.FC<Props> = (props) => {
    const [selectedWorkflow, setSelectedWorkflow] = useState<Domain.Shared.WorkflowTemplateWithStates>();
    const [selectedName, setSelectedName] = useState<string>();

    return (
        <LsModal
            id="modal-set-workflow"
            title='Workflow wijzigen'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                leftButtonDisabled: props.disabled,
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'OPSLAAN',
                onRightButtonClick: () => props.onConfirm(selectedWorkflow.id, selectedName),
                rightButtonDisabled: !selectedWorkflow || !selectedName || selectedName.trim() !== props.currentName.trim() || props.disabled,
            }}
        >
            <MuiGrid container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1}>
                    <Styled.TextWrapper>
                        <Text value='U staat op het punt om de workflow voor de journaalsoort te wijzigen. Alle eerder gekoppelde gebruikers worden verwijderd en alle journalen met deze journaalsoort die in de workflow staan, dienen opnieuw in de workflow gezet te worden.' />
                        <Text danger bold value='LET OP: U kunt deze actie niet ongedaan maken.' />
                    </Styled.TextWrapper>
                </MuiGrid>
                <MuiGrid item xs={1}>
                    <AutoFocus>
                        <SelectElement<Domain.Shared.WorkflowTemplateWithStates>
                            id='select-new-workflow'
                            label='Workflow'
                            displayExpr='name'
                            optionItems={props.workflows}
                            value={selectedWorkflow}
                            clearable={false}
                            searchable={false}
                            editorSettings={{
                                disabled: false,
                                restrictions: { required: true },
                                validationErrors: [],
                                onChange: setSelectedWorkflow,
                            }}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid item xs={1}>
                    <TextElement
                        id='name-field'
                        label='Voer als bevestiging de naam van de journaalsoort in:'
                        editorSettings={{
                            disabled: false,
                            validationErrors: [],
                            restrictions: { required: true, minLength: 2, maxLength: 100 },
                            onChange: setSelectedName
                        }}
                        value={selectedName}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    );
};

export { SetWorkflowDialog };