import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    title: string,
    text: string,
    onCloseDialog: () => void
};

/**
 * Represents a UI component that renders timeout message modal dialog.
 */
export const TimeoutMessageDialog: React.FC<Props> = (props) => {
    return (
        <LsModal
            id='modal-timeout-message'
            title={props.title}
            toolbar={{
                enabled: true,
                rightButtonText: 'Sluiten',
                onRightButtonClick: props.onCloseDialog
            }}
        >
            <Text value={props.text} />
        </LsModal>
    );
};
