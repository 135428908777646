import React from 'react';
import { HelpText, Label, palette } from '@liasincontrol/ui-basics';
import { ColorPickerProps } from './index.props';
import { Dot } from './index.styled';

/**
 * Represents a UI component that renders a color picker viewer.
 */
export const ColorPickerViewer: React.FC<ColorPickerProps> = (props) => {
    return (
        <>
            {props.title && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.title} />}
            {props.helpText && props.helpText?.text && <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>}
            <Dot id={props.id} backgroundColor={props.value ? props.value : props.defaultColor} />
            {props.withBorder && <div style={{ height: '1rem', borderBottom: `2px solid ${palette.grey4}`, marginBottom: '20px' }} />}
        </>
    );
};