import React, { useState } from 'react';
import { Button } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';
import * as Styled from './index.styled';

type Props = {
    error?: { title: string, detail: string },
    onCancel: () => void,
}

const CsvFeedbackModal: React.FC<Props> = (props) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);

    return (
        <LsModal
            id='modal-csv-feedback'
            title="Validatie mislukt"
            toolbar={{
                enabled: true,
                leftButtonText: 'Sluiten',
                onLeftButtonClick: props.onCancel,
            }}
        >
            <Styled.DescriptionAndTips>
                <Styled.Description>
                    De connectie-informatie correspondeert niet met het CSV bestand. Veelvoorkomende fouten:
                </Styled.Description>
                <Styled.Tips>
                    <Styled.Tip>Verkeerd gespelde kolomnamen</Styled.Tip>
                    <Styled.Tip>Verkeerde datatypes</Styled.Tip>
                    <Styled.Tip>Verkeerd type ColumnSeparator</Styled.Tip>
                    <Styled.Tip>Ontbrekende [indeling] en/of [kolommen]</Styled.Tip>
                </Styled.Tips>
            </Styled.DescriptionAndTips>
            <Button btnbase='textbuttons' btntype='small_icon' onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? "Verberg Foutcode" : "Bekijk Foutcode"}
            </Button>
            {showDetails && <Styled.Details>{props.error.title}. {props.error.detail}</Styled.Details>}
        </LsModal>
    );
};

export default CsvFeedbackModal;