import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { RadioGroupField } from '@liasincontrol/ui-basics';
import { siteAccessItems } from '..';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    saveButtonDisabled: boolean,
    currentSiteAccess: boolean,
    onCloseDialog: () => void,
    onChangeSiteAccess: (isPublic: boolean) => void,
};

/**
 * Represents a UI component that renders the version site access configuration modal dialog.
 */
export const VersionSiteAccessConfiguration: React.FC<Props> = (props) => {
    const [isPublicWebsite, setIsPublicWebsite] = useState<boolean>(props.currentSiteAccess || false);

    return (
        <LsModal
            id='modal-site-access-confirmation'
            title='Toegang'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCloseDialog,
                rightButtonText: 'Opslaan',
                onRightButtonClick: () => props.onChangeSiteAccess(isPublicWebsite),
                rightButtonDisabled: props.saveButtonDisabled,
            }}
        >
            <MuiGrid container
                columns={1}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} key='siteAccess'>
                    <RadioGroupField
                        id='webVersion-siteAccess'
                        label='Toegang tot de publicatie'
                        alignment='vertical'
                        items={siteAccessItems}
                        value={isPublicWebsite.toString()}
                        onChange={(val: string) => setIsPublicWebsite(JSON.parse(val))}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    );
};
