import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    elementDefinition: Domain.Shared.ElementDefinition,
    onConfirm: (elementDefinitionId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to deactivate an element definition.
 */
const DeactivateElementDefinitionDialog: React.FC<Props> = (props) => {

    return (
        <LsModal
            id='modal-deactivate-element-definition'
            title="Uitschakelen elementdefinities"
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Uitschakelen',
                onRightButtonClick: () => props.onConfirm(props.elementDefinition.id),
            }}
        >
            <Text danger={true} value={`Weet u zeker dat u de definitie voor ${props.elementDefinition.name} wilt uitschakelen?`} />
        </LsModal>
    );
};

export { DeactivateElementDefinitionDialog };