import { Label, px, palette, Heading1 } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(10)} repeat(10, [col-start] 1fr) ${px(10)};
    grid-template-rows: ${px(10)} repeat(7, [row-start] auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const HeaderForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 4;
    grid-row: 3 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 5 / span 14;
    grid-row: 3 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 21;
    grid-row: 4 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    padding: ${px(70)} 0 0 0;
`;

const ContentHeader = styled.div`
    grid-column: 2 / span 20;
    grid-row: 5 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const ExplanationFieldWrapper = styled.div<{ isTypePolicy: boolean }>`
    > div > textarea {
        padding-bottom: ${({ isTypePolicy }) => isTypePolicy ? px(18) : px(11)};
    }
`;

const ContentAttachments = styled.div`
    padding: ${px(10)} ${px(5)};
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(6)};
    margin-bottom: ${px(5)};
`;

const LeftSideBarPanel = styled.div`
    height: 100%;
    overflow-y: auto;
    width: ${px(500)};

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const TabsPlaceholder = styled.div`
    background-color: #FFF;
    grid-column: 2;
    grid-row: 1 / -1;
`;

const EdgeToolbarContent = styled.div`
    margin: ${px(24)};
`;

const ToolbarTitle = styled(Heading1)`
    display: block;
    margin: 0 0 ${px(24)} 0;
`;

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SingleValueLabel = styled.div`
    margin-left: ${px(3)};
`;

const styledComponents = {
    Grid, HeaderForm, LeftSideBarPanel, TabsPlaceholder, ToolbarTitle, EdgeToolbarContent, StyledLabel, HeaderNumber, HeaderName, ContentHeaderBar, ContentHeader,
    ExplanationFieldWrapper, SingleValueWrapper,
    SingleValueLabel, ContentAttachments, IconContainer
};

export default styledComponents;
