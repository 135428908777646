import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { DateUtils } from '@liasincontrol/core-service';
import { Button, Heading2, BasicText, CardHeaderTab } from '@liasincontrol/ui-basics';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import * as Styled from './AuditTrail.styled';
import { AuditTrailTabContent } from './AuditTrailTabContent';
import { LsModal } from '@liasincontrol/ui-devextreme';

enum CardTabs {
    FirstTab = "Vergelijking",
    SecondTab = "Gecombineerd",
    ThirdTab = "Nieuwe versie"
}

type Props = {
    /**
     * The selected element's definition.
     */
    elementDefinition: Domain.Shared.ElementDefinition,
    /**
     * The complete list of changes.
     */
    auditTrail: { content: Domain.Shared.AuditEvent[] },
};

/**
 * Represents a UI component that renders a list of audit events.
 */
const AuditTrail: React.FC<Props> = (props) => {
    const [selectedAuditEvent, setSelectedAuditEvent] = useState<{ newValue: Domain.Shared.AuditEvent; oldValue: Domain.Shared.AuditEvent }>({ newValue: undefined, oldValue: undefined });
    const [selectedTab, setSelectedTab] = useState<CardTabs>(CardTabs.FirstTab);

    const shownContentAuditTrail = useMemo(() => _.filter(props.auditTrail.content, (auditEvent) => auditTrailShownFields.includes(props.elementDefinition.fields.find((item) => item.id === auditEvent.fieldId)?.systemId)), [props.auditTrail, props.elementDefinition]);

    const shownAuditTrail = shownContentAuditTrail.sort((x, y) => x.timestamp < y.timestamp ? 1 : -1);

    const [showFullHistory, setShowFullHistory] = useState<boolean>(shownAuditTrail.length <= 10);

    const renderViewer = (fieldId: string, oldValue: string, newValue: string, mode: "inline" | "split" | "modified") => {
        const fieldDefinition = props.elementDefinition.fields.find((item) => item.id === fieldId);
        switch (fieldDefinition?.systemId) {
            case SystemFieldDefinitions.Pub.TextualContent:
            case SystemFieldDefinitions.Pub.HtmlContent:
            case SystemFieldDefinitions.Pub.TextHtmlContent:
                return <AuditTrailTabContent mode={mode} oldValue={oldValue} newValue={newValue} />
            default:
                return null;
        }
    };

    const cardHeaderTabElements: JSX.Element[] = Object.keys(CardTabs).map((cardTab) => (
        <CardHeaderTab
            id={`btn-nav-${cardTab}`}
            key={cardTab}
            active={selectedTab === CardTabs[cardTab]}
            onClick={() => setSelectedTab(CardTabs[cardTab])}
            title={CardTabs[cardTab]}
        />
    ));

    const renderAuditEvent = (auditEvent: Domain.Shared.AuditEvent, idx: number) => {
        const historyDate = DateUtils.formatDateTime(auditEvent.timestamp);
        const previousAuditEvent: Domain.Shared.AuditEvent = _.slice(shownAuditTrail, idx + 1).find((item: Domain.Shared.AuditEvent) => item.fieldId === auditEvent.fieldId);

        return (
            <React.Fragment key={`key-${idx}`}>
                <Styled.AuditTrailItem
                    key={idx}
                    id={`btn-${idx}`}
                    onClick={() => {
                        setSelectedAuditEvent({ newValue: auditEvent, oldValue: previousAuditEvent });
                    }}
                    isActive={selectedAuditEvent.newValue === auditEvent}
                >
                    <Styled.Item>{auditEvent.user?.name || 'Onbekende gebruiker'}</Styled.Item>
                    <Styled.Item>{historyDate}</Styled.Item>

                </Styled.AuditTrailItem>
            </React.Fragment>
        );
    };

    return (
        <Styled.Wrapper>
            {shownAuditTrail.length <= 0 && <BasicText>Geen gegevens beschikbaar</BasicText>}
            {shownAuditTrail.slice(0, !showFullHistory ? 10 : undefined).map((auditEvent, idx) => renderAuditEvent(auditEvent, idx))}
            {!showFullHistory && (
                <Button id='btn-load-full-history' btnbase='textbuttons' btntype='medium_icon' onClick={() => setShowFullHistory(true)}>
                    Laad alles...
                </Button>
            )}
            {selectedAuditEvent.newValue && (
                <LsModal
                    id='modal-set-workflow-state'
                    title='Wijzigingen raadplegen'
                    width={1280}
                    toolbar={{
                        enabled: false,
                        onLeftButtonClick: () => {
                            setSelectedAuditEvent({ newValue: undefined, oldValue: undefined });
                            setSelectedTab(CardTabs.FirstTab);
                        },
                    }}
                >
                    <Styled.Container>
                        <Styled.ContentHeaderBar />
                        <Styled.ContentHeader>{cardHeaderTabElements}</Styled.ContentHeader>
                        {selectedTab === CardTabs.FirstTab &&
                            <Styled.Tab>
                                {renderViewer(selectedAuditEvent.newValue.fieldId, selectedAuditEvent.oldValue?.fieldValue || '', selectedAuditEvent.newValue?.fieldValue || '', "split")}
                            </Styled.Tab>}
                        {selectedTab === CardTabs.SecondTab &&
                            <Styled.Tab>
                                <Heading2>Nieuwe versie</Heading2>
                                {renderViewer(selectedAuditEvent.newValue.fieldId, selectedAuditEvent.oldValue?.fieldValue || '', selectedAuditEvent.newValue?.fieldValue || '', "inline")}
                            </Styled.Tab>
                        }
                        {selectedTab === CardTabs.ThirdTab &&
                            <Styled.Tab>
                                <Heading2>Nieuwe versie</Heading2>
                                {renderViewer(selectedAuditEvent.newValue.fieldId, selectedAuditEvent.oldValue?.fieldValue || '', selectedAuditEvent.newValue?.fieldValue || '', "modified")}
                            </Styled.Tab>
                        }
                    </Styled.Container>
                </LsModal>)}
        </Styled.Wrapper>
    );
};

/**
 * List of fields we want to display history for.
 */
const auditTrailShownFields: string[] = [SystemFieldDefinitions.Pub.TextualContent, SystemFieldDefinitions.Pub.TextHtmlContent];

export { AuditTrail };
