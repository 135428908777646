import React from 'react';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import * as Domain from '@liasincontrol/domain';
import { AnyFormData, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import { DateElement, DecimalElement, HyperlinkElement, IntegerElement, MultiLineTextElement, RichTextElement, SelectElement, TextElement, ToggleElement } from '@liasincontrol/ui-elements';
import { Button, palette } from '@liasincontrol/ui-basics';
import { typeInputCustomOptions } from './CustomCircleIconOptionItem';
import Styled from './index.styled';
import { DesignerWrapper } from '../DesignerWrapper';
import { DetailCardLayout, DetailCardLayoutColumn } from './utils';
import { getFieldEditorSettings } from '../../../ElementDefinitions/ElementDefinitionSettings/FieldsDefinitions/utils';

/**
 * Defines the form view properties.
 */
export type FormViewProps = {
    form: AnyFormData,
    selectedControl?: string,
};

/**
 * Defines the form edit properties.
 */
export type FormEditProps = {
    validators: ValidatorsDictionary,
    isMeasureMomentClosed: boolean,
    onSelect: (fieldId: string) => void,
    onChangeCallBack: (value, fieldId) => void,
} & FormViewProps;

/**
 * Defines the form design properties.
 */
type FormDesignProps = {
    onSelect: (columnIndex: number, fieldIndex: number) => void,
    onAddElement: (columnIndex: number) => void,
    onRemoveElement: (columnIndex: number, fieldIndex: number) => void,
    onMoveElement: (ccolumnIndex: number, fieldIndex: number, moveUp: boolean) => void,
} & FormViewProps;

export enum StudioFormMode {
    Edit,
    Design,
    View,
}

type FormDetails = {
    elementDefinition: Domain.Shared.ElementDefinition,
    selectedFieldId?: string,
    detailLayout: DetailCardLayout,
    mode: StudioFormMode,
    editData?: FormEditProps,
    designData?: FormDesignProps,
    viewData?: FormViewProps,
    canMaximize?: boolean,
    maximizedId?: string,
    onMaximize?: (id: string) => void,
    hasTextAssistant?: boolean,
    onTextAssistant?: () => void,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    relatedTargetIdsFirignoreFocusOut?: string[],
};

/**
 * Represents a UI component that renders the details of an element definition.
 */
export const ElementDetailsForm: React.FC<FormDetails> = (props) => {

    //select only used icons?
    //each fieldelement uses a subset of icons , its time consuming to filter out them?
    const getDesignerElement = (fieldDefinition: Domain.Shared.FieldDefinition, columnIndex: number, fieldIndex: number, formData: FormDesignProps, canMoveUp: boolean, canMoveDown: boolean) => {
        return (
            <DesignerWrapper
                controlId={fieldDefinition.id}
                controlName={fieldDefinition.name}
                columnIndex={columnIndex}
                fieldIndex={fieldIndex}
                canMoveUp={canMoveUp}
                canMoveDown={canMoveDown}
                selectedcontrolId={formData.selectedControl}
                onSelect={(columnIndex: number, fieldIndex: number) => formData.onSelect(columnIndex, fieldIndex)}
                onRemoveElement={(columnIndex: number, fieldIndex: number) => formData.onRemoveElement(columnIndex, fieldIndex)}
                onMoveElement={(columnIndex: number, fieldIndex: number, moveUp: boolean) => formData.onMoveElement(columnIndex, fieldIndex, moveUp)}
            >
                <FormElement
                    disabled={false}
                    fieldDefinition={fieldDefinition}
                    form={initFormaData(props.elementDefinition.fields)}
                    icons={props.icons}
                />
            </DesignerWrapper>
        );
    };

    const getEditElement = (fieldDefinition: Domain.Shared.FieldDefinition, formData: FormEditProps) => {
        const isMaximized = (props.maximizedId === fieldDefinition.id);
        return (
            <FormElement
                disabled={formData.isMeasureMomentClosed}
                isSelected={props.selectedFieldId === fieldDefinition.id}
                fieldDefinition={fieldDefinition}
                form={formData.form}
                validators={formData.validators}
                onChange={(val) => {
                    formData.onChangeCallBack(val, fieldDefinition.id);
                }}
                canMaximize={props.canMaximize}
                isMaximized={isMaximized}
                onMaximize={(id: string) => props.onMaximize(id)}
                onSelect={formData.onSelect}
                hasTextAssistant={props.hasTextAssistant}
                onTextAssistant={props.onTextAssistant}
                icons={props.icons}
                relatedTargetIdsFirignoreFocusOut={props.relatedTargetIdsFirignoreFocusOut}
            />
        );
    };

    const getViewElement = (fieldDefinition: Domain.Shared.FieldDefinition, formData: FormViewProps) => {
        return <FormElement
            disabled={true}
            fieldDefinition={fieldDefinition}
            form={formData.form}
            icons={props.icons}
        />
    };

    const getDetailFormElement = () => {
        if (!props.detailLayout || !props.detailLayout.columns) {
            return null;
        }

        const fieldDefinitionIds = props.elementDefinition.fields.map((field) => field.id);
        let cleanedLayoutColumns: DetailCardLayoutColumn[] = props.detailLayout.columns.map((column) => ({ ...column, fieldIds: column.fieldIds?.filter((value) => fieldDefinitionIds.includes(value)) }));

        if (props.maximizedId) {
            const maximizedLayoutColumn = { fieldIds: [props.maximizedId], width: 100 };
            cleanedLayoutColumns = [maximizedLayoutColumn];
        }

        let colStart = 0;
        const columnElements = cleanedLayoutColumns.map((column, columnIndex) => {
            const colSpan = (width: number) => _.ceil(12 * (width / 100));
            colStart += column.width;
            const fieldElements = column.fieldIds?.map((item, itemIndex) => {
                const fieldDefinition = props.elementDefinition.fields.find((field) => field.id === item);
                switch (props.mode) {
                    case StudioFormMode.Design:
                        return getDesignerElement(fieldDefinition, columnIndex, itemIndex, props.designData, itemIndex > 0, itemIndex < column.fieldIds.length - 1);
                    case StudioFormMode.Edit:
                        return getEditElement(fieldDefinition, props.editData);
                    case StudioFormMode.View:
                    default:
                        return getViewElement(fieldDefinition, props.viewData);
                }
            });
            return (
                <Styled.ContentColumn colStart={columnIndex > 0 ? colSpan(colStart - column.width) + 1 : 1} colSpan={colSpan(column.width)} mode={props.mode} key={`content-column-${columnIndex}`}>
                    {fieldElements}
                    {props.mode === StudioFormMode.Design && (
                        <Styled.AddButtonContainer>
                            <Button icon={<AddIcon />} btnbase='iconbuttons' btntype='small_fabprimary' title='Nieuw formulier veld toevoegen' onClick={() => props.designData.onAddElement(columnIndex)} />
                        </Styled.AddButtonContainer>
                    )}
                </Styled.ContentColumn>
            );
        });
        return columnElements;
    };

    return (
        <Styled.Content>
            {getDetailFormElement()}
        </Styled.Content>
    );
};

type FormElementProps = {
    fieldDefinition: Domain.Shared.FieldDefinition,
    isSelected?: boolean,
    disabled: boolean,
    form: AnyFormData,
    validators?: ValidatorsDictionary,
    isMaximized?: boolean,
    canMaximize?: boolean,
    onMaximize?: (id: string) => void,
    onChange?: (value) => void,
    onSelect?: (id: string) => void,
    hasTextAssistant?: boolean,
    onTextAssistant?: () => void,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    relatedTargetIdsFirignoreFocusOut?: string[],
};

/**
 * Represents a UI component that renders a control element based on field definition.
 */
const FormElement: React.FC<FormElementProps> = (props) => {
    const sharedProps = {
        key: props.fieldDefinition.id,
        id: `studio-${props.fieldDefinition.id}`,
        label: props.fieldDefinition.label ? props.fieldDefinition.label : props.fieldDefinition.name,
    };

    if (props.form && props.validators && props.onChange) {
        sharedProps['editorSettings'] = ValidationUtils.getEditorSettings(true, props.disabled, props.validators, props.form, props.onChange, props.fieldDefinition.id);
    } else {
        sharedProps['editorSettings'] = {
            disabled: props.disabled,
            restrictions: [],
            validationErrors: [],
            onChange: noOp,
        }
    }

    switch (props.fieldDefinition.dataType) {
        case Domain.Shared.FieldDataType.String.toString(): {
            const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(props.fieldDefinition);
            switch (editorSettings?.stringDisplayFormat) {
                case Domain.Studio.StringDisplayType.MultiLine:
                    return <MultiLineTextElement
                        {...sharedProps}
                        rows={!props.isMaximized ? 9 : 24}
                        canMaximize={props.canMaximize}
                        maximized={props.isMaximized}
                        onMaximize={() => props.onMaximize?.(props.fieldDefinition.id)}
                        value={props.form.values[props.fieldDefinition.id] as string}
                        onFocus={() => props.onSelect(props.fieldDefinition.id)}
                        onFocusOut={(e) => {
                            if (props.relatedTargetIdsFirignoreFocusOut.includes(e.event?.originalEvent?.relatedTarget?.id)) return;
                            props.onSelect(null);
                        }}
                    />;
                case Domain.Studio.StringDisplayType.HTML:
                    return <Styled.Text
                        textColor={palette.grey2}
                        headerColor={palette.grey4}
                        headerBackgroundColor={palette.grey1}>
                        <RichTextElement
                            {...sharedProps}
                            value={props.form.values[props.fieldDefinition.id] as string}
                            isToolbarHidden={!props.isSelected}
                            height={!props.isMaximized ? 270 : 560}
                            canMaximize={props.canMaximize}
                            maximized={props.isMaximized}
                            onMaximize={() => props.onMaximize?.(props.fieldDefinition.id)}
                            onFocusIn={() => props.onSelect?.(props.fieldDefinition.id)}
                            withTextAssistant={props.hasTextAssistant}
                            onTextAssistant={props.onTextAssistant}
                            withSitemapLinks={false}
                            theme='light'
                        />
                    </Styled.Text>
                case Domain.Studio.StringDisplayType.HyperLink:
                    return <HyperlinkElement
                        {...sharedProps}
                        value={props.form.values[props.fieldDefinition.id] as string}
                    />
            }
            return <TextElement
                {...sharedProps}
                value={props.form.values[props.fieldDefinition.id] as string}
            />;
        }
        case Domain.Shared.FieldDataType.DateTimeOffset.toString():
            return <DateElement
                {...sharedProps}
                value={props.form.values[props.fieldDefinition.id] as Date}
            />;

        case Domain.Shared.FieldDataType.Decimal.toString(): {
            const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(props.fieldDefinition);
            return <DecimalElement
                {...sharedProps}
                format={editorSettings?.numericFormatString}
                value={props.form.values[props.fieldDefinition.id] as number}
            />;
        }
        case Domain.Shared.FieldDataType.Integer.toString():
            return <IntegerElement
                {...sharedProps}
                value={props.form.values[props.fieldDefinition.id] as number}
                updownAmount={1}
                startValue={1}
                controlButtons={true}
            />;
        case Domain.Shared.FieldDataType.Option.toString(): {
            return <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                {...sharedProps}
                placeholder='Kies...'
                optionItems={props.fieldDefinition.optionItems}
                clearable={true}
                searchable={true}
                value={props.fieldDefinition.optionItems.find(ot => ot.id === props.form.values[props.fieldDefinition.id])}
                customOptions={(customProps) => typeInputCustomOptions({ ...customProps, id: `studio-${props.fieldDefinition.id}`, icon: props.icons?.[customProps?.icon]?.svg })}
                customSingleValue={(customProps) => typeInputCustomOptions({ ...customProps, id: `studio-${props.fieldDefinition.id}`, icon: props.icons?.[customProps?.icon]?.svg }, { isFieldTemplate: true, placeholder: 'Kies...' })}
                editorSettings={{ ...sharedProps['editorSettings'], onChange: (i) => props.onChange(i?.id) }}
            />;
        }
        case Domain.Shared.FieldDataType.Boolean.toString():
            return <ToggleElement
                {...sharedProps}
                booleanTrueLabel={props.fieldDefinition.booleanTrueLabel || 'Ja'}
                booleanFalseLabel={props.fieldDefinition.booleanFalseLabel || 'Nee'}
                value={props.form.values[props.fieldDefinition.id] as boolean}
            />;
        case Domain.Shared.FieldDataType.Attachment.toString():
        case Domain.Shared.FieldDataType.Relation.toString():
            console.log(`${props.fieldDefinition.id} isn't developed yet.`);
            return;
        default:
            console.log(`No element for ${props.fieldDefinition.id}.`);
            return;
    }
};

export const initFormaData = (fieldDefinitions?: Domain.Shared.FieldDefinition[]) => {
    const values: Record<string, ValueType> = fieldDefinitions?.reduce((collection, item) =>
        ({ ...collection, [`${item.id}`]: item.dataType === Domain.Shared.FieldDataType.String.toString() ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' : '' }), {});
    return {
        values: values,
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

const noOp = () => {
    return;
};