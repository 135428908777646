import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Add from '@mui/icons-material/Add';
import { ContextMenu, GridColumn, LsGrid, LsModal } from '@liasincontrol/ui-devextreme'
import { Shared } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { Bar, Button, ErrorOverlay, Section, Text, WarningWrapper, WarningLook } from '@liasincontrol/ui-basics';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { FieldDefinitionAddForm } from './FieldDefinitionAddForm';
import { getFieldDisplayType } from './utils';
import { DetailCardLayout } from '../../../_shared/Renderer/ElementDetailsForm/utils';
import { SystemFieldDefinitions } from '@liasincontrol/domain';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

export type FieldDefinition = Domain.Shared.FieldDefinition & { placedOnForm?: boolean };

/**
 * Represents a UI component that renders the field definitions overview.
 */
const Index = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props) => {
    const { id: elementDefinitionId } = useParams<{ id: string }>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [entities, setEntities] = useState<FieldDefinition[]>([]);

    const [showFormDialog, setShowFormDialog] = useState<{ visible: boolean, mode: FormMode, item: FieldDefinition }>({ visible: false, mode: undefined, item: undefined });
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<{ visible: boolean, item: string }>({ visible: false, item: undefined });
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    // #region Grid state...
    const availableColumns = getColumnConfiguration(
        (fieldDefinition: FieldDefinition) => setShowFormDialog({ visible: true, mode: FormMode.Edit, item: fieldDefinition }),
        (fieldDefinition: FieldDefinition) => setIsDeleteDialogVisible({ visible: true, item: fieldDefinition.id })
    );

    const [selectedElementDefinition, setSelectedElementDefinition] = useState<any>();

    // #endregion...
    useEffect(() => {
        if (!props.elementDefinitions?.items || !props.elementDefinitions.items[elementDefinitionId]) {
            return;
        }
        setSelectedElementDefinition(props.elementDefinitions.items[elementDefinitionId]);

    }, [props.elementDefinitions]);

    useEffect(() => {
        if (!selectedElementDefinition) {
            return;
        }
        let placed = [];
        const detailCard = selectedElementDefinition.detailcards.find((card) => card.id === selectedElementDefinition.defaultDetailcardId);
        if (!detailCard) {
            placed = [];
        } else {
            const flatten = JSON.parse(detailCard.layout) as DetailCardLayout;
            placed = flatten.columns.flatMap(a => a.fieldIds);
        }
        setEntities(selectedElementDefinition.fields.map((field) => ({
            ...field,
            placedOnForm: field.systemId === SystemFieldDefinitions.Studio.Number || field.systemId === SystemFieldDefinitions.Studio.Name || placed.some((fieldId) => fieldId === field.id),
        })));

    }, [selectedElementDefinition]);

    const allRequiredFieldsUsed = useMemo(() => {
        const requiredCount = entities.filter(i => i.required).length;
        const placedCount = entities.filter(i => i.required && i.placedOnForm).length;
        return requiredCount === placedCount;
    }, [entities]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    //refetch if no detailcard
    if (props.elementDefinitions.status === AjaxRequestStatus.Done && !props.elementDefinitions.includeDetailCards) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    if (!selectedElementDefinition) { return null; }

    const onSave = (fieldArray: Domain.Shared.FieldDefinition[]) => {
        Shared.ElementDefinitions.createFields(fieldArray, elementDefinitionId).then(() => {
            props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);

            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioFieldNotUnique)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioFieldNotUnique] });
            } else {
                setError(errorInfo);
            }

        }).finally(() => {
            setShowFormDialog({ visible: false, mode: undefined, item: undefined });
            setIsDeleting(false);
        });
    };

    const onFieldSave = (field: Domain.Shared.FieldDefinition) => {
        const fieldArray = showFormDialog.mode === FormMode.AddNew
            ? [...props.elementDefinitions.items[elementDefinitionId].fields, field]
            : [...props.elementDefinitions.items[elementDefinitionId].fields.filter(fieldDefinition => fieldDefinition.id !== field.id), field];
        onSave(fieldArray);
    };

    const onFieldDelete = (fieldDefinitionId: string) => {
        const fieldArray = props.elementDefinitions.items[elementDefinitionId].fields.filter(fieldDefinition => fieldDefinition.id !== fieldDefinitionId);
        onSave(fieldArray);
    };

    const fieldNames = entities.map(i => i.name);

    return (
        <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
            <Bar look='toolbar'>
                <Bar start>
                    <Button
                        id='btn-create'
                        btnbase='textbuttons'
                        btntype='medium_icon'
                        icon={<Add />}
                        onClick={() => setShowFormDialog({ visible: true, mode: FormMode.AddNew, item: undefined })}>
                        Nieuw
                    </Button>
                </Bar>
            </Bar>
            {entities?.length && !allRequiredFieldsUsed &&
                <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='mb-100 p-025'
                    messageText='Let op: Niet alle verplichte velden staan op de kaart.' />
            }
            <Section look='white'>
                <LsGrid
                    dataSource={entities}
                    columns={availableColumns}
                    enableColumnChooser={true}
                    searching={false}
                    paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                    showRowLines={true}
                    keyExpr='id'
                    onClickRow={(item: FieldDefinition) => {
                        if (item.systemId === Domain.SystemFieldDefinitions.Studio.Dynamic) {
                            setShowFormDialog({ visible: true, mode: FormMode.Edit, item: item });
                        }
                    }}
                />
            </Section>

            {showFormDialog.visible &&
                <FieldDefinitionAddForm
                    fieldDefinition={showFormDialog.item}
                    icons={props.icons?.items}
                    onSave={onFieldSave}
                    onCancel={() => setShowFormDialog({ visible: false, mode: undefined, item: undefined })}
                    mode={showFormDialog.mode}
                    fields={fieldNames}
                />
            }

            {isDeleteDialogVisible.visible && (
                <LsModal
                    id={`modal-delete-field-${isDeleteDialogVisible.item}`}
                    title='Veld verwijderen'
                    toolbar={{
                        enabled: true,
                        leftButtonText: 'Annuleren',
                        onLeftButtonClick: () => setIsDeleteDialogVisible({ visible: false, item: undefined }),
                        rightButtonText: 'Verwijderen',
                        onRightButtonClick: () => {
                            setIsDeleting(true);
                            onFieldDelete(isDeleteDialogVisible.item);
                            setIsDeleteDialogVisible({ visible: false, item: undefined })
                        },
                        rightButtonDisabled: isDeleting,
                    }}
                >
                    <Text value='Weet u zeker dat u dit onderdeel wilt verwijderen? De actie kan niet ongedaan worden gemaakt.' />
                </LsModal>
            )}
        </ErrorOverlay>
    );
});

const getColumnConfiguration = (
    onEdit?: (fieldDefinition: FieldDefinition) => void,
    onDelete?: (fieldDefinition: FieldDefinition) => void): GridColumn<FieldDefinition>[] => {
    return [
        {
            name: 'name',
            title: 'Veldnaam',
            width: '30%',
        },
        {
            name: 'dataType',
            title: 'Soort veld',
            renderCustom: (item) => <>{getFieldDisplayType(item.data)}</>,
            calculateSortValue: (item) => getFieldDisplayType(item),
        },
        {
            name: 'required',
            title: 'Verplicht',
            width: '10%',
            align: 'center',
            renderCustom: (item) => <>{item.data.required ? 'Ja' : 'Nee'}</>,
        },
        {
            name: 'placedOnForm',
            title: 'Kaart',
            width: '10%',
            align: 'center',
            renderCustom: (item) => <>{item.data.placedOnForm ? 'Ja' : 'Nee'}</>,
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => data.systemId === Domain.SystemFieldDefinitions.Studio.Dynamic && (
                <ContextMenu<FieldDefinition>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            action: onEdit,
                            actionName: `edit-${data.id}`,
                            displayName: 'Bewerken',
                            ariaLabel: `Bewerk ${data.name}`,
                            hidden: !onEdit
                        },
                        {
                            action: onDelete,
                            actionName: `delete-${data.id}`,
                            displayName: 'Verwijderen',
                            ariaLabel: `Verwijder ${data.name}`,
                            hidden: !onDelete
                        }
                    ]}
                />),
        },
    ];
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        icons: state.icons,
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
