import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { LsModal } from '@liasincontrol/ui-devextreme';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { FormInfo, FormMode } from '@liasincontrol/core-service';
import { DataStoreForm } from '../../DataStoreForm';

type Props = {
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    onLoadAttachment: (attachmentId: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onSaveChanges: (formValues: Record<string, string>) => void,
    onCancelChanges: () => void,
};

/**
 * Represents a UI component that renders a dialog to add a new data store object.
 */
const DataStoreAdd: React.FC<Props> = (props) => {
    const [formData, setFormData] = useState<FormInfo<string>>({ values: undefined, isValid: true, isTouched: false });

    return <LsModal
        id="modal-add-new-datastore"
        title="Gegevensverbinding aanmaken"
        toolbar={{
            enabled: true,
            onLeftButtonClick: props.onCancelChanges,
            onRightButtonClick: () => {
                props.onSaveChanges(formData?.values);
                setFormData(prev => ({ ...prev, isTouched: false }));
            },
            rightButtonDisabled: !formData?.isTouched || !formData?.isValid,
        }}
    >
        <AutoFocus>
            <DataStoreForm
                fieldDefinitions={props.fieldDefinitions}
                hierarchyDefinitions={props.hierarchyDefinitions}
                mode={FormMode.AddNew}
                onLoadAttachment={props.onLoadAttachment}
                onUploadAttachment={props.onUploadAttachment}
                onFormDataChanged={setFormData}
            />
        </AutoFocus>
    </LsModal>;
};

export { DataStoreAdd };
