import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    readonly entityId: string,
    readonly disableSubmitButton: boolean,
    readonly onDelete: (entityId: string) => void,
    readonly onCancel: () => void,
};

/**
 * Represents an UI component that opens a dialog that asks the user if they wants to delete an entity.
 */
export const HierarchyItemDeleteDialog: React.FC<Props> = (props) => {
    return (
        <LsModal
            id='modal-delete-entity'
            title="Entiteit verwijderen"
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Verwijderen',
                onRightButtonClick: () => props.onDelete(props.entityId),
                rightButtonDisabled: props.disableSubmitButton,
            }}
        >
            <Text value="Weet u zeker dat u dit onderdeel wilt verwijderen? De actie kan niet ongedaan worden gemaakt." />
        </LsModal>
    );
};
