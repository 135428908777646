import React, { useState, useEffect } from 'react';
import buildQuery from 'odata-query';
import { Publisher } from '@liasincontrol/data-service';
import { Button, ErrorOverlay, Text } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator, LsModal } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import * as Styled from './CommentList.styled';
import { AddComment } from './AddComment';
import { CommentHeader, CommentItem } from './CommentItem';

type Props = {
    publicationId: string;
    pageId: string;
    elementId: string;
    currentUserId: string;
    componentWorkflowTasks: Domain.Publisher.WorkflowTask[],
}

/** List comments for a selected element */
const CommentList: React.FC<Props> = (props) => {
    const [comments, setComments] = useState<{ list: Domain.Publisher.Comment[], count: number }>({ list: [], count: 0 });
    const [editedComment, setEditedComment] = useState<string>();
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<{ visible: boolean, id: string, inProgress: boolean }>({ visible: false, id: null, inProgress: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    useEffect(() => {
        if (!props.publicationId || !props.pageId || !props.elementId) return;
        setIsBusy(true);
        const query = buildQuery({
            top: loadMore ? undefined : 10,
            skip: loadMore ? 10 : 0,
            count: true,
            orderBy: 'createdOn desc'
        });

        Publisher.Comments.getAll(props.publicationId, props.pageId, props.elementId, query)
            .then((response) => {
                setComments((prev) => {
                    const completelist = loadMore ? prev.list.concat(response.data.value) : response.data.value;
                    return {
                        list: completelist,
                        count: response.data['@count']
                    };
                });
            })
            .catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            }).finally(() => setIsBusy(false));

    }, [props.publicationId, props.pageId, props.elementId, lastRefresh, loadMore]);

    const saveComment = (commentText: string) => {
        Publisher.Comments.create(props.publicationId, props.pageId, props.elementId, commentText)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err, true));
            });
    };

    const updateComment = (commentId: string, commentText: string) => {
        Publisher.Comments.update(commentId, commentText)
            .then((response) => {
                setLastRefresh(Date.now());
                setEditedComment(null);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err, true));
            });
    };

    const deleteComment = (commentId: string) => {
        setConfirmDeleteModal((prev) => ({ ...prev, inProgress: true }));
        Publisher.Comments.delete(commentId)
            .then((response) => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err, true));
            }).finally(() => {
                setConfirmDeleteModal({ visible: false, id: null, inProgress: false });
            });
    };

    return (
        <Styled.Wrapper>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <AddComment disabled={!!editedComment} onSave={saveComment} />
                {isBusy
                    ? <LoadIndicator variant={IndicatorSize.default} />
                    : <>
                        <Styled.ItemList>
                            {comments.list.map((comment) => {
                                return <Styled.Item>
                                    <CommentHeader
                                        comment={comment}
                                        userHasTask={props.componentWorkflowTasks.some(task => task.userId === comment.authorId)}
                                        isEditable={!!editedComment || props.currentUserId !== comment.authorId}
                                        key={`comment-header-${comment.id}`}
                                        onEdit={(id) => setEditedComment(id)}
                                        onDelete={(id) => setConfirmDeleteModal({ visible: true, id: id, inProgress: false })}
                                    />
                                    <CommentItem
                                        key={`comment-${comment.id}`}
                                        isEdited={editedComment === comment.id}
                                        comment={comment}
                                        onCancel={() => setEditedComment(null)}
                                        onSave={updateComment}
                                    />
                                </Styled.Item>;
                            }
                            )}
                        </Styled.ItemList>

                        {!loadMore && comments.count > 10 && <Button id={`btn-load-all-comments`} btnbase="primarybuttons" btntype="medium_icon" onClick={() => { setLoadMore(true); }}>
                            Load alles
                        </Button>}
                        {confirmDeleteModal.visible &&
                            <LsModal
                                id='modal-confirm-delete'
                                title='Opmerking verwijderen'
                                toolbar={{
                                    enabled: true,
                                    leftButtonText: 'Annuleren',
                                    onLeftButtonClick: () => setConfirmDeleteModal({ visible: false, id: undefined, inProgress: false }),
                                    rightButtonText: 'Verwijderen',
                                    onRightButtonClick: () => deleteComment(confirmDeleteModal.id),
                                }}
                            >
                                <Text value='Weet u zeker dat u de opmerking wilt verwijderen?' />
                            </LsModal>
                        }
                    </>
                }
            </ErrorOverlay>
        </Styled.Wrapper>
    );
}

export { CommentList };