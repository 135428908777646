import React, { useMemo, useState } from 'react';
import TextBox from "devextreme-react/text-box";
import { isString } from 'lodash';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as Domain from '@liasincontrol/domain';
import { CardHeaderTab, IconSize, WarningWrapper, WarningLook, SVGIcon } from '@liasincontrol/ui-basics';
import { AnyFormData, AttachmentValidator, BasicValidator, DateUtils, DateValidator, FormHelper, FormInfo, FormMode, IconHelper, NumberValidator, TextValidator, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import { SelectElement, SelectElementTemplateProps, TextElement } from '@liasincontrol/ui-elements';
import { ElementDetailsForm, StudioFormMode, FormEditProps, FormViewProps } from '../../_shared/Renderer/ElementDetailsForm';
import { DetailCardLayout } from '../../_shared/Renderer/ElementDetailsForm/utils';
import { HierarchyItemAuditTrail } from '../HierarchyItemAuditTrail';
import { StudioElementAttachments } from './StudioElementAttachments';
import StyledOption from '../../_shared/OptionItem/index.styled';
import Styled from './index.styled';
import { getFieldEditorSettings } from '../../ElementDefinitions/ElementDefinitionSettings/FieldsDefinitions/utils';
import { MomentType } from '../HierarchyList/HerarchyListItem';
import { HierarchyItemStructures } from '../HierarchyItemStructures';

enum CardTabs {
    Details = "Details",
    History = "Historie",
    Attachments = "Bijlagen",
    Structures = "Structuren"
}

/**
 * Defines the props of the studio element form.
 */
type Props = {
    selectedFieldId?: string,
    hierarchyItemId: string,
    linkedStructures: Domain.Shared.LinkedStructure[],
    fields: FormInfo<ValueType>,
    audit: Domain.Dto.Shared.AuditEvent[],
    users: Domain.Shared.User[],
    elementDefinition: Domain.Shared.ElementDefinition,
    workflowTemplate?: Domain.Shared.WorkflowTemplateWithStates,
    measureMoment: MomentType,
    mode: FormMode,
    hasEditPermission?: boolean,
    onFieldsDataChanged: (fields: FormInfo<ValueType>) => void,
    onWorkflowStateChanged: (workflowState: Domain.Shared.AbstractElementWorkflowStatus) => void,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    onStartMultipleUpload: () => void,
    onFinishMultipleUpload: () => void,
    onSelect: (fieldId: string) => void,
    onRefresh: () => void,
    hasTextAssistant: boolean,
    onTextAssistant: () => void,
    allRequiredFieldsUsed: boolean,
    leaseInfo?: Domain.Shared.AcquireLease,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    relatedTargetIdsFirignoreFocusOut?: string[],
};

/**
 * Represents a UI component that renders a Studio element form.
 */
export const StudioElementForm: React.FC<Props> = (props) => {
    const validators = getValidators(props.elementDefinition.fields);
    const [form, setForm] = useState<AnyFormData>(initFormData(props.elementDefinition.fields, props.fields, validators));
    const [selectedCardTab, setSelectedCardTab] = useState<CardTabs>(CardTabs.Details);
    const [maximizedId, setMaximizedId] = useState<string>(null);

    const customValidatorIds = useMemo(() => (
        props.elementDefinition.fields.filter(field => {
            if (field.dataType === Domain.Shared.FieldDataType.String.toString()) {
                const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(field);
                if (editorSettings?.stringDisplayFormat && editorSettings.stringDisplayFormat === Domain.Studio.StringDisplayType.HyperLink) {
                    return true;
                }
            }
            return false;
        }).map(x => x.id)), [props.elementDefinition]);

    const detailCard = useMemo(() => {
        if (!props.elementDefinition) return;
        return props.elementDefinition.detailcards.find((card) => card.id === props.elementDefinition.defaultDetailcardId);
    }, [props.elementDefinition]);

    const sortedUsers = useMemo(() => props.users.sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0)), [props.users]);

    if (!props.elementDefinition) {
        return null;
    }

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId, customValidatorIds);
        setForm(newForm);
        props.onFieldsDataChanged({
            values: newForm.values,
            complex: newForm.complex,
            attachments: newForm.attachments,
            workflow: newForm.workflow,
            isValid: newForm.isValid,
            isTouched: Object.keys(newForm.touched).length > 0
        });
    };

    const itemIsLocked = props.measureMoment?.closed || props.leaseInfo?.result === "Denied" || !props.hasEditPermission || props.mode === FormMode.View;

    const cardHeaderTabElements = Object.keys(CardTabs).map((cardTab) => {
        if (props.mode === FormMode.AddNew && CardTabs[cardTab] !== CardTabs.Details) {
            return null;
        }

        return (
            <CardHeaderTab
                id={`btn-nav-${cardTab}`}
                key={cardTab}
                active={selectedCardTab === CardTabs[cardTab]}
                onClick={() => setSelectedCardTab(CardTabs[cardTab])}
                title={CardTabs[cardTab]} />
        );
    });

    const getWorkflowElement = () => {
        if (!form.workflow || !props.workflowTemplate) {
            return null;
        }

        const workflowInputCustomOptions = (props: Domain.Shared.WorkflowTemplateState, templateProps?: SelectElementTemplateProps) => {
            return <StyledOption.SingleValueWrapper>
                {IconHelper.getWorkFlowStatusIcon(props?.name, IconSize.medium)}
                {templateProps?.isFieldTemplate ?
                    <TextBox stylingMode='outlined' value={props?.name} />
                    :
                    <StyledOption.SingleValueLabel>
                        {props?.name}
                    </StyledOption.SingleValueLabel>
                }
            </StyledOption.SingleValueWrapper>
        };

        return (
            <>
                <Styled.HeaderWorkflowState>
                    <SelectElement<Domain.Shared.WorkflowTemplateState>
                        id={`input-status-${props.workflowTemplate.id}`}
                        label='Status'
                        editorSettings={{
                            disabled: itemIsLocked,
                            validationErrors: undefined,
                            restrictions: undefined,
                            onChange: (selectedItem) => {
                                setForm(form => ({
                                    ...form,
                                    workflow: {
                                        ...form.workflow,
                                        workflowState: selectedItem?.id
                                    },
                                }));

                                props.onWorkflowStateChanged({
                                    ...form.workflow,
                                    workflowState: selectedItem?.id
                                });
                            },
                        }}
                        searchable={false}
                        clearable={false}
                        optionItems={props.workflowTemplate.workflowStates}
                        value={props.workflowTemplate.workflowStates.find(ws => ws.id === form.workflow?.workflowState)}
                        customOptions={(item) => workflowInputCustomOptions(item)}
                        customSingleValue={(item) => workflowInputCustomOptions(item, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    />
                </Styled.HeaderWorkflowState>
                <Styled.HeaderAssignedUser>
                    <SelectElement<Domain.Shared.User>
                        id={`input-assigneduser-${props.workflowTemplate.id}`}
                        label='Toegewezen aan'
                        displayExpr='name'
                        editorSettings={{
                            disabled: itemIsLocked,
                            validationErrors: undefined,
                            restrictions: undefined,
                            onChange: (item) => {
                                setForm(form => ({
                                    ...form,
                                    workflow: {
                                        ...form.workflow,
                                        assignedUser: item?.id
                                    },
                                }));

                                props.onWorkflowStateChanged({
                                    ...form.workflow,
                                    assignedUser: item?.id
                                });
                            }
                        }}
                        searchable={false}
                        clearable={true}
                        optionItems={sortedUsers}
                        value={sortedUsers.find(u => u.id === form.workflow?.assignedUser)}
                    />
                </Styled.HeaderAssignedUser>
            </>
        );
    };

    const convertToDomainAuditEvent = (auditEvent: Domain.Dto.Shared.AuditEvent) => {
        return {
            ...auditEvent,
            eventType: auditEvent.eventTypeId as Domain.Shared.EventType,
            user: sortedUsers?.find(user => user.id === auditEvent.userId)
        } as Domain.Shared.AuditEvent;
    };

    const handleMaximize = (id: string) => {
        setMaximizedId(prev => prev === id ? null : id);
    }

    const numberFieldDefinition = props.elementDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Studio.Number);
    const nameFieldDefinition = props.elementDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Studio.Name);

    let editData: FormEditProps = null;
    let viewData: FormViewProps = null;

    if (props.hasEditPermission) {
        editData = {
            form: form,
            validators: validators,
            selectedControl: props.selectedFieldId,
            isMeasureMomentClosed: props.measureMoment?.closed,
            onSelect: props.onSelect,
            onChangeCallBack: (val, fieldDefinitionId) => storeFormValue(val, fieldDefinitionId),
        };
    }
    viewData = {
        form: form
    };

    return (
        <Styled.Grid>
            <Styled.HeaderForm>
                <SVGIcon value={props.icons[props.elementDefinition.icon]?.svg} size={IconSize.medium} color={props.elementDefinition.color} />
                <Styled.StyledLabel>{props.elementDefinition.name}</Styled.StyledLabel>
            </Styled.HeaderForm>
            {props.leaseInfo?.result === "Denied" && <Styled.WarningForm>
                <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='p-025'
                    messageText={`Wordt bewerkt door ${sortedUsers?.find(user => user.id === props.leaseInfo?.ownerId)?.name}`}
                />
            </Styled.WarningForm>}
            <Styled.WarningBar>
                {detailCard && !props.allRequiredFieldsUsed && <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='p-025'
                    messageText='Let op: niet alle verplichte velden staan op de kaart, neem contact op met de beheerder.' />}
            </Styled.WarningBar>
            <Styled.HeaderNumber>
                <TextElement
                    id={`input-nummer-${numberFieldDefinition.id}`}
                    label={numberFieldDefinition.label ? numberFieldDefinition.label : numberFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, numberFieldDefinition.id)}
                    value={form.values[numberFieldDefinition.id] as string}
                />
            </Styled.HeaderNumber>
            <Styled.HeaderName>
                <TextElement
                    id={`input-name-${nameFieldDefinition.id}`}
                    label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, itemIsLocked, validators, form, storeFormValue, nameFieldDefinition.id)}
                    value={form.values[nameFieldDefinition.id] as string}
                />
            </Styled.HeaderName>
            {getWorkflowElement()}
            <Styled.ContentHeaderBar />
            <Styled.ContentHeader>{cardHeaderTabElements}</Styled.ContentHeader>
            {selectedCardTab === CardTabs.Details && detailCard && (
                <ElementDetailsForm
                    selectedFieldId={props.selectedFieldId}
                    elementDefinition={props.elementDefinition}
                    detailLayout={JSON.parse(detailCard.layout) as DetailCardLayout}
                    mode={!itemIsLocked ? StudioFormMode.Edit : StudioFormMode.View}
                    editData={editData}
                    viewData={viewData}
                    canMaximize={true}
                    maximizedId={maximizedId}
                    onMaximize={handleMaximize}
                    hasTextAssistant={props.hasTextAssistant}
                    onTextAssistant={props.onTextAssistant}
                    icons={props.icons}
                    relatedTargetIdsFirignoreFocusOut={props.relatedTargetIdsFirignoreFocusOut}
                />
            )}
            {selectedCardTab === CardTabs.History && (
                <HierarchyItemAuditTrail
                    key={`audit-${props.elementDefinition.id}`}
                    elementDefinition={props.elementDefinition}
                    auditTrail={props.audit.map(convertToDomainAuditEvent)}
                />
            )}
            {selectedCardTab === CardTabs.Attachments && (
                <StudioElementAttachments
                    elementDefinition={props.elementDefinition}
                    fields={props.fields}
                    audit={props.audit}
                    users={sortedUsers}
                    disabled={itemIsLocked}
                    onUploadAttachment={props.onUploadAttachment}
                    onRemoveAttachment={props.onRemoveAttachment}
                    onStartMultipleUpload={props.onStartMultipleUpload}
                    onFinishMultipleUpload={props.onFinishMultipleUpload} />
            )}
            {selectedCardTab === CardTabs.Structures && (
                <HierarchyItemStructures
                    hierarchyItemId={props.hierarchyItemId}
                    linkedStructures={props.linkedStructures}
                    measureMoment={props.measureMoment}
                    onRefresh={props.onRefresh}
                />
            )}
        </Styled.Grid>
    );
};

/**
 * Get a field's value in it's correct type.
 * @param dataType Represents the field vale type.
 * @param value Represent the field's value.
 * @returns The field's value in the correct type.
 */
const getFieldValue = (dataType: string, value: ValueType) => {
    switch (dataType) {
        case Domain.Shared.FieldDataType.DateTimeOffset.toString():
            return DateUtils.fromInvariantString(value) || undefined;
        case Domain.Shared.FieldDataType.Integer.toString():
        case Domain.Shared.FieldDataType.Decimal.toString():
            return value !== '' && !isNaN(Number(value)) ? Number(value) : undefined;
        case Domain.Shared.FieldDataType.Boolean.toString():
            return isString(value) ? value.toLowerCase() === 'true' : !!value;
        case Domain.Shared.FieldDataType.Attachment.toString():
        case Domain.Shared.FieldDataType.Relation.toString():
        default:
            return value || '';
    }
};

/**
 * Initializes the form data with entity type data.
 */
const initFormData = (fieldDefinitions?: Domain.Shared.FieldDefinition[], fields?: FormInfo<ValueType>, validators?: ValidatorsDictionary): AnyFormData => {
    const values: Record<string, ValueType> = fieldDefinitions?.reduce((collection, item) => ({ ...collection, [`${item.id}`]: getFieldValue(item.dataType, fields?.values[item.id]) }), {});
    const touched: Record<string, boolean> = fieldDefinitions?.reduce((collection, item) => ({ ...collection, [`${item.id}`]: true }), {});
    const { errors, hasErrors } = validators ? FormHelper.validateForm(validators, values, {}) : { errors: {}, hasErrors: false };
    return {
        values: values || {},
        workflow: fields?.workflow,
        touched: touched,
        validationErrors: errors || {},
        isValid: !hasErrors,
    };
};

/**
 * Initializes the validators for the form.
 */
const getValidators = (fieldDefinitions: Domain.Shared.FieldDefinition[]): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return fieldDefinitions.reduce((collection, item) =>
    ({
        ...collection,
        [`${item.id}`]: getValidatorElement(item),
    }),
        {});
};

/**
 * Create the validator object based on fieldDefinition's data type.
 */
const getValidatorElement = (fieldDefinition: Domain.Shared.FieldDefinition) => {
    if (fieldDefinition.systemId === Domain.SystemFieldDefinitions.Studio.Number) {
        return new TextValidator({
            required: fieldDefinition.required,
            stringMaxLength: fieldDefinition.stringMaxLength,
            stringMinLength: fieldDefinition.stringMinLength,
            stringType: fieldDefinition.stringType
        });
    }

    switch (fieldDefinition.dataType) {
        case Domain.Shared.FieldDataType.String.toString():
            return new TextValidator({
                required: fieldDefinition.required,
                stringMaxLength: fieldDefinition.stringMaxLength,
                stringMinLength: fieldDefinition.stringMinLength,
                stringType: fieldDefinition.stringType
            });
        case Domain.Shared.FieldDataType.Option.toString():
            return new TextValidator({
                required: fieldDefinition.required,
                stringMaxLength: fieldDefinition.stringMaxLength,
                stringMinLength: fieldDefinition.stringMinLength,
                stringType: fieldDefinition.stringType
            });
        case Domain.Shared.FieldDataType.DateTimeOffset.toString():
            return new DateValidator({ required: fieldDefinition.required, dateMinValue: fieldDefinition.dateMinValue, dateMaxValue: fieldDefinition.dateMaxValue });
        case Domain.Shared.FieldDataType.Integer.toString():
            return new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.integerMinValue, maxValue: fieldDefinition.integerMaxValue });
        case Domain.Shared.FieldDataType.Decimal.toString():
            return new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.decimalMinValue, maxValue: fieldDefinition.decimalMaxValue });
        case Domain.Shared.FieldDataType.Attachment.toString():
            return new AttachmentValidator({ required: fieldDefinition.required, attachmentAllowedFileTypes: fieldDefinition.attachmentAllowedFileTypes, attachmentMaxFileSize: fieldDefinition.attachmentMaxFileSize });
        case Domain.Shared.FieldDataType.Boolean.toString():
        case Domain.Shared.FieldDataType.Relation.toString():
            return new BasicValidator({ required: fieldDefinition.required });
        default:
            return new BasicValidator({ required: fieldDefinition.required });
    }
};
