import React, { useState } from 'react';
import { MultiSelectList, MultiSelectItem } from '@liasincontrol/ui-basics';
import { ArrowButton } from './arrow-button';
import * as Styled from './index.style';
import { LsModal, SearchField } from '@liasincontrol/ui-devextreme';

type Props = {
    readonly onCancelled: () => void,
    readonly onConfirmed: (list: MultiSelectItem[]) => void,
    readonly listItems: MultiSelectItem[],
    readonly selectedItems?: MultiSelectItem[],
    readonly title: string,
    readonly disableSaveButton?: boolean,
    readonly mandatory?: boolean,
    readonly children?: React.ReactNode,
}

const previewMax = {
    height: 417,
    width: 419,
};

/**
 * Represents an UI component that displays a dialog with 2 lists, allowing the selection of multiple items.
 */
export const ConnectingDialog: React.FC<Props> = (props) => {
    const {
        onCancelled,
        onConfirmed,
        listItems,
        selectedItems,
        title,
        disableSaveButton = false,
        mandatory = false,
    } = props;

    const [lists, setLists] = useState<{ left: MultiSelectItem[], right: MultiSelectItem[] }>({ left: [...listItems], right: selectedItems ?? [] });
    const [searchValue, setSearchValue] = useState<{ left: string, right: string }>({ left: '', right: '' });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    //runtime filtered lists
    const choosingListContents = sortAlphabetically(searchValue.left?.length > 0 ? filterList(lists.left, searchValue.left) : lists.left);
    const choosingListCount = choosingListContents.length;

    const selectedListContents = sortAlphabetically(searchValue.right?.length > 0 ? filterList(lists.right, searchValue.right) : lists.right);
    const selectedListCount = selectedListContents.length;

    /**
     * Update List if a checkbox is checked or unchecked
     */
    const updateItemValue = (item: MultiSelectItem, value: boolean) => {
        setLists({
            left: lists.left.map(existing => existing.id === item.id ? { ...existing, value } : existing),
            right: lists.right.map(existing => existing.id === item.id ? { ...existing, value } : existing)
        });
    };

    /**
     * Handle Arrow Buttons
     * 
     * TODO: for all 4 methods below, it's unclear why this acts on all items, not just on the filtered (visible) ones.
     */
    const chooseAllItems = () => {
        //uncheck all moved items
        const leftItems = lists.left.map(item => ({ ...item, value: false }));
        setLists({
            left: [],
            right: [...lists.right].concat(...leftItems)
        });

        setSearchValue({
            left: '',
            right: ''
        });
    };

    const chooseCheckedItems = () => {
        //uncheck all moved items
        const leftCheckedItems = lists.left.filter(item => item.value).map(item => ({ ...item, value: false }));
        const leftRemainingItems = lists.left.filter(item => leftCheckedItems.find(existing => existing.id === item.id) === undefined);
        setLists({
            left: [...leftRemainingItems],
            right: [...lists.right].concat(...leftCheckedItems)
        });

        setSearchValue({
            left: '',
            right: ''
        });
    };

    const unselectAllItems = () => {
        //uncheck all moved items
        const remainingList = lists.right.filter(item => item.disabled);
        const rightItems = lists.right.filter(item => !item.disabled).map(item => ({ ...item, value: false }));
        setLists({
            left: [...lists.left].concat(...rightItems),
            right: [...remainingList]
        });

        setSearchValue({
            left: '',
            right: ''
        });
    };

    const unselectCheckedItems = () => {
        //uncheck all moved items
        const rightCheckedItems = lists.right.filter(item => item.value).map(item => ({ ...item, value: false }));
        const rightRemainingItems = lists.right.filter(item => rightCheckedItems.find(existing => existing.id === item.id) === undefined);
        setLists({
            left: [...lists.left].concat(...rightCheckedItems),
            right: [...rightRemainingItems]
        });

        setSearchValue({
            left: '',
            right: ''
        });
    };

    const onSubmit = () => {
        setIsSubmitting(true);
        onConfirmed(lists.right);
    }

    return (
        <LsModal
            id='connecting-dialog'
            title={title}
            width={1040}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: onCancelled,
                onRightButtonClick: onSubmit,
                rightButtonDisabled: (mandatory && lists.right?.length === 0) || disableSaveButton || isSubmitting,
            }}
        >
            <Styled.Container>
                <Styled.Left>
                    <SearchField
                        value={searchValue.left}
                        id="SearchValueChoosingList"
                        label="Kies uit"
                        placeholder="Zoek..."
                        totalResults={choosingListCount}
                        onChange={(value) => setSearchValue({ left: value, right: searchValue.right })}
                    />
                    <MultiSelectList
                        withBorder={true}
                        listItems={choosingListContents}
                        previewMax={previewMax}
                        onChange={(item, value) => updateItemValue(item, value)}
                    />
                </Styled.Left>
                <Styled.Mid>
                    <ArrowButton
                        id="btn-moverightall"
                        look="inverted"
                        arrowDirection="right"
                        ariaLabel="Verplaats alle items naar de lijst Geselecteerd"
                        onClick={chooseAllItems}
                    />
                    <ArrowButton
                        id="btn-moveright"
                        look="default"
                        arrowDirection="right"
                        ariaLabel="Verplaats geselecteerde items naar de lijst Geselecteerd"
                        onClick={chooseCheckedItems}
                    />
                    <ArrowButton
                        id="btn-moveleft"
                        look="default"
                        arrowDirection="left"
                        ariaLabel="Verplaats geselecteerde items naar de lijst Geselecteerd"
                        onClick={unselectCheckedItems}
                    />
                    <ArrowButton
                        id="btn-moveleftall"
                        look="inverted"
                        arrowDirection="left"
                        ariaLabel="Verplaats alle items naar de lijst Geselecteerd"
                        onClick={unselectAllItems}
                    />
                </Styled.Mid>
                <Styled.Right>
                    <SearchField
                        value={searchValue.right}
                        id="SearchValueSelectedList"
                        placeholder="Zoek..."
                        label="Geselecteerd"
                        totalResults={selectedListCount}
                        onChange={(value) => setSearchValue({ left: searchValue.left, right: value })}
                    />
                    <MultiSelectList
                        withBorder={true}
                        listItems={selectedListContents}
                        onChange={(item, value) => updateItemValue(item, value)}
                        previewMax={previewMax}
                    />
                </Styled.Right>
            </Styled.Container>
            {props.children}
        </LsModal>
    );
};

const filterList = (list: MultiSelectItem[], filterValue: string): MultiSelectItem[] => {
    return list.filter((item) => item.label?.toLowerCase().includes(filterValue?.toLowerCase()));
};

const sortAlphabetically = (list: MultiSelectItem[]) => {
    return [...list].sort((a, b) => (a.label > b.label) ? 1 : -1);
};
