import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Text } from '@liasincontrol/ui-basics';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ButtonContainerTopRight, ActionButton } from './index.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    elementId: string,
    elementName: string,
    parentDirection: Domain.Publisher.Direction,
    rotateMoveIcons: boolean,
    canMoveUp: boolean,
    canMoveDown: boolean,
    canAdd?: boolean,
    canDelete?: boolean,
    onRemoveElement: (elementId: string) => void,
    onMoveElement: (moveUp: boolean) => void,
    onAddElement?: () => void,
    verticalOffset?: number,
    inPopup?: boolean,
};

/**
 * Represents a UI component that renders the element actions near an element wrapper.
 */
export const ElementActions: React.FC<Props> = (props): JSX.Element => {
    const [showDeleteControl, setShowDeleteControl] = useState<boolean>(false);

    const getRemoveDialogElement = (showDeleteControl: boolean, onClose: () => void, onConfirm: () => void): JSX.Element => {
        if (!showDeleteControl) {
            return null;
        }

        return (
            <LsModal
                id={`remove-element-${props.elementId}`}
                title="Element verwijderen"
                toolbar={{
                    enabled: true,
                    leftButtonText: 'Annuleren',
                    onLeftButtonClick: onClose,
                    rightButtonText: 'Verwijderen',
                    onRightButtonClick: onConfirm,
                }}
            >
                <Text value="U staat op het punt om een element te verwijderen. Als er content aan het element is toegevoegd zal deze verloren gaan. U kunt deze actie niet meer ongedaan maken."></Text>
            </LsModal>
        );
    };

    const getButtons = (props: Props) => {
        return (<>
            <ActionButton
                id="btn-remove"
                btnbase="iconbuttons"
                btntype="medium_fabprimary"
                icon={<DeleteOutlinedIcon />}
                disabled={!props.canDelete}
                aria-label={`Verwijder element ${props.elementName}`}
                onClick={(event) => {
                    event.stopPropagation();
                    setShowDeleteControl(true);
                }}
            />
            <ActionButton
                id="btn-move-up"
                btnbase="iconbuttons"
                btntype="medium_fabghost"
                icon={props.parentDirection === Domain.Publisher.Direction.Horizontal || props.rotateMoveIcons ? <ArrowBack /> : <ArrowUpward />}
                disabled={!props.canMoveUp}
                aria-label={`Verplaats element ${props.elementName} omhoog`}
                onClick={(event) => {
                    event.stopPropagation();
                    props.canMoveUp && props.onMoveElement(true);
                }}
            />
            <ActionButton
                id="btn-move-down"
                btnbase="iconbuttons"
                btntype="medium_fabghost"
                icon={props.parentDirection === Domain.Publisher.Direction.Horizontal || props.rotateMoveIcons ? <ArrowForward /> : <ArrowDownward />}
                disabled={!props.canMoveDown}
                aria-label={`Verplaats element ${props.elementName} omlaag`}
                onClick={(event) => {
                    event.stopPropagation();
                    props.canMoveDown && props.onMoveElement(false);
                }}
            />
            {props.canAdd && props.onAddElement && <ActionButton
                id="btn-add"
                btnbase="iconbuttons"
                btntype="medium_fabprimary"
                icon={<Add />}
                aria-label={`Nieuwe element ${props.elementName}`}
                onClick={(event) => {
                    event.stopPropagation();
                    props.onAddElement();
                }}
            />}
        </>);
    }

    return (<>
        <ButtonContainerTopRight
            className='elementActions'
            direction={props.parentDirection}
            buttonCount={props.canAdd ? 4 : 3}
            inPopup={props.inPopup}
            verticalOffset={props.verticalOffset ?? 0}
        >
            {getButtons(props)}
        </ButtonContainerTopRight>

        {getRemoveDialogElement(
            showDeleteControl,
            () => setShowDeleteControl(false),
            () => { setShowDeleteControl(false); props.onRemoveElement(props.elementId); })
        }
    </>
    );
};

