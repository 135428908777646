import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import * as Domain from '@liasincontrol/domain';
import { FormData, FormHelper, TextValidator, ValidationUtils, ValidatorsDictionary } from '@liasincontrol/core-service';
import { TextElement } from '@liasincontrol/ui-elements';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    publishProfileFieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    saveButtonDisabled: boolean,
    profileId?: string,
    profileName?: string,
    onCloseDialog: () => void,
    onSave: (profileName: string, profileId: string) => void
};

/**
 * Represents a UI component that renders the dialog for adding a new profile or updating an existing one.
 */
export const ProfileConfig: React.FC<Props> = (props) => {
    const [profileForm, setProfileForm] = useState<FormData<string>>(initForm(props.profileName));
    const publishProfileNameFieldDefinition = props.publishProfileFieldDefinitions[SystemFieldDefinitions.Pub.Name];

    const validators = getValidators(props.publishProfileFieldDefinitions);

    const storeFormValue = (value: string, systemId: keyof typeof validators) => {
        const validators = getValidators(props.publishProfileFieldDefinitions);
        setProfileForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    return (
        <LsModal
            title={`Publicatie profiel ${props.profileId && props.profileName ? 'bewerken' : 'aanmaken'}`}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCloseDialog,
                rightButtonText: 'Opslaan',
                onRightButtonClick: () => props.onSave(profileForm.values[SystemFieldDefinitions.Pub.Name], props.profileId),
                rightButtonDisabled: !profileForm.isValid || props.saveButtonDisabled,
            }}
        >
            <MuiGrid container
                spacing={{ xs: 2 }}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} key={publishProfileNameFieldDefinition.name}>
                    <AutoFocus>
                        <TextElement
                            id='publishProfile_name'
                            label={publishProfileNameFieldDefinition.label ? publishProfileNameFieldDefinition.label : publishProfileNameFieldDefinition.name}
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, profileForm, (value: string) => storeFormValue(value, SystemFieldDefinitions.Pub.Name), SystemFieldDefinitions.Pub.Name)}
                            value={profileForm.values[SystemFieldDefinitions.Pub.Name]}
                        />
                    </AutoFocus>
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    );
}

const initForm = (profileName: string): FormData<string> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: profileName
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
    };
};