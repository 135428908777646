import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    onConfirm: (elementDefinitionId?: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to deactivate an element definition.
 */
const DeleteElementDialog: React.FC<Props> = (props) => {

    return (
        <LsModal
            id='modal-delete-element'
            title='Entiteit verwijderen'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Verwijderen',
                onRightButtonClick: () => props.onConfirm(),
            }}
        >
            <Text danger={true} value='Weet u zeker dat u dit element wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt.' />
        </LsModal>
    );
};

export { DeleteElementDialog };