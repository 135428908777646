import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Add from '@mui/icons-material/Add';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, MeasureMomentsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { ApiErrorReportingHelper, DefinitionsHelper, FormMode, isMomentDeleted, isMomentOpen } from '@liasincontrol/core-service';
import { Bar, Button, ErrorOverlay, Heading1, MultiSelectField, MultiSelectItem, PageTitle, Section, WrapperContent } from '@liasincontrol/ui-basics';
import { LsGrid, GridColumn, ContextMenu, StyledDraggableWrapper } from '@liasincontrol/ui-devextreme';
import { Shared as DataAccess, Studio } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import './index.style.less';
import { MeasureMomentFormDialog } from './MeasureMomentFormDialog';
import { MeasureMomentOpenDialog } from './MeasureMomentOpenDialog';
import { MeasureMomentDeleteDialog } from './MeasureMomentDeleteDialog';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the list of measure moments.
 */
const Index: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [measureMoments, setMeasureMoments] = useState<Domain.Shared.MeasureMoment[]>();
    const [lastRefresh, setLastRefresh] = useState<number>();
    const [logicControls, setLogicControls] = useState<{
        showFormDialog: boolean,
        showOpenMomentDialog: boolean,
        showDeleteMomentDialog: boolean,
        isBusy: boolean,
        viewMode: FormMode,
        selectedMeasureMoment: { data: Domain.Shared.MeasureMoment, newStatusId: string },
    }>({
        showFormDialog: false,
        showOpenMomentDialog: false,
        showDeleteMomentDialog: false,
        isBusy: false,
        viewMode: FormMode.View,
        selectedMeasureMoment: { data: undefined, newStatusId: '' },
    });
    const [momentInputSelectors, setMomentInputSelectors] = useState<{
        values: { value: number, label: string }[],
        selectedStatuses: { value: number, label: string }[]
    }>({
        values: mapMeasureMomentsToOptions(),
        selectedStatuses: mapMeasureMomentsToOptions(Domain.Shared.MeasureMomentStatus.Open, Domain.Shared.MeasureMomentStatus.Closed),
    });
    const [filteredMeasureMoments, setFilteredMeasureMoments] = useState<Domain.Shared.MeasureMoment[]>();
    const hasStudioRight = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Studio, ActionType.Create);
    const [availableHierarchies, setAvailableHierarchies] = useState<Record<string, MultiSelectItem[]>>({});

    const [externalError, setExternalError] = useState<{ id: string, message: string }>();

    //Measure moments are not fetched from Redux because the Redux store has the deleted moments filtered out and they are required here. 
    const fetchMeasureMoments = () => {
        DataAccess.MeasureMomentsDataAccess.getAll().then(moments => {
            setMeasureMoments(moments.sort((a, b) => a.order - b.order));
            props.refreshMeasureMoments();
        });
    };

    const fetchUsedStudioHierarchies = () => {
        if (Object.values(props.hierarchyDefinitions.items).length === 0) {
            return;
        }

        const activeHierarchyDefinitions = Object.values(props.hierarchyDefinitions.items).filter((hd) => !hd.deleted);
        const getDefinition = (id: string, definitions: Domain.Shared.HierarchyDefinition[]) => definitions.find(hd => hd.hierarchyDefinitionId === id);
        Studio.HierarchyDataAccessor.getUsedHierarchies().then((response) => {
            const usedHierarchies = response.data || [];
            const usedHierarchiDefinition = usedHierarchies.reduce(
                (collection, item) => {
                    const available = item['hiearchyDefinitionIds']?.map(id => {
                        const definition = getDefinition(id, activeHierarchyDefinitions);

                        return definition ? {
                            id: definition.hierarchyDefinitionId,
                            value: false,
                            label: definition.name,
                        } : null;
                    }).filter(x => !!x);

                    return { ...collection, [item['measureMomentId']]: available }
                },
                {}
            );
            setAvailableHierarchies(usedHierarchiDefinition);
        });
    };

    useEffect(() => {
        fetchMeasureMoments();
    }, [lastRefresh]);

    useEffect(() => {
        if (!props.modules || !props.modules[SystemModuleDefinitions.Studio] || !hasStudioRight || !props.hierarchyDefinitions?.items) {
            return;
        }
        fetchUsedStudioHierarchies();
    }, [lastRefresh, props.hierarchyDefinitions, hasStudioRight]);

    useEffect(() => {
        if (!measureMoments || !momentInputSelectors.selectedStatuses) {
            return;
        }

        const filteredMoments = measureMoments.filter(moment => momentInputSelectors.selectedStatuses.find(status => status.value === moment.status));
        setFilteredMeasureMoments(filteredMoments);
    }, [measureMoments, momentInputSelectors.selectedStatuses]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinition || props.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    if (hasStudioRight && (!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return null;
    }

    const onCancel = () => {
        setLogicControls((prev) => ({ ...prev, showFormDialog: false, showOpenMomentDialog: false, showDeleteMomentDialog: false, isBusy: false, }));
    };

    const onDeletingMeasureMoment = (measureMoment: Domain.Shared.MeasureMoment) => {
        setLogicControls((prev) => ({ ...prev, showDeleteMomentDialog: true, selectedMeasureMoment: { data: measureMoment, newStatusId: undefined } }));
    };

    const onChangingMomentStatus = (measureMoment: Domain.Shared.MeasureMoment, statusId: string) => {
        setLastRefresh(Date.now());
        setLogicControls((prev) => ({ ...prev, showOpenMomentDialog: true, selectedMeasureMoment: { data: measureMoment, newStatusId: statusId } }));
    };

    const addMeasureMoment = (measureMoment: Domain.Shared.MeasureMoment) => {
        const mMoment = {
            ...measureMoment,
            status: Domain.Shared.MeasureMomentStatus.Closed
        }

        DataAccess.MeasureMomentsDataAccess.createMeasureMoment(mMoment)
            .then(() => {
                fetchMeasureMoments();
                onCancel();
            })
            .catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.MeasureMomentDuplicateTag)) {
                    setExternalError({
                        id: 'tags',
                        message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.MeasureMomentDuplicateTag],
                    });
                } else {
                    setError(errorInfo);
                    onCancel();
                }
            });
    };

    const onEditingMeasureMoment = (measureMoment: Domain.Shared.MeasureMoment) => {
        DataAccess.MeasureMomentsDataAccess.updateMeasureMoment(measureMoment.id, measureMoment)
            .then(() => {
                fetchMeasureMoments();
                onCancel();
            })
            .catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.MeasureMomentDuplicateTag)) {
                    setExternalError({
                        id: 'tags',
                        message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.MeasureMomentDuplicateTag],
                    });
                } else {
                    setError(errorInfo);
                    onCancel();
                }
            });
    };

    const deleteMeasureMoment = (measureMomentId: string) => {
        DataAccess.MeasureMomentsDataAccess.deleteMeasureMoment(measureMomentId)
            .then(fetchMeasureMoments)
            .catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            }).finally(onCancel);
    }

    const saveNewMomentStatus = (measureMomentId: string, status: number, templateMomentId?: string, studioHierarchyDefinitionsToClone?: Record<string, string[]>) => {
        setLogicControls((prev) => ({ ...prev, isBusy: true }));
        DataAccess.MeasureMomentsDataAccess.setMeasureMomentStatus(measureMomentId, status, templateMomentId, studioHierarchyDefinitionsToClone)
            .then(fetchMeasureMoments)
            .catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            }).finally(onCancel);
    };

    const openEditModal = (measureMoment: Domain.Shared.MeasureMoment) => {
        DataAccess.MeasureMomentsDataAccess.get(measureMoment.id).then(moment => {
            setLogicControls((prev) => ({
                ...prev,
                showFormDialog: true,
                viewMode: FormMode.Edit,
                selectedMeasureMoment: { ...prev.selectedMeasureMoment, data: moment.data }
            }));
        });
    };

    const availableColumns = getColumnConfiguration(
        ({ data }) => {
            const fieldDefinitionsOptions = props.fieldDefinitions[SystemFieldDefinitions.Performance.MeasureMomentStatus].optionItems;
            const momentOpen = isMomentOpen(data.status);
            const oppositeStatus = momentOpen
                ? fieldDefinitionsOptions.find(option => option.value === Domain.Shared.MeasureMomentStatus.Closed)
                : fieldDefinitionsOptions.find(option => option.value === Domain.Shared.MeasureMomentStatus.Open);

            return <ContextMenu<Domain.Shared.MeasureMoment>
                keyExpr='id'
                item={data}
                actions={[
                    {
                        action: (item) => onChangingMomentStatus(item, oppositeStatus.id),
                        ariaLabel: 'Sluit moment',
                        actionName: `set-measure-moment-status-${data.id}`,
                        displayName: momentOpen ? 'Sluit moment' : 'Open moment'
                    },
                    {
                        action: openEditModal,
                        ariaLabel: 'Bewerk moment',
                        actionName: `edit-measure-moment-${data.id}`,
                        displayName: 'Bewerk Moment'
                    },
                    {
                        action: onDeletingMeasureMoment,
                        ariaLabel: 'Verwijder moment',
                        actionName: `delete-measure-moment-${data.id}`,
                        displayName: 'Verwijder moment',
                        hidden: isMomentDeleted(data.status)
                    }
                ]}
            />
        }
    );

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Momenten</Heading1>
                </PageTitle>
                <Bar look="toolbar">
                    <Bar start>
                        <Button
                            id="btn-create"
                            btnbase="textbuttons"
                            btntype="medium_icon"
                            icon={<Add />}
                            onClick={() => setLogicControls((prev) => ({ ...prev, showFormDialog: true, viewMode: FormMode.AddNew, selectedMeasureMoment: { data: undefined, newStatusId: '' } }))}
                        >
                            Nieuw
                        </Button>
                        <Button
                            id='btn-refresh'
                            btnbase='textbuttons'
                            btntype='medium_icon'
                            icon={<RefreshOutlinedIcon />}
                            onClick={() => {
                                setLastRefresh(Date.now());
                            }}
                        >
                            Verversen
                        </Button>
                    </Bar>
                    <Bar end>
                        <div style={{ width: '400px' }}>
                            <MultiSelectField
                                id='measure-moment-filter'
                                placeholder='Kies...'
                                onChange={(selectedItem) => {
                                    setMomentInputSelectors((oldState) => ({
                                        ...oldState,
                                        selectedStatuses: selectedItem.selected
                                    }));
                                }}
                                value={{
                                    items: momentInputSelectors.values,
                                    selected: momentInputSelectors.selectedStatuses,
                                    searchable: false,
                                }}
                            />
                        </div>
                    </Bar>
                </Bar>

                <Section look='white'>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <StyledDraggableWrapper dragEnabled={true}>
                            <LsGrid
                                keyExpr='id'
                                dataSource={filteredMeasureMoments}
                                columns={availableColumns}
                                enableColumnChooser={false}
                                searching={false}
                                scrolling={{
                                    mode: "standard",
                                    columnRenderingMode: "standard",
                                    rowRenderingMode: "standard",
                                    showScrollbar: "always",
                                    useNative: true,
                                }}
                                onClickRow={(item: Domain.Shared.MeasureMoment) => {
                                    openEditModal(item);
                                }}
                                showRowLines={true}
                                rowDragging={{
                                    allowReordering: true,
                                    showDragIcons: true,
                                    dragDirection: 'vertical',
                                    dropFeedbackMode: 'push',
                                }}
                                onReorder={async (treeListData) => {
                                    if (treeListData.fromIndex === treeListData.toIndex) return;
                                    const visibleRows = treeListData.component.getVisibleRows();
                                    const toIndex = treeListData.fromIndex > treeListData.toIndex ? treeListData.toIndex : (treeListData.toIndex as number);
                                    const targetDataId = toIndex <= visibleRows.length - 1 ? visibleRows[toIndex].data.id : null;
                                    await DataAccess.MeasureMomentsDataAccess.updateMeasureMomentOrder(treeListData.itemData.id, targetDataId).then(fetchMeasureMoments);
                                }}
                            />
                        </StyledDraggableWrapper>
                    </ErrorOverlay>
                </Section>
            </WrapperContent>
            {logicControls.showFormDialog &&
                <MeasureMomentFormDialog
                    viewMode={logicControls.viewMode}
                    fieldDefinitions={props.fieldDefinitions}
                    measureMoment={logicControls.selectedMeasureMoment.data}
                    onSave={logicControls.viewMode === FormMode.Edit ? onEditingMeasureMoment : addMeasureMoment}
                    onCancel={onCancel}
                    externalError={externalError}
                />
            }
            {logicControls.showOpenMomentDialog &&
                <MeasureMomentOpenDialog
                    selectedMeasureMoment={logicControls.selectedMeasureMoment.data}
                    measureMoments={measureMoments}
                    fieldDefinitions={props.fieldDefinitions}
                    availableStudioHierarchies={availableHierarchies}
                    hasStudioRight={hasStudioRight}
                    formIsBusy={logicControls.isBusy}
                    onCancel={onCancel}
                    onChangingMomentStatus={saveNewMomentStatus}
                />}
            {logicControls.showDeleteMomentDialog &&
                <MeasureMomentDeleteDialog
                    selectedMeasureMoment={logicControls.selectedMeasureMoment.data}
                    onCancel={onCancel}
                    onConfirm={deleteMeasureMoment}
                />}
        </>
    );
};

const getColumnConfiguration = (contextMenuProps): GridColumn<Domain.Shared.MeasureMoment>[] => {
    return [
        {
            name: 'name',
            title: 'Naam',
            allowSorting: false,
        },
        {
            name: 'status',
            title: 'Status',
            allowSorting: false,
            width: '7%',
            calculateDisplayValue: (x) => dutchMomentStatus[Domain.Shared.MeasureMomentStatus[x.status]]
        },
        {
            name: 'baseYear',
            title: 'Basisjaar',
            allowSorting: false,
            align: 'left',
            width: '7%',
        },
        {
            name: 'startDate',
            title: 'Startdatum',
            align: 'left',
            allowSorting: false,
            width: '15%',
            dataType: 'date',
            formatter: 'date'
        },
        {
            name: 'endDate',
            title: 'Einddatum',
            align: 'left',
            allowSorting: false,
            width: '15%',
            dataType: 'date',
            formatter: 'date'
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: contextMenuProps,
        }];
};



const mapMeasureMomentsToOptions = (...filter: Domain.Shared.MeasureMomentStatus[]) => {
    return Object.keys(Domain.Shared.MeasureMomentStatus)
        .filter(key => isNaN(Number(Domain.Shared.MeasureMomentStatus[key])))
        .map(status => ({
            label: dutchMomentStatus[status[0]],
            value: Domain.Shared.MeasureMomentStatus[status]
        }))
        .filter((status) => (filter && filter.length !== 0)
            ? filter.includes(Domain.Shared.MeasureMomentStatus[(status.value as string)])
            : true);
};

export const dutchMomentStatus = {
    [Domain.Shared.MeasureMomentStatus.Open]: 'Open',
    [Domain.Shared.MeasureMomentStatus.Closed]: 'Gesloten',
    [Domain.Shared.MeasureMomentStatus.Deleted]: 'Verwijderd',
};

const mapStateToProps = (state: State) => {
    const hasElementDefinitions = !_.isEmpty(state.elementdefinitions?.[ActionSource.Performance]);
    const elementDefinition = hasElementDefinitions ? DefinitionsHelper.findElementDefinition(state.elementdefinitions[ActionSource.Performance].items, SystemElementDefinitions.Performance.MeasureMoment) : undefined;
    const fieldDefinitions = hasElementDefinitions ? DefinitionsHelper.findElementFieldsMap(state.elementdefinitions[ActionSource.Performance].items, SystemElementDefinitions.Performance.MeasureMoment) : undefined;

    return {
        modules: state.modules[ActionSource.Performance],
        elementDefinition: elementDefinition,
        fieldDefinitions: fieldDefinitions,
        status: hasElementDefinitions ? state.elementdefinitions[ActionSource.Performance].status : AjaxRequestStatus.NotSet,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Performance, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
        refreshMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };