import dxPieChart from 'devextreme/viz/pie_chart';
import dxBarChart from 'devextreme/viz/chart';
import { dxElement } from 'devextreme/core/element';
import { Template as DxTemplate } from 'devextreme-react/core/template';
import { GroupRenderModeProps } from './helpers/renderModes';
import { PivotGridArea, PivotGridDataType } from 'devextreme/ui/pivot_grid/data_source';
import { TabsIconPosition } from 'devextreme/common';

/* tree  */
import {
  TreeList as DxTreeList,
  Column as DxColumn,
  SearchPanel as DxSearchPanel,
  Paging as DxPaging,
  Pager as DxPager,
  Scrolling as DxScrolling,
  Selection as DxSelection,
  RemoteOperations as DxRemoteOperations,
  Button as DxButton,
  StateStoring as DxStateStoring,
  Editing as DxEditing,
  ColumnChooser as DxColumnChooser,
  Position as DxPosition,
  ColumnChooserSelection as DxColumnChooserSelection,
  Toolbar as DxToolbar,
  Item as DxItem
} from 'devextreme-react/tree-list';

/*Barchart */
import {
  Chart as DxChart,
  SeriesTemplate as DxSeriesTemplate,
  CommonSeriesSettings as DxCommonSeriesSettings,
} from 'devextreme-react/chart';

import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { List, ItemDragging } from 'devextreme-react/list';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Popover, Position as PopoverPosition } from 'devextreme-react/popover';
import { Popup } from 'devextreme-react/popup';
import { Toolbar, Item as ToolbarItem } from 'devextreme-react/toolbar';

import './assets/dx.material.blue.light.compact.css';
import './assets/dx-fonts.css';
import './assets/dx-buttons.css';
import './assets/dx-datagrid.css';
import './assets/dx-list.css';
import './assets/dx-treelist.css';
import './assets/dx-htmleditor.css';
import './assets/dx-editors.css';
import './assets/dx-contextmenu.css';
import './assets/dx.omnia-scheme.custom.css';

import { Column, SummaryGroupItem, SummaryTotalItem } from 'devextreme/ui/data_grid';

import { Button } from 'devextreme-react/button';

//Import all Ls components
export * from './components';
export * from './helpers/renderModes';
export * from './helpers/wrappers';
export * from './helpers/htmlModifiers';
export * from './helpers/DevExtremeToODataQueryFilter';
export * from './helpers/hooks';
export * from './helpers/leaseWrapper';

export const defaultPageSizes = [7, 10, 12, 25, 50, 75];

export type LsBaseField = {
  dataField?: string;
  dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'icon';
  color?: string;
};
export type CustomProps = {
  /**
   * Defines the amount by which the numeric values will be divided.
   */
  divide?: 1 | 1000 | 1000000;
  columnBuilderTitle?: string;
  showCaption?: boolean;
  columnWidth?: number;
  format?: string;
  visible?: boolean;
};

export type ILsColumnProps = Column & GroupRenderModeProps & CustomProps;
export type LsdxElement = dxElement;
export type LsdxPieChart = dxPieChart;
export type LsdxBarChart = dxBarChart;
export type { SummaryGroupItem, SummaryTotalItem };
export {
  DxTreeList,
  DxColumn,
  DxSearchPanel,
  DxPaging,
  DxPager,
  DxSelection,
  DxScrolling,
  DxTemplate,
  DxRemoteOperations,
  DxButton,
  DxStateStoring,
  DxEditing,
  CustomStore as DxCustomStore,
  DataSource as DxDataSource,
  Lookup,
  DropDownOptions,
  List,
  ItemDragging,
  ArrayStore,
  ScrollView,
  Popover,
  PopoverPosition,
  DxColumnChooser,
  DxPosition,
  DxColumnChooserSelection,
  Popup,
  PivotGridDataSource,
  Button,
  DxToolbar,
  DxItem,
  Toolbar,
  ToolbarItem,
};

/*Pivot grid */
export type DxPivotArea = PivotGridArea;
export type DxPivotGridDataType = PivotGridDataType;

/*Barchart */
export { DxChart, DxSeriesTemplate, DxCommonSeriesSettings };

/* Tab & Tabpanel */
export type DxTabsIconPosition = TabsIconPosition;