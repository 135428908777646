import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';
import posed from 'react-pose';

const Wrapper = styled.div<{ wide: boolean }>`
    height: 100%;
    display: grid;
    grid-template-rows: ${px(80)} 1fr ${px(80)};
    grid-template-columns: ${(p: { wide: boolean }) =>
        p.wide
            ? `minmax(${px(80)}, auto) minmax(auto, ${px(1200)}) minmax(${px(80)}, auto)`
            : `minmax(${px(400)}, auto) minmax(auto, ${px(1200)}) minmax(${px(40)}, auto)`};
`;

const DeviceWrapper = styled(
    posed.div({
        desktop: {
            width: '1200px',
            height: '100%',
            applyAtStart: {
                border: 'none',
                overflow: 'hidden',
            },
            applyAtEnd: {
                overflow: 'initial',
            },
        },
        tablet: {
            width: '1024px',
            height: '768px',
            applyAtStart: {
                border: 'solid 1px #d8d8d8',
                overflow: 'auto',
            },
        },
        phone: {
            width: '375px',
            height: '667px',
            applyAtStart: {
                border: 'solid 1px #d8d8d8',
                overflow: 'auto',
            },
        },
    }),
)`
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    overflow: auto;
    position: relative;
    transform: translate(0);
`;

const styledComponents = { Wrapper, DeviceWrapper };
export default styledComponents;