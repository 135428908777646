import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Add from '@mui/icons-material/Add';
import FileUpload from '@mui/icons-material/FileUpload';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import * as _ from 'lodash';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Finance as DataAccess, oDataResponseStructured } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, DateUtils, FormHelper, FormMode, isMomentOpen, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import { MultiLineTextElement, TextElement, SelectElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import {
    Button, Icon, IconSet, IconSize, IconValue, Label, palette, Text,
    ElementLabel, UserRightsControl, RadioGroupField, IRadioGroupFieldItem, useLoading,
    LiasStepper
} from '@liasincontrol/ui-basics';
import { LsGrid, GridColumn, createSource, ContextMenu, TextField, LsTabs, TabType, LsModal } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { BudgetLineForm } from '../BudgetLineForm';
import { SelectBudgetJournalKind } from '../SelectBudgetJournalKind';
import Styled from './index.styled';
import { editStageLabels } from '..';
import { ImportBudgetLines } from './ImportBudgetLines';
import { Grid as MuiGrid, Box as MuiBox, Paper as MuiPaper } from '@mui/material';

const CardTab = {
    DETAILS: 0,
    BOEKINGEN: 1,
    VOORTGANG: 2,
};

const CardTabs: TabType[] = Object.keys(CardTab).map(key => ({ id: CardTab[key], text: key }));

type Props = {
    budgetJournal: Domain.Finance.BudgetJournalDetail,
    measureMoments: Domain.Shared.MeasureMoment[],
    users: Domain.Shared.User[],
    disableSaveButton: boolean,
    userIdentity: UserIdentity,
    isReadonly: boolean,
    formMode: FormMode,
    yearsAhead: number,
    budgetJournalGroups: Domain.Finance.BudgetJournalGroup[],
    onSave?: (budgetJournal: Domain.Finance.BudgetJournalDetail, closeModal: boolean) => Promise<void>,
    onCancel: () => void,
    onError: (exception: any) => void,
    onRefresh?: () => void,
};

type StepType = {
    name: string,
    disabled: boolean,
    isLastStep: boolean,
    isCurrentStep: boolean,
    users: Domain.Shared.RightUser[]
}

const standardColumns: GridColumn<Domain.Finance.BudgetLineListItem>[] = [
    {
        name: 'isStructural',
        hidden: false,
        width: '5%',
        align: 'center',
        allowSorting: false,
        renderCustom: (item) => (item.data?.isStructural ? <CheckBoxIcon /> : null)
    },
    {
        name: 'isReserve',
        hidden: false,
        width: '5%',
        align: 'center',
        allowSorting: false,
        renderCustom: (item) => (item.data?.isReserve ? <CheckBoxIcon /> : null)
    },
    {
        name: 'accountClassCaption',
        hidden: false,
        width: '5%',
        allowSorting: false
    }
];

/**
 * Represents a UI component that renders the modal for managing a budgetjournal item.
 */
export const BudgetJournalForm: React.FC<Props> = (props) => {
    const [selectedCardTab, setSelectedCardTab] = useState<number>(CardTab.DETAILS);
    const [form, setForm] = useState<Domain.Finance.BudgetJournalDetail>(props.budgetJournal);
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [budgetColumns, setBudgetColumns] = useState<GridColumn<Domain.Finance.BudgetLineListItem>[]>([]);
    const [budgetLineModalState, setBudgetLineModalState] = useState<{ isOpen: boolean, budgetLineId?: string, budgetJournalId?: string }>({ isOpen: false });
    const [lastRefreshLines, setLastRefreshLines] = useState<number>();

    const [actionInProgress, setActionInProgress] = useState<boolean>();
    const [journalKindDialogOpen, setJournalKindDialogOpen] = useState<boolean>(false);

    const measureMomentOptions = props.measureMoments?.filter((moment) => moment.baseYear === props.budgetJournal.baseYear && isMomentOpen(moment.status));
    // Years ahead started with busgetjournal's baseyear
    const baseYears: number[] = useMemo(() => Array.from({ length: (props.yearsAhead || 0) + 1 }, (_, i) => (props.budgetJournal.baseYear || 0) + i), [props.budgetJournal.baseYear]);
    const [budgetAggregatesColumns, setBudgetAggregatesColumns] = useState<GridColumn<Domain.Finance.BudgetJournalSummaryValue>[]>([]);
    const [budgetAggregatesData, setBudgetAggregatesData] = useState<Domain.Finance.BudgetJournalSummaryValue[]>();

    const [startWorkflowDialogOpen, setStartWorkflowDialogOpen] = useState(false);
    const [changeWorkflowConfirm, setChangeWorkflowConfirm] = useState<{ visible: boolean; action?: 'change' | 'reset', value?: string }>({ visible: false });
    const [approveTaskConfirm, setApproveTaskConfirm] = useState(false);
    const [sendBackConfirm, setSendBackConfirm] = useState(false);
    const [workflowSteps, setWorkflowSteps] = useState<Domain.Finance.BudgetJournalKindWorkflowStep[]>([]);
    const [newStepDirection, setNewStepDirection] = useState<string>(null);
    const [showChangeStepModal, setShowChangeStepModal] = useState(false);
    const [budgetToDelete, setBudgetToDelete] = useState<{ modalVisible: boolean, item: { budgetJournalId: string, id: string } }>({ modalVisible: false, item: null });

    const [importModal, setImportModal] = useState<boolean>(false);

    const isFinalState = useMemo(() => {
        return (form ? form.editStage : props.budgetJournal.editStage) === Domain.Finance.EditStage.Final;
    }, [props.budgetJournal, form]);

    const budgetLinesDataSource = useMemo(() => {
        if (!props.budgetJournal.id) {
            return;
        }

        return createSource<Domain.Finance.BudgetLineListItem>({
            keyExpr: 'id',
            paginate: true,
            dataSourceMode: 'custom',
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: (query) => DataAccess.BudgetLineDataAccessor.getAll(props.budgetJournal.id, query),
        });

    }, [props.budgetJournal, lastRefreshLines, isFinalState]);

    const arrayStages = useMemo(() => (Array.from(editStageLabels).map(([key, value]) => ({ label: value, key: key }))), [editStageLabels]);

    const getBudgetJournalKind = (budgetJournalKindId: string) => {
        DataAccess.BudgetJournalKindDataAccessor.get(budgetJournalKindId)
            .then((response) => {
                const kind = response.data
                if (kind) {
                    setForm((prev) => ({
                        ...prev,
                        budgetJournalKindName: kind.name
                    }));
                }
            });
    };

    const fetchBudgetJournal = (budgetJournalId: string) => {
        DataAccess.BudgetJournalDataAccessor.get(budgetJournalId).then((response) => {
            setForm((form) => ({
                ...form,
                editStage: response.data.editStage,
                workflowStep: response.data.workflowStep,
            }));
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    const fetchBudgetJournalWorkflow = (budgetJournalKindId: string) => {
        DataAccess.BudgetJournalWorkflowDataAccessor.getWorkflowSteps(budgetJournalKindId).then((response) => {
            const sortedStates = response.data['value'].sort((a, b) => a.order - b.order);
            setWorkflowSteps(sortedStates);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    useEffect(() => {
        if (!props.budgetJournal || !props.budgetJournal.id || !props.budgetJournal.budgetJournalKindId) {
            return;
        }
        if (props.budgetJournal.budgetJournalKindId) {
            getBudgetJournalKind(props.budgetJournal.budgetJournalKindId);
            fetchBudgetJournalWorkflow(props.budgetJournal.budgetJournalKindId);
        }
    }, [props.budgetJournal]);

    useEffect(() => {
        if (!props.budgetJournal.id) {
            return;
        }

        if (selectedCardTab === CardTab.BOEKINGEN) {
            DataAccess.BudgetLineDataAccessor.getAll(props.budgetJournal.id, '?$count=true&$top=1&$skip=0')
                .then((response) => {
                    const columns: GridColumn<Domain.Finance.BudgetLineListItem>[] = response.data.columns.map(c => {
                        const standard = standardColumns.find(col => col.name === c.name);
                        if (standard) {
                            return {
                                ...standard,
                                title: c.title
                            }
                        }

                        if (c.name.startsWith('year')) {
                            return {
                                id: c.id,
                                name: c.name,
                                title: c.title,
                                hidden: false,
                                width: 100,
                                align: 'right',
                                allowSorting: false,
                                formatter: 'integer',
                                calculateDisplayValue: (item) => item.hasOwnProperty(c.name) ? item[c.name] : 0,
                            } as GridColumn<Domain.Finance.BudgetLineListItem>;
                        }

                        return {
                            id: c.id,
                            name: c.name,
                            title: c.title,
                            hidden: false,
                        };
                    })
                        ?.filter(x => !_.isEmpty(x))
                        ?.concat({
                            name: 'id',
                            title: '',
                            width: '5%',
                            type: 'buttons',
                            align: 'right',
                            renderCustom: ({ data }) => {
                                return !(props.isReadonly || isFinalState)
                                    ? <ContextMenu<Domain.Finance.BudgetLineListItem>
                                        item={data}
                                        keyExpr='id'
                                        actions={[
                                            {
                                                action: (item: Domain.Finance.BudgetLineListItem) => setBudgetToDelete({ modalVisible: true, item: { budgetJournalId: props.budgetJournal.id, id: item.id } }),
                                                displayName: 'Verwijderen',
                                                ariaLabel: `Verwijderen boeking`,
                                                actionName: `delete-line-${data.id}`
                                            },
                                            {
                                                action: (item: Domain.Finance.BudgetLineListItem) => onEditBudgetLine(props.budgetJournal.id, item.id),
                                                displayName: 'Bewerken',
                                                ariaLabel: `Bewerk boeking`,
                                                actionName: `edit-line-${data.id}`
                                            }
                                        ]}
                                    />
                                    : null;
                            },
                        });

                    setBudgetColumns([...columns]);
                });
        } else if (selectedCardTab === CardTab.DETAILS) {
            DataAccess.BudgetJournalDataAccessor.getSummary(props.budgetJournal.id)
                .then((data: oDataResponseStructured<Domain.Finance.BudgetJournalSummaryValue>) => {
                    const columns: GridColumn<Domain.Finance.BudgetJournalSummaryValue>[] = data.columns.map((c) => {
                        const column = { id: c.id, name: c.name, title: c.title };
                        if (c.name !== "accountClassCaption")
                            return { ...column, align: 'right', formatter: 'integer' };
                        return column;
                    });
                    setBudgetAggregatesColumns(columns);
                    setBudgetAggregatesData(data.values);
                });
        }

    }, [selectedCardTab, props.budgetJournal])

    const onSaveBudgetLine = (budgetJournalId: string, budgetLine: Domain.Finance.BudgetLine) => {
        setActionInProgress(true);
        const data = { ...budgetLine, amounts: budgetLine.amounts.filter((bl) => bl.amount !== 0) };
        if (!budgetLine.id) {
            createBudgetLine(budgetJournalId, data);
        } else {
            updateBudgetLine(budgetJournalId, data);
        }
    };

    const createBudgetLine = (budgetJournalId: string, budgetLine: Domain.Finance.BudgetLine) => {
        DataAccess.BudgetLineDataAccessor.create(budgetJournalId, budgetLine).then(() => {
            setLastRefreshLines(Date.now());
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.CreateLineOnBudgetJournalFinalStage)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.CreateLineOnBudgetJournalFinalStage] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setBudgetLineModalState({ isOpen: false });
            setActionInProgress(false);
        });
    };

    const updateBudgetLine = (budgetJournalId: string, budgetLine: Domain.Finance.BudgetLine) => {
        DataAccess.BudgetLineDataAccessor.update(budgetJournalId, budgetLine).then(() => {
            setLastRefreshLines(Date.now());
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.EditLineOnBudgetJournalFinalStage)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.EditLineOnBudgetJournalFinalStage] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setBudgetLineModalState({ isOpen: false });
            setActionInProgress(false);
        });
    };

    const onDeleteBudgetLine = (budgetJournalId: string, budgetLineId: string) => {
        DataAccess.BudgetLineDataAccessor.delete(budgetJournalId, budgetLineId).then(() => {
            setLastRefreshLines(Date.now());
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DeleteLineOnBudgetJournalFinalStage)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DeleteLineOnBudgetJournalFinalStage] });
            } else {
                setError(errorInfo);
            }
        });
    };

    const onEditBudgetLine = (budgetJournalId: string, budgetLineId: string) => {
        setBudgetLineModalState({ isOpen: true, budgetJournalId, budgetLineId });
        setError(null);
    };

    const onSave = (closeModal = true) => {
        const errors = validate(form, validationErrors.errors);

        if (errors.hasErrors) {
            setValidationErrors(errors);
            return;
        }
        props.onSave(form, closeModal);
    };

    const onChange = (value: string, fieldName: string) => {
        const data: Domain.Finance.BudgetJournalDetail = { ...form };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm(data);
        setFormHasChanges(true);

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        setValidationErrors(temporaryValidationError);
    };

    // #region workflow
    const [workflowLoading, setWorkflowLoading] = useState(false);
    const onStartWorkflow = () => {
        let promise: Promise<any> = null;
        tasksLoading.start();
        if (formHasChanges && !validationErrors.hasErrors) {
            promise = props.onSave(form, false).then(() => {
                setFormHasChanges(false);
                startWorkflow();
            });
        } else {
            promise = startWorkflow();
        }

        promise.finally(() => {
            tasksLoading.stop();
        });
    };

    const startWorkflow = () => {
        setWorkflowLoading(true);
        return DataAccess.BudgetJournalWorkflowDataAccessor.start(props.budgetJournal.id).then(() => {
            setRefreshTask(Date.now());
            fetchBudgetJournal(props.budgetJournal.id);
            props.onRefresh();
        }).catch((exception) => {
            //37628590 =< cant start workflow
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, exception));
        }).finally(() => {
            setStartWorkflowDialogOpen(false);
            setWorkflowLoading(false);
        });
    };
    // #endregion workflow

    const handleConfirmJournalKind = (action: 'change' | 'reset', value?: string) => {
        setChangeWorkflowConfirm({ visible: true, action, value });
    };

    const onConfirmKindChange = () => {
        switch (changeWorkflowConfirm.action) {
            case 'change':
                setJournalKindDialogOpen(true);
                break;
            case 'reset':
                deleteJournalKind(changeWorkflowConfirm.value);
                break;
        }
    };

    const deleteJournalKind = (value) => {
        setChangeWorkflowConfirm({ visible: false });
        if (value === '') {
            setForm({
                ...form,
                budgetJournalKindId: null,
                budgetJournalKindName: null,
                editStage: Domain.Finance.EditStage.Draft,
            });
        }
    };

    // #region tasks
    const [tasks, setTasks] = useState<Domain.Finance.Task[]>([]);
    const [refreshTask, setRefreshTask] = useState(Date.now());
    const tasksLoading = useLoading();

    const currentUserActiveTask = useMemo(() => {
        return tasks.find(task => task.userId === props.userIdentity.profile.sub && task.taskIsActive === true && task.currentStateId === task.taskStateId)
    }, [tasks, props.userIdentity.profile.sub]);

    const maxInProgressOrder = useMemo(() => {
        const inProgressWorkflowStates = workflowSteps?.filter(state => state.category === Domain.Shared.WorkflowCategoryType.InProgress);
        return inProgressWorkflowStates ? _.maxBy(inProgressWorkflowStates || [], (item) => item.order)?.order : 0;
    }, [workflowSteps]);

    const isLastInProgressStep = useMemo(() => {
        if (!workflowSteps || !currentUserActiveTask) return false;
        const currentOrder = workflowSteps.find(workflowStep => workflowStep.id === currentUserActiveTask.taskStateId)?.order;
        return currentOrder >= maxInProgressOrder;
    }, [workflowSteps, maxInProgressOrder, currentUserActiveTask]);

    useEffect(() => {
        const params: DataAccess.GetTasksParams = {
            onlyActive: false,
            onlyCurrentUser: false,
            onlyNotCompleted: false,
            includeStateDetails: true,
            includeUserDetails: true,
            budgetJournalId: props.budgetJournal.id,
        };
        tasksLoading.start();
        DataAccess.TasksDataAccessor.getAll(params).then((response) => {
            setError(undefined);
            setTasks(response.data.value);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        }).finally(() => {
            tasksLoading.stop();
        });
    }, [refreshTask]);

    const userWorkflowSteps = useMemo<StepType[]>(() => {
        if (tasks.length > 0 && workflowSteps.length > 0) {
            const orderedSteps = workflowSteps.sort((s1, s2) => s1.order - s2.order);

            const result: StepType[] = orderedSteps.map((s, i) => {
                const stepTasks = tasks.filter(t => t.taskStateId === s.id);
                const stepEnabled = (s.order === 0 || (stepTasks.length && (stepTasks.every(t => t.taskIsCompleted) || stepTasks.some(t => t.taskIsActive))));
                return {
                    disabled: !stepEnabled,
                    name: s.name,
                    isCurrentStep: s.id === tasks[0].currentStateId,
                    isLastStep: s.category === Domain.Shared.WorkflowCategoryType.Done,
                    users: tasks.filter(t => t.taskStateId === s.id).map(t => ({
                        email: t.userEmail,
                        enabled: true,
                        id: t.userId,
                        name: t.userName,
                        frontIcon: {
                            icon: t.taskIsCompleted ? <TaskAltIcon /> : <CircleOutlinedIcon />,
                            color: t.taskIsCompleted ? palette.green : palette.grey2
                        },
                    }))
                }
            });
            return result;
        }
        return [];
    }, [tasks, workflowSteps]);

    const onApproveTask = () => {
        tasksLoading.start();
        DataAccess.BudgetJournalWorkflowDataAccessor.approve(props.budgetJournal.id, currentUserActiveTask.taskId, true).then(() => {
            setRefreshTask(Date.now());
            fetchBudgetJournal(props.budgetJournal.id);
            props.onRefresh();
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        }).finally(() => {
            tasksLoading.stop();
        });
    };

    const rejectTask = () => {
        tasksLoading.start();
        return DataAccess.BudgetJournalWorkflowDataAccessor.reject(props.budgetJournal.id, currentUserActiveTask.taskId, true).then(() => {
            setRefreshTask(Date.now());
            fetchBudgetJournal(props.budgetJournal.id);
            props.onRefresh();
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        }).finally(() => {
            setSendBackConfirm(false);
            tasksLoading.stop();
        });
    };

    const onSendBackInWorkflow = () => {
        let promise: Promise<any> = null;
        tasksLoading.start();
        if (formHasChanges && !validationErrors.hasErrors) {
            promise = props.onSave(form, false)
                .then(() => {
                    setFormHasChanges(false);
                    rejectTask();
                });
        } else {
            promise = rejectTask();
        }
        promise.finally(() => { tasksLoading.stop() });
    };

    const onReopenTask = () => {
        tasksLoading.start();
        DataAccess.BudgetJournalWorkflowDataAccessor.reopen(props.budgetJournal.id, currentUserActiveTask.taskId).then(() => {
            setRefreshTask(Date.now());
            fetchBudgetJournal(props.budgetJournal.id);
            props.onRefresh();
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        }).finally(() => {
            tasksLoading.stop();
        });
    };

    // #endregion tasks
    const changeStepChoices: IRadioGroupFieldItem[] = useMemo(() => {
        if (workflowSteps.length === 0 || !form.workflowStep) return [];
        const index = workflowSteps.findIndex(wfs => wfs.name === form.workflowStep);
        if (workflowSteps[index].category !== Domain.Shared.WorkflowCategoryType.InProgress) return [];

        const prevStep = workflowSteps[index - 1];
        const nextStep = workflowSteps[index + 1];

        // values intentionally set to 1 and 0 to match MoveBudgetJournalDirection enum
        const items: IRadioGroupFieldItem[] = [
            { value: '1', label: `Vorige workflowstap: ${prevStep?.name}` },
            { value: '0', label: `Volgende workflowstap: ${nextStep?.name}` },
        ];
        return items;
    }, [workflowSteps, form]);

    const onCancelChangeStep = () => {
        setShowChangeStepModal(false);
        setNewStepDirection(null);
    };

    const onChangeStep = () => {
        const dir: Domain.Finance.MoveBudgetJournalDirection = Number.parseInt(newStepDirection);
        tasksLoading.start();
        DataAccess.BudgetJournalWorkflowDataAccessor.moveBudgetJournalForwardOrBackward(form.id, true, dir).then(() => {
            setNewStepDirection(null);
            setRefreshTask(Date.now());
            props.onRefresh();
            fetchBudgetJournal(props.budgetJournal.id);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
        }).finally(() => {
            setShowChangeStepModal(false);
            tasksLoading.stop();
        });
    };

    const isStartWorkflowDisabled = props.isReadonly
        || !form.budgetJournalKindId
        || props.budgetJournal.budgetJournalKindId !== form.budgetJournalKindId
        || !form.measureMomentId;

    const renderStartWorkflowButton = (): JSX.Element => {
        return (<Button
            id="btn-start-workflow"
            btnbase="ghostbuttons"
            btntype="small_icon"
            disabled={isStartWorkflowDisabled}
            onClick={() => {
                setStartWorkflowDialogOpen(true);
                setError(null);
            }}
        >
            Activeer workflow
        </Button>);
    };

    const renderWorkflowStep = (workflowStep: string, isReadOnly = false): JSX.Element => {
        return <TextField
            id='txt-workflow-step'
            label='Workflowstap'
            disabled={isReadOnly}
            readOnly
            placeholder="Kies..."
            value={workflowStep}
            icon='copy'
            showClearButton={false}
            withoutFeedback={true}
            onButtonClick={() => {
                setShowChangeStepModal(true);
            }}
        />;
    };

    // #endregion JSX.Elements...

    const onImportingBudgetLinesSuccess = useCallback(() => {
        setImportModal(false);
        setLastRefreshLines(Date.now());
    }, []);

    return (<>
        <LsModal
            id={`modal-bidget-journal-${props.budgetJournal.id}`}
            toolbar={{
                enabled: true,
                detached: true,
                leftButtonText: 'Sluiten',
                rightButtonText: 'Opslaan',
                rightButtonDisabled: props.disableSaveButton || props.isReadonly || isFinalState || !formHasChanges || validationErrors.hasErrors,
                onLeftButtonClick: props.onCancel,
                onRightButtonClick: onSave,
            }}
        >
            <MuiBox sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* Upper Section - White Background */}
                <MuiBox sx={{ backgroundColor: palette.white, paddingX: 3, paddingTop: 3 }}>
                    {/* Top Section */}
                    <MuiGrid container spacing={2}>
                        <MuiGrid item xs={12} sx={{ display: 'flex' }}>
                            <Icon value={IconValue.Abacus} size={IconSize.small} iconSet={IconSet.default} />
                            <Styled.StyledLabel>Journaal</Styled.StyledLabel>
                        </MuiGrid>
                        <MuiGrid item xs={12} md={1}>
                            <TextElement
                                id='code-field'
                                label='Code'
                                editorSettings={{
                                    disabled: props.isReadonly || isFinalState,
                                    restrictions: { required: true, minLength: 1, maxLength: 50 },
                                    validationErrors: validationErrors.errors['code'],
                                    onChange: (value: string) => onChange(value, 'code'),
                                }}
                                value={form.code}
                            />
                        </MuiGrid>
                        <MuiGrid item xs={12} md={11}>
                            <TextElement
                                id='name-field'
                                label='Naam'
                                editorSettings={{
                                    disabled: props.isReadonly || isFinalState,
                                    restrictions: { required: true, minLength: 2, maxLength: 200 },
                                    validationErrors: validationErrors.errors['name'],
                                    onChange: (value: string) => onChange(value, 'name')
                                }}
                                value={form.name}
                            />
                        </MuiGrid>
                    </MuiGrid>
                </MuiBox>

                {/* Lower Section - Grey Background */}
                <MuiBox
                    sx={{
                        backgroundColor: palette.grey4,
                        paddingX: 3,
                        paddingTop: 1,
                        paddingBottom: 0,
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {/* Tab Section */}
                    <MuiBox sx={{ marginBottom: 0 }}>
                        <MuiGrid container spacing={2}>
                            <MuiGrid item xs={selectedCardTab === CardTab.DETAILS ? 8 : 12} md={selectedCardTab === CardTab.DETAILS ? 9 : 12} >
                                <LsTabs
                                    tabs={CardTabs}
                                    direction='horizontal'
                                    selectedTab={selectedCardTab}
                                    stylingMode="secondary"
                                    onItemClick={(e) => {
                                        if (e.itemData === selectedCardTab) return;
                                        setSelectedCardTab(e.itemData.id);
                                    }}
                                />
                            </MuiGrid>
                        </MuiGrid>
                    </MuiBox>

                    {/* Main Content Section */}
                    {selectedCardTab === CardTab.DETAILS &&
                        <MuiGrid container sx={{ flexGrow: 1, marginBottom: 0 }}>
                            {/* Left and Middle Rectangles in White Background */}
                            <MuiGrid item xs={8} md={9} sx={{ flexGrow: 1, }}>
                                <MuiPaper
                                    elevation={3}
                                    sx={{
                                        height: '100%',
                                        backgroundColor: palette.white,
                                        padding: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        borderRadius: 0,
                                    }}
                                >
                                    <MuiGrid container spacing={3} sx={{ flexGrow: 1 }}>
                                        <MuiGrid item xs={12} md={6}>
                                            <MultiLineTextElement
                                                id='description-field'
                                                rows={6}
                                                label='Omschrijving'
                                                key='description-key'
                                                editorSettings={{
                                                    disabled: props.isReadonly || isFinalState,
                                                    restrictions: {},
                                                    validationErrors: validationErrors.errors['description'],
                                                    onChange: (value: string) => onChange(value, 'description')
                                                }}
                                                value={form.description} />

                                            <MultiLineTextElement
                                                id='officialtext-field'
                                                rows={6}
                                                label='Ambtelijke toelichting'
                                                key='officialtext-key'
                                                editorSettings={{
                                                    disabled: props.isReadonly || isFinalState,
                                                    restrictions: {},
                                                    validationErrors: validationErrors.errors['officialText'],
                                                    onChange: (value: string) => onChange(value, 'officialText')
                                                }}
                                                value={form.officialText} />
                                            <MultiLineTextElement
                                                id='administrativetext-field'
                                                rows={6}
                                                label='Bestuurlijke toelichting'
                                                key='administrativetext-key'
                                                editorSettings={{
                                                    disabled: props.isReadonly || isFinalState,
                                                    restrictions: {},
                                                    validationErrors: validationErrors.errors['administrativeText'],
                                                    onChange: (value: string) => onChange(value, 'administrativeText')
                                                }}
                                                value={form.administrativeText} />
                                        </MuiGrid>
                                        <MuiGrid item xs={12} md={6}>
                                            <LiasStepper
                                                label='Status'
                                                steps={arrayStages}
                                                activeStep={arrayStages.findIndex(obj => obj.key === form.editStage)}
                                            />
                                            {props.budgetJournal.id && <>
                                                <Label text='Totalen' />
                                                <LsGrid
                                                    dataSource={budgetAggregatesData}
                                                    keyExpr='accountClassCaption'
                                                    columns={budgetAggregatesColumns}
                                                    boldedRowKeys={['Saldo']}
                                                    enableColumnChooser={false}
                                                    searching={false}
                                                    showRowLines={true}
                                                    showColumnLines={false}
                                                    showBorders={false}
                                                />
                                            </>}
                                        </MuiGrid>
                                    </MuiGrid>
                                </MuiPaper>
                            </MuiGrid>

                            {/* Right Rectangle - White Background */}
                            <MuiGrid item xs={4} md={3} sx={{ flexGrow: 1 }}>
                                <MuiPaper
                                    elevation={3}
                                    sx={{
                                        height: '100%',
                                        backgroundColor: palette.white,
                                        marginLeft: 3,
                                        padding: 3,
                                        borderBottom: 'none',
                                        borderRadius: 0
                                    }}
                                >
                                    <MuiGrid container>
                                        {form.editStage === Domain.Finance.EditStage.Workflow && currentUserActiveTask && currentUserActiveTask.taskIsActive &&
                                            <MuiGrid item paddingBottom={1.5} xs={12}>
                                                <ElementLabel className='mb-050'>Beoordeling</ElementLabel>
                                                <Styled.ButtonContainer>
                                                    <Button
                                                        id="btn-approve-task"
                                                        disabled={currentUserActiveTask.taskIsCompleted || tasksLoading.isLoading()}
                                                        btnbase="ghostbuttons"
                                                        btntype="medium_icon_green"
                                                        onClick={() => {
                                                            const remainingTasks = tasks.some(t => t.taskId !== currentUserActiveTask.taskId && t.taskIsCompleted === false && t.currentStateId === t.taskStateId);
                                                            if (remainingTasks)
                                                                onApproveTask();
                                                            else
                                                                setApproveTaskConfirm(true);
                                                        }}
                                                    >
                                                        {currentUserActiveTask.taskIsCompleted ? "Goedgekeurd" : "Goedkeuren"}
                                                    </Button>
                                                    {!currentUserActiveTask.taskIsCompleted
                                                        ? <Button
                                                            id="btn-rollback-workflow"
                                                            disabled={tasksLoading.isLoading()}
                                                            btnbase="ghostbuttons"
                                                            btntype="medium_icon_red"
                                                            onClick={() => setSendBackConfirm(true)}
                                                        >
                                                            Afkeuren
                                                        </Button>
                                                        : <Button
                                                            id="btn-start-workflow"
                                                            disabled={tasksLoading.isLoading()}
                                                            btnbase="ghostbuttons"
                                                            btntype="medium_icon"
                                                            onClick={onReopenTask}
                                                        >
                                                            Opnieuw
                                                        </Button>
                                                    }
                                                </Styled.ButtonContainer>
                                            </MuiGrid>
                                        }
                                        {form.editStage !== Domain.Finance.EditStage.Final &&
                                            <MuiGrid item xs={12} paddingBottom={3} borderBottom={'2px solid #e0e0e0'}>
                                                {form.editStage === Domain.Finance.EditStage.Draft && renderStartWorkflowButton()}
                                                {form.editStage === Domain.Finance.EditStage.Workflow && renderWorkflowStep(form.workflowStep, props.isReadonly)}
                                            </MuiGrid>
                                        }
                                        {form.baseYear && <MuiGrid item paddingY={1.5} paddingTop={{ lg: 3 }} xs={12} lg={5} paddingRight={{ lg: 1 }}>
                                            <TextElement
                                                id='baseYear-field'
                                                label='Basisjaar'
                                                key='baseYear-key'
                                                value={form.baseYear.toString()}
                                                helpText={{ text: 'Het basisjaar is achteraf niet te wijzigen' }}
                                                editorSettings={{
                                                    disabled: true,
                                                    withoutFeedback: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    onChange: (value: string) => onChange(value, 'code')
                                                }}
                                            />
                                        </MuiGrid>}
                                        <MuiGrid item paddingY={1.5} paddingTop={{ lg: 3 }} xs={12} lg={form.baseYear ? 7 : 12}>
                                            <SelectElement<Domain.Shared.MeasureMoment>
                                                id='measuremoment-field'
                                                label='Moment'
                                                key='measuremoment-key'
                                                displayExpr='name'
                                                optionItems={measureMomentOptions}
                                                value={measureMomentOptions.find(mm => mm.id === form.measureMomentId)}
                                                clearable={form.editStage === Domain.Finance.EditStage.Draft}
                                                searchable={false}
                                                editorSettings={{
                                                    disabled: props.isReadonly || isFinalState,
                                                    restrictions: { required: form.editStage === Domain.Finance.EditStage.Workflow },
                                                    validationErrors: validationErrors.errors['measureMomentId'],
                                                    onChange: (item) => onChange(item?.id || null, 'measureMomentId'),
                                                    withoutFeedback: true,
                                                }}
                                            />
                                        </MuiGrid>

                                        <MuiGrid item paddingY={1.5} xs={12}>
                                            <TextField
                                                id='budget-journal-kind'
                                                label='Journaalsoort'
                                                disabled={props.isReadonly || isFinalState}
                                                placeholder="Kies..."
                                                value={form.budgetJournalKindName}
                                                icon='copy'
                                                showClearButton={true}
                                                withoutFeedback={true}
                                                onButtonClick={() => {
                                                    if (form.editStage === Domain.Finance.EditStage.Workflow)
                                                        handleConfirmJournalKind('change');
                                                    else
                                                        setJournalKindDialogOpen(true);
                                                }}
                                                onChange={(value) => {
                                                    if (form.editStage === Domain.Finance.EditStage.Workflow)
                                                        handleConfirmJournalKind('reset', value);
                                                    else
                                                        deleteJournalKind(value);
                                                }}
                                            />
                                        </MuiGrid>

                                        <MuiGrid item paddingY={1.5} paddingBottom={3} xs={12} borderBottom={'2px solid #e0e0e0'}>
                                            <SelectElement<Domain.Finance.BudgetJournalGroup>
                                                id='budget-journal-group-field'
                                                label='Journaalgroep'
                                                key='budget-journal-group-field-key'
                                                displayExpr='name'
                                                optionItems={props.budgetJournalGroups}
                                                value={props.budgetJournalGroups?.find(bg => bg.id === form.budgetJournalGroupId)}
                                                clearable={form.editStage === Domain.Finance.EditStage.Draft}
                                                searchable={false}
                                                editorSettings={{
                                                    disabled: props.isReadonly || isFinalState,
                                                    restrictions: { required: form.editStage === Domain.Finance.EditStage.Workflow },
                                                    validationErrors: validationErrors.errors['budgetJournalGroupId'],
                                                    onChange: (item) => onChange(item?.id, 'budgetJournalGroupId'),
                                                    withoutFeedback: true,
                                                }}
                                            />
                                        </MuiGrid>

                                        {form.authorId && form.createdOn && <MuiGrid item paddingY={1.5} paddingTop={3} xs={12}>
                                            <TextElement
                                                id='createdby-field'
                                                label='Aangemaakt door'
                                                key='createdby-key'
                                                value={`${props.users.find(item => item.id === form.authorId)?.name}, ${DateUtils.formatDate(form.createdOn)}`}
                                                editorSettings={{
                                                    disabled: true,
                                                    restrictions: {},
                                                    validationErrors: [],
                                                    withoutFeedback: true,
                                                }}
                                            />
                                        </MuiGrid>}
                                    </MuiGrid>
                                </MuiPaper>
                            </MuiGrid>
                        </MuiGrid>
                    }
                    {selectedCardTab === CardTab.BOEKINGEN && props.budgetJournal && props.budgetJournal.id &&
                        <MuiGrid container sx={{
                            flexGrow: 1,
                            marginBottom: 0,
                            height: '100%',
                            backgroundColor: palette.white,
                            padding: 2,
                            alignContent: 'flex-start',
                        }} >
                            <MuiGrid item xs={12} >
                                {error && <Text danger value={error.message} />}
                                {form.editStage !== Domain.Finance.EditStage.Final &&
                                    <>
                                        <Button
                                            id="btn-add-new-transaction"
                                            btnbase="textbuttons"
                                            btntype="medium_icon"
                                            icon={<Add />}
                                            disabled={props.isReadonly}
                                            onClick={() => {
                                                setBudgetLineModalState({ isOpen: true });
                                                setError(null);
                                            }}
                                            style={{ marginRight: '1rem' }}
                                        >
                                            Nieuw
                                        </Button>
                                        <Button
                                            id='btn-import-budget-lines'
                                            btnbase='textbuttons'
                                            btntype='medium_icon'
                                            disabled={props.isReadonly}
                                            onClick={() => setImportModal(true)}
                                            icon={<FileUpload />}
                                        >
                                            Importeren
                                        </Button>
                                    </>
                                }
                            </MuiGrid>
                            <MuiGrid item xs={12}>
                                <LsGrid
                                    dataSource={budgetLinesDataSource}
                                    columns={budgetColumns}
                                    enableColumnChooser={false}
                                    noDataMessage={`Lijst is leeg, klik op "nieuw" een budgetwijziging toe te voegen`}
                                    paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                    showRowLines={true}
                                />

                                {budgetLineModalState.isOpen && <BudgetLineForm
                                    baseYear={props.budgetJournal.baseYear}
                                    baseYears={baseYears}
                                    budgetJournalId={budgetLineModalState.budgetJournalId}
                                    budgetLineId={budgetLineModalState.budgetLineId}
                                    disableSaveButton={actionInProgress}
                                    onError={props.onError}
                                    onSave={(budgetLine: Domain.Finance.BudgetLine) => {
                                        onSaveBudgetLine(props.budgetJournal.id, budgetLine);
                                    }}
                                    onCancel={() => setBudgetLineModalState({ isOpen: false })} />}
                            </MuiGrid>
                        </MuiGrid>
                    }
                    {selectedCardTab === CardTab.VOORTGANG &&
                        <MuiGrid container sx={{
                            flexGrow: 1,
                            marginBottom: 0,
                            height: '100%',
                            backgroundColor: palette.white,
                            padding: 2,
                            alignContent: 'flex-start',
                        }} >
                            <MuiGrid item xs={12}>
                                {userWorkflowSteps.map((step) => {
                                    return (<UserRightsControl
                                        users={step.users}
                                        disabled={step.disabled}
                                        actionsEnabled={false}
                                        showListHeading={false}
                                        workflowTemplateStateName={step.name}
                                        isCurrentStep={step.isCurrentStep}
                                        isLastStep={step.isLastStep}
                                    />)
                                })}
                            </MuiGrid>
                        </MuiGrid>
                    }
                </MuiBox>
            </MuiBox>
        </LsModal>

        {journalKindDialogOpen && <SelectBudgetJournalKind
            onCancel={() => { setChangeWorkflowConfirm({ visible: false }); setJournalKindDialogOpen(false); }}
            onError={props.onError}
            baseYear={form.baseYear}
            onKindSelected={(budgetJournalKind: Domain.Finance.BudgetJournalKind) => {
                setForm({
                    ...form,
                    budgetJournalKindId: budgetJournalKind.id,
                    budgetJournalKindName: budgetJournalKind.name,
                    editStage: Domain.Finance.EditStage.Draft,
                });
                setFormHasChanges(true);
                setJournalKindDialogOpen(false);
                setChangeWorkflowConfirm({ visible: false });
            }}
        />
        }
        {startWorkflowDialogOpen && <LsModal
            id='modal-confirm-start-workflow'
            title='Activeer workflow'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: () => setStartWorkflowDialogOpen(false),
                rightButtonText: 'BEVESTIGEN',
                rightButtonDisabled: workflowLoading || tasksLoading.isLoading(),
                onRightButtonClick: onStartWorkflow,
            }}
        >
            <Text value='U staat op het punt om de workflow te activeren. Wanneer u de workflow activeert ontvangen de gekoppelde gebruikers hun taken.' />
        </LsModal>
        }
        {changeWorkflowConfirm.visible && <LsModal
            id='modal-confirm-change-kind'
            title={changeWorkflowConfirm.action === 'change' ? 'Journaalsoort aanpassen' : 'Journaalsoort wissen'}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: () => setChangeWorkflowConfirm({ visible: false }),
                rightButtonText: 'BEVESTIGEN',
                onRightButtonClick: onConfirmKindChange,
            }}
        >
            <Text value='Weet u zeker dat u de journaalsoort wilt aanpassen? Indien u doorgaat, worden alle taken verwijderd en gaat het journaal terug naar concept.' />
        </LsModal>
        }
        {approveTaskConfirm && <LsModal
            id='modal-approve-task'
            title='Doorzetten journaal'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: () => setApproveTaskConfirm(false),
                rightButtonText: 'BEVESTIGEN',
                rightButtonDisabled: tasksLoading.isLoading(),
                onRightButtonClick: () => {
                    onApproveTask();
                    setApproveTaskConfirm(false);
                },
            }}
        >
            <Text value={isLastInProgressStep
                ? 'Na het goedkeuren bereikt het journaal de status gereed. Het journaal kan dan niet meer worden gewijzigd. Wilt u doorgaan?'
                : 'U bent de laatste om het journaal in de huidige workflowstap goed te keuren. Na het goedkeuren zal het journaal direct doorgezet worden naar de volgende stap. Wilt u doorgaan?'
            } />
        </LsModal>
        }
        {sendBackConfirm && <LsModal
            id='modal-send-back'
            title='Afkeuren journaal'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: () => setSendBackConfirm(false),
                rightButtonText: 'BEVESTIGEN',
                rightButtonDisabled: tasksLoading.isLoading(),
                onRightButtonClick: () => onSendBackInWorkflow(),
            }}
        >
            <Text value='Weet u zeker dat u het journaal wilt afkeuren?' />
        </LsModal>
        }
        {showChangeStepModal && <LsModal
            id='modal-change-step'
            title='Status wijzigen'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: onCancelChangeStep,
                rightButtonDisabled: !newStepDirection || tasksLoading.isLoading(),
                rightButtonText: 'Opslaan',
                onRightButtonClick: onChangeStep
            }}
        >
            <Text value='U staat op het punt om de workflow status te wijzigen. Wanneer u het item terug plaatst, worden alle taken uit de vorige workflowstatus opnieuw geactiveerd. Wanneer u het item vooruit plaatst worden alle huidige taken afgesloten en wordt de volgende workflow stap actief.' />
            <Styled.ChoicesContainer>
                <RadioGroupField
                    id='confirm-advance-options'
                    label='Nieuwe workflowstatus'
                    alignment='vertical'
                    items={changeStepChoices ?? []}
                    value={newStepDirection}
                    onChange={setNewStepDirection}
                />
            </Styled.ChoicesContainer>
        </LsModal>
        }
        {budgetToDelete.modalVisible && <LsModal
            id="modal-delete-budget-line"
            title="Mutatie verwijderen"
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: () => setBudgetToDelete({ modalVisible: false, item: null }),
                rightButtonText: 'Verwijderen',
                onRightButtonClick: () => {
                    onDeleteBudgetLine(budgetToDelete.item.budgetJournalId, budgetToDelete.item.id);
                    setBudgetToDelete({ modalVisible: false, item: null });
                },
            }}
        >
            <Text value="Weet u zeker dat u de mutatie wilt verwijderen?" />
        </LsModal>
        }
        {importModal && <ImportBudgetLines
            budgetJournalId={props.budgetJournal.id}
            onImportSuccess={onImportingBudgetLinesSuccess}
            onImportFailure={setError}
            onModalClose={() => setImportModal(false)}
        />
        }
    </>);
};

const validate = (form: Domain.Finance.BudgetJournal, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 200, stringType: Domain.Shared.StringType.SingleLine }),
    'code': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine })
};
