import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Bar, Button, ErrorOverlay, Heading2, PageTitle, ResetZIndex, Section, Wrapper, WrapperContent, Text, IDataItemProps } from '@liasincontrol/ui-basics';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ContextMenu, createSource, GridColumn, LsGrid, LsModal } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Studio } from '@liasincontrol/data-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, MeasureMomentsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { CreateHierarchyForm } from '../CreateHierarchyForm';
import { FilterOptions, HierarchyListFilter } from './HierarchyListFilter';

/**
 * Defines the props of the Studio hierarchy list component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders a list of Studio hierarchies.
 */
const StudioHierarchiesList: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [deleteModal, setDeleteModal] = useState<{ id: string, visible: boolean, isRemoving: boolean }>({ id: undefined, visible: false, isRemoving: false });
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [showTimeoutMessage, setShowTimeoutMessage] = useState<boolean>(false);
    const [isImportDialogSaveButtonActive, setIsImportDialogSaveButtonActive] = useState<boolean>(true);

    const canDelete = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_StudioHierarchies, ActionType.Delete);
    const canCreate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_StudioHierarchies, ActionType.Create);

    const availableColumns: GridColumn<Domain.Studio.HierarchyListItem>[] = getColumnConfiguration(
        canDelete ? (data: Domain.Studio.HierarchyListItem) => setDeleteModal({ id: data.id, visible: true, isRemoving: false }) : null,
    );

    const navigate = useNavigate();

    const [filter, setFilter] = useState<FilterOptions>();

    const measureMomentStatuses = useMemo<IDataItemProps<string>[]>(() => {
        if (props.elementdefinitions?.status !== AjaxRequestStatus.Done) return [];

        const items = Object.values(props.elementdefinitions.items)
            .find(ed => ed.systemId === Domain.SystemElementDefinitions.Performance.MeasureMoment)
            .fields.find(f => f.systemId === Domain.SystemFieldDefinitions.Performance.MeasureMomentStatus)
            .optionItems.filter(item => item.name !== 'Verwijderd').map(item => ({ label: item.name, value: item.id }));
        return items;
    }, [props.elementdefinitions]);

    const customDataSource = useMemo(() => {
        const cleanedFilter = Object.entries(filter || {})
            .filter(([_, v]) => v === 0 || v)
            .map(([k, v]) => getColumnFilter(k, v));

        return createSource({
            keyExpr: "id",
            paginate: true,
            filter: cleanedFilter,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: async (query) => Studio.HierarchyDataAccessor.getV2(query)
        });
    }, [lastRefresh, filter]);

    // #region new hierarchy create
    const [showCreateHierarchyDialog, setShowCreateHierarchyDialog] = useState<{ visible: boolean, data?: any }>({ visible: false });
    const [usedHierarchies, setUsedHierarchies] = useState([]);

    useEffect(() => {
        Studio.HierarchyDataAccessor.getUsedHierarchies().then((response) => {
            setUsedHierarchies(response.data || []);
        });
    }, []);

    if (!props.modules) {
        props.fetchModules();
        return;
    }

    if (!props.measureMoments || props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return;
    }

    if (!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return;
    }
    if (!props.elementdefinitions || props.elementdefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Performance]);
        return;
    }

    const onCreateHierarchy = (data: Record<string, any>) => {
        let promise: Promise<any> = null;
        if (data['importData']) {
            promise = Studio.HierarchyDataAccessor.importHierarchy(data.hierarchydefinitionId, data.sourceMeasureMomentId, data.measureMomentId);
        } else {
            promise = Studio.HierarchyDataAccessor.createEmptyHierarchy(data.measureMomentId, data.hierarchydefinitionId);
        }
        promise.then((response) => {
            setIsImportDialogSaveButtonActive(false);
            setShowCreateHierarchyDialog((prev) => ({ ...prev, visible: false, data: undefined }));
            navigate(`../hierarchy/${data.hierarchydefinitionId}/moment/${data.measureMomentId}/${response.data}`, { relative: 'route' });
        }).catch((err) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                setShowTimeoutMessage(true);
            } else {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            }
        }).finally(() => setIsImportDialogSaveButtonActive(true));
    };
    // #endregion

    const onDelete = () => {
        setDeleteModal((prev) => ({ ...prev, isRemoving: true }));
        Studio.HierarchyDataAccessor.deleteHierarchy(deleteModal.id)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DeleteOnBudgetJournalFinalStage)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DeleteOnBudgetJournalFinalStage] });
                } else {
                    setError(errorInfo);
                }
            });
        setDeleteModal({ id: undefined, visible: false, isRemoving: false });
    };

    const rowClick = (row: Domain.Studio.HierarchyListItem) => {
        navigate(`../hierarchy/${row.definitionId}/moment/${row.momentId}/${row.id}`, { relative: 'route' });
    };

    const onFilterChanged = (filter) => {
        setFilter(filter);
    };

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Hiërarchieën</Heading2>
                </PageTitle>
                {canCreate &&
                    <Bar look="toolbar">
                        <Bar start>
                            <Button
                                id='btn-create-hierarchy'
                                btnbase='textbuttons'
                                btntype='medium_icon'
                                icon={<AddIcon />}
                                onClick={() => {
                                    setShowCreateHierarchyDialog((prev) => ({ ...prev, visible: true }));
                                }}
                            >
                                Nieuw
                            </Button>
                        </Bar>
                    </Bar>
                }
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <ResetZIndex>
                        <Section look='white'>
                            <HierarchyListFilter
                                measureMoments={props.measureMoments?.items}
                                measureMomentStatuses={measureMomentStatuses}
                                hierarchyDefinitions={Object.values(props.hierarchyDefinitions?.items || {})}
                                onFilterChanged={onFilterChanged}
                            />
                            <LsGrid
                                showRowLines={true}
                                dataSource={customDataSource}
                                columns={availableColumns}
                                onClickRow={rowClick}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            />
                        </Section>
                    </ResetZIndex>
                </ErrorOverlay>
            </WrapperContent>
            {showCreateHierarchyDialog.visible && <CreateHierarchyForm
                hierarchyDefinitions={props.hierarchyDefinitions?.items}
                selectedHierarchyDefinitionId={filter?.definitionId}
                selectedMomentId={filter?.momentId}
                measureMoments={props.measureMoments?.items}
                usedHierarchies={usedHierarchies}
                isImportDialogSaveButtonActive={isImportDialogSaveButtonActive}
                onSave={onCreateHierarchy}
                onCancel={() => { setShowCreateHierarchyDialog((prev) => ({ ...prev, visible: false, data: undefined })); }}
            />}
            {deleteModal.visible &&
                <LsModal
                    id={`modal-delete-hierarchy-${deleteModal.id}`}
                    title="Hiërarchie verwijderen"
                    toolbar={{
                        enabled: true,
                        leftButtonText: 'Annuleren',
                        leftButtonDisabled: deleteModal.isRemoving,
                        onLeftButtonClick: () => setDeleteModal({ id: undefined, visible: false, isRemoving: false }),
                        rightButtonText: 'Verwijderen',
                        rightButtonDisabled: deleteModal.isRemoving,
                        onRightButtonClick: onDelete,
                    }}
                >
                    <Text value="Weet u zeker dat u de hiërarchie wilt verwijderen? Databronnen die gebruik maken van deze hiërarchie kunnen niet meer ververst worden. Deze actie kan niet meer ongedaan worden gemaakt." />
                </LsModal>
            }
            {showTimeoutMessage &&
                <LsModal
                    id='modal-timeout'
                    title='Kopie maken'
                    toolbar={{
                        enabled: true,
                        rightButtonText: 'Sluiten',
                        onRightButtonClick: () => {
                            setShowCreateHierarchyDialog((prev) => ({ ...prev, visible: false, data: undefined }));
                            setShowTimeoutMessage(false);
                        },
                    }}
                >
                    <Text value='Kopieren duurt wat langer dan verwacht.' />
                </LsModal>
            }
        </Wrapper >
    );
};

const getColumnFilter = (column, filterValue) => {
    switch (column) {
        case 'momentId':
        case 'definitionId':
        case 'momentStateId':
            return { [column]: { eq: { type: 'guid', value: filterValue } } };
        default:
            return { [column]: filterValue };
    }
};

const getColumnConfiguration = (onDelete: (data: Domain.Studio.HierarchyListItem) => void): GridColumn<Domain.Studio.HierarchyListItem>[] => {
    return [
        {
            name: 'name',
            title: 'Hiërarchie',
            width: '50%',
        },
        {
            name: 'momentName',
            title: 'Moment',
            calculateSortValue: 'momentOrderIndex',
            width: '18%',
        },
        {
            name: 'momentYear',
            title: 'Basisjaar',
            width: '16%',
            align: 'left',
        },
        {
            name: 'momentStateName',
            title: 'Status',
            width: '16%',
            calculateDisplayValue: (rowData: Domain.Studio.HierarchyListItem) => rowData.momentStateName === 'Open' ? 'Open' : 'Gesloten',
            calculateSortValue: (rowData: Domain.Studio.HierarchyListItem) => rowData.momentStateName === 'Open' ? 'Open' : 'Gesloten',
        },
        {
            align: 'right',
            type: 'buttons',
            name: 'id',
            title: '',
            renderCustom: ({ data }) =>
            (onDelete &&
                <ContextMenu<Domain.Studio.HierarchyListItem>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            ariaLabel: 'Verwijder hierarchie',
                            actionName: `delete-${data.id}`,
                            displayName: 'Verwijderen',
                            action: onDelete,
                        }
                    ]}
                />)
        }
    ];
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        measureMoments: state.measuremoments,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
        elementdefinitions: state.elementdefinitions[ActionSource.Performance],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
        //we need this for the closed and open measure moment state ids
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(StudioHierarchiesList);
export { Component as index };
