import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    onLeftButtonClick: () => void,
    onRightButtonClick: () => void,
    disabled: boolean
};

/**
 * Confirm dialog shown when closing a publication
 */
export const PublicationCloseDialog: React.FC<Props> = (props) => {
    return (
        <LsModal
            id='modal-close-publication'
            title='Publicatie afsluiten'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                rightButtonText: 'Afsluiten',
                onLeftButtonClick: props.onLeftButtonClick,
                onRightButtonClick: props.onRightButtonClick,
                leftButtonDisabled: props.disabled,
                rightButtonDisabled: props.disabled,
            }}
        >
            <Text value='Weet u zeker dat u de publicatie wilt afsluiten? De publicatie kan daarna niet meer aangepast worden.' />
        </LsModal>
    );
};
