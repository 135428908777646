import React, { useMemo, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, IconSize, palette, SVGIcon } from '@liasincontrol/ui-basics';
import { ValidationErrorData, ValueType, TextValidator, FormHelper } from '@liasincontrol/core-service';
import { TextElement, ColorPickerElement, IconSelectElement } from '@liasincontrol/ui-elements';
import Styled from '../index.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    optionItem: Domain.Shared.FieldDefinitionOptionItem,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    onSave: (optionItem: Domain.Shared.FieldDefinitionOptionItem) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the field definition details form.
 */
export const OptionItemForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<Domain.Shared.FieldDefinitionOptionItem>(props.optionItem);
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    const availableIcons = useMemo(() => {
        const icons = Object.values(props.icons);
        if (props.optionItem?.icon) {
            const idx = icons.findIndex(i => i.id === props.optionItem.icon);
            return [icons[idx], ...icons.slice(0, idx), ...icons.slice(idx + 1)];
        }
        return icons;
    }, [props.icons, props.optionItem.icon]);

    if (!form) {
        return null;
    }

    const onChange = (value: string | number, fieldName: string) => {
        const data: Domain.Shared.FieldDefinitionOptionItem = { ...form };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm(data);
        setFormHasChanges(true);
        const validationResult = validate(data, validationErrors.errors);
        setValidationErrors(validationResult);
    };

    const onSave = () => {
        const data: Domain.Shared.FieldDefinitionOptionItem = { ...form };
        if (!data.icon) { data.icon = Domain.Shared.DefaultIconId; }
        props.onSave(data);
    };

    return (
        <LsModal
            id={`modal-add-optionitem-${props.optionItem.id}`}
            title='Waarde toevoegen'
            width={768}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Opslaan',
                onRightButtonClick: onSave,
                rightButtonDisabled: !formHasChanges || validationErrors.hasErrors,
            }}
        >
            <MuiGrid container
                spacing={{ xs: 2 }}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid key='field-name' item xs={1}>
                    <AutoFocus>
                        <TextElement
                            id='name-field'
                            label='Naam'
                            editorSettings={{
                                disabled: false,
                                validationErrors: validationErrors.errors['name'],
                                restrictions: { required: true, minLength: 2, maxLength: 50 },
                                onChange: (value: string) => onChange(value, 'name'),
                            }}
                            value={form.name}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid key='field-color' item xs={1}>
                    <ColorPickerElement
                        id='color-field'
                        title='Icoon kleur'
                        editorSettings={{
                            disabled: false,
                            validationErrors: [],
                            restrictions: { required: true },
                            onChange: (value: string) => {
                                const val = parseInt(value.replace('#', ''), 16);
                                onChange(val, 'color');
                            },
                        }}
                        size='small'
                        defaultColor={palette.grey1}
                        value={form.color !== undefined ? `#${form.color?.toString(16).padStart(6, '0')}` : palette.grey1}
                    />
                </MuiGrid>
                <MuiGrid key='field-icon' item xs={1}>
                    <IconSelectElement
                        id='icon-field'
                        label='Selecteer icoon'
                        direction='horizontal'
                        items={availableIcons}
                        value={form.icon || Domain.Shared.DefaultIconId}
                        baseWidth={IconSize.large}
                        baseHeight={IconSize.large}
                        keyExpr='id'
                        searchEnabled={true}
                        searchExpr='keywords'
                        itemRender={(item) => {
                            const hexColor = form.color !== undefined ? `#${form.color?.toString(16).padStart(6, '0')}` : palette.grey1;
                            return <Styled.CenteredDiv>
                                <SVGIcon value={item.svg} size={IconSize.large} color={hexColor} />
                            </Styled.CenteredDiv>;
                        }}
                        editorSettings={{
                            disabled: false,
                            validationErrors: [],
                            restrictions: { required: false },
                            onChange: (item) => {
                                onChange(item?.id, 'icon');
                            }
                        }}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    );
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
};

const validate = (form: Domain.Shared.FieldDefinitionOptionItem, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};
