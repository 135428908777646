import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { FilterModel, JournalElementsGrid } from '../../../components/JournalElementsGrid';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
  journalElementKinds: Domain.Finance.JournalElementKind[],
  filter: FilterModel,
  filterCallback?: (item: any) => void,
  onCancel: () => void,
  onError: (exception: any) => void,
};

export const JournalElementsResults: React.FC<Props> = (props) => {
  return (
    <LsModal
      id='modal-journal-elements-results'
      title='Gekoppelde boekingselementen'
      width={1300}
      toolbar={{
        enabled: true,
        leftButtonText: 'Sluiten',
        onLeftButtonClick: props.onCancel,
      }}
    >
      {props.journalElementKinds &&
        <JournalElementsGrid
          journalElementKinds={props.journalElementKinds}
          pageSize={10}
          filter={props.filter}
          filterCallback={props.filterCallback}
          onError={props.onError}
        />}
    </LsModal>
  );
};
