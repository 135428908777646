import React, { useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, FieldsHelper, FormData, FormMode, ValueType } from '@liasincontrol/core-service';
import { ErrorOverlay, WarningLook, WarningWrapper } from '@liasincontrol/ui-basics';
import { DataSourceForm } from '../DataSourceForm';
import { getType, isDataStoreType } from '../DataSourcesList/index.helper';
import { usePublicationSettings } from '../../../../../../helpers/PublicationContext';
import Helper from '../../../../../_shared/PublicationItem/PublicationInformation/index.helper';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    publicationId: string,
    dataSourceId: string,
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    studioElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    performanceElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    hierarchyDefinitions: Record<string, Domain.Shared.HierarchyDefinition>,
    measureMoments: Domain.Shared.MeasureMoment[],
    structures: Domain.Finance.Structure[],
    mode: FormMode,
    onCancel: () => void,
    onSubmit: (id: string) => void,
};

/**
 * Represents a UI component that renders a dialog to edit a data source.
 */
const EditDataSourceItem: React.FC<Props> = (props) => {
    const [dataStore, setDataStore] = useState<{ id: string, kind: string, element: Domain.Publisher.DataStoreElement }>({ id: null, kind: null, element: undefined });

    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [warningMessage, setWarningMessage] = useState<{ isVisible: boolean, text: string, look: WarningLook }>({ isVisible: false, text: '', look: WarningLook.warning });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [formData, setFormData] = useState<FormData<ValueType>>({ values: undefined, isValid: true, touched: undefined, validationErrors: undefined });

    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [dataSourceFormExternalErrors, setDataSourceFormExternalErrors] = useState<Record<string, string>>();
    const publication = usePublicationSettings();

    useEffect(() => {
        if (!publication || !props.elementDefinitions) return;
        const publicationElement = props.elementDefinitions[SystemElementDefinitions.Pub.Publication];

        const isClosed = Helper.getPublicationIsClosed(publication, publicationElement);
        if (isClosed) {
            setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
        }
    }, [publication, props.elementDefinitions]);

    useEffect(() => {
        if (!props.elementDefinitions[SystemElementDefinitions.Pub.DataSource]) return;

        DataAccess.DataSources.getDataSource(props.publicationId, props.dataSourceId)
            .then((response) => {
                const dataSourceItem = new Domain.Publisher.DataSourceElement();
                // fill the translated object with the data from the list of element fields based on field definitions:
                FieldsHelper.mapObject<Domain.Publisher.DataSourceElement>(
                    dataSourceItem,
                    props.elementDefinitions[SystemElementDefinitions.Pub.DataSource].fields,
                    response.data.fields,
                    props.elementDefinitions[SystemElementDefinitions.Pub.DataSource].complexFields,
                    response.data.complexFields
                );
                setDataSource(dataSourceItem);
            }).catch((err) => {
                setDataSource(undefined);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [props.publicationId, props.dataSourceId, lastRefresh, props.elementDefinitions[SystemElementDefinitions.Pub.DataSource]]);

    useEffect(() => {
        if (!dataSource || !props.elementDefinitions[SystemElementDefinitions.Pub.DataStore]) return;

        setFormData(initForm(dataSource));

        if (!dataSource.dataStoreId) {
            setDataStore({ id: null, kind: null, element: undefined });
            setWarningMessage({ isVisible: true, text: 'De databron kan niet meer bewerkt of ververst worden omdat de gekoppelde gegevensverbinding is verwijderd.', look: WarningLook.dangerInverted });
            return;
        }

        DataAccess.DataStores.getDataStore(dataSource.dataStoreId).then((response) => {
            const dataStoreItem = new Domain.Publisher.DataStoreElement();
            // fill the translated object with the data from the list of element fields based on field definitions:
            FieldsHelper.mapObject<Domain.Publisher.DataStoreElement>(
                dataStoreItem,
                props.elementDefinitions[SystemElementDefinitions.Pub.DataStore].fields,
                response.data.fields
            );
            const selectedType = getType(
                props.elementDefinitions[SystemElementDefinitions.Pub.DataStore].fields.reduce(
                    (collection, item) => ({ ...collection, [item.systemId]: item }),
                    {}
                ), dataStoreItem.typeId) as Domain.Publisher.DataStoreTypes;

            const mappedType = Object.keys(Domain.Publisher.DataStoreTypesDictionary).find((key) => Domain.Publisher.DataStoreTypesDictionary[key] === selectedType);
            setDataStore({ id: response.data.elementId, kind: mappedType, element: dataStoreItem });
        }).catch((err) => {
            console.error(err);
        });
    }, [dataSource, props.elementDefinitions[SystemElementDefinitions.Pub.DataStore]]);

    if (!dataSource || !dataStore.id) return null;

    const handleError = (error: Domain.Shared.ErrorInfo, showAsWarning?: boolean) => {
        if (showAsWarning) {
            setWarningMessage({ isVisible: true, text: error.message, look: WarningLook.warning });
        } else {
            setError(error);
        }
    };

    const validateDataSource = (successCallback: () => void) => {
        const dataStoreId = formData.values[SystemFieldDefinitions.Pub.Datastore];

        if (isDataStoreType(dataStore.kind, Domain.Publisher.DataStoreTypes.DataPlatform)) {
            const filterSettings = formData.values[SystemFieldDefinitions.Pub.Filter];

            return DataAccess.DataSources.validateDataSourceFilter(filterSettings, dataStoreId, props.dataSourceId)
                .then(successCallback)
                .catch(err => {
                    setIsProcessing(false);
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge)) {
                        setDataSourceFormExternalErrors({ [SystemFieldDefinitions.Pub.Filter]: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge] });
                    } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DataPlatformDataSourceError)) {
                        setError(ApiErrorReportingHelper.generateErrorInfo(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DataPlatformDataSourceError], err));
                    } else {
                        setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err, true));
                    }
                });
        } else {
            successCallback();
        }
    };

    /**
     * Represents an event handler that saves the datasource changes.
     */
    const saveChanges = () => {
        validateDataSource(() => {
            const autoRefreshValue = formData.values[SystemFieldDefinitions.Pub.DataSourceAutoRefresh];
            const autoRefreshValString = (String(autoRefreshValue).charAt(0).toUpperCase() + String(autoRefreshValue).slice(1));
            const dataSourceDefinition = props.elementDefinitions[SystemElementDefinitions.Pub.DataSource];
            const nameDefinition = dataSourceDefinition.fields.find((field) => field.systemId === SystemFieldDefinitions.Pub.Name);
            const autoRefreshDefinition = dataSourceDefinition.fields.find((field) => field.systemId === SystemFieldDefinitions.Pub.DataSourceAutoRefresh);
            const complexFieldDefinition = dataSourceDefinition.complexFields[0];
            const dataStoreDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.Datastore);

            const columnsDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceColumns);
            const filterDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.Filter);
            //hierarchy
            const mmDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceMeasureMomentId);
            const edDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceElementDefinitionId);
            const structureDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceStructure);
            const measureMoments = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds);

            const fields = {
                [nameDefinition.id]: formData.values[SystemFieldDefinitions.Pub.Name],
                [autoRefreshDefinition.id]: autoRefreshValString,
            };

            const complexFields = {
                [dataStoreDefinition.id]: formData.values[SystemFieldDefinitions.Pub.Datastore],
            };

            let extraComplexFields: Record<string, string> = {};
            if (isDataStoreType(dataStore.kind, Domain.Publisher.DataStoreTypes.DataPlatform)) {
                extraComplexFields = {
                    [columnsDefinition.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceColumns],
                    [filterDefinition.id]: formData.values[SystemFieldDefinitions.Pub.Filter],
                }
            }

            if (isDataStoreType(dataStore.kind, Domain.Publisher.DataStoreTypes.Performance) || isDataStoreType(dataStore.kind, Domain.Publisher.DataStoreTypes.Studio)) {
                extraComplexFields = {
                    [mmDefinition.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId],
                    [edDefinition.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId],
                    [columnsDefinition.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceColumns],
                }
            }

            if (isDataStoreType(dataStore.kind, Domain.Publisher.DataStoreTypes.Finance)) {
                extraComplexFields = {
                    [structureDefinition.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceStructure],
                    [measureMoments.id]: formData.values[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds],
                }
            }

            const createComplexField = { complexFieldDefinitionId: complexFieldDefinition.id, rowIndex: 1, fields: { ...complexFields, ...extraComplexFields } } as Domain.Shared.ComplexField;

            const createElement = { elementDefinitionId: dataSourceDefinition.id, fields: fields, complexFields: [createComplexField] } as Domain.Publisher.Element;

            DataAccess.DataSources.updateDataSource(props.publicationId, props.dataSourceId, createElement)
                .then(() => {
                    props.onSubmit(props.dataSourceId);
                })
                .catch((err) => ApiErrorReportingHelper.generateCustomErrorMessage(err, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Saving))
        });
    };

    /**
     * Represents an event handler that discards the datasource changes.
     */
    const cancelChanges = () => {
        // reset previous edit form values.
        setFormData(initForm(dataSource));
        props.onCancel()
    };

    return (
        <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
            <LsModal
                id={`modal-edit-datasource${dataSource?.id}`}
                title="Databron bewerken"
                width='90%'
                toolbar={{
                    enabled: true,
                    onLeftButtonClick: cancelChanges,
                    onRightButtonClick: saveChanges,
                    rightButtonDisabled: props.mode === FormMode.View || !formData?.isValid || Object.keys(formData.touched).length === 0 || !formData.values || isProcessing,
                }}
            >
                {(warningMessage?.isVisible || dataSource?.failedRefresh) &&
                    <WarningWrapper
                        look={warningMessage?.look}
                        icon={<WarningAmberIcon />}
                        className='pt-00 pl-050 mb-100'
                        messageText={warningMessage?.text || 'Let op: De databron kon niet automatisch bijgewerkt worden. Het automatisch verversen zal hervat worden nadat de databron succesvol handmatig ververst.'} />
                }
                <DataSourceForm
                    dataSource={dataSource}
                    dataStore={dataStore}
                    form={formData}
                    elementdefinitions={props.elementDefinitions}
                    measureMoments={props.measureMoments}
                    structures={props.structures}
                    hierarchyDefinitions={Object.values(props.hierarchyDefinitions)}
                    studioElementDefinitions={props.studioElementDefinitions}
                    performanceElementDefinitions={props.performanceElementDefinitions}
                    mode={props.mode}
                    onFormDataChanged={(formdata) => {
                        setFormData(formdata);
                    }}
                    externalErrors={dataSourceFormExternalErrors}
                    onError={handleError}
                />
            </LsModal>
        </ErrorOverlay >
    );
};

const initForm = (dataSource: Domain.Publisher.DataSourceElement): FormData<ValueType> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: dataSource?.name ? dataSource.name : '',
            [SystemFieldDefinitions.Pub.DataSourceLastRefreshDate]: dataSource?.lastRefreshDate ? dataSource.lastRefreshDate : '',
            [SystemFieldDefinitions.Pub.Datastore]: dataSource?.dataStoreId ? dataSource.dataStoreId : '',
            [SystemFieldDefinitions.Pub.DataSourceAutoRefresh]: dataSource ? dataSource.autoRefresh : true,


            [SystemFieldDefinitions.Pub.DataSourceMeasureMomentId]: dataSource?.measureMomentId ? dataSource.measureMomentId : '',
            [SystemFieldDefinitions.Pub.DataSourceElementDefinitionId]: dataSource?.elementDefinitionId ? dataSource.elementDefinitionId : '',
            [SystemFieldDefinitions.Pub.DataSourceColumns]: dataSource?.columns ? dataSource.columns : '',
            [SystemFieldDefinitions.Pub.DataSourceStructure]: dataSource?.structureRk ? dataSource.structureRk : '',

            [SystemFieldDefinitions.Pub.Filter]: dataSource?.filter ? dataSource.filter : '',
            [SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds]: dataSource?.measureMomentIds ?? '',
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

export default EditDataSourceItem;
