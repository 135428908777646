import React, { useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { AttachmentElement } from '@liasincontrol/ui-elements';
import { ValidatorsDictionary, FormHelper, AttachmentValidator, ValidationErrorData, AttachmentsHelper } from '@liasincontrol/core-service';
import { State, ActionSource, AttachmentsActionCreator } from '@liasincontrol/redux-service';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    budgetJournalId: string;
    onImportSuccess: () => void;
    onImportFailure: (err: any) => void;
    onModalClose: () => void;
}

enum Feedback {
    None,
    Success,
    Failed,
}

const ImportBudgetLines = (props: Props) => {
    const [form, setForm] = useState<{ attachment: string, touched: boolean }>({ attachment: null, touched: false });
    const [importFeedback, setImportFeedback] = useState<Feedback>(Feedback.None);
    const [formValidationErrors, setFormValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: true });

    const onImportSuccess = () => {
        setImportFeedback(Feedback.Success);
    };

    const onImportFailure = (err) => {
        setImportFeedback(Feedback.Failed);
        props.onImportFailure(err);
    };

    const onLoadAttachment = useCallback(async (id: string) => {
        return AttachmentsHelper.loadExistingAttachment(id, props.attachments, props.setAttachment);
    }, [props.attachments, props.setAttachment])

    const onUploadAttachment = async (file: File, abort: AbortSignal) => {
        return AttachmentsHelper.uploadAttachment(file, abort, onFileUploadedCallback);
    };

    const onFileUploadedCallback = (attachmentId: string, file: File) => {
        DataAccess.BudgetLineDataAccessor.validateImportedBudgetLine(props.budgetJournalId, attachmentId)
            .then(response => {
                if (response.data.length > 0) {
                    const errors = response.data.reduce((acc, error) => ({ "customErrors": [...acc["customErrors"], { error }] }), { "customErrors": [] });
                    setFormValidationErrors({ errors, hasErrors: true });
                } else {
                    setFormValidationErrors({ errors: {}, hasErrors: false });
                }
                props.setAttachment(attachmentId, file);
            });
    };

    const onClose = () => {
        setForm((form) => {
            return {
                ...form,
                attachment: null,
                touched: false
            };
        });
        props.onModalClose();
    };

    const onSubmit = () => {
        DataAccess.BudgetLineDataAccessor.importBudgetLine(props.budgetJournalId, form.attachment)
            .then(onImportSuccess)
            .catch(onImportFailure);
    };

    const validateAttachment = (form: Record<string, any>, formValidationErrors: { errors?: Record<string, ValidationErrorData[]>; hasErrors: any; }) => {
        if (!form.touched) return {
            errors: {},
            hasErrors: false,
        };

        const uploaderErrors = FormHelper.validateForm(validators, form, {});
        const importErrors = form.attachment ? cloneDeep(formValidationErrors) : { errors: {} };
        const hasErrors = uploaderErrors.hasErrors || formValidationErrors.hasErrors;
        const errors = { errors: { ...importErrors.errors, ...uploaderErrors.errors }, hasErrors };
        setFormValidationErrors(errors);
        return errors;
    };

    const validationErrors = Object.values(formValidationErrors.errors).flat();

    return (
        <>
            <LsModal
                id='modal-import-budget-lines'
                title='Importeren'
                width={1300}
                toolbar={{
                    enabled: true,
                    leftButtonText: 'Sluiten',
                    onLeftButtonClick: onClose,
                    rightButtonText: 'Importeren',
                    onRightButtonClick: onSubmit,
                    rightButtonDisabled: formValidationErrors.hasErrors,
                }}
            >
                <AttachmentElement
                    id='import-budget-lines'
                    label='Importeren boekingen'
                    editorSettings={{
                        restrictions: {
                            required: true,
                            allowedFileTypes: allowedTypes,
                            maxFileSize: maxFileSize
                        },
                        withoutFeedback: false,
                        disabled: false,
                        validationErrors,
                        onChange: (fileId) => {
                            const cloneForm = { ...form, touched: true, attachment: fileId };
                            setForm(cloneForm);
                            validateAttachment(cloneForm, formValidationErrors);
                        }
                    }}
                    value={form.attachment}
                    onUploadAttachment={onUploadAttachment}
                    onLoadAttachment={onLoadAttachment} />
            </LsModal>

            {importFeedback === Feedback.Success &&
                <LsModal
                    id='modal-import-budget-lines-feedback'
                    title='Success'
                    width={1300}
                    toolbar={{
                        enabled: true,
                        rightButtonText: 'Sluiten',
                        onRightButtonClick: () => {
                            props.onImportSuccess();
                            setImportFeedback(Feedback.None);
                        },
                    }}
                >
                    He-ya! Importeren is gelukt!
                </LsModal>}
        </>
    );
};

const allowedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const maxFileSize = 10000000;

const validators: ValidatorsDictionary = {
    attachment: new AttachmentValidator({
        required: true,
        attachmentAllowedFileTypes: allowedTypes,
        attachmentMaxFileSize: maxFileSize,
    }),
};

const mapStateToProps = (state: State) => {
    return {
        attachments: state.attachments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(ImportBudgetLines);
export { Component as ImportBudgetLines };
