import * as Domain from '@liasincontrol/domain';
import { Dictionary } from 'lodash';
import { ValidationErrorData } from '@liasincontrol/core-service';
import { ActionSource } from '@liasincontrol/redux-service';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { ActionType, Actions } from '@liasincontrol/userrights-service';

enum HierarchySource { Performance, Studio }

enum Tabs {
    Main = 1,
    AdvancedSettings = 2,
    Users = 3,
    Comments = 4,
    History = 5,
}

type SourcedHierarchyMap = Dictionary<Dictionary<Domain.Performance.HierarchyItem[] | Domain.Studio.HierarchyItem[]>>;
type HierarchyMap = Dictionary<SourcedHierarchyMap>;

type Props = {
    publicationId: string,
    selectedElement: Domain.Publisher.Element,
    selectedPageId?: string,
    isVisible: boolean,
    isDesigner: boolean,
    userControlTask?: Domain.Publisher.WorkflowTask,
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any, componentHasDiscussion: boolean }>,
    auditEvents?: { content: Domain.Shared.AuditEvent[] },
    isProcessing?: boolean,
    elementTypes?: IDataItemProps<string>[],
    measureMoments?: Domain.Shared.MeasureMoment[],
    availablePublishProfiles?: IDataItemProps<string>[],
    selectedPublishProfiles?: IDataItemProps<string>[],
    sitemap?: Domain.Publisher.Sitemap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onToggleVisibility: () => void,
    onSingleFieldChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onComplexFieldChanged: (change: Domain.Publisher.ComplexFieldPatch) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    onErrorsInSettings: (value: boolean, elementId: string) => void,
    getParentElement?: (elementId: string) => Domain.Publisher.Element,
    onToggleTaskStatus?: (workflowTask: Domain.Publisher.WorkflowTask, completeTask: boolean) => void,
    loadPerformanceHierarchy?: (measureMomentId?: string) => void,
    onUpdateControlPublishProfiles?: (publishProfiles: IDataItemProps<string | number>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    getDefinition?: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    hierarchies: HierarchyMap,
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,
    getAvailableHierarchyTypes?: (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => void,
    onAuditRefresh?: () => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    onChangeElementState?: (elementId: string, stateId: string, userIds: string[], remark: string) => Promise<boolean>,
    publicationWorkflowStates?: Domain.Publisher.TaskManagerWorkflowState[],
    componentWorkflowTasks?: Domain.Publisher.WorkflowTask[],
    statusAuditEvents?: Domain.Shared.WorkflowAuditEvent[],
    currentUserId?: string,
    templateName?: string,
    templateOperations?: Domain.Publisher.Operation[],
    invalidElements?: Record<string, any>,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    variables?: Domain.Shared.ComplexFieldItem[],
    onVariablesChanged?: (change: Domain.Shared.ComplexField) => void,
    resetSelectedElement?: () => void,
};

type StepDetailsType = {
    disabled: boolean,
    isLastStep: boolean,
    isCurrentStep: boolean,
}

type EditorRendererProps = {
    publicationId: string,
    selectedElement: Domain.Publisher.Element,
    disabled: boolean,
    isDesigner: boolean,
    fieldId: string,
    validationErrors: ValidationErrorData[],
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSingleFieldChanged: (changes: Domain.Publisher.FieldPatch[]) => void,
    onComplexFieldChanged: (change: Domain.Publisher.ComplexFieldPatch) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (attachmentId: string) => void,
    measureMoments?: Domain.Shared.MeasureMoment[],
    elementTypes?: IDataItemProps<string>[],
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    getDefinition: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    hierarchies: SourcedHierarchyMap,
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,
    getAvailableHierarchyTypes?: (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    hierarchySource: HierarchySource,
    sitemap: Domain.Publisher.Sitemap,
    selectedPageId?: string,
    variables?: Domain.Shared.ComplexFieldItem[],
    complexFieldsValidationErrors?: Record<string, Dictionary<ValidationErrorData[]>>,
    templateName?: string,
    templateOperations?: Domain.Publisher.Operation[],
    icons?: Record<string, Domain.Shared.SvgIcon>,
    onVariablesChanged?: (change: Domain.Shared.ComplexField) => void,
}

export { EditorRendererProps, StepDetailsType, Props, Tabs, HierarchySource }