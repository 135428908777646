import React, { useState } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions } from '@liasincontrol/domain';
import { AutoFocus, ErrorOverlay } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper, FormData, FormMode, ValueType } from '@liasincontrol/core-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { DataSourceForm } from '../../DataSourceForm';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    elementdefinitions: Record<SystemElementDefinitions.Pub.DataSource | SystemElementDefinitions.Pub.DataStore, Domain.Shared.ElementDefinition>,
    measureMoments: Domain.Shared.MeasureMoment[],
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    studioElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    performanceElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    structures: Domain.Finance.Structure[],
    onSaveChanges: (formValues: Record<string, string>, type: string) => void,
    onCancelChanges: () => void,
};

/**
 * Represents a UI component that renders a dialog to add a new data source object.
 */
const DataSourceAdd: React.FC<Props> = (props) => {

    const [formData, setFormData] = useState<FormData<ValueType>>(initForm());

    const [dataStoreType, setDataStoreType] = useState<string>();
    const [formExternalErrors, setFormExternalErrors] = useState<Record<string, string>>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

    const saveDataSource = () => {
        setSaveButtonDisabled(true);
        const dataStoreId = formData.values[SystemFieldDefinitions.Pub.Datastore];

        if (dataStoreType === 'DataPlatform') {
            validateRowFilter(dataStoreId);
        } else {
            props.onSaveChanges(formData?.values, dataStoreType);
        }
    };

    const validateRowFilter = (dataStoreId: string) => {
        const filterSettings = formData.values[SystemFieldDefinitions.Pub.Filter];
        DataAccess.DataSources.validateDataSourceFilter(filterSettings, dataStoreId)
            .then(() => (props.onSaveChanges(formData?.values, dataStoreType)))
            .catch(err => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge)) {
                    setFormExternalErrors({ [SystemFieldDefinitions.Pub.Filter]: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DataSourceTooLarge] });
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, false, true));
                }
            })
            .finally(() => {
                setSaveButtonDisabled(false);
            });
    };

    return (
        <LsModal
            id='modal-new-datasource'
            title="Databron aanmaken"
            toolbar={{
                enabled: true,
                onLeftButtonClick: props.onCancelChanges,
                onRightButtonClick: saveDataSource,
                rightButtonDisabled: !formData?.isValid || _.isEmpty(formData?.touched) || saveButtonDisabled,
            }}
        >
            <AutoFocus>
                <ErrorOverlay error={error?.message} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <DataSourceForm
                        elementdefinitions={props.elementdefinitions}
                        measureMoments={props.measureMoments}
                        hierarchyDefinitions={props.hierarchyDefinitions}
                        studioElementDefinitions={props.studioElementDefinitions}
                        performanceElementDefinitions={props.performanceElementDefinitions}
                        structures={props.structures}
                        mode={FormMode.AddNew}
                        form={formData}
                        inValidateForm={() => {
                            setFormData((formData) => ({ ...formData, isValid: false }));
                        }}
                        onFormDataChanged={(formdata, type) => {
                            setFormData(formdata);
                            setDataStoreType(type);
                        }}
                        externalErrors={formExternalErrors}
                        onError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, error, true, true))}
                    />
                </ErrorOverlay>
            </AutoFocus>
        </LsModal>
    );
};

const initForm = (): FormData<ValueType> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: '',
            [SystemFieldDefinitions.Pub.DataSourceLastRefreshDate]: '',
            [SystemFieldDefinitions.Pub.Datastore]: '',
            [SystemFieldDefinitions.Pub.DataSourceAutoRefresh]: true,

            [SystemFieldDefinitions.Pub.DataSourceMeasureMomentId]: '',
            [SystemFieldDefinitions.Pub.DataSourceElementDefinitionId]: '',
            [SystemFieldDefinitions.Pub.DataSourceColumns]: '',

            [SystemFieldDefinitions.Pub.Filter]: '',

            [SystemFieldDefinitions.Pub.DataSourceStructure]: '',
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};

export { DataSourceAdd };
