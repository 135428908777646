import { AxiosResponse, CancelToken } from 'axios';
import { Dictionary } from 'lodash';
import buildQuery from 'odata-query';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DefinitionsHelper } from '@liasincontrol/core-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the publication data accessor.
 */
export class Publications {
    /**
     * Gets a publication based on its unique identifier.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static getPublication = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.Publication>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Publication>(`/api/publication/${publicationId}`, config);
    };

    /**
     * Gets a list of publications.
     *
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static getPublications = async (query = '', cancelToken?: CancelToken): Promise<AxiosResponse<oDataResponse<Domain.Publisher.AbstractElement[]>>> => {

        const config = await DataAccessHostUtils.getPubRequestConfig();

        const basePath = '/api/publication/list';

        return HttpClient.get<oDataResponse<Domain.Publisher.AbstractElement[]>>(`${basePath}${query}`, { ...config, cancelToken: cancelToken });
    };

    /**
     * Gets a list of publications.
     *
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static getPublicationsV2 = async (query = '', cancelToken?: CancelToken): Promise<AxiosResponse<oDataResponse<Domain.Publisher.PublicationV2[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Publisher.PublicationV2[]>>(`/api/publication${query}`, { ...config, cancelToken: cancelToken });
    };

    /**
     * Gets the default oData selector to retrieve all publications.
     */
    public static getDefaultOdataQuery = (elementDefinition: Domain.Shared.ElementDefinition, count = false, skip?: number): string => {
        const config = DataAccessHostUtils.getDefaultPublicationsConfig();
        const selectableColumnNames = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Publisher.PublicationElement(), config, elementDefinition);

        const query = buildQuery({
            select: selectableColumnNames.join(','),
            top: AppSettingsService.getAppSettings().Api.Odata.DefaultBackendLimitOverride,
            count: count,
            skip: skip
        });
        return query;
    };

    /**
     * Gets the page operations of a publication page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     */
    public static getPageOperations = async (publicationId: string, pageId: string): Promise<AxiosResponse<{ layers: Domain.Publisher.Layer[] }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<{ layers: Domain.Publisher.Layer[] }>(`/api/publication/${publicationId}/page/${pageId}/content`, config);
    };

    /**
     * Gets the element definitions of a page.
     */
    public static getPageElementDefinitions = async (): Promise<AxiosResponse<{}>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<{}>(`/api/meta/page/elementdefinitions`, config);
    };

    /**
     * Gets the sitemap of a page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param depth Defines the depth of the sitemap.
     */
    public static getPageSitemap = async (publicationId: string, pageId: string, depth: number = null): Promise<AxiosResponse<Domain.Publisher.Sitemap>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Sitemap>(`/api/publication/${publicationId}/sitemap/${pageId}?levels=${depth ? depth : 4}`, config);
    };

    /**
     * Gets the element definition with the related fields of a publication.
     *
     * @returns a promise that contains the publication definition.
     */
    public static getPublicationDefinition = async (): Promise<AxiosResponse<Domain.Shared.ElementDefinition>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.ElementDefinition>(`/api/meta/publication/elementdefinition`, config);
    };

    /**
     * Gets the templates associated with a specific publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static getTemplates = async (publicationId: string, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.AbstractElement[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Publisher.AbstractElement[]>>(`/api/publication/${publicationId}/pagetemplate${query}`, config);
    };

    /**
     * Gets the template details.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageTemplateId Defines the unique identifier of the template.
     */
    public static getTemplateDetails = async (publicationId: string, pageTemplateId: string): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Element>(`/api/publication/${publicationId}/pagetemplate/${pageTemplateId}`, config);
    };

    /**
     * Gets the template element definition.
     */
    public static getTemplateElementDefinition = async (): Promise<AxiosResponse<Domain.Publisher.TemplateElementDefinition>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.TemplateElementDefinition>(`/api/meta/pagetemplate/elementdefinitions`, config);
    };

    /**
     * Send a reminder to all users with opened tasks in a publication.
     *
     * @param publicationId Defines the publication id.
     * @param subject Defines the reminder's subject.
     * @param message Defines the reminder's message.
     */
    public static sendOpenTasksReminder = async (publicationId: string, subject: string, message: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication/tasks/${publicationId}/reminder`, { subject: subject, messageBody: message }, config);
    };

    /**
     * Creates a new publication.
     *
     * @param newPublication Defines the properties of the new publication.
     */
    public static createPublication = async (newPublication: Domain.Dto.Publisher.CreatePublication): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication`, newPublication, config);
    };

    /**
     * Clones a publication.
     * 
     * @param publicationId Defines the publication id.
     * @param clonedPublication Defines the request for cloning a publication.
     * @returns The id of the new publication.
     */
    public static clonePublication = async (publicationId: string, clonedPublication: Domain.Dto.Publisher.ClonePublication): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication/${publicationId}/clone`, clonedPublication, config);
    };

    /**
     * Opens or closes a publication.
     * 
     * @param publicationId Defines the publication id.
     * @param isClosed The opened/closed state of the publication
     */
    public static closePublication = async (publicationId: string, isClosed: boolean): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const body = { isClosed: isClosed };
        return HttpClient.post<string>(`/api/publication/${publicationId}/close`, body, config);
    };

    /**
     * Saves the changes made over the content of a publication.
     *
     * @param publicationId Defines the publication id.
     * @param pageId Defines the page id.
     * @param elementId Defines the element id.
     * @param fields Defines the patched fields that have to be saved.
     * @param complexFields Defines the patched complex fields that have to be saved.
     * @param attachments Defines the attachments that are related to a patch.
     */
    public static savePublicationElement = async (
        publicationId: string,
        pageId: string,
        elementId: string,
        fields: Dictionary<string>,
        complexFields: Domain.Shared.ComplexField[],
        attachments: Domain.Shared.Attachment[]
    ): Promise<AxiosResponse<Domain.Publisher.Operation>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<Domain.Publisher.Operation>(
            `/api/publication/${publicationId}/page/${pageId}/content/${elementId}`,
            {
                fields,
                complexFields,
                attachments,
            },
            config
        );
    };

    /**
     * Sets the publication's site access visibilty.
     *
     * @param publicationId Defines the publication id.
     * @param versionId Defines the publication version id.
     * @param isPublic Determines the publication's site visibilty.
     */
    public static setPublicationSiteAccess = async (publicationId: string, versionId: string, isPublic: boolean): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/siteaccess/${versionId}`, { isPublic: isPublic }, config);
    };

    /**
     * Update the publication settings.
     *
     * @param publicationId Defines the publication id.
     * @param settings Defines the settings that have to be saved.
     * @param attachments Defines the attachments that are related to a publication identity.
     */
    public static updatePublicationSettings = async (publicationId: string, settings: Domain.Dto.Publisher.Settings, attachments: Domain.Shared.Attachment[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(
            `/api/publication/${publicationId}`,
            {
                ...settings,
                attachments: attachments,
            },
            config
        );
    };

    /**
     * Deletes a publication by a unique identifier.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static deletePublication = async (publicationId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/publication/${publicationId}`, config);
    };

    /**
     * Gets the audit information for a publication page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param includeWorkflowState Defines if the response will contain the workflowstate changes or not.
     */
    public static getPageAuditData = async (
        publicationId: string,
        pageId: string,
        includeWorkflowState: boolean
    ): Promise<AxiosResponse<{ contents: Domain.Dto.Shared.AuditEvent[]; workflowStates: Domain.Dto.Shared.AuditEvent[] }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const extraQueryString = includeWorkflowState ? '?includeWorkflowStatus=true' : '';

        return HttpClient.get<{ contents: Domain.Dto.Shared.AuditEvent[]; workflowStates: Domain.Dto.Shared.AuditEvent[] }>(
            `/api/publication/${publicationId}/page/${pageId}/audit${extraQueryString}`,
            config
        );
    };

    /**
     * Deletes a publication version.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param versionId Defines the unique identifier of the publication version.
     */
    public static deleteVersion = async (publicationId: string, versionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/publication/${publicationId}/versions/${versionId}`, config);
    };


    /**
     * Gets the workflow item audit information for a component.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param componentId Defines the unique identifier of the component.
     */
    public static getWorkflowItemAuditTrail = async (
        publicationId: string,
        pageId: string,
        componentId: string
    ): Promise<AxiosResponse<Domain.Dto.Shared.WorkflowAuditEvent[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Dto.Shared.WorkflowAuditEvent[]>(
            `/api/publication/${publicationId}/page/${pageId}/component/${componentId}/workflowitem/audittrail`,
            config
        );
    };

    /**
     * Gets the list of editors for the publication.
     *
     * @param publicationId Id of the publication.
     */
    public static getPublicationEditors = async (publicationId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/publication/${publicationId}/editors`, config);
    };

    /**
     * Sets the list of editors for the publication..
     *
     * @param publicationId Defines the publication id.
     * @param userIds the user ids to set as redactors
     */
    public static setPublicationEditors = async (publicationId: string, userIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/editors`, { userIds: userIds }, config);
    };

    /**
     * Gets the page elditable element list for logged in user.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     */
    public static getPageComponentInfo = async (publicationId: string, pageId: string): Promise<AxiosResponse<Domain.Publisher.PageComponentInfo[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.PageComponentInfo[]>(`/api/publication/${publicationId}/page/${pageId}/componentinfo`, config);
    };

    /**
     * Gets the control on a page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param controlId Defines the unique identifier of the control on the page.
     */
    public static getControl = async (publicationId: string, pageId: string, controlId: string): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Element>(`/api/publication/${publicationId}/page/${pageId}/control/${controlId}`, config);
    };

    /**
     * Gets the list of readers for the publication.
     *
     * @param publicationId Id of the publication.
     */
    public static getPublicationReaders = async (publicationId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/publication/${publicationId}/readers`, config);
    };

    /**
     * Sets the list of readers for the publication..
     *
     * @param publicationId Defines the publication id.
     * @param userIds the user ids to set as redactors
     */
    public static setPublicationReaders = async (publicationId: string, userIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/readers`, { userIds: userIds }, config);
    };

}

export default Publications;
