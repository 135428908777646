import React, { useEffect, useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { DefinitionsHelper, FieldsHelper, ValidationUtils, FormMode, FormInfo, ApiErrorReportingHelper, AttachmentsHelper } from '@liasincontrol/core-service';
import { State, ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, AttachmentsActionCreator, AjaxRequestStatus, HierarchyDefinitionsActionCreator } from '@liasincontrol/redux-service';
import { WrapperContent, PageTitle, Heading1, Bar, Button, Section, ErrorOverlay, Text } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator, LsModal } from '@liasincontrol/ui-devextreme';
import { DataStoreForm } from '../DataStoreForm';
import CsvFeedbackModal from '../DataStoresList/CsvFeedbackModal';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders a datastore details page.
 */
const Index: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [csvErrorFeedback, setCsvErrorFeedback] = useState<{ title: string, detail: string }>();
    const [displayType, setDisplayType] = useState<FormMode>(FormMode.View);
    const [isRemoving, setIsRemoving] = useState<boolean>(false);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [dataStore, setDataStore] = useState<{ store: Domain.Publisher.DataStoreElement, attachments: Domain.Shared.Attachment[] }>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [formData, setFormData] = useState<FormInfo<string>>({ values: undefined, isValid: true, isTouched: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    const dataStoreElementDef = useMemo(() => {
        return DefinitionsHelper.findElementDefinition(props.elementDefinitions?.items, SystemElementDefinitions.Pub.DataStore);
    }, [props.elementDefinitions]);

    useEffect(() => {
        if (!dataStoreElementDef) return;

        DataAccess.DataStores.getDataStore(id).then((response) => {
            setError(undefined);
            const dataStoreItem = new Domain.Publisher.DataStoreElement();
            // fill the translated object with the data from the list of element fields based on field definitions.
            FieldsHelper.mapObject<Domain.Publisher.DataStoreElement>(
                dataStoreItem,
                dataStoreElementDef.fields,
                response.data.fields
            );

            setDataStore({
                store: { ...dataStoreItem },
                attachments: response.data.attachments
            });
        }).catch((err) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
        });

    }, [id, lastRefresh, dataStoreElementDef]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if ((!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return null;
    }

    /**
     * Represents an event handler that triggers the loading process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const loadAttachment = async (attachmentId: string): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment, AttachmentsHelper.getAttachmentNamesDictionary(dataStore?.attachments));
    };

    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const uploadAttachment = async (file: File, abortSignal: AbortSignal) => {
        return AttachmentsHelper.uploadAttachment(file, abortSignal, props.setAttachment);
    };

    const getDataStoreControl = (mode: FormMode = FormMode.View): JSX.Element => {
        const fieldDefinitions = dataStoreElementDef?.fields?.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Record<string, Domain.Shared.FieldDefinition>;
        return <DataStoreForm
            dataStore={dataStore?.store}
            attachments={dataStore?.attachments}
            fieldDefinitions={fieldDefinitions}
            hierarchyDefinitions={Object.values(props.hierarchyDefinitions.items)}
            mode={mode}
            onLoadAttachment={loadAttachment}
            onUploadAttachment={uploadAttachment}
            onFormDataChanged={setFormData}
        />;
    };

    /**
     * Represents an event handler that triggers when deletingd a dataStore.
     */
    const deleteStore = () => {
        setIsRemoving(true);
        DataAccess.DataStores.deleteDataStore(id)
            .then(() => {
                navigate(`/admin/datastore/list`);
                setIsRemoving(false);
                setConfirmDialogVisible(false);
            }).catch((err) => {
                setIsRemoving(false);
                setConfirmDialogVisible(false);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            });
    };

    const startEditing = () => {
        setDisplayType(FormMode.Edit);
        // reset previous edit form values.
        setFormData({ values: undefined, isValid: true });
    }

    /**
     * Represents an event handler that triggers when saving the dataStore changes.
     */
    const saveChanges = () => {

        if (!formData?.values) {
            // no form values means no fields were changed, so nothing to do but close the page.
            setDisplayType(FormMode.View);
            return;
        }

        const fieldDefinitions = dataStoreElementDef?.fields?.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Record<string, Domain.Shared.FieldDefinition>;

        const elementFieldValues = Object.keys(formData.values).reduce((collection, systemId) => ({ ...collection, [fieldDefinitions[systemId].id]: formData.values[systemId] }), {}) as Record<string, string>;

        const isTypeCsvSelected = fieldDefinitions && !ValidationUtils.isEmpty(formData.values[SystemFieldDefinitions.Pub.Type]) && (formData.values[SystemFieldDefinitions.Pub.Type] === fieldDefinitions[SystemFieldDefinitions.Pub.Type].optionItems[0].id);

        const attachments = [] as Domain.Shared.Attachment[];
        const newSourceFileId = formData?.values[SystemFieldDefinitions.Pub.SourceFile];
        if (isTypeCsvSelected && newSourceFileId !== dataStore?.store.sourceFileId) {
            // replace the attachment's ID with a new GUID.
            const newAttachmentId = uuid();
            const newAttachment = { ...AttachmentsHelper.mapFileToAttachment(props.attachments[newSourceFileId], newSourceFileId, true), id: newAttachmentId };

            // the new attachment will contain a newly generated UUID. Set that as the SourceFileId instead of the current blobId.
            elementFieldValues[fieldDefinitions[SystemFieldDefinitions.Pub.SourceFile].id] = newAttachment.id;
            attachments.push(newAttachment);
        }
        DataAccess.DataStores.saveDataStore(id, attachments, elementFieldValues).then(() => {
            setLastRefresh(Date.now());
            setDisplayType(FormMode.View);
        }).catch((err) => {
            const errorTitle = err.response.data.title;
            const errorDetail = err.response.data.detail;
            if (errorTitle || errorDetail) {
                setCsvErrorFeedback({ title: err.response.data.title, detail: err.response.data.detail });
                setFormData((prev) => ({ ...prev, isTouched: false }));
            } else {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
                setDisplayType(FormMode.View);
            }
        });
    };

    /**
     * Represents an event handler that triggers when discarding the dataStore changes.
     */
    const cancelChanges = () => {
        setDisplayType(FormMode.View);
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>
                    <Button
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        icon={<ArrowBack />}
                        onClick={() => navigate('/admin/datastore/list')}
                    />
                    {dataStore?.store ? dataStore?.store.name || 'Naamloos' : !error ? <LoadIndicator variant={IndicatorSize.default} /> : `Gegevensverbinding`}
                </Heading1>
            </PageTitle>
            <Bar look="toolbar">
                <Bar start>
                    {!error && (<>
                        <Button id={`btn-edit-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={startEditing}>
                            Bewerken
                        </Button>
                        <Button id={`btn-delete-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={() => setConfirmDialogVisible(true)}>
                            Verwijderen
                        </Button>
                    </>)}
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    {getDataStoreControl(FormMode.View)}
                </Section>
            </ErrorOverlay>
            {displayType === FormMode.Edit ?
                <LsModal
                    id={`modal-edit-datastore-${id}`}
                    title="Wijzig gegevensverbinding"
                    toolbar={{
                        enabled: true,
                        onLeftButtonClick: cancelChanges,
                        onRightButtonClick: saveChanges,
                        leftButtonDisabled: false,
                        rightButtonDisabled: !formData?.isTouched || !formData?.isValid,
                    }}
                >
                    {getDataStoreControl(FormMode.Edit)}
                </LsModal>
                : null}
            {confirmDialogVisible ? (
                <LsModal
                    id={`modal-confirm-delete-${id}`}
                    title="Verwijder gegevensverbinding"
                    toolbar={{
                        enabled: true,
                        leftButtonText: 'Annuleren',
                        onLeftButtonClick: () => setConfirmDialogVisible(false),
                        rightButtonText: 'Verwijderen',
                        onRightButtonClick: deleteStore,
                        leftButtonDisabled: false,
                        rightButtonDisabled: isRemoving,
                    }}
                >
                    <Text value="U staat op het punt een gegevensverbinding te verwijderen. Weet u zeker dat u dit wilt doen? U kunt deze actie niet meer ongedaan maken en gekoppelde databronnen kunnen niet meer ververst worden." />
                </LsModal>
            ) : null}
            {csvErrorFeedback &&
                <CsvFeedbackModal
                    error={csvErrorFeedback}
                    onCancel={() => setCsvErrorFeedback(undefined)}
                />}
        </WrapperContent>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
        attachments: state.attachments,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }))
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
