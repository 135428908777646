import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import _, { Dictionary } from 'lodash';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { BasicValidator, FormHelper, FormMode, NumberValidator, TextValidator, ValidationErrorData, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import { AutoFocus, Button, Feedback, IconSize, IDataItemProps, Label, MandatoryIcon, palette, px, SVGIcon } from '@liasincontrol/ui-basics';
import { EditorSettings, TextElement, ToggleElement, SelectElement, IntegerElement } from '@liasincontrol/ui-elements';
import { List, ItemDragging, LsModal } from '@liasincontrol/ui-devextreme';
import { formatOptionItems, getFieldEditorSettings } from '../utils';
import { OptionItemForm } from './OptionItemForm';
import Styled from './index.styled';

type Props = {
    fieldDefinition?: Domain.Shared.FieldDefinition,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    customNavigationElement?: React.ReactNode,
    fields: string[],
    onSave: (field: Domain.Shared.FieldDefinition) => void,
    onCancel: () => void,
    mode: FormMode,
};

enum FieldType {
    None,
    SingleLineText,
    MultilineText,
    FormattedText,
    Date,
    Integer,
    Numeric,
    Dropdown,
    Boolean,
    Hyperlink,
}

/**
 * Represents a UI component that renders the field definition details form.
 */
export const FieldDefinitionAddForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<Domain.Shared.FieldDefinition>();
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });
    const [selectedFieldType, setSelectedFieldType] = useState<IDataItemProps<FieldType>>({ label: '', value: FieldType.None });
    const [showOptionForm, setShowOptionForm] = useState<{ visible: boolean, mode?: FormMode, optionItem?: Domain.Shared.FieldDefinitionOptionItem }>({ visible: false });

    useEffect(() => {
        if (props.fieldDefinition) {
            setForm(props.fieldDefinition);
            setSelectedFieldType(getFieldType(props.fieldDefinition));
        } else {
            setForm({
                ...new Domain.Shared.FieldDefinition(),
                id: uuid(),
                systemId: Domain.SystemFieldDefinitions.Studio.Dynamic,
                optionItems: [],
                name: '',
                required: false,
                dataType: null,
            });
        }
    }, [props.fieldDefinition]);

    useEffect(() => {
        switch (selectedFieldType?.value) {
            case FieldType.SingleLineText:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.String,
                    stringType: Domain.Shared.StringType.SingleLine,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.MultilineText:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.String,
                    stringType: Domain.Shared.StringType.MultiLine,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.FormattedText:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.String,
                    stringType: Domain.Shared.StringType.Html,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.Hyperlink:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.String,
                    stringType: Domain.Shared.StringType.Hyperlink,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.Date:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.DateTimeOffset,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.Integer:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.Integer,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.Dropdown:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.Option,
                    editorSettings: getControlSettings(selectedFieldType),
                });
                break;
            case FieldType.Numeric:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.Decimal,
                    editorSettings: getControlSettings(selectedFieldType, getEditorSettings(form['editorSettings'])?.numericFormatString),
                });
                break;
            case FieldType.Boolean:
                setForm({
                    ...form,
                    dataType: Domain.Shared.FieldDataType.Boolean,
                    editorSettings: getControlSettings(selectedFieldType),
                    booleanTrueLabel: 'Ja',
                    booleanFalseLabel: 'Nee',
                });
                break;
            default:
                break;
        }
        setValidationErrors(validate(form || new Domain.Shared.FieldDefinition(), props.fields, validationErrors.errors, props.fieldDefinition, props.mode));
    }, [selectedFieldType]);

    if (!form) {
        return null;
    }

    function editorSettings<T>(fieldName: string, onChange: (value: any) => void, required = false, extraRestrictions: Dictionary<string | number | boolean>): EditorSettings<T> {
        return {
            disabled: false,
            restrictions: { required, ...extraRestrictions },
            validationErrors: validationErrors.errors[fieldName],
            onChange: (value) => onChange(value)
        };
    }

    const onChange = (value: string | Domain.Shared.FieldDefinitionOptionItem[], fieldName: string) => {
        const data: Domain.Shared.FieldDefinition = { ...form };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm(data);
        setValidationErrors(validate(data, props.fields, validationErrors.errors, props.fieldDefinition, props.mode));
    };

    const onSave = () => {
        const errors = validate(form, props.fields, validationErrors.errors, props.fieldDefinition, props.mode);

        if (errors.hasErrors) {
            setValidationErrors(errors);
            return;
        }

        props.onSave(form);
    };

    // #region JSX.Elements...
    const getOptionItemsElements = () => {
        if (!form.optionItems) {
            return <></>
        }

        const sourceList: Domain.Shared.FieldDefinitionOptionItem[] = _.orderBy(form.optionItems, item => item.order);
        return <List
            useNativeScrolling={false}
            scrollingEnabled={sourceList.length > 8 ? true : false}
            height={sourceList.length > 8 ? `${px(8 * (IconSize.medium + 20))}` : 'auto'}
            dataSource={sourceList}
            keyExpr='id'
            className='lias-list row-grey-background'
            selectionMode='none'
            noDataText='Geen gegevens beschikbaar'
            pageLoadingText='Laden...'
            itemRender={(item) => {
                const hexColor = item.color !== undefined ? `#${item.color?.toString(16).padStart(6, '0')}` : palette.grey1;
                return (<Styled.StyledItem canRestore={false}>
                    <SVGIcon value={props.icons[item.icon]?.svg} size={IconSize.medium} color={hexColor} />
                    <Styled.StyledLabel title={item.name}>{item.name}</Styled.StyledLabel>
                    <Button
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        aria-label="Bewerken waarde"
                        icon={<Edit />}
                        onClick={() => setShowOptionForm({ visible: true, mode: FormMode.Edit, optionItem: item })} />
                    <Button
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        aria-label="Verwijder waarde"
                        icon={<Delete />}
                        onClick={() => {
                            const optionItems = [...form.optionItems];
                            const optionItem = optionItems.find(oi => oi.id === item.id);
                            _.remove(optionItems, optionItem);
                            onChange(optionItems, 'optionItems');
                        }} />
                </Styled.StyledItem>);
            }}>
            <ItemDragging
                allowReordering={true}
                dragDirection='vertical'
                onReorder={(e) => {
                    const movedElement: Domain.Shared.FieldDefinitionOptionItem = { ...sourceList[e.fromIndex], order: e.toIndex + 1 }; // with new order
                    const removedFrom = [
                        ...sourceList.slice(0, e.fromIndex), //correct order
                        ...sourceList.slice(e.fromIndex + 1).map(e => ({ ...e, order: e.order - 1 })), //one smaller order
                    ];
                    const insertedTo = [
                        ...removedFrom.slice(0, e.toIndex), //correct order
                        movedElement,
                        ...removedFrom.slice(e.toIndex).map(e => ({ ...e, order: e.order + 1 })) // one bigger order
                    ];
                    setForm({
                        ...form,
                        optionItems: insertedTo
                    });
                }}
            />
        </List>;
    };
    // #endregion JSX.Elements...

    return (<>
        <LsModal
            id={`modal-add-field-${props.fieldDefinition?.id}`}
            title={props.mode === FormMode.AddNew ? 'Veld aanmaken' : 'Veld bewerken'}
            width={768}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Opslaan',
                onRightButtonClick: onSave,
                rightButtonDisabled: validationErrors.hasErrors || !form.dataType
            }}
        >
            <MuiGrid container
                spacing={{ xs: 2 }}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                {props.customNavigationElement &&
                    <MuiGrid key='key-custom-navigation-element' item xs={1}>
                        {props.customNavigationElement}
                    </MuiGrid>
                }
                <MuiGrid key='key-field-definition-type' item xs={1}>
                    <AutoFocus>
                        <SelectElement<IDataItemProps<FieldType>>
                            id='field-definition-type'
                            label='Soort veld'
                            displayExpr='label'
                            optionItems={optionItems}
                            value={selectedFieldType}
                            clearable={true}
                            searchable={true}
                            editorSettings={{
                                disabled: props.mode === FormMode.Edit,
                                restrictions: { required: true },
                                validationErrors: [],
                                onChange: (item) => {
                                    if (!item) {
                                        setSelectedFieldType({ label: '', value: FieldType.None })
                                    } else {
                                        setSelectedFieldType(item)
                                    }
                                }
                            }}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid key='key-field-definition-name' item xs={1}>
                    <TextElement
                        id='field-definition-name'
                        label='Naam'
                        editorSettings={editorSettings<string>('name' as keyof Domain.Shared.FieldDefinition, (value) => { onChange(value, 'name') }, true, { minLength: 2, maxLength: 50 })}
                        value={form['name']} />
                </MuiGrid>
                {(selectedFieldType.value === FieldType.MultilineText || selectedFieldType.value === FieldType.SingleLineText) &&
                    <MuiGrid key='key-field-definition-max-length' item xs={1}>
                        <IntegerElement
                            id='field-definition-max-length'
                            label='Maximaal aantal tekens'
                            editorSettings={editorSettings<number>('stringMaxLength' as keyof Domain.Shared.FieldDefinition, (value) => { onChange(value, 'stringMaxLength') }, true, null)}
                            value={form['stringMaxLength']}
                        />
                    </MuiGrid>
                }
                {(selectedFieldType.value === FieldType.Numeric) &&
                    <MuiGrid key='key-field-definition-format' item xs={1}>
                        <SelectElement<IDataItemProps<string>>
                            id='field-definition-format'
                            label='Format'
                            displayExpr='label'
                            optionItems={formatOptionItems}
                            clearable={true}
                            searchable={true}
                            editorSettings={{
                                restrictions: {},
                                validationErrors: [],
                                onChange: (item) => {
                                    setForm({
                                        ...form,
                                        editorSettings: getControlSettings(selectedFieldType, item?.value),
                                    });
                                }
                            }}
                            value={formatOptionItems.find(ot => ot.value === getEditorSettings(form['editorSettings'])?.numericFormatString)}
                        />

                    </MuiGrid>
                }
                {(selectedFieldType.value === FieldType.Dropdown) &&
                    <MuiGrid key='key-field-definition-optionlist' item xs={1}>
                        <Label>Waarden</Label>
                        <MandatoryIcon />
                        <Styled.StyledAddOptions>
                            {getOptionItemsElements()}
                            {validationErrors.errors['optionItems'] && <Feedback
                                error={!!validationErrors.errors['optionItems'][0]?.error}
                                children={validationErrors.errors['optionItems'][0]?.error}
                                withCounter={false}
                                id='optionItems-feedback'
                            />}
                            <Button
                                id='btn-create-option-item'
                                btnbase='ghostbuttons'
                                btntype='small_icon'
                                icon={<Add />}
                                className="mt-050"
                                onClick={() => {
                                    const temp = { ...form };
                                    const val = (temp.optionItems && temp.optionItems.length > 0) ? Math.max(...temp.optionItems.map(item => item.order)) + 1 : 1;
                                    const blankOptionItem = {
                                        id: uuid(),
                                        name: '',
                                        order: val,
                                        value: val
                                    };
                                    setShowOptionForm({ visible: true, mode: FormMode.AddNew, optionItem: blankOptionItem });
                                }}
                            >
                                Voeg toe
                            </Button>
                        </Styled.StyledAddOptions>
                    </MuiGrid>
                }
                {(selectedFieldType.value !== FieldType.Boolean) &&
                    <MuiGrid key='key-field-definition-required' item xs={1}>
                        <ToggleElement
                            id='field-definition-required'
                            label='Verplicht veld'
                            booleanTrueLabel='Aan'
                            booleanFalseLabel='Uit'
                            editorSettings={editorSettings<boolean>('required' as keyof Domain.Shared.FieldDefinition, (value) => { onChange(value, 'required') }, true, null)}
                            value={form['required']}
                        />
                    </MuiGrid>
                }
            </MuiGrid>
        </LsModal>
        {showOptionForm?.visible &&
            <OptionItemForm
                optionItem={showOptionForm.optionItem}
                icons={props.icons}
                onSave={(optionItem: Domain.Shared.FieldDefinitionOptionItem) => {
                    if (showOptionForm.mode === FormMode.Edit) {
                        const tmpOptionItems = [...form.optionItems];
                        const replacedOptionItem = tmpOptionItems.find(oi => oi.id === optionItem.id);
                        _.remove(tmpOptionItems, replacedOptionItem);
                        const newValue = tmpOptionItems.concat({ ...optionItem });
                        onChange(newValue, 'optionItems');
                    } else {
                        const tmpOptionItems = [...form.optionItems];
                        const newValue = tmpOptionItems.concat({ ...optionItem })
                        onChange(newValue, 'optionItems');
                    }
                    setShowOptionForm({ visible: false, mode: FormMode.View, optionItem: undefined });
                }}
                onCancel={() => setShowOptionForm({ visible: false, mode: FormMode.View, optionItem: undefined })}
            />
        }
    </>);
};

const optionItems: IDataItemProps<FieldType>[] = [
    {
        label: 'Geheel getal',
        value: FieldType.Integer
    },
    {
        label: 'Numeriek',
        value: FieldType.Numeric
    },
    {
        label: 'Enkele regel tekst',
        value: FieldType.SingleLineText
    },
    {
        label: 'Meerdere regels tekst',
        value: FieldType.MultilineText
    },
    {
        label: 'Tekst met opmaak',
        value: FieldType.FormattedText
    },
    {
        label: 'Datum',
        value: FieldType.Date
    },
    {
        label: 'Lijst met waarden',
        value: FieldType.Dropdown
    },
    {
        label: 'Ja/Nee',
        value: FieldType.Boolean
    },
    {
        label: 'Hyperlink',
        value: FieldType.Hyperlink
    },
];

const getFieldType = (fieldDefinition: Domain.Shared.FieldDefinition): IDataItemProps<FieldType> => {
    const res: IDataItemProps<FieldType> = { value: null, label: fieldDefinition.name };
    switch (fieldDefinition.dataType) {
        case Domain.Shared.FieldDataType.String.toString():
            const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
            switch (editorSettings?.stringDisplayFormat) {
                case Domain.Studio.StringDisplayType.SingleLine:
                    return { ...res, value: FieldType.SingleLineText };
                case Domain.Studio.StringDisplayType.MultiLine:
                    return { ...res, value: FieldType.MultilineText };
                case Domain.Studio.StringDisplayType.HTML:
                    return { ...res, value: FieldType.FormattedText };
                case Domain.Studio.StringDisplayType.HyperLink:
                    return { ...res, value: FieldType.Hyperlink };
                default:
                    return { ...res, value: FieldType.SingleLineText };
            }
        case Domain.Shared.FieldDataType.DateTimeOffset.toString(): return { ...res, value: FieldType.Date };
        case Domain.Shared.FieldDataType.Decimal.toString(): return { ...res, value: FieldType.Numeric };
        case Domain.Shared.FieldDataType.Integer.toString(): return { ...res, value: FieldType.Integer };
        case Domain.Shared.FieldDataType.Option.toString(): return { ...res, value: FieldType.Dropdown };
        case Domain.Shared.FieldDataType.Boolean.toString(): return { ...res, value: FieldType.Boolean };
    }
    return;
};

const validate = (form: Domain.Shared.FieldDefinition, fieldNames: string[], errors: Record<string, ValidationErrorData[]>, currentField: Domain.Shared.FieldDefinition, mode: FormMode) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    let validators: ValidatorsDictionary;

    if (form.dataType === Domain.Shared.FieldDataType.String && (form.stringType === Domain.Shared.StringType.SingleLine || form.stringType === Domain.Shared.StringType.MultiLine)) {
        validators = {
            'name': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
            'stringMaxLength': new NumberValidator({ required: true, minValue: 2, maxValue: 60000 }),
        };
    } else if (form.dataType === Domain.Shared.FieldDataType.Option) {
        validators = {
            'name': new TextValidator({ required: true, stringMaxLength: 200, stringType: Domain.Shared.StringType.SingleLine, }, (value: string): ValidationErrorData[] => {
                const namesList = (mode === FormMode.Edit)
                    ? fieldNames.filter(f => f !== currentField.name).map(name => name.toLocaleLowerCase())
                    : fieldNames.map(name => name.toLocaleLowerCase());
                if (namesList.includes(value.toLocaleLowerCase())) {
                    return [{ error: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioFieldNotUnique] }];
                }
                return [];
            }),
            'optionItems': new BasicValidator<Domain.Shared.FieldDefinitionOptionItem[]>({ required: false },
                (value: Domain.Shared.FieldDefinitionOptionItem[]): ValidationErrorData[] => {
                    if (!value.length) {
                        return [{ error: 'Veld vereist.' }];
                    }
                    return [];
                }),
        };
    } else {
        validators = {
            'name': new TextValidator({ required: true, stringMaxLength: 200, stringType: Domain.Shared.StringType.SingleLine, }, (value: string): ValidationErrorData[] => {
                const namesList = (mode === FormMode.Edit)
                    ? fieldNames.filter(f => f !== currentField.name).map(name => name.toLocaleLowerCase())
                    : fieldNames.map(name => name.toLocaleLowerCase());
                if (namesList.includes(value.toLocaleLowerCase())) {
                    return [{ error: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioFieldNotUnique] }];
                }
                return [];
            }),
        };
    }

    return FormHelper.validateForm(validators, dictionary, errors);
};

const getControlSettings = (selectedFieldType: IDataItemProps<FieldType>, numericFormatString: string = null): string => {
    let stringFormat = null;
    let numberFormat = null;

    switch (selectedFieldType.value) {
        case FieldType.SingleLineText:
            stringFormat = Domain.Studio.StringDisplayType.SingleLine;
            break;
        case FieldType.MultilineText:
            stringFormat = Domain.Studio.StringDisplayType.MultiLine;
            break;
        case FieldType.FormattedText:
            stringFormat = Domain.Studio.StringDisplayType.HTML;
            break;
        case FieldType.Hyperlink:
            stringFormat = Domain.Studio.StringDisplayType.HyperLink;
            break;
        case FieldType.Numeric:
            numberFormat = numericFormatString;
            break;
        default:
            break;
    }

    if (!!stringFormat && !!numberFormat) {
        //No special control formatting needed.
        return null;
    }

    const editorSettings: Domain.Studio.FieldEditorControlSettings = {
        stringDisplayFormat: stringFormat,
        numericFormatString: numberFormat,
    };

    return JSON.stringify(editorSettings);
};

const getEditorSettings = (settings: string): Domain.Studio.FieldEditorControlSettings => {
    return settings ? JSON.parse(settings) : undefined;
};
