import styled from 'styled-components';
import { px, Heading1 } from '@liasincontrol/ui-basics';

const LeftSideBarPanel = styled.div`
    height: 100%;
    overflow-y: auto;
    width: ${px(500)};

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const TabsPlaceholder = styled.div`
    background-color: #FFF;
    grid-column: 2;
    grid-row: 1 / -1;
`;

const EdgeToolbarContent = styled.div`
    margin: ${px(24)};
`;

const ToolbarTitle = styled(Heading1)`
    display: block;
    margin: 0 0 ${px(24)} 0;
`;


const styledComponents = {
    LeftSideBarPanel, TabsPlaceholder, EdgeToolbarContent, ToolbarTitle
};

export default styledComponents;