import React, { useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import he from 'he';
import ReactDOM from 'react-dom/client';
import dxDataGrid from 'devextreme/ui/data_grid';
import { dxElement } from 'devextreme/core/element';
import { Format, formatDate, formatNumber } from 'devextreme/localization';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Pager, Paging, Sorting, GroupPanel, IColumnProps, Toolbar, Item, SearchPanel, Column, Summary, KeyboardNavigation } from 'devextreme-react/data-grid';
import { IconSize } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { StringUtils } from '@liasincontrol/core-service';
import { IDataGridOptions } from 'devextreme-react/data-grid';
import { CustomProps, defaultPageSizes, injectTargetBlank } from '../..';
import { GroupRenderModeProps, groupCellRender, getColumnFormat } from "../../helpers/renderModes";
import * as TypeFormatter from '../helper/formatters';

import Tooltip from "devextreme-react/tooltip"
import { on } from "devextreme/events";

export type LsDataGridOptions = IDataGridOptions & {
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,
    readonly iconSize?: IconSize,

    /**
     * Summary total items settings.
     */
    readonly summaryItems?: ILsSummaryItem[];
    /**
    * Column chooser visible
    */
    readonly enableColumnChooser?: boolean;
    /**
     * Summary grouped items settings.
     */
    readonly groupItems?: ILsGroupItem[];
    /**
     * Enables client side sorting & paging.
     */
    readonly clientSide?: boolean;
    /**
     * The title setting of datatable.
     */
    readonly title?: string;
    /**
     * The description setting of datatable.
     */
    readonly description?: string;
    /**
     * The publication element needed for title styles.
     */
    readonly publicationElement: Domain.Publisher.PublicationElement,

    /* set panel visibility*/
    readonly panelsVisible?: boolean,

    readonly className?: string,

    readonly totalSummaryText?: string,

    readonly characterWidth?: number,

    readonly showExpandCollapse?: boolean,

    readonly columnHidingEnabled?: boolean,
}

export type LsGridColumnProps = IColumnProps & GroupRenderModeProps & CustomProps;

export interface ILsSummaryItem {
    alignment?: 'center' | 'left' | 'right',
    column?: string,
    cssClass?: string,
    customizeText?: ((itemInfo: { value?: string | number | Date, valueText?: string }) => string),
    displayFormat?: string,
    name?: string,
    showInColumn?: string,
    skipEmptyValues?: boolean,
    summaryType?: 'avg' | 'count' | 'custom' | 'max' | 'min' | 'sum' | string,
    valueFormat?: Format,
    divide?: 1 | 1000 | 1000000,
}

export interface ILsGroupItem {
    alignByColumn?: boolean,
    column?: string,
    customizeText?: ((itemInfo: { value?: string | number | Date, valueText?: string }) => string),
    displayFormat?: string,
    name?: string,
    showInColumn?: string,
    showInGroupFooter?: boolean,
    skipEmptyValues?: boolean,
    summaryType?: 'avg' | 'count' | 'custom' | 'max' | 'min' | 'sum' | string,
    valueFormat?: Format,
    divide?: 1 | 1000 | 1000000,
}

export interface ILsContentReadyEvent {
    component?: dxDataGrid;
    element?: dxElement;
    model?: any;
}

export const LsDataTable = (props: LsDataGridOptions) => {
    const { columns, summaryItems, groupItems, ...restProps } = props;
    const [hasData, setHasData] = useState(false);

    const gridRef = useRef(null);
    const tooltipRef = useRef(null);
    const isExpanded = useRef(false);

    //TODO: There are so many null checks of columns and mappedColumns throughout this component. Maybe we can just render an empty grid if 
    //the columns prop is empty or undefined and just be care free in the rest of the component's code

    const mappedColumns = columns?.map((column) => {
        const mappedColumn = column as LsGridColumnProps;

        const ret = {
            dataField: mappedColumn.dataField,
            dataType: mappedColumn.dataType,
            alignment: mappedColumn.alignment,
            cssClass: mappedColumn.cssClass,
            caption: mappedColumn.caption,
            visible: mappedColumn.visible,
            sortIndex: mappedColumn.sortIndex,
            sortOrder: mappedColumn.sortOrder,
            width: mappedColumn.width,
            key: mappedColumn.dataField,
            minWidth: mappedColumn.minWidth || 64,
            encodeHtml: !mappedColumn.groupRenderMode && mappedColumn.format !== "html" && mappedColumn.format !== "hyperlink",
            groupIndex: mappedColumn.groupIndex,
            autoExpandGroup: !!mappedColumn.autoExpandGroup,
            format: getColumnFormat(column),
            allowGrouping: mappedColumn.format !== "html",
            groupCellTemplate: (container, params) => {
                const cellDom = ReactDOM.createRoot(container as HTMLElement);
                cellDom.render(cellRender(params.displayValue, params.column));
            },
            // DevExtreme: Do not use this (aka calculateDisplayValue) property to format text in cells. 
            // Instead, use the format, customizeText, or cellTemplate property.
            customizeText: mappedColumn.divide && mappedColumn.divide > 1 ? (cellInfo) => {
                if (!cellInfo.value || !mappedColumn?.divide) {
                    return cellInfo.value?.toString() || '';
                }

                if (mappedColumn.divide > 1) {
                    const newValue = Math.round(cellInfo.value / mappedColumn.divide);
                    return (mappedColumn.format ? formatNumber(newValue, mappedColumn.format) : newValue.toString());
                }

                return cellInfo.valueText.toString();
            } : undefined,

            calculateCellValue: mappedColumn.groupRenderMode || mappedColumn.dataType === "string"
                ? (rowData) => {
                    let cellValue = mappedColumn.groupRenderMode ? rowData[`${mappedColumn.propertyGroupName}Name`] : rowData[mappedColumn.dataField];
                    if (mappedColumn.format === "hyperlink") {
                        cellValue = injectTargetBlank(cellValue);
                    }
                    return he.decode(String(cellValue ?? ''));
                } : undefined,
            calculateGroupValue: mappedColumn.groupRenderMode || mappedColumn.divide
                ? (rowData) => {
                    const groupValue = mappedColumn.divide
                        ? Math.round(rowData[mappedColumn.dataField] / mappedColumn.divide)
                        : rowData[`${mappedColumn.propertyGroupName}Name`];
                    return groupValue;
                } : undefined
        };
        if (!mappedColumn) { return ret; }

        if (mappedColumn.groupRenderMode) {
            ret['calculateSortValue'] = (rowData) => {
                const sortValue = rowData[`${mappedColumn.propertyGroupName}Name`];
                return sortValue;
            };
            ret['cellTemplate'] = (container, params) => {
                const templateNode = groupCellRender(params.data, column, restProps.icons, restProps.iconSize);
                const cellDom = ReactDOM.createRoot(container as HTMLElement);
                cellDom.render(templateNode);
            };
        }

        if (mappedColumn.columnWidth && restProps.characterWidth) {
            //approximated width of a character * length + adjustments
            ret['width'] = `${(restProps.characterWidth * mappedColumn.columnWidth) + 24}px`;
            ret['autoFill'] = false;
        } else {
            ret['width'] = undefined;
            ret['autoFill'] = true;
        }
        return ret;
    }).filter(x => !_.isEmpty(x));

    if (mappedColumns?.length) {
        const hasAutoColumn = mappedColumns.some((column) => !column.width || column.width === 'auto');
        if (!hasAutoColumn && mappedColumns.length > 0) {
            mappedColumns[0]['width'] = undefined;
            mappedColumns[0]['autoFill'] = true;
        }
    }

    const totalSummaries: ILsSummaryItem[] = summaryItems ? [...summaryItems] : [];
    if (mappedColumns?.length && totalSummaries.length && restProps.totalSummaryText) {
        const firstVisible = mappedColumns.find(col => col.visible && col.groupIndex === undefined);
        if (firstVisible) {
            const itemIndex = totalSummaries.findIndex(sum => sum.column === firstVisible?.dataField);

            if (itemIndex === -1) {
                //not first column summarized
                totalSummaries.push({
                    alignment: firstVisible.alignment,
                    column: firstVisible.dataField,
                    summaryType: 'custom',
                    customizeText: (data) => {
                        return `${restProps.totalSummaryText}`;
                    }
                });
            } else {
                //first column summarized
                totalSummaries[itemIndex] = {
                    ...totalSummaries[itemIndex],
                    customizeText: (data) => {
                        return `${restProps.totalSummaryText}: ${data.valueText ?? data.value}`;
                    }
                }
            }
        }
    }

    const summary = {
        texts: defaultSummaryTexts,
        groupItems: groupItems.map(mapValueFormatFunction),
        totalItems: totalSummaries.map(mapValueFormatFunction),
    };

    const hasGroups = mappedColumns?.some((c: any) => c.groupIndex !== undefined);
    const hasDivide = mappedColumns?.some((c: any) => c.calculateDisplayValue !== undefined);

    //we use contentReady event to wait for the datasource to load it's data (with filters and groups) so we can check if it has any rows or not
    const onContentReady = () => {
        const items = (restProps.dataSource as DataSource<any, any>).items();
        setHasData(items?.length > 0);
    }

    //TODO: react/devextreme is not working properly here so we use ref and set the text manually
    const buttonOptions = useMemo(() => {
        return {
            text: (isExpanded.current ? 'ALLES INKLAPPEN' : 'ALLES UITKLAPPEN'),
            stylingMode: 'text',
            onClick: (e) => {
                e.event?.stopPropagation();
                if (isExpanded.current) {
                    gridRef?.current?.instance()?.collapseAll();
                    isExpanded.current = false;
                    e.component.option('text', 'ALLES UITKLAPPEN');
                } else {
                    gridRef?.current?.instance()?.expandAll();
                    isExpanded.current = true;
                    e.component.option('text', 'ALLES INKLAPPEN');
                }
            },
        };
    }, []);

    return (<>
        <DataGrid
            {...restProps}
            ref={gridRef}
            className={restProps.className}
            noDataText='Geen gegevens beschikbaar'
            repaintChangesOnly={false}
            width='100%'
            onContentReady={onContentReady}
            allowColumnReordering={false}
            columnAutoWidth={false}
            showBorders={true}
            showColumnLines={true}
            wordWrapEnabled={true}
            cellHintEnabled={true}
            onCellPrepared={hasDivide ? (e) => {
                if ((e.rowType === "data" || e.rowType === "group" || e.rowType === "groupFooter" || e.rowType === "totalFooter") && !!e.column.calculateDisplayValue) {
                    on(e.cellElement, "mouseover", arg => {
                        const valueFormat = getColumnFormat(e.column);
                        const displayedContent = e.rowType === "data"
                            ? [formatNumber(e.data[e.column.dataField], valueFormat)]
                            : e['summaryItems'].map(summary => formatNumber(summary.value, valueFormat)).join(', ');
                        tooltipRef?.current?.instance()?.option("contentTemplate", () => (`<div class='tooltipContent'><div>${displayedContent}</div></div>`));
                        tooltipRef?.current?.instance()?.show(arg.target);
                    });

                    on(e.cellElement, "mouseout", arg => {
                        tooltipRef?.current?.instance()?.hide();
                    });
                }
            } : undefined}
        >
            {mappedColumns?.map((column) => {
                const mappedColumn = column as LsGridColumnProps;
                return <Column {...mappedColumn} />;
            })}
            {(groupItems.length > 0 || totalSummaries.length > 0) && <Summary {...summary} />}

            <GroupPanel visible={true} />
            <SearchPanel visible={true} placeholder='Zoeken...' searchVisibleColumnsOnly={true} highlightCaseSensitive={false} />
            <Toolbar visible={restProps.panelsVisible}>
                <Item name="groupPanel" locateInMenu="auto" location="before" />
                <Item visible={hasGroups && hasData} widget="dxButton" locateInMenu="auto" options={buttonOptions} />
                <Item name="searchPanel" locateInMenu="auto" location="after" />
            </Toolbar>
            <Sorting mode="none" />
            <KeyboardNavigation enabled={false} />
            <Paging enabled={restProps.paging!.enabled} defaultPageSize={restProps.paging!.pageSize} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={defaultPageSizes}
                showInfo={true}
            />
        </DataGrid >
        {hasDivide && <Tooltip ref={tooltipRef} position="right" />}
    </>
    );
};

export const noColumnNameGroupCellTemplate = (cellElement: HTMLElement, cellInfo: { value?: any }): HTMLElement => {
    cellElement.appendChild(document.createTextNode(cellInfo.value));
    return cellElement;
};


const cellRender = (text = '', column) => {
    if (!text) return;
    switch (column.dataType) {
        case "boolean":
            return <>{text ? 'Ja' : 'Nee'}</>;
        case "number":
            return <>{TypeFormatter.decimalFormatter(+text, column.format)}</>;
        case "date":
        case "datetime":
            return <>{formatDate(new Date(text), column.format)}</>;
        case "html":
        case "string":
            return (<span dangerouslySetInnerHTML={StringUtils.toRawMarkup(text)} style={{ overflowY: 'hidden', boxSizing: 'border-box' }}></span>);
        default:
            return <>{text}</>;
    }
};

const mapValueFormatFunction = ({ valueFormat, ...item }: ILsGroupItem) => {
    const ret = { ...item };
    ret['valueFormat'] = item.divide ? (rowData) => {
        const cellValue = Math.round(rowData / item.divide!);

        return (valueFormat ? formatNumber(cellValue, valueFormat) : cellValue);
    } : valueFormat;

    return ret;
};

const defaultSummaryTexts = { sum: '{0}', 'avg': '{0}', 'count': '{0}', 'max': '{0}', 'min': '{0}' };
