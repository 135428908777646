import React from 'react';
import * as Domain from '@liasincontrol/domain';
import * as Styled from './index.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    isOpened: boolean,
    errors: Domain.Publisher.BuildError[],
    onClose?: () => void,
};

/**
 * Modal dialog for that shows the build errors when a publication is published.
 */
export const ErrorLogDialog: React.FC<Props> = (props) => {
    if (!props.isOpened) {
        return null;
    }

    return (
        <LsModal
            id={`modal-error-log`}
            title='Foutenlog'
            toolbar={{
                enabled: true,
                rightButtonText: "Sluiten",
                onRightButtonClick: props.onClose,
            }}
        >
            <Styled.ErrorMessages>
                {props.errors.map((err, i) => (
                    <React.Fragment key={i}>{err.code}: {err.detail}<br /></React.Fragment>
                ))}
            </Styled.ErrorMessages>
        </LsModal>
    );
};