import React, { useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { CheckboxElement, MultiLineTextElement, TextElement } from '@liasincontrol/ui-elements';
import { Label, MandatoryIcon, Text } from '@liasincontrol/ui-basics';
import { AnyFormData, BasicValidator, FormHelper, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import * as Styled from '../index.styled';
import _ from 'lodash';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    newWorkflowStateName: string,
    saveButtonDisabled: boolean,
    previousWorkflowStateUsers?: Domain.Publisher.WorkflowTask[],
    onCloseDialog: () => void,
    onChangeWorkflowState: (userIds: string[], remark: string) => void,
}

export const SetWorkflowStateDialog: React.FC<Props> = (props) => {
    const [form, setForm] = useState<AnyFormData>(initFormData(props.newWorkflowStateName));
    const [validationErrors, setValidationErrors] = useState<{ errors: Record<string, ValidationErrorData[]>, hasErrors: boolean, isTouched: boolean; }>({ errors: {}, hasErrors: false, isTouched: false, });

    const onChange = (value: string | number | string[], fieldName: string) => {
        const data = { ...form.values };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm((prev) => ({ ...prev, values: data }));

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        temporaryValidationError.isTouched = true;
        setValidationErrors(temporaryValidationError);
    };

    return (
        <LsModal
            id='modal-set-workflow-state'
            title='Status wijzigen'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCloseDialog,
                rightButtonText: 'WIJZGEN',
                onRightButtonClick: () => {
                    props.onChangeWorkflowState(form?.values?.userIds, !form?.values?.remark ? null : form?.values?.remark)
                },
                rightButtonDisabled: props.saveButtonDisabled || validationErrors.hasErrors || !validationErrors.isTouched,
            }}
        >
            <Text value="U staat op het punt om het item terug te plaatsen naar de vorige workflowstatus. Taken van de geselecteerde personen uit de vorige workflowstatus worden opnieuw geactiveerd." />
            <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={1}>
                <MuiGrid item key='newWorkflowState-name' xs={1}>
                    <TextElement
                        id='newWorkflowState-name'
                        label='Nieuwe workflowstatus'
                        editorSettings={{
                            disabled: true,
                            validationErrors: validationErrors.errors['newWorkflowStateName'],
                            restrictions: { required: true, minLength: 2, maxLength: 100 },
                            onChange: (value: string) => onChange(value, 'newWorkflowStateName'),
                        }}
                        value={form?.values?.newWorkflowStateName}
                    />
                </MuiGrid>
                <MuiGrid item key='newWorkflowState-users' xs={1}>
                    <Label text="Taak toewijzen aan" />
                    <MandatoryIcon float='none' />
                    {validationErrors.errors['userIds'] && <Text danger value={validationErrors.errors['userIds'][0]?.error} />}
                    <Styled.CustomWrapper>
                        {props.previousWorkflowStateUsers?.map((task) => (
                            <Styled.OptionWrapper>
                                <CheckboxElement
                                    id={task.userId}
                                    altLabel={task.userName}
                                    value={form?.values?.userIds.some((u) => u === task.userId)}
                                    editorSettings={{
                                        disabled: false,
                                        restrictions: { required: false },
                                        validationErrors: [],
                                        onChange: (value: boolean) => {
                                            let ids = [];
                                            if (value)
                                                ids = form?.values?.userIds.concat(task.userId);
                                            else
                                                ids = form?.values?.userIds.filter((p) => p !== task.userId);
                                            onChange(ids, 'userIds');
                                        },
                                    }}
                                />
                            </Styled.OptionWrapper>
                        ))}
                    </Styled.CustomWrapper>
                </MuiGrid>
                <MuiGrid item key='newWorkflowState-remark' xs={1}>
                    <MultiLineTextElement
                        id='newWorkflowState-remark'
                        rows={4}
                        label='Opmerking'
                        key='newWorkflowState-remark'
                        editorSettings={{
                            disabled: false,
                            validationErrors: validationErrors.errors['remark'],
                            restrictions: { required: false, minLength: 2, maxLength: 100 },
                            onChange: (value: string) => onChange(value, 'remark'),
                        }}
                        value={form?.values?.remark}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    );
};

const validate = (values: Record<string, any>, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(values).reduce((a, x) => ({ ...a, [x]: values[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'newWorkflowStateName': new TextValidator({ required: true, stringMaxLength: 100, stringType: Domain.Shared.StringType.SingleLine }),
    'remark': new TextValidator({ required: false, stringMaxLength: 5000, stringType: Domain.Shared.StringType.MultiLine }),
    'userIds': new BasicValidator<string[]>({ required: false },
        (value: string[]): ValidationErrorData[] => {
            if (value.length <= 0) {
                return [{ error: 'Veld vereist.' }];
            }
            return [];
        }),

};

/**
 * Initialises the form data with entity type data.
 */
const initFormData = (newWorkflowStateName: string): AnyFormData => {
    return {
        values: {
            'newWorkflowStateName': newWorkflowStateName,
            'remark': '',
            'userIds': [],
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};