import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { SortDescriptor } from 'devextreme/data';
import _ from 'lodash';
import { ILsColumnProps, ILsPagingProps } from '../..';

/**
 * Create a general datasource used by datasource based components, with devextreme.
 */
export const CreateCustomDataSource = (
    data: Array<{}>,
    columns?: ILsColumnProps[],
    paging?: ILsPagingProps,
    filterValue?: string | Array<any> | Function,
    sortExpression?: SortDescriptor<ILsColumnProps> | SortDescriptor<ILsColumnProps>[],
    normalizeFilter = false): DataSource => {

    const source = new DataSource({
        store: new CustomStore({
            load: () => data,
            loadMode: 'raw',

        }),
        langParams: {
            locale: 'nl',
            collatorOptions: {
                sensitivity: 'accent',
            }
        }
    });

    if (!_.isEmpty(filterValue)) {
        if (normalizeFilter) {
            source.filter(specialCharactersNormalizedArray(filterValue));
        } else {
            source.filter(filterValue);
        }
    }

    if (paging) {
        source.paginate(true);
        source.pageSize(paging.pageSize);
        source.requireTotalCount(true);
    } else {
        source.paginate(false);
    }

    if (sortExpression && sortExpression !== null && !_.isEmpty(sortExpression)) {
        source.sort(sortExpression);
    }

    if (columns) {
        source.select(columns.map(c => c.dataField));
    }

    return source;
}

export const encodeSpecialChars = (text: string): string => {
    // eslint-disable-next-line no-control-regex
    return text?.replace(/[^\u0000-\u007F]/g, (char: string) => {
        return `&#${char.charCodeAt(0)};`;
    });
};

export const specialCharactersNormalizedArray = (value) => {
    if (Array.isArray(value)) {
        return value.map((element) => specialCharactersNormalizedArray(element));
    } else {
        if (typeof value === 'string') {
            return encodeSpecialChars(value);
        }
        return value;
    }
};
