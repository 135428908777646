import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Grid as MuiGrid } from '@mui/material';
import { FormData, FormMode, ValueType } from '@liasincontrol/core-service';
import { isDataStoreType } from '../DataSourcesList/index.helper';
import { DataPlatformForm } from './SubForms/DataPlatformForm';
import { HierarchyForm } from './SubForms/HierarchyForm';
import { SharedForm } from './SubForms/SharedForm';
import { FinanceForm } from './SubForms/FinanceForm';

type Props = {
    dataSource?: Domain.Publisher.DataSourceElement,
    dataStore?: { id: string, kind: string, element: Domain.Publisher.DataStoreElement },
    form: FormData<ValueType>,
    elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    measureMoments: Domain.Shared.MeasureMoment[],
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    studioElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    performanceElementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    structures: Domain.Finance.Structure[],
    mode: FormMode,
    externalErrors?: Record<string, string>,
    onFormDataChanged: (formInfo: FormData<ValueType>, type?: string) => void,
    onError?: (error: Domain.Shared.ErrorInfo, showAsWarning?: boolean) => void,
    inValidateForm?: () => void,
};

/**
 * Represents a UI component that renders a form to view/edit a data source.
 */
const DataSourceForm: React.FC<Props> = (props) => {
    const [selectedDataStore, setSelectedDataStore] = useState<{ id: string, kind: string, element: Domain.Publisher.DataStoreElement }>
        ({ id: null, kind: null, element: null });

    useEffect(() => {
        if (props.mode !== FormMode.AddNew) {
            if (!props.dataSource?.dataStoreId || !props.dataStore.kind) return;
            setSelectedDataStore({ id: props.dataSource?.dataStoreId, kind: props.dataStore.kind, element: props.dataStore.element });
        }
    }, [props.dataSource, props.dataStore, props.mode]);

    return (
        <MuiGrid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 2, sm: 1, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
        >
            {/* shared data */}
            <SharedForm
                form={props.form}
                selectedDataStore={selectedDataStore}
                mode={props.mode}
                elementdefinitions={props.elementdefinitions}
                onDataStoreChange={(id, kind, element) => {
                    setSelectedDataStore({ id, kind, element });
                }}
                onChange={(formData: FormData<ValueType>) => {
                    props.onFormDataChanged(formData, selectedDataStore.kind)
                }}
                inValidateForm={props.inValidateForm}
            />
            {/* type based data */}
            {isDataStoreType(selectedDataStore.kind, Domain.Publisher.DataStoreTypes.DataPlatform) && <>
                <DataPlatformForm
                    datasourceId={props.dataSource?.id}
                    form={props.form}
                    selectedDataStore={selectedDataStore}
                    mode={props.mode}
                    elementdefinitions={props.elementdefinitions}
                    onError={props.onError}
                    onChange={(formData: FormData<ValueType>) => {
                        props.onFormDataChanged(formData, selectedDataStore.kind)
                    }}
                />
            </>}
            {isDataStoreType(selectedDataStore.kind, Domain.Publisher.DataStoreTypes.Performance) && <>
                <HierarchyForm
                    form={props.form}
                    selectedDataStore={selectedDataStore}
                    mode={props.mode}
                    measureMoments={props.measureMoments}
                    elementdefinitions={props.elementdefinitions}
                    hierarchyElementDefinitions={props.performanceElementDefinitions}
                    onChange={(formData: FormData<ValueType>) => {
                        props.onFormDataChanged(formData, selectedDataStore.kind)
                    }}
                    inValidateForm={props.inValidateForm}
                />
            </>}
            {isDataStoreType(selectedDataStore.kind, Domain.Publisher.DataStoreTypes.Studio) && <>
                <HierarchyForm
                    form={props.form}
                    selectedDataStore={selectedDataStore}
                    mode={props.mode}
                    measureMoments={props.measureMoments}
                    elementdefinitions={props.elementdefinitions}
                    hierarchyElementDefinitions={props.studioElementDefinitions}
                    hierarchyDefinitions={props.hierarchyDefinitions}
                    onChange={(formData: FormData<ValueType>) => {
                        props.onFormDataChanged(formData, selectedDataStore.kind)
                    }}
                    inValidateForm={props.inValidateForm}
                />
            </>}
            {isDataStoreType(selectedDataStore.kind, Domain.Publisher.DataStoreTypes.Finance) && <>
                <FinanceForm
                    form={props.form}
                    selectedDataStore={selectedDataStore}
                    structures={props.structures}
                    mode={props.mode}
                    elementdefinitions={props.elementdefinitions}
                    measureMoments={props.measureMoments}
                    onChange={(formData: FormData<ValueType>) => {
                        props.onFormDataChanged(formData, selectedDataStore.kind)
                    }}
                    inValidateForm={props.inValidateForm}
                />
            </>}
        </MuiGrid>
    );
};

export { DataSourceForm };
