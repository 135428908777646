import * as Domain from '@liasincontrol/domain';

/**
 * Sort-function to sort an array with fieldDefinitions based on the systemIds.
 * 
 * @param inputA FieldDefinition with a systemId.
 * @param inputB FieldDefinition with a systemId.
 * 
 * @returns a number to detemine the order of the sorting.
 */
const sortFieldDefinitionsList = (inputA: Domain.Shared.FieldDefinition, inputB: Domain.Shared.FieldDefinition): number => {
    const ordered: string[] = [
        Domain.SystemFieldDefinitions.Pub.Name,
        Domain.SystemFieldDefinitions.Pub.Title,
        Domain.SystemFieldDefinitions.Pub.DataTableDescription,
        Domain.SystemFieldDefinitions.Pub.MapDescription,
        Domain.SystemFieldDefinitions.Pub.PivotTableDescription,
        Domain.SystemFieldDefinitions.Pub.BarChartDescription,
        Domain.SystemFieldDefinitions.Pub.LineChartDescription,
        Domain.SystemFieldDefinitions.Pub.PieChartDescription,
        Domain.SystemFieldDefinitions.Pub.MapSize,
        Domain.SystemFieldDefinitions.Pub.TextualContent,
        Domain.SystemFieldDefinitions.Pub.HtmlContent,
        Domain.SystemFieldDefinitions.Pub.PieChartType,
        Domain.SystemFieldDefinitions.Pub.DataSource,
        Domain.SystemFieldDefinitions.Pub.ColumnsAutoHide,
        Domain.SystemFieldDefinitions.Pub.PieChartArgument,
        Domain.SystemFieldDefinitions.Pub.PieChartValue,
        Domain.SystemFieldDefinitions.Pub.PieChartValueFormat,
        Domain.SystemFieldDefinitions.Pub.BarChartArgument,
        Domain.SystemFieldDefinitions.Pub.BarChartValue,
        Domain.SystemFieldDefinitions.Pub.BarChartValueFormat,
        Domain.SystemFieldDefinitions.Pub.BarChartOrientation,
        Domain.SystemFieldDefinitions.Pub.LineChartArgument,
        Domain.SystemFieldDefinitions.Pub.LineChartValue,
        Domain.SystemFieldDefinitions.Pub.LineChartValueFormat,
        Domain.SystemFieldDefinitions.Pub.ChartLegend,
        Domain.SystemFieldDefinitions.Pub.ChartShowLabels,
        Domain.SystemFieldDefinitions.Pub.TableColumnSettings,
        Domain.SystemFieldDefinitions.Pub.PieChartCustomLabel,
        Domain.SystemFieldDefinitions.Pub.PieChartMaximumSlices,
        Domain.SystemFieldDefinitions.Pub.PieChartCombinedSliceLabel,
        Domain.SystemFieldDefinitions.Pub.ImageSize,
        Domain.SystemFieldDefinitions.Pub.ShowCompleteImage,
        Domain.SystemFieldDefinitions.Pub.ImageSource,
        Domain.SystemFieldDefinitions.Pub.ImageFocusPoint,
        Domain.SystemFieldDefinitions.Pub.AltText,
        Domain.SystemFieldDefinitions.Pub.TitleStyling,
        Domain.SystemFieldDefinitions.Pub.ImageCaption,
        Domain.SystemFieldDefinitions.Pub.Filter,
        Domain.SystemFieldDefinitions.Pub.Latitude,
        Domain.SystemFieldDefinitions.Pub.Longitude,
        Domain.SystemFieldDefinitions.Pub.LabelColumnName,
        Domain.SystemFieldDefinitions.Pub.MenuType,
        Domain.SystemFieldDefinitions.Pub.FileSource,
        Domain.SystemFieldDefinitions.Pub.DataSourceTitleColumn,
        Domain.SystemFieldDefinitions.Pub.DataSourceTextColumn,
        Domain.SystemFieldDefinitions.Pub.IsStudioControl,
        Domain.SystemFieldDefinitions.Pub.MeasureMomentId,
        Domain.SystemFieldDefinitions.Pub.HierarchyDefinitionId,
        Domain.SystemFieldDefinitions.Pub.EntityTypeId,
        Domain.SystemFieldDefinitions.Pub.ParentEntityTypeId,
        Domain.SystemFieldDefinitions.Pub.ParentEntityId,
        Domain.SystemFieldDefinitions.Pub.EntityId,
        Domain.SystemFieldDefinitions.Pub.FieldId,
        Domain.SystemFieldDefinitions.Pub.TablePanelIsVisible,
        Domain.SystemFieldDefinitions.Pub.HeaderField,
        Domain.SystemFieldDefinitions.Pub.PivotGridFields,
        Domain.SystemFieldDefinitions.Pub.ShowColumnGrandTotals,
        Domain.SystemFieldDefinitions.Pub.ShowColumnTotals,
        Domain.SystemFieldDefinitions.Pub.ShowRowGrandTotals,
        Domain.SystemFieldDefinitions.Pub.ShowRowTotals,
        Domain.SystemFieldDefinitions.Pub.PivotTableGrandTotalCaption,
        Domain.SystemFieldDefinitions.Pub.TileImageKind,
        // The items below should not be changed.
        Domain.SystemFieldDefinitions.Pub.AllowPatchContent,
        Domain.SystemFieldDefinitions.Pub.PrintAsLandscape,
        Domain.SystemFieldDefinitions.Pub.PivotTableExpandByDefault,
        Domain.SystemFieldDefinitions.Pub.ShowBackground,
        Domain.SystemFieldDefinitions.Pub.ShowShadow,
        Domain.SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField,
        Domain.SystemFieldDefinitions.Pub.StackContainerDirection,
        // HelpText (toelichting) must always be last.
        Domain.SystemFieldDefinitions.Pub.HelpText,
    ];

    const indexInputA = ordered.indexOf(inputA.systemId || '');
    const indexInputB = ordered.indexOf(inputB.systemId || '');

    if (indexInputA === indexInputB) {
        return 0;
    }
    if (indexInputA === -1) {
        return 1;
    }
    if (indexInputB === -1) {
        return -1;
    }

    return indexInputA - indexInputB;
}

const getVirtualWhitespaceFieldDefinition = (): Domain.Shared.FieldDefinition => {
    return {
        attachmentAllowedFileTypes: null,
        attachmentMaxFileSize: 0,
        booleanFalseLabel: null,
        booleanStartValue: null,
        booleanTrueLabel: null,
        dataType: null,
        dateMaxValue: null,
        dateMinValue: null,
        dateStartValue: null,
        decimalMaxValue: 0,
        decimalMinValue: 0,
        decimalStartValue: 0,
        editorSettings: null,
        id: Domain.SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField, //TODO: should we add a different id?
        integerMaxValue: 0,
        integerMinValue: 0,
        integerStartValue: 0,
        name: '_whitespace_group_',
        label: '_whitespace_group_',
        helpText: null,
        helpTextTitle: null,
        optionItems: null,
        optionMaxSelectionCount: 0,
        optionStartValue: null,
        relationElementDefinitionId: null,
        required: false,
        stringMaxLength: 0,
        systemId: Domain.SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField
    };
};

const AccordionTypes = [
    {
        value: 'false',
        label: 'Performance',
    },
    {
        value: 'true',
        label: 'Studio',
    },
];

const getVirtualVariablesFieldDefinition = (): Domain.Shared.FieldDefinition => {
    return {
        attachmentAllowedFileTypes: null,
        attachmentMaxFileSize: 0,
        booleanFalseLabel: null,
        booleanStartValue: null,
        booleanTrueLabel: null,
        dataType: null,
        dateMaxValue: null,
        dateMinValue: null,
        dateStartValue: null,
        decimalMaxValue: 0,
        decimalMinValue: 0,
        decimalStartValue: 0,
        editorSettings: null,
        id: Domain.SystemFieldDefinitions.Pub.VirtualVariablesField, //TODO: should we add a different id?
        integerMaxValue: 0,
        integerMinValue: 0,
        integerStartValue: 0,
        name: '_variables_',
        label: '_variables_',
        helpText: null,
        helpTextTitle: null,
        optionItems: null,
        optionMaxSelectionCount: 0,
        optionStartValue: null,
        relationElementDefinitionId: null,
        required: false,
        stringMaxLength: 0,
        systemId: Domain.SystemFieldDefinitions.Pub.VirtualVariablesField
    };
};

const getFieldDefinition = (elementDefinition: Domain.Shared.ElementDefinition, fieldId: string): Domain.Shared.FieldDefinition => {
    if (fieldId === Domain.SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField) {
        return getVirtualWhitespaceFieldDefinition();
    }

    if (fieldId === Domain.SystemFieldDefinitions.Pub.VirtualPivotGridFields) {
        const auxField = elementDefinition.fields.find((item) => item.systemId === Domain.SystemFieldDefinitions.Pub.PivotGridFields);
        return { ...auxField, systemId: Domain.SystemFieldDefinitions.Pub.VirtualPivotGridFields, id: Domain.SystemFieldDefinitions.Pub.VirtualPivotGridFields };
    }

    if (fieldId === Domain.SystemFieldDefinitions.Pub.VirtualVariablesField) {
        return getVirtualVariablesFieldDefinition();
    }

    if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.ReferenceAttachments || elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.TileMenuControl) {
        const complexFieldDefinition = elementDefinition.complexFields[0].fields.find((item) => item.id === fieldId);
        if (complexFieldDefinition) {
            return complexFieldDefinition;
        }
    }

    return elementDefinition.fields.find((item) => item.id === fieldId);
};

/**
 * Defines a list of systemIds, which need a performance hierarchy loaded.
 */
const controlsRequiredPerformanceHierarchy = [
    Domain.SystemElementDefinitions.Pub.AccordionDataControl,
    Domain.SystemElementDefinitions.Pub.PerformanceInformationControl,
] as string[];

/**
 * Clear dependent fields on cascade from the selected element.
 * 
 * @param selectedElement The selected Pub control.
 * @param elementDefinition The element definition of the selected Pub element.
 * @param fieldSystemIds The list of system Id's from Pub element to be cleaned.
 * @param performanceElementDefinition The performance element definition ??
 * @param headerId The field Id used for header on accordion control.
 * @returns An array with the resetted values.
 */
const getDependentChanges = (selectedElement: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition, fieldSystemIds: string[], hierarchyElementDefinition?: Domain.Shared.ElementDefinition, headerId?: string): Domain.Publisher.FieldPatch[] => {
    const changes: Domain.Publisher.FieldPatch[] = [];
    fieldSystemIds.forEach(fieldSystemId => {
        const dependentField = elementDefinition.fields?.find(e => e.systemId === fieldSystemId);
        let allFields = [];
        if (dependentField && dependentField.id) {
            if (hierarchyElementDefinition && dependentField.systemId === Domain.SystemFieldDefinitions.Pub.FieldsListJson) {
                allFields = hierarchyElementDefinition.fields?.map((e: Domain.Shared.FieldDefinition) => ({
                    fieldId: e.id,
                    label: e.name,
                    visible: !(e.id === headerId),
                    index: 0,
                    showFieldName: true
                })) || [];
            }
            changes.push({
                elementId: selectedElement.elementId,
                fieldId: dependentField.id,
                value: allFields.length > 0 ? JSON.stringify(allFields) : "",
            });
        }
    });
    return changes;
};


/**
 * Get field value from Publisher item.
*/
const getFieldValue = (selectedElement: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition, fieldSystemId: string): string | undefined => {
    const fieldDefinitionId = elementDefinition.fields?.find((item: Domain.Shared.FieldDefinition) => item.systemId === fieldSystemId)?.id;
    const fieldValue = selectedElement.fields[fieldDefinitionId];
    return fieldValue;
};


const getAvailableAncestorsElementDefinitions = (hierachyDefinition: Domain.Shared.HierarchyDefinition, entityTypeId: string, result: Domain.Shared.HierarchyLinkDefinition[] = []): Domain.Shared.HierarchyLinkDefinition[] => {
    const parents = hierachyDefinition.items?.filter((hierarchyDefinitionLink) => !!hierarchyDefinitionLink.fromElementDefinitionId && hierarchyDefinitionLink.toElementDefinitionId === entityTypeId);
    if (parents.length > 0) {
        result = result.concat(parents);
        return getAvailableAncestorsElementDefinitions(hierachyDefinition, parents[0].fromElementDefinitionId, result);
    } else return result;
};

const helpers = {
    sortFieldDefinitionsList, getVirtualWhitespaceFieldDefinition, getVirtualVariablesFieldDefinition,
    AccordionTypes, getFieldDefinition, getAvailableAncestorsElementDefinitions, getFieldValue, getDependentChanges,
    controlsRequiredPerformanceHierarchy
};

export default helpers;