import React, { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Domain from '@liasincontrol/domain';
import { Button, ElementLabel, FlexBox, IconSize, SVGIcon, palette } from '@liasincontrol/ui-basics';
import { IconSelectElement } from '@liasincontrol/ui-elements';
import * as Styled from './IconSelectionEditor.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    value: string,
    disabled?: boolean,
    key?: string,
    id?: string,
    label?: string,
    icons: Record<string, Domain.Shared.SvgIcon>,
    onChange?: (value: string) => void,
}

/**
 * Represents an UI component that renders a performance field list editor.
 */
const IconSelectionEditor: React.FC<Props> = (props) => {
    const [choosingIcon, setChoosingIcon] = useState<boolean>(false);

    const selectedIcon = useMemo(() => props.value ? props.icons[props.value] : null, [props.value, props.icons]);

    return (
        <>
            {props.label && <ElementLabel>{props.label}</ElementLabel>}
            {selectedIcon
                ? <FlexBox>
                    <Button
                        id="btn-change-icon"
                        icon={<SVGIcon value={selectedIcon.svg} size={IconSize.medium} color={palette.grey1} />}
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        onClick={() => setChoosingIcon(true)} />

                    <div className='icon-name'>
                        {selectedIcon.name}
                    </div>
                    <Button
                        id="btn-delete-icon"
                        icon={<DeleteIcon />}
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        onClick={() => props.onChange('')}
                        aria-label="Icoon wijzigen"
                        disabled={props.disabled}
                    />
                </FlexBox>
                :
                <FlexBox><Button
                    id="btn-select-icon"
                    btnbase="ghostbuttons"
                    btntype="small_noicon"
                    onClick={() => setChoosingIcon(true)}
                    aria-label="Selecteren"
                    disabled={props.disabled}
                >
                    Selecteren
                </Button>
                </FlexBox>
            }

            {choosingIcon && props.icons && (
                <PageIconDialog
                    icons={props.icons}
                    currentIconId={props.value}
                    onChange={(iconId: string) => {
                        props.onChange(iconId);
                        setChoosingIcon(false);
                    }}
                    onCancel={() => setChoosingIcon(false)}
                />
            )}
        </>
    );
};

type IconDialogProps = {
    icons: Record<string, Domain.Shared.SvgIcon>,
    currentIconId?: string,
    title?: string,
    onChange: (iconId: string) => void,
    onCancel: () => void,
};

const PageIconDialog: React.FC<IconDialogProps> = (props) => {
    const [iconId, setIconId] = useState<string>(props.currentIconId);

    const availableIcons = useMemo(() => {
        const icons = Object.values(props.icons);
        if (props.currentIconId) {
            const idx = icons.findIndex(i => i.id === props.currentIconId);
            return [icons[idx], ...icons.slice(0, idx), ...icons.slice(idx + 1)];
        }
        return icons;
    }, [props.icons, props.currentIconId]);

    return (
        <LsModal
            id='modal-icon-select'
            title={props.title || "Icoon op tab"}
            width={768}
            toolbar={{
                enabled: true,
                leftButtonText: "Sluiten",
                onLeftButtonClick: () => { setIconId(undefined); props.onCancel(); },
                rightButtonText: 'Opslaan',
                onRightButtonClick: () => { props.onChange(iconId); },
            }}
        >
            <Styled.DialogContainer>
                <IconSelectElement
                    id='icon-field'
                    label='Selecteer icoon'
                    direction='horizontal'
                    items={availableIcons}
                    value={iconId}
                    height='300px'
                    baseWidth={IconSize.large}
                    baseHeight={IconSize.large}
                    keyExpr='id'
                    searchEnabled={true}
                    searchExpr='keywords'
                    itemRender={(item) => (<Styled.CenteredDiv>
                        <SVGIcon value={item.svg} size={IconSize.large} color={'#000000'} />
                    </Styled.CenteredDiv>
                    )}
                    editorSettings={{
                        disabled: false,
                        validationErrors: [],
                        restrictions: { required: false },
                        onChange: (item) => {
                            setIconId(item?.id);
                        }
                    }}
                />
            </Styled.DialogContainer>
        </LsModal>
    );
};

export { IconSelectionEditor };
