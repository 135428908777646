import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { FormMode } from '@liasincontrol/core-service';
import { MeasureMomentForm } from '../../MeasureMomentForm';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    viewMode: FormMode,
    measureMoment: Domain.Shared.MeasureMoment,
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    externalError?: { id: string, message: string },
    onSave: (measureMoment: Domain.Shared.MeasureMoment) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the measure moment dialog page.
 */
export const MeasureMomentFormDialog: React.FC<Props> = (props) => {
    const [formInfo, setFormInfo] = useState<{ value: Domain.Shared.MeasureMoment, isValid: boolean }>({ value: undefined, isValid: true });
    const [selectedMeasureMoment, setSelectedMeasureMoment] = useState<Domain.Shared.MeasureMoment>(undefined);

    useEffect(() => {
        if (!props.measureMoment) {
            return;
        }
        setSelectedMeasureMoment(props.measureMoment)
    }, [props.measureMoment]);

    return <LsModal
        id="modal-add-new-measuremoment"
        title={`Moment ${props.viewMode === FormMode.AddNew ? 'aanmaken' : 'bewerken'}`}
        toolbar={{
            enabled: true,
            onLeftButtonClick: props.onCancel,
            onRightButtonClick: () => props.onSave(formInfo.value),
            rightButtonDisabled: !formInfo.isValid,
        }}
    >
        <AutoFocus>
            <MeasureMomentForm
                measureMoment={props.viewMode !== FormMode.AddNew && selectedMeasureMoment}
                mode={props.viewMode}
                onFormDataChanged={(formData) => {
                    setFormInfo({ value: { ...selectedMeasureMoment, ...formData.value }, isValid: formData.isValid });
                }}
                externalError={props.externalError}
            />
        </AutoFocus>
    </LsModal>;
};
