import React, { useEffect, useMemo, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import * as Domain from '@liasincontrol/domain';
import { FormData, FormHelper, BasicValidator, TextValidator, ValidationUtils, ValidatorsDictionary } from '@liasincontrol/core-service';
import { SelectEditor, TextElement } from '@liasincontrol/ui-elements';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { AutoFocus, IDataItemProps, IRadioGroupFieldItem, RadioGroupField } from '@liasincontrol/ui-basics';
import { siteAccessItems } from '..';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    publishButtonDisabled: boolean,
    versionFieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    currentVersionName: string,
    currentPublishProfileId: string,
    currentVersionVisibility: string,
    errors: Record<string, string>,
    onPublishWebsite: (versionName: string, isPublicWebsite: boolean, publishProfileId: string) => void,
    onCloseDialog: () => void,
};

/**
 * Represents a UI component that renders the version configuration modal dialog.
 */
export const VersionConfiguration: React.FC<Props> = (props) => {
    const [versionForm, setVersionForm] = useState<FormData<string>>(initForm(props.currentVersionName, props.currentPublishProfileId));
    const [availablePublishProfiles, setAvailablePublishProfiles] = useState<IDataItemProps<string>[]>();
    const [publishProfileType, setPublishProfileType] = useState<string>(props.currentPublishProfileId ? '2' : '1');
    const [isPublicWebsite, setIsPublicWebsite] = useState<string>(props.currentVersionVisibility ? props.currentVersionVisibility : 'false');

    useEffect(() => {
        DataAccess.SiteMap.getAvailablePublishProfiles().then((response) => {
            setAvailablePublishProfiles(response.data.map((item) => ({ value: item.profileId, label: item.profileName })));
        });
    }, []);

    useEffect(() => {
        if (!props.errors) {
            return;
        }
        if (props.errors[SystemFieldDefinitions.Pub.VersionName]) {
            setExternalFieldError(props.errors[SystemFieldDefinitions.Pub.VersionName], SystemFieldDefinitions.Pub.VersionName);
        }
    }, [props.errors]);

    const hasErrors = useMemo(() => Object.keys(versionForm?.validationErrors)
        .some(fieldId => versionForm?.validationErrors[fieldId]?.length > 0), [versionForm?.validationErrors]);

    const storeFormValue = (value: string, systemId: keyof typeof validators, selectProfile: boolean, resetExternalErrorFieldSystemIds: string[] = []) => {
        const validators = getValidators(props.versionFieldDefinitions, selectProfile);
        setVersionForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId, resetExternalErrorFieldSystemIds));
    };

    const setExternalFieldError = (error: string, fieldSystemId: keyof typeof validators, fieldValue?: string): void => {
        setVersionForm((prevForm) => FormHelper.setExternalFieldError(error, fieldSystemId, validators, prevForm, fieldValue) as FormData<string>);
    };

    const validators = getValidators(props.versionFieldDefinitions, publishProfileType === '2');
    const versionNameFieldDefinition = props.versionFieldDefinitions[SystemFieldDefinitions.Pub.VersionName];
    const publishProfileFieldDefinition = { systemId: publishProfileDefinitionId, id: publishProfileDefinitionId, optionItems: availablePublishProfiles ? availablePublishProfiles?.map(pp => ({ id: pp.value, name: pp.label })) : [] } as Domain.Shared.FieldDefinition;

    const saveButtonDisabled = hasErrors || props.publishButtonDisabled || (Object.keys(versionForm.touched).length === 0 && !props.currentVersionName);

    return (
        <LsModal
            id='modal-version-configuration'
            title='Publiceren'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCloseDialog,
                rightButtonText: 'Publiceren',
                onRightButtonClick: () => props.onPublishWebsite(versionForm.values[SystemFieldDefinitions.Pub.VersionName], JSON.parse(isPublicWebsite), versionForm.values[publishProfileDefinitionId]),
                rightButtonDisabled: saveButtonDisabled,
            }}
        >
            <MuiGrid container
                columns={1}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1} key={versionNameFieldDefinition.name}>
                    <AutoFocus>
                        <TextElement
                            id='webVersion-name'
                            label={versionNameFieldDefinition.label ? versionNameFieldDefinition.label : versionNameFieldDefinition.name}
                            helpText={{ title: versionNameFieldDefinition.helpTextTitle, text: versionNameFieldDefinition.helpText }}
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, versionForm, (val: string) => storeFormValue(val, SystemFieldDefinitions.Pub.VersionName, publishProfileType === '2', [SystemFieldDefinitions.Pub.VersionName]), SystemFieldDefinitions.Pub.VersionName)}
                            value={versionForm.values[SystemFieldDefinitions.Pub.VersionName] as string} />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid item xs={1} key='profiles'>
                    <RadioGroupField
                        id='webVersion-withProfile'
                        label='Publicatie profiel'
                        alignment='vertical'
                        items={publishProfileTypeItems}
                        value={publishProfileType}
                        onChange={(val: string) => {
                            setPublishProfileType(val);
                            storeFormValue(val === '1' ? null : versionForm.values[publishProfileDefinitionId], publishProfileDefinitionId, val === '2');
                        }}
                    />
                    <SelectEditor<Domain.Shared.FieldDefinitionOptionItem>
                        id='webVersion-publishProfile'
                        searchable={false}
                        displayExpr='name'
                        clearable={true}
                        optionItems={publishProfileFieldDefinition.optionItems}
                        editorSettings={ValidationUtils.getEditorSettings(true, publishProfileType === '1', validators, versionForm, (val: Domain.Shared.FieldDefinitionOptionItem) => storeFormValue(val?.id, publishProfileDefinitionId, publishProfileType === '2'), publishProfileDefinitionId)}
                        value={publishProfileFieldDefinition.optionItems.find(o => o.id === versionForm.values[publishProfileDefinitionId])}
                    />
                </MuiGrid>
                <MuiGrid item xs={1} key='siteAccess'>
                    <RadioGroupField
                        id='webVersion-siteAccess'
                        label='Toegang tot de publicatie'
                        alignment='vertical'
                        items={siteAccessItems}
                        value={isPublicWebsite}
                        onChange={(val: string) => setIsPublicWebsite(val)}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>);
};

const initForm = (currentVersionName: string, currentPublishProfileId: string): FormData<string> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.VersionName]: currentVersionName,
            [publishProfileDefinitionId]: currentPublishProfileId,
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, selectProfile: boolean): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.VersionName]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.VersionName].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.VersionName].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.VersionName].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.VersionName].stringType
        }),
        [publishProfileDefinitionId]: new BasicValidator<string>({ required: selectProfile }),
    };
};

const publishProfileDefinitionId = 'publish-profile-id';
const publishProfileTypeItems: IRadioGroupFieldItem[] = [
    { value: '1', label: 'Publiceer alles' },
    { value: '2', label: 'Gebruik profiel' },
];
