import React, { useState } from 'react';
import { Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { SelectElement } from '@liasincontrol/ui-elements';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    entityId: string,
    disableSubmitButton: boolean,
    onReparent: (parentEntityId: string) => void,
    onCancel: () => void,
    getAvailableParents: () => Domain.Performance.HierarchyItemElement[]
};

/**
 * Represents an UI component that opens a dialog that ask for changing the parent of an existing performance hierarchy item.
 */
export const HierarchyItemReparentDialog: React.FC<Props> = (props) => {
    const [selectedParent, setSelectedParent] = useState<Domain.Performance.HierarchyItemElement>();
    const options = props.getAvailableParents();
    const selectedOption = selectedParent && options.find(item => item.id === selectedParent.id);

    return (
        <LsModal
            id='modal-reparent-entity'
            title="Verplaatsen"
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Verplaatsen',
                onRightButtonClick: () => props.onReparent(selectedParent.id),
                rightButtonDisabled: props.disableSubmitButton || !selectedParent,
            }}
        >
            <Text value="Verplaats het item naar een ander bovenliggend element." />
            <div style={{ width: `100%` }}>
                <SelectElement<Domain.Performance.HierarchyItemElement>
                    id='reparent-select-parent'
                    displayExpr='name'
                    optionItems={options}
                    placeholder='Kies...'
                    value={selectedOption}
                    editorSettings={{
                        onChange: setSelectedParent
                    }}
                />
            </div>
        </LsModal>
    );
};
