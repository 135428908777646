import React, { useState } from 'react';
import { LsModal, SearchField } from '@liasincontrol/ui-devextreme';
import * as Styled from './index.style';

export type SearchListItem = {
    readonly label: string;
    readonly value: boolean;
    readonly id: string;
}

type Props = {
    readonly onCancelled: () => void,
    readonly selectedItems?: SearchListItem[],
    readonly title: string,
    readonly label: string,
    readonly placeholderEmptyList: string,
}

/**
 * Represents an UI component that displays a searchable list in a dialog.
 */
export const SearchListDialog: React.FC<Props> = ({
    onCancelled,
    selectedItems,
    title,
    label,
    placeholderEmptyList,
}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const selectedListContents = sortAlphabetically(searchValue.length > 0 ? filterList(selectedItems || [], searchValue) : selectedItems || []);
    const selectedListCount = selectedListContents.length;
    return (
        <LsModal
            id='modal-search-list-dialog'
            title={title}
            width={800}
            toolbar={{
                enabled: true,
                leftButtonText: 'Sluiten',
                onLeftButtonClick: onCancelled,
            }}
        >
            <Styled.Container>
                <Styled.ListWrapper>
                    <SearchField
                        value={searchValue}
                        id="SearchValueList"
                        placeholder="Zoek..."
                        label={label}
                        totalResults={selectedListCount}
                        onChange={setSearchValue}
                    />
                    <Styled.Border>
                        <Styled.List>
                            {selectedListContents.length === 0 ? (
                                <Styled.LiEmpty key="noItem">
                                    {placeholderEmptyList}
                                </Styled.LiEmpty>
                            ) : selectedListContents.map((item: SearchListItem) => (
                                <Styled.Li key={item.id}>
                                    {item.label}
                                </Styled.Li>
                            ))}
                        </Styled.List>
                    </Styled.Border>
                </Styled.ListWrapper>
            </Styled.Container>
        </LsModal>
    );
};

const filterList = (list: SearchListItem[], filterValue: string): SearchListItem[] => {
    return list.filter((item) => item.label?.toLowerCase().includes(filterValue?.toLowerCase()));
};

const sortAlphabetically = (list: SearchListItem[]) => {
    return list.sort((a, b) => (a.label > b.label) ? 1 : -1);
};
