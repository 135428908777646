import { Label, px, palette } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(20)} repeat(12, [col-start] 1fr) ${px(20)};
    grid-template-rows: ${px(20)} repeat(7, [row-start] auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const HeaderForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const WarningForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 3 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 2;
    grid-row: 4 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 3 / span 10;
    grid-row: 4 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 14;
    grid-row: 5 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    padding: ${px(70)} 0 0 0;
`;

const HeaderWorkflowState = styled.div`
    grid-column: col-start / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const HeaderAssignedUser = styled.div`
    grid-column: col-start 3 / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const ContentHeader = styled.div`
    grid-column: 2 / span 12;
    grid-row: 6 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 7 / -3;
    display: grid;
    grid-template-columns: repeat(12, [col-content-start] 1fr);
    grid-template-rows: ${px(20)} repeat(4, [row-content-start] auto) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const ContentColumn1 = styled.div<{ isMaximized?: boolean }>`
    grid-column: col-content-start 1 / span ${({ isMaximized }) => isMaximized ? 12 : 4};
    grid-row: row-content-start 2 / -1;
`;

const ContentColumn2 = styled.div`
    grid-column: col-content-start 5 / span 4;
    grid-row: row-content-start 2 / -1;
`;

const ContentColumn3 = styled.div`
    grid-column: col-content-start 9 / span 4;
    grid-row: row-content-start 2 / -1 ;
`;

const ExplanationFieldWrapper = styled.div<{ isTypePolicy: boolean }>`
    > div > textarea {
        padding-bottom: ${({ isTypePolicy }) => isTypePolicy ? px(18) : px(11)};
    }
`;

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SingleValueLabel = styled.div`
    margin-left: ${px(3)};
`;

const ContentAttachments = styled.div`
    padding: ${px(10)} ${px(5)};
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(6)};
    margin-bottom: ${px(5)};
`;

const styledComponents = {
    Grid, HeaderForm, StyledLabel, HeaderNumber, HeaderName, ContentHeaderBar, ContentHeader,
    Content, ContentColumn1, ContentColumn2, ContentColumn3, ExplanationFieldWrapper, SingleValueWrapper,
    SingleValueLabel, HeaderWorkflowState, HeaderAssignedUser, ContentAttachments,
    WarningForm
};

export default styledComponents;
