import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, Button, Feedback, Label } from '@liasincontrol/ui-basics';
import { AnyFormData, TextValidator, ValidatorsDictionary, ValueType, FormMode, FormInfo, FormHelper, ValidationErrorData, BasicValidator } from '@liasincontrol/core-service';
import { TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import { workflowStatusListGuid, WorkflowStatusMode } from '../WorkflowTemplateList';
import { DeleteWorkflowStateDialog } from './DeleteWorkflowStateDialog';

type Props = {
    workflowTemplate?: Domain.Shared.WorkflowTemplateWithStates,
    workflowNames?: string[],
    mode: FormMode,
    isOrdering: boolean,
    onFormDataChanged: (formInfo: FormInfo<ValueType>) => void,
    onOrderState?: (stateId: string, newOrder: number) => void,
};

export type WorkflowStateEntry = {
    id: string,
    name: string,
    category: Domain.Shared.WorkflowCategoryType,
    order?: number,
    status?: WorkflowStatusMode,
    replaceWithStateName?: string,
};

const STATES_LIMIT = 10;

/**
 * Represents a UI component that renders the workflow template form.
 */
export const WorkflowTemplateForm: React.FC<Props> = (props) => {
    const validators = getValidators(props.workflowNames);
    const [form, setForm] = useState<AnyFormData>(initFormData(undefined));
    const [deleting, setDeleting] = useState<{ show: boolean, toDeleteId: string }>({ show: false, toDeleteId: undefined });

    useEffect(() => {
        if (!props.workflowTemplate) {
            return;
        }
        setForm(initFormData(props.workflowTemplate));
    }, [props.workflowTemplate]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const clonedForm = FormHelper.handleFormValueChanged(value, systemId, form) as AnyFormData;
        const { errors, hasErrors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, [Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId, workflowStatusListGuid]);

        const nameFieldValidator = new TextValidator({
            required: true,
            stringMaxLength: 50,
            stringType: Domain.Shared.StringType.SingleLine,
        });
        let hasStateNameError = false;
        const allErrors = (clonedForm.values[workflowStatusListGuid] as WorkflowStateEntry[])
            .reduce(
                (collection, item) => {
                    const validationErrors = nameFieldValidator.validate(item.name, clonedForm.values);
                    if (validationErrors.length > 0) {
                        hasStateNameError = true;
                        return { ...collection, [item.id]: validationErrors };
                    }
                    return collection;
                },
                errors
            );

        clonedForm.validationErrors = allErrors;
        clonedForm.isValid = !hasErrors && !hasStateNameError;

        setForm(clonedForm);
        props.onFormDataChanged({ values: clonedForm.values, isValid: clonedForm.isValid, isTouched: Object.keys(clonedForm.touched).length > 0 });
    };

    const onRemoveState = (deletedState: WorkflowStateEntry) => {
        if (props.mode === FormMode.AddNew || deletedState.status === WorkflowStatusMode.New) {
            const optionItems = form.values[workflowStatusListGuid] as WorkflowStateEntry[];
            const statesCopy = [...optionItems];
            const state = statesCopy.find(oi => oi.id === deletedState.id);
            _.remove(statesCopy, state);
            storeFormValue(fixStatusOrder(statesCopy), workflowStatusListGuid);
        } else {
            setDeleting({ show: true, toDeleteId: deletedState.id });
        }
    };

    const getOptionItemsElements = (category: Domain.Shared.WorkflowCategoryType, editable = false) => {
        if (!form.values[workflowStatusListGuid]) {
            return;
        }
        const optionItems = form.values[workflowStatusListGuid] as WorkflowStateEntry[];

        const statesInCategory = optionItems?.filter(state => state.category === category);

        if (!editable) {
            return statesInCategory?.map((item) => {
                return <Styled.Li disabled={true} key={`item-${item.name}`}>
                    <Styled.StyledItem mode={FormMode.View} disabled={true}>
                        {item.name}
                    </Styled.StyledItem>
                </Styled.Li>;
            });
        }

        const onValueChanged = (changedItem: WorkflowStateEntry): void => {
            if (changedItem) {
                const values = optionItems.map(item => {
                    if (item.id === changedItem.id) return changedItem;
                    else return item;
                });
                storeFormValue(values, workflowStatusListGuid);
            }
        };

        const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
            const offset = +statesInCategory[0].order;
            if (props.mode === FormMode.Edit) {
                const state: WorkflowStateEntry = statesInCategory[oldIndex];
                if (props.onOrderState) props.onOrderState(state.id, offset + newIndex);
            } else {
                const items = optionItems;
                items.splice(offset + newIndex, 0, items.splice(offset + oldIndex, 1)[0]);

                storeFormValue(fixStatusOrder(items).map(state => ({ ...state, status: state.status || WorkflowStatusMode.New })), workflowStatusListGuid);
            }
        };

        const canEdit = props.mode === FormMode.AddNew || (props.mode === FormMode.Edit && !props.isOrdering);
        const canDrag = props.mode === FormMode.AddNew || (props.mode === FormMode.Edit && props.isOrdering);

        return (
            <SortableList
                useDragHandle
                items={statesInCategory}
                optionItems={optionItems}
                onSortEnd={onSortEnd}
                onValueChanged={onValueChanged}
                canEdit={canEdit}
                canDrag={canDrag}
                errors={form.validationErrors}
                onRemoveState={onRemoveState} />
        );
    };

    const onDeleteState = (deletedState: string, targetState: string) => {
        const optionItems = form.values[workflowStatusListGuid] as WorkflowStateEntry[];
        if (props.mode === FormMode.Edit) {
            const deletedIndex = optionItems.findIndex(oi => oi.id === deletedState);
            const targetIndex = optionItems.findIndex(oi => oi.id === targetState);
            optionItems[deletedIndex].replaceWithStateName = optionItems[targetIndex].name;
            optionItems[deletedIndex].status = optionItems[deletedIndex].status || WorkflowStatusMode.Changed;
            storeFormValue(fixStatusOrder(optionItems), workflowStatusListGuid);
        }
        setDeleting({ show: false, toDeleteId: undefined })
    };

    const activeStatuses = form?.values[workflowStatusListGuid]?.filter(val => !!!val.replaceWithStateName && val.category === Domain.Shared.WorkflowCategoryType.InProgress)?.length;

    return (<>
        <MuiGrid container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: props.mode === FormMode.View ? 2 : 1 }}
            justifyContent="flex-start"
            alignItems="flex-end">
            <MuiGrid key={Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId} item xs={1} sm={2}>
                <AutoFocus>
                    <TextElement
                        id='input-workflow-name'
                        label='Naam'
                        editorSettings={{
                            disabled: props.mode === FormMode.View || (props.mode === FormMode.Edit && props.isOrdering),
                            restrictions: validators[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId] ? validators[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId].getRestrictions() : undefined,
                            validationErrors: form.touched[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId] ? form.validationErrors[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId] : [],
                            onChange: (val: string) => storeFormValue(val, Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId),
                        }}
                        value={form.values[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId] as string}
                    />
                </AutoFocus>
            </MuiGrid>
            <MuiGrid key={workflowStatusListGuid} item xs={1}>
                <Label>Workflow</Label>
                <Styled.StyledAddOptions>{getOptionItemsElements(Domain.Shared.WorkflowCategoryType.New)}</Styled.StyledAddOptions>
                <Styled.StyledAddOptions>{getOptionItemsElements(Domain.Shared.WorkflowCategoryType.InProgress, true)}</Styled.StyledAddOptions>
                <Feedback
                    error={form.validationErrors[workflowStatusListGuid]?.length > 0}
                    children={form.validationErrors[workflowStatusListGuid]?.map(ve => ve.error)}
                    id='workflow-statuses-feedback'
                />
                {props.mode !== FormMode.View && !(props.mode === FormMode.Edit && props.isOrdering) &&
                    <Styled.AddNewWorkflowButton>
                        <Button
                            id='btn-create-status'
                            btnbase='ghostbuttons'
                            btntype='small_icon'
                            icon={<Add />}
                            disabled={activeStatuses >= STATES_LIMIT}
                            onClick={() => {
                                const statusList = form.values[workflowStatusListGuid] as WorkflowStateEntry[];

                                const newItem: WorkflowStateEntry = {
                                    id: uuid(),
                                    name: '',
                                    category: Domain.Shared.WorkflowCategoryType.InProgress,
                                    status: WorkflowStatusMode.New,
                                    //force to be the last in the list
                                    order: statusList.length + 1,
                                };
                                const newStatusList = [...statusList, newItem];
                                storeFormValue(fixStatusOrder(newStatusList), workflowStatusListGuid);
                            }}
                        >
                            Nieuw
                        </Button>
                    </Styled.AddNewWorkflowButton>
                }
                <Styled.StyledAddOptions>{getOptionItemsElements(Domain.Shared.WorkflowCategoryType.Done)}</Styled.StyledAddOptions>
            </MuiGrid>
        </MuiGrid>
        {
            deleting.show && <DeleteWorkflowStateDialog
                currentState={deleting.toDeleteId}
                workflowTemplateStates={form.values[workflowStatusListGuid] as WorkflowStateEntry[]}
                onConfirm={(targetState) => onDeleteState(deleting.toDeleteId, targetState)}
                onCancel={() => setDeleting({ show: false, toDeleteId: undefined })} />
        }
    </>
    );
};

const SortableItem = SortableElement<{ item: WorkflowStateEntry, isTargetedStatus: boolean, onValueChanged: (v: WorkflowStateEntry) => void, errors: Record<string, ValidationErrorData[]>, canEdit: boolean, canDrag: boolean, onRemoveState: (deletedState: WorkflowStateEntry) => void }>((
    { index, item, isTargetedStatus, errors, canEdit, canDrag, onValueChanged, onRemoveState }:
        { index: number, item: WorkflowStateEntry, isTargetedStatus: boolean, errors: Record<string, ValidationErrorData[]>, canEdit: boolean, canDrag: boolean, onValueChanged: (changedValue: WorkflowStateEntry) => void, onRemoveState: (deletedState: WorkflowStateEntry) => void }
) => {
    const DragHandle = SortableHandle(() => <Styled.StyledIconDrag disabled={!canDrag} />);

    return <Styled.Li id={`btn-item-${item.id}`}>
        {canDrag && <Styled.DragWrap id={`btn-drag-${item.id}`} aria-label={`Verslepen status ${item.name}`} title={`Verslepen status ${item.name}`}>
            <DragHandle />
        </Styled.DragWrap>}
        <Styled.MiddleWrap>
            <TextElement
                id={`input-workflow-status-${item.id}`}
                editorSettings={{
                    disabled: !canEdit || !!item.replaceWithStateName || isTargetedStatus,
                    restrictions: { required: true, minLength: 2, maxLength: 50 },
                    validationErrors: errors[item.id],
                    onChange: (value: string) => {
                        const changedState = item;
                        changedState.name = value;
                        changedState.status = changedState.status || WorkflowStatusMode.Changed;
                        onValueChanged(changedState);
                    },
                    withoutFeedback: true,
                }}
                value={item.replaceWithStateName ? `${item.name} verplaatsen naar ${item.replaceWithStateName}` : item.name}
            />
        </Styled.MiddleWrap>
        {canEdit &&
            <Styled.ActionsWrap>
                {!!item.replaceWithStateName
                    ? <Button
                        id={`btn-restore-status-${item.id}`}
                        btnbase='iconbuttons'
                        btntype='medium_background'
                        aria-label={`Herstellen status ${item.name}`}
                        icon={<DeleteOutlineIcon />}
                        onClick={() => {
                            const changedState = item;
                            delete changedState.replaceWithStateName;
                            onValueChanged(changedState);
                        }} />
                    : <Button
                        id={`btn-delete-status-${item.id}`}
                        btnbase='iconbuttons'
                        btntype='medium_background'
                        aria-label={`Verwijder status ${item.name}`}
                        icon={<DeleteIcon />}
                        disabled={isTargetedStatus}
                        onClick={() => onRemoveState(item)} />
                }
            </Styled.ActionsWrap>}
    </Styled.Li>;
});

const SortableList = SortableContainer<{ items: WorkflowStateEntry[], optionItems: WorkflowStateEntry[], onValueChanged: (v: WorkflowStateEntry) => void, canEdit: boolean, canDrag: boolean, errors: Record<string, ValidationErrorData[]>, onRemoveState: (deletedState: WorkflowStateEntry) => void }>((
    { items, optionItems, errors, canEdit, canDrag, onValueChanged, onRemoveState }:
        { items: WorkflowStateEntry[], optionItems: WorkflowStateEntry[], errors: Record<string, ValidationErrorData[]>, canEdit: boolean, canDrag: boolean, onValueChanged: (changedValue: WorkflowStateEntry) => void, onRemoveState: (deletedState: WorkflowStateEntry) => void }
) => {
    return (
        <Styled.ListContainer>
            {items.map((item, index) => {
                const isTargetedStatus = optionItems.some((oi: WorkflowStateEntry) => oi.replaceWithStateName === item.name);
                return <SortableItem
                    key={`item-${item.id}`}
                    index={index}
                    item={item}
                    isTargetedStatus={isTargetedStatus}
                    onValueChanged={onValueChanged}
                    errors={errors}
                    canEdit={canEdit}
                    canDrag={canDrag}
                    onRemoveState={onRemoveState}
                />
            })}
        </Styled.ListContainer>
    );
});

/**
 * Initialises the validators for the form.
 */
const getValidators = (workflowNames: string[] = []): ValidatorsDictionary => {
    return {
        [Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId]: new TextValidator({
            required: Domain.FieldDefinitions.Shared.nameFieldDefinition.required,
            stringMaxLength: 50,
            stringType: Domain.Shared.StringType.SingleLine,
        }, (value: string): ValidationErrorData[] => {
            const lowerCaseNamelist = workflowNames.map(name => name.toLocaleLowerCase());
            if (lowerCaseNamelist.includes(value.toLocaleLowerCase())) {
                return [{ error: 'Naam is niet uniek.' }];
            }
            return [];
        }),
        [workflowStatusListGuid]: new BasicValidator<any>({ required: false },
            (value: any): ValidationErrorData[] => {
                const values = (value as WorkflowStateEntry[]);

                const lowercaseNames = values.filter(val => !!!val.replaceWithStateName).map(value => {
                    return value.name.toLocaleLowerCase()
                });
                if (lowercaseNames.filter((item, index) => lowercaseNames.indexOf(item) !== index).length > 0) {
                    return [{ error: 'Status is niet uniek.' }];
                }
                if (values?.filter(val => !!!val.replaceWithStateName && val.category === Domain.Shared.WorkflowCategoryType.InProgress)?.length <= 0) {
                    return [{ error: 'Minimaal één status verplicht.' }];
                }
                // if (values?.filter(val => !!!val.replaceWithStateName && val.category === Domain.Shared.WorkflowCategoryType.InProgress)?.length >= STATES_LIMIT) {
                //     return [{ error: `Maximaal ${STATES_LIMIT} statussen toegestaan.` }];
                // }
                return [];
            }),
    };
};

/**
 * Initialises the form data with reporting period data.
 */
const initFormData = (workflowTemplate: Domain.Shared.WorkflowTemplateWithStates): AnyFormData => {
    const states = workflowTemplate?.workflowStates?.map(state => ({
        id: state.id, name: state.name,
        category: state.category,
        order: state.order,
    })) || staticStateList;
    return {
        values: {
            [Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId]: workflowTemplate?.name || '',
            [workflowStatusListGuid]: states,
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};

const staticStateList = [
    { id: uuid(), name: 'Nieuw', category: Domain.Shared.WorkflowCategoryType.New, order: 0, },
    { id: uuid(), name: 'Gereed', category: Domain.Shared.WorkflowCategoryType.Done, order: 1, }
];

const fixStatusOrder = (items: WorkflowStateEntry[]): WorkflowStateEntry[] => {
    let sortColumns = 0;
    Object.keys(Domain.Shared.WorkflowCategoryType).forEach((category) => {
        items.forEach((item) => {
            if (item.category === category && !!!item.replaceWithStateName) {
                item.order = sortColumns++;
            }
        });
    });
    return items?.sort((a, b) => a.order - b.order);
};
