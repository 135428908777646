import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { IDataItemProps, Label, MultiSelectItem, MultiSelectList, Text } from '@liasincontrol/ui-basics';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { IndicatorSize, LoadIndicator, LsModal } from '@liasincontrol/ui-devextreme';
import { SelectElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { isMomentDeleted } from '@liasincontrol/core-service';
import Styled from './index.styled';

type Props = {
    selectedMeasureMoment: Domain.Shared.MeasureMoment,
    measureMoments: Domain.Shared.MeasureMoment[],
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    availableStudioHierarchies: Record<string, MultiSelectItem[]>,
    hasStudioRight: boolean;
    formIsBusy: boolean,
    onChangingMomentStatus: (measureMomentId: string, status: number, templateMeasureMomentId?: string, studioHierarchyDefinitionsToClone?: Record<string, string[]>) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a dialog that asks the user to select a measure moment to clone the performance tree from 
 * and set a list of measuremomentId, hierarchy definition id to clone from.
 */
const MeasureMomentOpenDialog: React.FC<Props> = (props) => {
    const oppositeStatus = props.fieldDefinitions[SystemFieldDefinitions.Performance.MeasureMomentStatus].optionItems.find(option => option.name !== props.selectedMeasureMoment.status.toString());

    const [measureMoments, setMeasureMoments] = useState<IDataItemProps<string>[]>([]);
    const [templateMeasureMoment, setTemplateMeasureMoment] = useState<IDataItemProps<string>>();

    const [availableHierarchies, setAvailableHierarchies] = useState<Record<string, MultiSelectItem[]>>(props.availableStudioHierarchies);

    const errorMultipleOccurences = useMemo(() => {
        const occurrenceMap = Object.values(availableHierarchies).flat()?.reduce((finalMap: Record<string, number>, item) => {
            if (item.value) {
                finalMap[item.id] = ++finalMap[item.id] || 1;
            }
            return finalMap;
        }, {});
        return Object.values(occurrenceMap).some(value => value > 1);
    }, [availableHierarchies]);

    const hasActiveStudioHierarchies = useMemo(() => (Object.keys(availableHierarchies).some(mm => mm === props.selectedMeasureMoment.id)), [availableHierarchies]);

    const studioHierarchyDefinitionsToClone = useMemo(() => {
        return Object.keys(availableHierarchies)
            .reduce((collection, item) => {
                const idArray = availableHierarchies[item].filter(item => item.value).map(item => item.id);
                if (idArray.length <= 0) {
                    return { ...collection };
                }
                return { ...collection, [item]: availableHierarchies[item].filter(item => item.value).map(item => item.id) };
            }, {});
    }, [availableHierarchies]);

    useEffect(() => {
        setAvailableHierarchies(props.availableStudioHierarchies);
    }, [props.availableStudioHierarchies]);

    useEffect(() => {
        const selectionItems = props.measureMoments.filter(mm => mm.id !== props.selectedMeasureMoment.id).map((mm) => ({
            value: mm.id,
            label: mm.name,
            disabled: false
        }));
        setMeasureMoments(selectionItems);
    }, [props.measureMoments, props.selectedMeasureMoment]);

    const updateItemValue = (measureMomentId: string, item: MultiSelectItem, value: boolean) => {
        const cloned = _.cloneDeep(availableHierarchies);
        cloned[measureMomentId] = [...cloned[measureMomentId]].map(existing => existing.id === item.id ? { ...existing, value } : existing);
        setAvailableHierarchies(cloned);
    };

    return (<>
        {oppositeStatus.value === Domain.Shared.MeasureMomentStatus.Open ?
            <LsModal
                id='open-measure-moment-modal'
                title="Open Moment"
                toolbar={{
                    enabled: true,
                    leftButtonText: 'Annuleren',
                    onLeftButtonClick: props.onCancel,
                    rightButtonText: 'Open Moment',
                    onRightButtonClick: () => props.onChangingMomentStatus(props.selectedMeasureMoment.id, oppositeStatus.value, templateMeasureMoment?.value, studioHierarchyDefinitionsToClone),
                    rightButtonDisabled: props.formIsBusy || errorMultipleOccurences,
                }}
            >
                {props.formIsBusy
                    ? <LoadIndicator variant={IndicatorSize.extralarge} />
                    : ((props.hasStudioRight && !hasActiveStudioHierarchies) || !props.selectedMeasureMoment.hasActiveHierarchy) && (
                        <MuiGrid container justifyContent="flex-start" alignItems="flex-start" spacing={2} columns={{ xs: 1, md: 6 }}>
                            {!props.selectedMeasureMoment.hasActiveHierarchy &&
                                <MuiGrid item xs={1} md={6} >
                                    <Label>Gegevens kopiëren uit Performance Management App</Label>
                                    <SelectElement<IDataItemProps<string>>
                                        id='measure-moment-performance-selector'
                                        label='Gegevens kopiëren'
                                        optionItems={measureMoments}
                                        searchable={true}
                                        clearable={true}
                                        editorSettings={{
                                            onChange: setTemplateMeasureMoment
                                        }}
                                        value={templateMeasureMoment}
                                    />
                                </MuiGrid>
                            }
                            {props.hasStudioRight && !hasActiveStudioHierarchies && (
                                <>
                                    <MuiGrid item xs={1} md={6} >
                                        <Label>Gegevens kopiëren uit Studio App</Label>
                                        {errorMultipleOccurences && <Text danger={true} value='U kunt een hiërarchie maar voor één moment selecteren.' />}
                                    </MuiGrid>
                                    <MuiGrid item xs={1} md={6} >
                                        <Styled.Wrapper>
                                            {props.measureMoments.map((mm, index) => (
                                                !isMomentDeleted(mm.status) &&
                                                availableHierarchies[mm.id] &&
                                                <MuiGrid key={mm.id} item xs={1} md={6} >
                                                    <Label>{mm.name}</Label>
                                                    <MultiSelectList
                                                        withBorder={false}
                                                        listItems={availableHierarchies[mm.id] || []}
                                                        previewMax={{ height: undefined, width: undefined }}
                                                        onChange={(item, value) => updateItemValue(mm.id, item, value)}
                                                    />
                                                </MuiGrid>
                                            ))}
                                        </Styled.Wrapper>
                                    </MuiGrid>
                                </>
                            )}
                        </MuiGrid>
                    )
                }
            </LsModal>
            :
            <LsModal
                id='close-measure-moment-modal'
                title="Moment sluiten"
                toolbar={{
                    enabled: true,
                    leftButtonText: 'Annuleren',
                    onLeftButtonClick: props.onCancel,
                    rightButtonText: 'Moment sluiten',
                    onRightButtonClick: () => props.onChangingMomentStatus(props.selectedMeasureMoment.id, oppositeStatus.value, templateMeasureMoment?.value),
                }}
            >
                <Text danger={true} value='Weet je zeker dat je dit moment wilt afsluiten?' />
            </LsModal>}
    </>
    );
};

export { MeasureMomentOpenDialog };