import React, { useState } from 'react';
import Sms from '@mui/icons-material/Sms';
import { Heading2, Icon, IconSize, IconValue } from '@liasincontrol/ui-basics';
import { DateUtils, IconHelper } from '@liasincontrol/core-service';
import { MultiLineTextElement } from '@liasincontrol/ui-elements'
import * as Domain from '@liasincontrol/domain';
import * as Styled from './StatusTrail.styled';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    items: Domain.Shared.WorkflowAuditEvent[];
}

/**
 * Represents a UI component that renders a list of status changes events of a component.
 */

const StatusTrailItem: React.FC<Domain.Shared.WorkflowAuditEvent & { key: string }> = (props) => {
    const [commentVisible, setCommentVisible] = useState(false);

    return (
        <Styled.ItemWrapper key={props.key}>
            <Styled.Item>
                <Styled.ArrowIcon>
                    <Icon value={IconValue.ArrowDown} size={IconSize.extralarge} />
                </Styled.ArrowIcon>

                <Styled.Line>{props.eventTypeCaption}</Styled.Line>
                <Styled.SubLine>{props.userId ? `Door ${props.userName} op ` : ``}<span>{DateUtils.formatDate(props.timeStamp)}</span></Styled.SubLine>
            </Styled.Item>

            <div style={{ display: "inline-grid" }}>
                <Styled.CustomFlexBox>
                    {IconHelper.getWorkFlowStatusIcon(props.toStateName, IconSize.small)}
                    {props.toStateName || 'Nieuw'}
                    {props.description &&
                        <>
                            <Styled.Comment onClick={() => {
                                setCommentVisible(true);
                            }}>
                                <Sms />
                            </Styled.Comment>
                        </>
                    }
                    {commentVisible &&
                        <LsModal
                            id='modal-audit-trail-comment'
                            title='Opmerking raadplegen'
                            toolbar={{
                                enabled: true,
                                rightButtonText: 'Sluiten',
                                onRightButtonClick: () => { setCommentVisible(false) },
                            }}
                        >
                            <MultiLineTextElement
                                id="audit-trail-comment-dialog-content"
                                value={props.description}
                                editorSettings={{ disabled: true }}
                                label={`Opmerking van ${props.userName || "Onbekende gebruiker"}:`}
                            />
                        </LsModal>}
                </Styled.CustomFlexBox>
            </div>
        </Styled.ItemWrapper>
    );
}

const StatusTrail: React.FC<Props> = (props) => {

    const sortedAuditTrail = props.items.sort((x, y) => x.timeStamp > y.timeStamp ? 1 : -1)
    return (
        <Styled.Wrapper>
            <Heading2>Statuswijzigingen</Heading2>
            {sortedAuditTrail.map((statusEvent, idx) => (
                <StatusTrailItem key={`key-${idx}`} {...statusEvent} />
            ))}
        </Styled.Wrapper>
    );
}
export { StatusTrail };