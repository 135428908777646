import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { EditorRendererProps, HierarchySource } from './index.types';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemHierarchyDefinitions } from '@liasincontrol/domain';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { TextElement, SelectElement, ToggleElement, MultiLineTextElement, ImageFocusPointElement, AttachmentElement, ColumnBuilderElement, FilterBuilderElement, defaultAllowedAction, IntegerElement } from '@liasincontrol/ui-elements';
import { LsBaseField, renderModeAvailable } from '@liasincontrol/ui-devextreme';
import { ActionSource } from '@liasincontrol/redux-service';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../../helpers';
import Helpers from './index.helper';
import { DataSourceEditor, DataSourceSelection, FieldListEditor, FormatType, IconSelectionEditor, PieChartValueFormatEditor, PivotTableFieldsAdvancedEditor, PivotTableFieldsEditor, ReferenceAttachmentsEditor, VariableListEditor, WhitespaceEditor } from './Editors';

/**
 * Represents a UI component that renders an editor.
 */
export const EditorRenderer: React.FC<EditorRendererProps> = (props) => {
    const elementDefinition = props.getElementDefinition(props.selectedElement.elementDefinitionSystemId, props.selectedElement.elementDefinitionId);
    const fieldDefinition = Helpers.getFieldDefinition(elementDefinition, props.fieldId);

    const templateElement = props.templateOperations?.find(to => to.element.elementId === props.selectedElement.elementId);
    const templateFd = elementDefinition.fields.find(fd => fd.systemId === fieldDefinition.systemId);
    const templateDsFieldValue = templateElement?.element.fields[templateFd?.id];
    const fieldValue = props.selectedElement.fields[props.fieldId];

    const complexFieldValues = props.selectedElement.complexFields;

    const [dataSourceFields, setDataSourceFields] = useState<LsBaseField[]>([]);
    const [selectedHierarchyDefinition, setSelectedHierarchyDefinition] = useState<Domain.Shared.HierarchyDefinition>();
    const pubContext = useContext(PublicationContext);
    const [dataSourceElement, setDataSourceElement] = useState<Domain.Publisher.DataSourceElement>();

    useEffect(() => {
        const asyncWrapper = async () => {
            const dataSourceFieldDefinition = elementDefinition.fields.find(
                (item) => item.systemId === SystemFieldDefinitions.Pub.DataSource
            );

            if (!dataSourceFieldDefinition) {
                setDataSourceElement(null);
                return;
            }

            const writerDataSourceId = props.selectedElement.fields[dataSourceFieldDefinition.id];
            const templateDataSourceId = templateElement?.element.fields[dataSourceFieldDefinition.id];
            const usedDateSourceId = writerDataSourceId || templateDataSourceId;

            if (usedDateSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(usedDateSourceId);
                setDataSourceElement(dsElement);
            } else {
                setDataSourceElement(null);
            }
        };

        asyncWrapper();
    }, [props.selectedElement, elementDefinition, templateElement]);


    useEffect(() => {
        if (!dataSourceElement || fieldDefinition.systemId !== SystemFieldDefinitions.Pub.Filter) {
            return;
        }

        if (dataSourceElement?.schemaFileId) {
            props.onLoadAttachment(dataSourceElement.schemaFileId)
                .then((response) => response.text())
                .then((jsonText) => {
                    const fields = DataSourceControlsUtils.mapsDataSourceBaseFields(JSON.parse(jsonText));
                    setDataSourceFields(fields);
                });
        }

    }, [props.onLoadAttachment, dataSourceElement, fieldDefinition.systemId]);

    useEffect(() => {
        if (!props.hierarchyDefinitions) {
            return;
        }

        if (props.hierarchySource === HierarchySource.Performance) {
            setSelectedHierarchyDefinition(props.hierarchyDefinitions[ActionSource.Performance][0]);
        } else {
            const hierarchyDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const hierarchyDefinitionFieldValue = hierarchyDefinition ? props.selectedElement.fields[hierarchyDefinition.id] : null;
            if (hierarchyDefinition && !!hierarchyDefinitionFieldValue) setSelectedHierarchyDefinition(props.hierarchyDefinitions[ActionSource.Studio].find(hd => hd.hierarchyDefinitionId === hierarchyDefinitionFieldValue));
        }
    }, [props.hierarchyDefinitions]);

    useEffect(() => {
        if (!Helpers.controlsRequiredPerformanceHierarchy.includes(props.selectedElement.elementDefinitionSystemId) || !props.measureMoments) {
            return;
        }

        const measureMomentFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
        if (props.hierarchySource === HierarchySource.Performance) {
            if (!!measureMomentFieldValue && props.loadPerformanceHierarchy) {
                props.loadPerformanceHierarchy(measureMomentFieldValue);
            }
        } else {
            const hierarchyDefinitionFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === measureMomentFieldValue && h.definitionId === hierarchyDefinitionFieldValue);
            if (!!hierarchy && props.loadStudioHierarchy) {
                props.loadStudioHierarchy(hierarchy.id, hierarchyDefinitionFieldValue, measureMomentFieldValue);
            } else {
                console.error("Cannot load studio hierarchy due to missing data");
            }
        }

    }, [props.measureMoments]);

    useEffect(() => {
        if (!selectedHierarchyDefinition) {
            return;
        }
        props.getAvailableHierarchyTypes(selectedHierarchyDefinition.items, props.hierarchySource === HierarchySource.Studio ? ActionSource.Studio : ActionSource.Performance);
    }, [selectedHierarchyDefinition]);

    // Checks if an imageSource field has a value.
    const hasImageSourceFieldValue = (): boolean => {
        const imageFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ImageSource);
        return imageFieldDefinition && !!props.selectedElement.fields[imageFieldDefinition.id];
    };

    // Checks if element patching is allowed on page content level.
    const allowPatchContent = (): boolean => {
        const allowPatchContentFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.AllowPatchContent);
        return allowPatchContentFieldDefinition && props.selectedElement.fields[allowPatchContentFieldDefinition.id].toLowerCase() === 'true';
    };

    const disabled = !props.isDesigner && (!allowPatchContent() || props.disabled);

    //TODO if accordion control, set correct default actions
    const isTreeviewControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.TreeViewControl;
    const isAccordionControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.AccordionDsControl;
    const isDataTableControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.DataTableControl;
    const isTabPanelControl = props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.TabDsControl;

    const allowedActions = {
        ...defaultAllowedAction,
        canGroup: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canSummarize: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canExpandGroup: !isTreeviewControl && !isAccordionControl && !isTabPanelControl,
        canHighLight: !isAccordionControl && !isTabPanelControl,
        canHideCaption: isAccordionControl || isTabPanelControl,
        canAlign: !isAccordionControl && !isTabPanelControl,
        canSetColumnWidth: isDataTableControl || isTreeviewControl,
    };

    const onSingleFieldChanged = (value: string, fieldDefinitionId?: string) => {
        // This prevents infinite rendering when modifying template name; the purpose of templateName prop is solely for this workaround.
        if (elementDefinition.systemId === SystemElementDefinitions.Pub.PageTemplate && props.templateName === value) {
            return;
        }

        props.onSingleFieldChanged([{
            elementId: props.selectedElement.elementId,
            fieldId: fieldDefinitionId || fieldDefinition.id,
            value
        }]);
    };

    const onFocusOut = () => {
        const value = !props.isDesigner && (_.isEmpty(fieldValue) || fieldValue === "[]")
            ? templateDsFieldValue : fieldValue;

        onSingleFieldChanged(value);
    };

    const OnSingleNumberFieldChanged = (value: number) => {
        const newVal = value ? value.toString() : '0';
        onSingleFieldChanged(newVal);
    };

    //mandatory systemId to be able to reduce the field list on performance info control
    const wantedOptionItems = [
        SystemFieldDefinitions.Performance.Description,
        SystemFieldDefinitions.Performance.Explanation,
        SystemFieldDefinitions.Performance.Result,
    ] as string[];

    const isStudioComponent = props.hierarchySource === HierarchySource.Studio;

    const isTitleCompatible = (fieldDefinition: Domain.Shared.FieldDefinition) => {
        switch (fieldDefinition.dataType) {
            case Domain.Shared.FieldDataType.Attachment:
            case Domain.Shared.FieldDataType.Boolean:
            case Domain.Shared.FieldDataType.Option:
            case Domain.Shared.FieldDataType.Relation:
                return false;
            case Domain.Shared.FieldDataType.Integer:
            case Domain.Shared.FieldDataType.Decimal:
            case Domain.Shared.FieldDataType.DateTimeOffset:
                return true;
            case Domain.Shared.FieldDataType.String:
                const editorSettings: Domain.Studio.FieldEditorControlSettings = JsonUtils.toJson<Domain.Studio.FieldEditorControlSettings>(fieldDefinition?.editorSettings, undefined);
                return editorSettings && editorSettings.stringDisplayFormat ? editorSettings.stringDisplayFormat === (Domain.Studio.StringDisplayType.SingleLine as Domain.Studio.StringDisplayType) : true;
            default:
                return false;
        }
    }

    const sharedProps = {
        key: `${props.selectedElement.elementId}-${fieldDefinition.id}`,
        id: `${fieldDefinition.id}`,
        label: fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name,
        editorSettings: {
            disabled: disabled,
            restrictions: { required: fieldDefinition.required, maxLength: fieldDefinition.stringMaxLength },
            validationErrors: props.validationErrors,
            onChange: onSingleFieldChanged
        },
        value: fieldValue
    };

    const sharedDsEditorProps = {
        disabled, onFocusOut,
        schemaFileId: dataSourceElement?.schemaFileId,
        onChange: onSingleFieldChanged,
        onLoadAttachment: props.onLoadAttachment,
    }

    switch (fieldDefinition.systemId) {
        case SystemFieldDefinitions.Pub.Name:
            return (
                <TextElement
                    {...sharedProps}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: !props.isDesigner,
                    }}
                />
            );
        case SystemFieldDefinitions.Pub.TextualContent:
            // Don't render this field if it is part of the TextControl.
            if (props.selectedElement.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TextControl) {
                return null;
            }
        // eslint-disable-next-line no-fallthrough    
        case SystemFieldDefinitions.Pub.DataTableDescription:
        case SystemFieldDefinitions.Pub.Title:
        case SystemFieldDefinitions.Pub.MapDescription:
        case SystemFieldDefinitions.Pub.PivotTableDescription:
        case SystemFieldDefinitions.Pub.BarChartDescription:
        case SystemFieldDefinitions.Pub.LineChartDescription:
        case SystemFieldDefinitions.Pub.PieChartDescription:
        case SystemFieldDefinitions.Pub.GrandSummaryText:
        case SystemFieldDefinitions.Pub.PivotTableGrandTotalCaption:
            return (<TextElement {...sharedProps} onFocusOut={onFocusOut} />);
        case SystemFieldDefinitions.Pub.ImageCaption: {
            const showField = hasImageSourceFieldValue();
            return showField ? (
                <TextElement {...sharedProps} onFocusOut={onFocusOut} />) : null;
        }
        case SystemFieldDefinitions.Pub.AltText: {
            const showField = hasImageSourceFieldValue();
            return showField ? (
                <TextElement
                    {...sharedProps}
                    helpText={{ title: fieldDefinition.helpTextTitle, text: fieldDefinition.helpText }}
                    onFocusOut={onFocusOut}
                />
            ) : null;
        }
        case SystemFieldDefinitions.Pub.TablePanelIsVisible:
        case SystemFieldDefinitions.Pub.ShowCompleteImage:
        case SystemFieldDefinitions.Pub.ColumnsAutoHide:
        case SystemFieldDefinitions.Pub.PivotTableExpandByDefault:
            return (
                <ToggleElement
                    {...sharedProps}
                    booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                    booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (val: boolean) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                    }}
                    value={fieldValue ? fieldValue.toLowerCase() === 'true' : false}
                />
            );
        case SystemFieldDefinitions.Pub.ShowColumnGrandTotals:
        case SystemFieldDefinitions.Pub.ShowColumnTotals:
        case SystemFieldDefinitions.Pub.ShowRowGrandTotals:
        case SystemFieldDefinitions.Pub.ShowRowTotals:
            if (!props.isDesigner) return null;
            const value = fieldValue === undefined ? true : fieldValue.toLowerCase() === 'true';
            return (
                <ToggleElement
                    {...sharedProps}
                    booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                    booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (val: boolean) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                    }}
                    value={value}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartValue:
        case SystemFieldDefinitions.Pub.BarChartValue:
        case SystemFieldDefinitions.Pub.LineChartValue:
        case SystemFieldDefinitions.Pub.Latitude:
        case SystemFieldDefinitions.Pub.Longitude:
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        //editorSettings is passed, but it is never used inside.
                        {...sharedProps}
                        {...sharedDsEditorProps}
                        schemaFilter={(item) => item.dataType === 'number'}
                    /> : null
            );
        case SystemFieldDefinitions.Pub.DataSourceTextColumn:
        case SystemFieldDefinitions.Pub.PieChartArgument:
        case SystemFieldDefinitions.Pub.BarChartArgument:
        case SystemFieldDefinitions.Pub.LineChartArgument:
            return (<DataSourceEditor {...sharedProps} {...sharedDsEditorProps} />);
        case SystemFieldDefinitions.Pub.DataSourceTitleColumn:
            return (
                <DataSourceEditor
                    {...sharedProps}
                    {...sharedDsEditorProps}
                    schemaFilter={(item) => item.format ? item.format === 'singleline' : item.dataType === "string"}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartCustomLabel:
            return (<MultiLineTextElement {...sharedProps} onFocusOut={onFocusOut} />);
        case SystemFieldDefinitions.Pub.PieChartValueFormat:
        case SystemFieldDefinitions.Pub.BarChartValueFormat:
        case SystemFieldDefinitions.Pub.LineChartValueFormat:
            {
                const pieChartValueFormat: FormatType = JsonUtils.toJson(fieldValue);
                return (
                    <PieChartValueFormatEditor
                        key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        myKey={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                        id={fieldDefinition.id}
                        disabled={disabled}
                        required={fieldDefinition.required}
                        validationErrors={props.validationErrors}
                        value={pieChartValueFormat}
                        onValueChange={(format) => {
                            onSingleFieldChanged(JSON.stringify(format));
                        }}
                    />)
            }
        case SystemFieldDefinitions.Pub.PieChartMaximumSlices: 
            return (
                <IntegerElement
                    {...sharedProps}
                    controlButtons={true}
                    startValue={10}
                    min={0}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: OnSingleNumberFieldChanged
                    }}
                    value={fieldValue ? Number.parseInt(fieldValue) : 0}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartCombinedSliceLabel:
            return (<TextElement {...sharedProps} />);
        case SystemFieldDefinitions.Pub.PivotGridFields: {
            const originalValues = JsonUtils.toJson(fieldValue, []);
            const groupedFields = _.groupBy(originalValues, 'area');
            return (
                <PivotTableFieldsEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    schemaFileId={dataSourceElement?.schemaFileId}
                    onLoadAttachment={props.onLoadAttachment}
                    value={groupedFields}
                    onValueChange={onSingleFieldChanged}
                />);
        }
        case SystemFieldDefinitions.Pub.VirtualPivotGridFields: {
            const relatedFieldId = elementDefinition.fields?.find(e => e.systemId === SystemFieldDefinitions.Pub.PivotGridFields)?.id;
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.PivotGridFields);
            return (
                <PivotTableFieldsAdvancedEditor
                    key={`advanced-${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    id={fieldDefinition.id}
                    label={fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name}
                    disabled={disabled}
                    value={relatedFieldValue}
                    sourceElementId={props.selectedElement.elementId}
                    sourceFieldId={relatedFieldId}
                    onChange={(change: Domain.Publisher.FieldPatch) => props.onSingleFieldChanged([change])}
                />
            );
        }
        case SystemFieldDefinitions.Pub.HelpText:
            return (
                <MultiLineTextElement
                    {...sharedProps}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: !props.isDesigner,
                        readMore: {
                            active: true,
                            length: 450,
                        },
                    }}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.HtmlContent:
            if (props.isDesigner) return null;
            return (<MultiLineTextElement {...sharedProps} rows={10} onFocusOut={onFocusOut} />);
        case SystemFieldDefinitions.Pub.ImageSource:
            return (
                <AttachmentElement
                    {...sharedProps}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        restrictions: { required: fieldDefinition.required, maxFileSize: fieldDefinition.attachmentMaxFileSize, allowedFileTypes: fieldDefinition.attachmentAllowedFileTypes },
                    }}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                />
            );
        case SystemFieldDefinitions.Pub.FileSource:
            if (props.isDesigner) return null;

            const attachmentComplexDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === SystemFieldDefinitions.Pub.AttachmentsComplex);
            const fileSourceDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileSource);
            const fileNameDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileName);
            const fileSizeDefinition = attachmentComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.FileSize);
            const values = Object.values(complexFieldValues)
                .filter((complexField) => Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === attachmentComplexDefinition.id)
                .map((complexField) => ({
                    rowIndex: complexField.rowIndex,
                    source: complexField.fields[fileSourceDefinition.id],
                    name: complexField.fields[fileNameDefinition.id],
                    size: complexField.fields[fileSizeDefinition.id],
                }));

            const onAttachmentFieldChanged = (rowIndex: number, fileSource: string, fileName: string, fileSize: string, deleted: boolean): void => {
                props.onComplexFieldChanged({
                    elementId: props.selectedElement.elementId,
                    complexFieldDefinitionId: attachmentComplexDefinition.id,
                    rowIndex: rowIndex,
                    complexField: {
                        rowIndex: rowIndex,
                        complexFieldDefinitionId: attachmentComplexDefinition.id,
                        fields: {
                            [fileSourceDefinition.id]: fileSource,
                            [fileNameDefinition.id]: fileName,
                            [fileSizeDefinition.id]: fileSize
                        }
                    },
                    deleted: deleted,
                });
            };

            return (
                <ReferenceAttachmentsEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    disabled={disabled}
                    uploaderId={fileSourceDefinition.id}
                    uploaderLabel={fileSourceDefinition.label ? fileSourceDefinition.label : fileSourceDefinition.name}
                    maxFileSize={fileSourceDefinition.attachmentMaxFileSize}
                    allowedFileTypes={fileSourceDefinition.attachmentAllowedFileTypes}
                    listId={attachmentComplexDefinition.id}
                    listLabel={attachmentComplexDefinition.label ? attachmentComplexDefinition.label : attachmentComplexDefinition.name}
                    validationErrors={props.validationErrors}
                    values={values}
                    onAttachmentFieldChanged={onAttachmentFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    onRemoveAttachment={props.onRemoveAttachment}
                />
            );
        case SystemFieldDefinitions.Pub.ImageFocusPoint: {
            const imageFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ImageSource);
            const fillFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.ShowCompleteImage);
            const showFocusPoint = props.selectedElement.fields[fillFieldDefinition.id] ? props.selectedElement.fields[fillFieldDefinition.id].toLowerCase() === 'true' : false;
            return showFocusPoint ? (
                <ImageFocusPointElement
                    {...sharedProps}
                    attachmentId={props.selectedElement.fields[imageFieldDefinition.id]}
                    helpText={{ title: fieldDefinition.helpTextTitle, text: fieldDefinition.helpText }}
                    onLoadAttachment={props.onLoadAttachment}
                />) : null;
        }
        case SystemFieldDefinitions.Pub.DataSource:
            return (
                <DataSourceSelection
                    {...sharedProps}
                    publicationId={props.publicationId}
                    disabled={disabled}
                    selectedElement={props.selectedElement}
                    validationErrors={props.validationErrors}
                    getElementDefinition={props.getElementDefinition}
                    onFieldsChanged={props.onSingleFieldChanged}
                    onLoadAttachment={props.onLoadAttachment}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.TableColumnSettings:
            return (
                dataSourceElement?.schemaFileId ?
                    <ColumnBuilderElement
                        {...sharedProps}
                        editorSettings={{
                            ...sharedProps.editorSettings,
                            disabled: !(dataSourceElement?.schemaFileId) || disabled,
                        }}
                        allowedActions={allowedActions}
                    /> : null
            );
        case SystemFieldDefinitions.Pub.Filter:
            const variables = props.variables?.map(variable => ({ label: `$${variable.name}`, value: `$${variable.name}` }));

            return (
                <FilterBuilderElement
                    {...sharedProps}
                    fields={dataSourceFields}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: !(dataSourceElement?.schemaFileId) || disabled,
                    }}
                    variables={variables}
                    onReset={onFocusOut}
                />
            );
        // Template designer only fields
        case SystemFieldDefinitions.Pub.VirtualWhitespaceGroupField:
            if (!props.isDesigner) return null;

            return (
                <WhitespaceEditor
                    key={`${props.selectedElement.elementId}-${fieldDefinition.id}`}
                    element={props.selectedElement}
                    fieldDefinitions={elementDefinition.fields}
                    fieldValues={props.selectedElement.fields}
                    disabled={disabled}
                    validationErrors={props.validationErrors}
                    onChange={(change: Domain.Publisher.FieldPatch) => props.onSingleFieldChanged([change])}
                />
            );
        case SystemFieldDefinitions.Pub.ShowBackground:
        case SystemFieldDefinitions.Pub.ShowShadow:
        case SystemFieldDefinitions.Pub.AllowPatchContent:
        case SystemFieldDefinitions.Pub.PrintAsLandscape:
            if (!props.isDesigner) return null;
            return (
                <ToggleElement
                    {...sharedProps}
                    booleanTrueLabel={fieldDefinition.booleanTrueLabel}
                    booleanFalseLabel={fieldDefinition.booleanFalseLabel}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (val) => onSingleFieldChanged((String(val).charAt(0).toUpperCase() + String(val).slice(1)))
                    }}
                    value={fieldValue ? fieldValue.toLowerCase() === 'true' : false}
                />
            );


        // Render no editor for fields that should not be visible anywhere.
        case SystemFieldDefinitions.Pub.TextHtmlContent:
        case SystemFieldDefinitions.Pub.ShowWhitespaceTop:
        case SystemFieldDefinitions.Pub.ShowWhitespaceBottom:
        case SystemFieldDefinitions.Pub.ShowWhitespaceLeft:
        case SystemFieldDefinitions.Pub.ShowWhitespaceRight:
            // The Container item itself is selectable when placed inside a tabbed control. In this case, ignore all of its fields.
            return null;
        case SystemFieldDefinitions.Pub.IsStudioControl: {
            const source = fieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : (fieldValue?.toLowerCase() === 'false' ? ActionSource.Performance : null);

            return (
                <SelectElement<IDataItemProps<string>>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='label'
                    label='Module'
                    placeholder='Selecteer...'
                    searchable={false}
                    clearable={true}
                    optionItems={Helpers.AccordionTypes}
                    value={Helpers.AccordionTypes.find(at => at.value === fieldValue?.toLowerCase())}
                    onFocusOut={onFocusOut}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.HierarchyDefinitionId,
                                SystemFieldDefinitions.Pub.EntityTypeId,
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item?.value, source)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: (String(item?.value).charAt(0).toUpperCase() + String(item?.value).slice(1)),
                            });

                            if (item?.value?.toLowerCase() === 'false') {
                                const controlSelectedHierarchyDefinition = props.hierarchyDefinitions[ActionSource.Performance][0];
                                setSelectedHierarchyDefinition(controlSelectedHierarchyDefinition);
                                props.getAvailableHierarchyTypes(controlSelectedHierarchyDefinition.items, ActionSource.Performance);
                                const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
                                if (!!relatedFieldValue && props.loadPerformanceHierarchy) {
                                    props.loadPerformanceHierarchy(relatedFieldValue);
                                }
                            }

                            props.onSingleFieldChanged(changes);
                        },
                    }}
                />);
        }
        case SystemFieldDefinitions.Pub.MeasureMomentId:
            return (
                <SelectElement<Domain.Shared.MeasureMoment>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={true}
                    optionItems={props.measureMoments}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(props.selectedElement, elementDefinition, [SystemFieldDefinitions.Pub.HierarchyDefinitionId, SystemFieldDefinitions.Pub.EntityId, SystemFieldDefinitions.Pub.ParentEntityId]);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.id
                            });
                            props.onSingleFieldChanged(changes);
                            //If performance
                            if (props.hierarchySource === HierarchySource.Performance && !!item && props.loadPerformanceHierarchy) {
                                props.loadPerformanceHierarchy(item?.id);
                            }

                            if (props.hierarchySource === HierarchySource.Studio && !!item) {
                                const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
                                const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === item?.id && h.definitionId === relatedFieldValue);
                                if (!!relatedFieldValue && !!hierarchy) {
                                    props.loadStudioHierarchy(hierarchy.id, relatedFieldValue, item.id);
                                }
                            }
                        }
                    }}
                    value={props.measureMoments?.find(m => m.id === fieldValue)}
                    onFocusOut={onFocusOut}
                />
            );
        case SystemFieldDefinitions.Pub.HierarchyDefinitionId: {
            const isStudioControlFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            if (!isStudioControlFieldValue || isStudioControlFieldValue.toLowerCase() === 'false') return null;
            const measureMomentDefinitionIdFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const canSetValue = isStudioControlFieldValue?.toLowerCase() === 'false' || (isStudioControlFieldValue?.toLowerCase() === 'true' && !!measureMomentDefinitionIdFieldValue);
            const value = pubContext.studioHierarchies?.find(hd => hd.momentId === measureMomentDefinitionIdFieldValue && hd.definitionId === fieldValue);

            return (
                <SelectElement<Domain.Studio.HierarchyListItem>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={pubContext.studioHierarchies?.filter(hd => hd.momentId === measureMomentDefinitionIdFieldValue)}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !canSetValue,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.EntityTypeId,
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item?.definitionId, ActionSource.Studio)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.definitionId
                            });

                            const controlSelectedHierarchyDefinition = props.hierarchyDefinitions[ActionSource.Studio].find(hd => hd.hierarchyDefinitionId === item?.definitionId);

                            if (!!controlSelectedHierarchyDefinition) {
                                setSelectedHierarchyDefinition(controlSelectedHierarchyDefinition);
                                props.getAvailableHierarchyTypes(controlSelectedHierarchyDefinition.items, ActionSource.Studio);
                                const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
                                const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === relatedFieldValue && h.definitionId === item?.definitionId);
                                if (!!relatedFieldValue && !!hierarchy && !!item?.definitionId && props.loadStudioHierarchy) {
                                    props.loadStudioHierarchy(hierarchy.id, item.definitionId, relatedFieldValue);
                                }
                            }
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={value}
                />
            );
        }
        case SystemFieldDefinitions.Pub.EntityTypeId: {
            const isStudioControlFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            const source = isStudioControlFieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : ActionSource.Performance;
            const hierarchyDefinitionIdFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId);
            const canSetValue = (props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.PerformanceInformationControl)
                ? true
                : isStudioControlFieldValue?.toLowerCase() === 'false' || (isStudioControlFieldValue?.toLowerCase() === 'true' && !!hierarchyDefinitionIdFieldValue);

            return (
                <SelectElement<IDataItemProps<string>>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='label'
                    searchable={false}
                    clearable={false}
                    optionItems={props.elementTypes || []}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !canSetValue,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(props.selectedElement, elementDefinition, [
                                SystemFieldDefinitions.Pub.EntityId,
                                SystemFieldDefinitions.Pub.FieldId,
                                SystemFieldDefinitions.Pub.ParentEntityTypeId,
                                SystemFieldDefinitions.Pub.ParentEntityId,
                                SystemFieldDefinitions.Pub.HeaderFieldId,
                                SystemFieldDefinitions.Pub.FieldsListJson,
                            ], props.getDefinition(item.value, source)
                            );

                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item.value
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={props.elementTypes?.find(el => el.value === fieldValue)}
                />
            );
        }
        case SystemFieldDefinitions.Pub.EntityId: {
            const measureMomentFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const hierarchyDefinitionFieldValue = isStudioComponent ? Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId) : 'performance';
            const entityDefinition = relatedFieldValue ? props.getDefinition(relatedFieldValue, ActionSource.Performance) : undefined;
            const canSetValue = !!measureMomentFieldValue && !!relatedFieldValue && !!hierarchyDefinitionFieldValue;

            const optionItems = entityDefinition && canSetValue && props.hierarchies[measureMomentFieldValue]
                ? props.hierarchies[measureMomentFieldValue][hierarchyDefinitionFieldValue]
                    ?.filter((item: Domain.Performance.HierarchyItem) => item.element.elementDefinitionId === relatedFieldValue)
                    ?.map((item: Domain.Performance.HierarchyItem) => {
                        const elementItem = new Domain.Studio.HierarchyItemElement();
                        FieldsHelper.mapObject<Domain.Studio.HierarchyItemElement>(elementItem, entityDefinition.fields, item.element.fields);
                        elementItem.id = item.element.elementId;
                        return { value: elementItem.id, label: elementItem.name };
                    })
                : [];

            return (
                <SelectElement<IDataItemProps<string>>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='label'
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !canSetValue,
                        onChange: (item) => onSingleFieldChanged(item?.value)
                    }}
                    value={optionItems?.find(item => item.value === fieldValue)}
                />
            );
        }
        case SystemFieldDefinitions.Pub.FieldId: {
            const isStudioControlFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            const source = isStudioControlFieldValue?.toLowerCase() === 'true' ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue && selectedHierarchyDefinition ?
                props.getDefinition(relatedFieldValue, source)
                    ?.fields
                    ?.filter((e) => (selectedHierarchyDefinition.systemId === SystemHierarchyDefinitions.Performance && wantedOptionItems.includes(e.systemId))
                        || selectedHierarchyDefinition.systemId === SystemHierarchyDefinitions.Dynamic) : [];
            return (
                <SelectElement<Domain.Shared.FieldDefinition>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !relatedFieldValue,
                        onChange: (item) => onSingleFieldChanged(item?.id),
                    }}
                    value={!!relatedFieldValue ? optionItems?.find(opt => opt.id === fieldValue) : null}
                />
            );
        }
        case SystemFieldDefinitions.Pub.ParentEntityTypeId: {
            const isStudioControlFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
            if (!isStudioControlFieldValue || !selectedHierarchyDefinition) return null;
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue
                ? Helpers.getAvailableAncestorsElementDefinitions(selectedHierarchyDefinition, relatedFieldValue)
                    ?.map((linkDefinition) => props.elementTypes?.find(e => e.value === linkDefinition.fromElementDefinitionId))
                : [];
            const uniqueOptionItems = _.uniqWith(optionItems, (a, b) => a.value === b.value);

            return (
                <SelectElement<IDataItemProps<string>>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='label'
                    searchable={false}
                    clearable={false}
                    optionItems={uniqueOptionItems}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !relatedFieldValue,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(props.selectedElement, elementDefinition, [SystemFieldDefinitions.Pub.ParentEntityId]);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.value
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                    value={!!relatedFieldValue ? uniqueOptionItems.find(item => item.value === fieldValue) : null}
                />
            );
        }
        case SystemFieldDefinitions.Pub.ParentEntityId: {
            if (props.isDesigner) return null;
            const measureMomentFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.MeasureMomentId);
            const hierarchyDefinitionFieldValue = isStudioComponent ? Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HierarchyDefinitionId) : 'performance';
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.ParentEntityTypeId);
            const source = isStudioComponent ? ActionSource.Studio : ActionSource.Performance;
            const entityDefinition = relatedFieldValue ? props.getDefinition(relatedFieldValue, source) : undefined;
            const canSetValue = !!measureMomentFieldValue && !!relatedFieldValue && !!hierarchyDefinitionFieldValue;
            const optionItems = entityDefinition && canSetValue && props.hierarchies[measureMomentFieldValue] ? props.hierarchies[measureMomentFieldValue][hierarchyDefinitionFieldValue]
                ?.filter((item: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) => item.element.elementDefinitionId === relatedFieldValue)
                ?.map((item: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) => {
                    const elementItem = new Domain.Studio.HierarchyItemElement();
                    FieldsHelper.mapObject<Domain.Studio.HierarchyItemElement>(elementItem, entityDefinition.fields, item.element.fields);
                    elementItem.id = item.element.elementId;
                    return { value: elementItem.id, label: elementItem.name };
                }) : [];
            const uniqueOptionItems = _.uniqWith(optionItems, (a, b) => a.value === b.value);

            return (
                <SelectElement<IDataItemProps<string>>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='label'
                    value={canSetValue ? uniqueOptionItems?.find(item => item.value === fieldValue) : null}
                    searchable={false}
                    clearable={true}
                    optionItems={uniqueOptionItems}
                    editorSettings={{
                        disabled: disabled || !canSetValue,
                        restrictions: { required: !props.isDesigner },
                        validationErrors: props.validationErrors,
                        onChange: (item) => onSingleFieldChanged(item?.value)
                    }}
                    onFocusOut={onFocusOut}
                />
            );
        }
        case SystemFieldDefinitions.Pub.HeaderFieldId: {
            const source = isStudioComponent ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            const optionItems = relatedFieldValue ? props.getDefinition(relatedFieldValue, source)
                ?.fields?.filter((field: Domain.Shared.FieldDefinition) => isTitleCompatible(field))
                ?.filter(item => !!item)
                : [];
            return (
                <SelectElement<Domain.Shared.FieldDefinition>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={optionItems}
                    value={!!relatedFieldValue ? optionItems?.find(item => item.id === fieldValue) : null}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        disabled: disabled || !relatedFieldValue,
                        onChange: (item) => {
                            const changes = Helpers.getDependentChanges(
                                props.selectedElement,
                                elementDefinition,
                                [SystemFieldDefinitions.Pub.FieldsListJson],
                                props.getDefinition(relatedFieldValue, source),
                                item?.id);
                            changes.push({
                                elementId: props.selectedElement.elementId,
                                fieldId: fieldDefinition.id,
                                value: item?.id
                            });
                            props.onSingleFieldChanged(changes);
                        },
                    }}
                />
            );
        }
        case SystemFieldDefinitions.Pub.FieldsListJson: {
            const source = props.hierarchySource === HierarchySource.Studio ? ActionSource.Studio : ActionSource.Performance;
            const relatedFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.EntityTypeId);
            // filter out headerId from shown fields
            const headerFieldValue = Helpers.getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.HeaderFieldId);

            const hierarchyEntityFields = relatedFieldValue ?
                props.getDefinition(relatedFieldValue, source)?.fields
                : [];
            const originalValues = !!fieldValue ? JSON.parse(fieldValue) : [];

            const optionItems = hierarchyEntityFields?.reduce((acc, e: Domain.Shared.FieldDefinition) => {
                if (e.id === headerFieldValue) {
                    return acc;
                }

                const originalValue = originalValues?.find((original) => original.fieldId === e.id);

                const item = originalValue ?? { fieldId: e.id, label: e.name, visible: true, index: 0, showFieldName: true };

                if (_.isUndefined(item.showFieldName)) {
                    originalValue.showFieldName = true;
                }

                return [...acc, item];
            }, [])?.sort((a, b) => a.index - b.index) || [];


            return (
                <FieldListEditor
                    {...sharedProps}
                    value={optionItems}
                    onValuesChanged={onSingleFieldChanged}
                    disabled={disabled || !!!relatedFieldValue}
                />
            );
        }

        case Domain.SystemFieldDefinitions.Pub.TilePage: {
            return null;
        }
        case SystemFieldDefinitions.Pub.TitleColumn:
        case SystemFieldDefinitions.Pub.HeaderField: {
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        {...sharedProps}
                        {...sharedDsEditorProps}
                        schemaFilter={(item) => item.propertyGroupKind !== 'SystemField' && item.format !== 'html'}

                    /> : null
            );
        }

        case SystemFieldDefinitions.Pub.LabelColumnName: {
            return (
                dataSourceElement?.schemaFileId
                    ? <DataSourceEditor
                        {...sharedProps}
                        {...sharedDsEditorProps}
                        schemaFilter={(item) => item.dataType === 'string' && !renderModeAvailable.includes(item.propertyGroupKind)}
                    /> : null
            );
        }
        case SystemFieldDefinitions.Pub.StackContainerDirection:
            if (!props.isDesigner) return null;
            // eslint-disable-next-line no-fallthrough
        case SystemFieldDefinitions.Pub.ChartLegend:
            if (props.selectedElement.elementDefinitionSystemId === SystemElementDefinitions.Pub.BarChartControl) return null;
            // eslint-disable-next-line no-fallthrough
        case Domain.SystemFieldDefinitions.Pub.TileImageKind:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldValue ? fieldDefinition.optionItems.find(fd => fd.id === fieldValue) : fieldDefinition.optionItems.find(fd => fd.value === 1)}
                />
            );
        case SystemFieldDefinitions.Pub.PieChartType:
        case SystemFieldDefinitions.Pub.ChartShowLabels:
        case SystemFieldDefinitions.Pub.TitleStyling:
        case SystemFieldDefinitions.Pub.ImageSize:
        case SystemFieldDefinitions.Pub.MenuType:
        case SystemFieldDefinitions.Pub.BarChartOrientation:
        case SystemFieldDefinitions.Pub.MapSize:
        case SystemFieldDefinitions.Pub.TabPosition:
        case SystemFieldDefinitions.Pub.TabIconPosition:
            return (
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    {...sharedProps}
                    id={`sel-${fieldDefinition.systemId}-${fieldDefinition.id}`}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={fieldDefinition.optionItems}
                    editorSettings={{
                        ...sharedProps.editorSettings,
                        onChange: (item) => onSingleFieldChanged(item?.id)
                    }}
                    value={fieldDefinition.optionItems.find(fd => fd.id === fieldValue)}
                />
            );
        case SystemFieldDefinitions.Pub.TabIcon:
            return <IconSelectionEditor
                {...sharedProps}
                disabled={disabled}
                icons={props.icons}
                onChange={onSingleFieldChanged} />;
        case SystemFieldDefinitions.Pub.VirtualVariablesField: {
            const variablesComplexDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === SystemFieldDefinitions.Pub.VariablesComplex);
            const variableNameDefinition = variablesComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.VariableNameField);
            const variableValueDefinition = variablesComplexDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.VariableValueField);

            const values = Object.values(complexFieldValues)
                .filter((complexField) =>
                    complexField.fields && Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === variablesComplexDefinition.id)
                .map((complexField) => ({
                    rowIndex: complexField.rowIndex,
                    name: complexField.fields[variableNameDefinition.id],
                    value: complexField.fields[variableValueDefinition.id],
                }));

            return <VariableListEditor
                label={variablesComplexDefinition.label}
                values={values}
                onAddRemoveVariable={(item) => {
                    const maxRowIndex = Math.max(...values.map(v => v.rowIndex || 0), -1) + 1;
                    const data: Domain.Shared.ComplexField & { type: string } = {
                        rowIndex: maxRowIndex,
                        complexFieldDefinitionId: variablesComplexDefinition.id,
                        fields: {
                            [variableNameDefinition.id]: item.name,
                            [variableValueDefinition.id]: item.value,
                        },
                        type: item.type,
                    };

                    props.onVariablesChanged(data);
                }}
                allowCreate={props.isDesigner}
                allowDelete={props.isDesigner}
            />;
        }
        default:
            console.log(fieldDefinition);
            return (<>{fieldDefinition.systemId} - {fieldDefinition.name}<br /></>);
    }
};
