import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { JournalCombinationsGrid } from '../../../components/JournalCombinationsGrid';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    baseYear: number,
    journalElementKinds: Domain.Finance.JournalElementKind[],
    onCancel: () => void,
    onError: (exception: any) => void,
    onCombinationSelected: (combination: Domain.Finance.JournalCombination) => void
};

export const SelectJournalCombination: React.FC<Props> = (props) => {
    const [selectedCombination, setSelectedCombination] = useState<Domain.Finance.JournalCombination>();

    const onRowClick = (combination: Domain.Finance.JournalCombination) => {
        setSelectedCombination(combination);
    }

    return (
        <LsModal
            id='modal-select-journal-combination'
            title='Kies een boekingscombinatie'
            width={1300}
            height={window.innerHeight - 100}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                rightButtonText: 'Opslaan',
                rightButtonDisabled: !selectedCombination,
                onLeftButtonClick: props.onCancel,
                onRightButtonClick: () => props.onCombinationSelected(selectedCombination),
            }}
        >
            <JournalCombinationsGrid
                journalElementKinds={props.journalElementKinds}
                baseYear={props.baseYear}
                pageSize={7}
                onError={props.onError}
                selectedRowKey={selectedCombination?.elementCombinationRK}
                onRowClick={onRowClick}
            />
        </LsModal>
    );
};
