import styled from 'styled-components';
import { cssFontNormal, cssFontSemiBold, palette, px, FlexBox } from '@liasincontrol/ui-basics';

const Wrapper = styled.div`
    margin-top: ${px(16)};
`;

const ItemWrapper = styled.div`
    margin-top: ${px(8)};
`;

const Item = styled.div`
    padding: ${px(8)} 0 0 0;
    display: grid;
    grid-template-columns: ${px(48)} auto;
    grid-template-rows: ${px(20)} 1fr;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ArrowIcon = styled.div`
    grid-row: 1 / span 2;
`;

const Line = styled.div`
    ${cssFontSemiBold(14, 16, 30)}
    margin-left: 5px;
`;

const SubLine = styled.div`
    ${cssFontNormal(14, 16, 30)}
    display: inline-grid;
    white-space: break-spaces;
    margin-bottom: 10px;
    margin-left: 5px;
`;

const CustomFlexBox = styled(FlexBox)`
    padding: ${px(16)};
    background-color: ${palette.grey4};
    border-radius: 2px;
    position: relative;
`;

const Comment = styled.div`
    color: #4B55F6;
    position: absolute;
    top: -13px;
    right: -17px;
    padding: 3px;
    cursor: pointer;

    > svg {
        display: block;
        margin: 0;
    }
`;

export { ItemWrapper, CustomFlexBox, Item, Line, SubLine, ArrowIcon, Wrapper, Comment };