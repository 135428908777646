import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    message: string,
    onClose: () => void,
};

/**
 * Represents an UI component which is a dialog to inform the user that textassistant failed.
 */
export const TextAssistantErrorDialog: React.FC<Props> = (props) => {
    return (
        <LsModal
            id="textassistant-error-dialog"
            title="Tekstassistent"
            width={1300}
            height={window.innerHeight - 100}
            toolbar={{
                enabled: false,
                rightButtonText: 'Sluiten',
                onRightButtonClick: props.onClose
            }}
        >
            <Text value={props.message} />
        </LsModal>
    );
};
