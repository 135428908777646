import React, { useEffect, useState } from 'react';
import _, { Dictionary } from 'lodash';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import TuneIcon from '@mui/icons-material/Tune';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions } from '@liasincontrol/domain';
import { BasicValidator, DateValidator, FieldsHelper, NumberValidator, TextValidator, IconHelper, ValidationErrorData } from '@liasincontrol/core-service';
import { AutoFocus, Button, EdgeTab, EdgeTabs, EdgeToolbar, Label, palette, UserRightsControl } from '@liasincontrol/ui-basics';
import { MultiSelectElement } from '@liasincontrol/ui-elements';
import { ActionType, Actions } from '@liasincontrol/userrights-service';
import { ActionSource } from '@liasincontrol/redux-service';
import { AuditTrail } from './History/AuditTrail';
import Helper from './index.helper';
import * as Styled from './index.styled';
import { StatusTrail } from './History/StatusTrail';
import { SetWorkflowStateDialog } from './SetWorkflowStateDialog/index';
import { CommentList } from './Comments/CommentList';
import { ArrowBack } from '@mui/icons-material';
import { EditorRenderer } from './EditorRenderer';
import { HierarchySource, Props, StepDetailsType, Tabs } from './index.types';

/**
 * Represents a UI component that renders the element settings form.
 */
export const Settings: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [formValidationErrors, setFormValidationErrors] = useState<Dictionary<ValidationErrorData[]>>({});
    const [complexFieldsValidationErrors, setComplexFieldsValidationErrors] = useState<Dictionary<Record<string, Dictionary<ValidationErrorData[]>>>>({});
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Main);
    const [currentStateName, setCurrentStateName] = useState<string>();
    const [changeWorkflowState, setChangeWorkflowState] = useState<boolean>(false);
    const [previousWorkflowStateWithTasks, setPreviousWorkflowStateWithTasks] = useState<{ state: Domain.Publisher.TaskManagerWorkflowState, stateUsers: Domain.Publisher.WorkflowTask[] }>({ state: null, stateUsers: [] });
    const elementDefinition = props.getElementDefinition(props.selectedElement.elementDefinitionSystemId, props.selectedElement.elementDefinitionId);

    useEffect(() => {
        if (!props.invalidElements) {
            return;
        }

        const invalidElementsIds = Object.keys(props.invalidElements);
        if (!invalidElementsIds.length) {
            return;
        }

        if (invalidElementsIds.findIndex((id) => id === props.selectedElement.elementId) < 0) {
            setFormValidationErrors({});
        }

    }, [props.selectedElement, props.invalidElements])

    useEffect(() => {
        setSelectedTab(Tabs.Main);
    }, [props.selectedElement.elementId]);

    useEffect(() => {
        if (!props.publicationWorkflowStates) return;

        if (props.userControlTask) {
            setCurrentStateName(props.userControlTask.currentStateName);
            const currentState = props.publicationWorkflowStates
                .find(state => state.id === props.userControlTask.taskStateId);

            if (currentState.category === Domain.Shared.WorkflowCategoryType.Done) {
                setPreviousWorkflowStateWithTasks({ state: null, stateUsers: [] });
                return;
            }

            const previousStates = props.publicationWorkflowStates
                .filter(state => state.order < currentState.order)
                .sort((a, b) => b.order - a.order);

            for (const previousState of previousStates) {
                if (previousState.category === Domain.Shared.WorkflowCategoryType.New) {
                    setPreviousWorkflowStateWithTasks({ state: null, stateUsers: [] });
                    return;
                }

                const previousStateTasks = props.componentWorkflowTasks.filter(task => task.taskStateId === previousState.id);
                if (previousStateTasks) {
                    setPreviousWorkflowStateWithTasks({ state: previousState, stateUsers: previousStateTasks });
                    return;
                }
            }
            return;
        }

        if (props.componentWorkflowTasks && props.componentWorkflowTasks.length) {
            setCurrentStateName(props.componentWorkflowTasks[0].currentStateName);
            return;
        }

        setCurrentStateName(props.publicationWorkflowStates.find(state => state.category === Domain.Shared.WorkflowCategoryType.New)?.name);
    }, [props.componentWorkflowTasks, props.userControlTask, props.publicationWorkflowStates]);

    if (!props.measureMoments) {
        return null;
    }

    if (!props.selectedElement) {
        return null;
    }

    const getExtraComplexFields = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.ReferenceAttachments) {
            const complexFieldDefinition = elementDefinition.complexFields
                .find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.AttachmentsComplex);
            return complexFieldDefinition.fields.filter((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.FileSource);
        }

        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.TileMenuControl) {
            const complexFieldDefinition = elementDefinition.complexFields.find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.TilesComplex);
            return complexFieldDefinition.fields.filter((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TilePage);
        }

        return [];
    };

    const getExtraDesignerLayoutFieldDefinitions = (): { fields: Domain.Shared.FieldDefinition[], ownerElement: Record<string, Domain.Publisher.Element> } => {
        const fields: Domain.Shared.FieldDefinition[] = [];
        let owners = {};

        if (props.isDesigner && props.getParentElement) {
            let parent: Domain.Publisher.Element = null;
            let parentDefinition: Domain.Shared.ElementDefinition = null;

            do {
                parent = props.getParentElement(props.selectedElement.elementId);
                parentDefinition = parent ? props.getElementDefinition(parent.elementDefinitionSystemId, parent.elementDefinitionId) : null;

                if (parent && parent?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItemPlaceHolder) {
                    //try again if we reached a placeholder container, until we either find a real container or reach the end.
                    continue;
                }
                break;
            } while (true);

            if (parent && parent?.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItem) {
                // Here we are adding certain fields present on the parent ContainerItem to the selected element.
                // Create a dummy field to represent the group of 4 whitespace fields.
                const virtualField = Helper.getVirtualWhitespaceFieldDefinition();
                fields.push(virtualField);
                owners[virtualField.id] = parent;

                switch (props.selectedElement.elementDefinitionSystemId) {
                    case Domain.SystemElementDefinitions.Pub.StackContainer:
                    case Domain.SystemElementDefinitions.Pub.TabContainer:
                    case Domain.SystemElementDefinitions.Pub.AccordionContainer:
                    case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
                        const extraContainerFields = parentDefinition.fields.filter((field) => ([SystemFieldDefinitions.Pub.ShowBackground, SystemFieldDefinitions.Pub.ShowShadow] as string[]).includes(field.systemId));
                        fields.push(...extraContainerFields);
                        owners = extraContainerFields.reduce((acc, fieldDef) => ({ ...acc, [fieldDef.id]: parent }), owners);
                        break;
                    case Domain.SystemElementDefinitions.Pub.ImageControl:
                    case Domain.SystemElementDefinitions.Pub.TitleControl:
                    case Domain.SystemElementDefinitions.Pub.TextControl:
                    case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
                    case Domain.SystemElementDefinitions.Pub.DataTableControl:
                    case Domain.SystemElementDefinitions.Pub.QuickNavControl:
                    case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
                    case Domain.SystemElementDefinitions.Pub.DataSourceText:
                    case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
                    case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
                    case Domain.SystemElementDefinitions.Pub.TreeViewControl:
                    case Domain.SystemElementDefinitions.Pub.PivotTableControl:
                    case Domain.SystemElementDefinitions.Pub.TabDsControl:
                        const printAsLandscapeField = parentDefinition.fields.find((field) => field.systemId === SystemFieldDefinitions.Pub.PrintAsLandscape);
                        fields.push(printAsLandscapeField);
                        owners[printAsLandscapeField.id] = parent;
                        break;
                    case Domain.SystemElementDefinitions.Pub.MenuControl:
                    case Domain.SystemElementDefinitions.Pub.TileMenuControl:
                    case Domain.SystemElementDefinitions.Pub.PieChartControl:
                    case Domain.SystemElementDefinitions.Pub.BarChartControl:
                    case Domain.SystemElementDefinitions.Pub.LineChartControl:
                    case Domain.SystemElementDefinitions.Pub.MapControl:
                        break;
                }
                return {
                    fields: fields,
                    ownerElement: owners
                };
            }
        }

        return {
            fields: [],
            ownerElement: {}
        };
    };

    const getClonedFieldDefinitions = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        const fields = [];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PivotTableControl) {

            const field = elementDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.PivotGridFields);
            fields.push({
                ...field,
                id: SystemFieldDefinitions.Pub.VirtualPivotGridFields,
                systemId: SystemFieldDefinitions.Pub.VirtualPivotGridFields
            });
        }

        return fields;
    };

    const getExtraFieldDefinitions = (elementDefinition: Domain.Shared.ElementDefinition): Domain.Shared.FieldDefinition[] => {
        const fields = [];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PageTemplate) {
            const virtualField = Helper.getVirtualVariablesFieldDefinition();
            fields.push(virtualField);
        }

        return fields;
    };

    const filterOutMainFields = (fieldDefinition: Domain.Shared.FieldDefinition, elementDefinition: Domain.Shared.ElementDefinition): boolean => {
        const unwantedFields = [
            SystemFieldDefinitions.Pub.ShowWhitespaceTop,
            SystemFieldDefinitions.Pub.ShowWhitespaceBottom,
            SystemFieldDefinitions.Pub.ShowWhitespaceLeft,
            SystemFieldDefinitions.Pub.ShowWhitespaceRight,
        ] as string[];
        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.ContainerItem) {
            unwantedFields.push(SystemFieldDefinitions.Pub.ShowShadow);
            unwantedFields.push(SystemFieldDefinitions.Pub.ShowBackground);
            unwantedFields.push(SystemFieldDefinitions.Pub.PrintAsLandscape);
        }
        if (unwantedFields.includes(fieldDefinition.systemId)) {
            return false;
        }

        if (elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.PageTemplate) {
            // In the admin template editor, it's possible to select the template itself. 
            // Only allow editing the name and variables field in this case.
            return fieldDefinition.systemId === SystemFieldDefinitions.Pub.Name || fieldDefinition.systemId === SystemFieldDefinitions.Pub.VirtualVariablesField;
        }

        return true;
    };

    const { fields: extraDesignerFields, ownerElement: extraFieldsOwner } = getExtraDesignerLayoutFieldDefinitions();

    const complexFields = getExtraComplexFields(elementDefinition);
    const clonedFields = getClonedFieldDefinitions(elementDefinition);
    const extraFields = getExtraFieldDefinitions(elementDefinition);

    const advancedFields = [
        SystemFieldDefinitions.Pub.TableColumnSettings,
        SystemFieldDefinitions.Pub.IsStudioControl,
        SystemFieldDefinitions.Pub.MeasureMomentId,
        SystemFieldDefinitions.Pub.HierarchyDefinitionId,
        SystemFieldDefinitions.Pub.ParentEntityTypeId,
        SystemFieldDefinitions.Pub.ParentEntityId,
        SystemFieldDefinitions.Pub.EntityTypeId,
        SystemFieldDefinitions.Pub.EntityId,
        SystemFieldDefinitions.Pub.FieldId,
        SystemFieldDefinitions.Pub.HeaderFieldId,
        SystemFieldDefinitions.Pub.FieldsListJson,
        SystemFieldDefinitions.Pub.VirtualPivotGridFields
    ] as string[];

    const fieldIdsMainTabList: string[] = elementDefinition.fields
        .concat(extraDesignerFields)
        .concat(complexFields)
        .concat(extraFields)
        .filter(fieldDefinition => filterOutMainFields(fieldDefinition, elementDefinition))
        // Filter out the field-definitions which should not be on the main-tab.
        .filter(fieldDefinition => !advancedFields.includes(fieldDefinition.systemId))
        .sort(Helper.sortFieldDefinitionsList)
        .map(fieldDefinition => fieldDefinition.id);

    // Get a list of fieldId's that should be on the advanced-tab.
    const fieldIdAdvancedSettingsTabList: string[] = elementDefinition.fields
        .concat(clonedFields)
        .filter(fieldDefinition => advancedFields.includes(fieldDefinition.systemId))
        .sort(Helper.sortFieldDefinitionsList)
        .map(fieldDefinition => fieldDefinition.id);

    const hasDataSourceValue = () => {
        if (fieldEditorElementsAdvancedSettingsTab && conditionalAdvancedSettingsElementIds.includes(props.selectedElement.elementDefinitionSystemId)) {
            const dataSourceFieldDefinition = elementDefinition.fields.find((item) => item.systemId === SystemFieldDefinitions.Pub.DataSource);
            if (!dataSourceFieldDefinition) {
                return false;
            }
            return !!props.selectedElement.fields[dataSourceFieldDefinition.id];
        } else {
            return false;
        }
    };

    const renderEditorRenderer = (fieldIdTabList: string[], extraFieldsOwner?: Record<string, Domain.Publisher.Element>) => {
        return fieldIdTabList
            .map((fieldId, index) => {
                const element = (extraFieldsOwner && extraFieldsOwner[fieldId]) || props.selectedElement;

                let isStudioComponent = false;

                if (props.selectedElement.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionDataControl) {
                    // const isStudioControlFieldValue = getFieldValue(props.selectedElement, elementDefinition, SystemFieldDefinitions.Pub.IsStudioControl);
                    const fieldDefinitionId = elementDefinition.fields?.find((item: Domain.Shared.FieldDefinition) => item.systemId === SystemFieldDefinitions.Pub.IsStudioControl)?.id;
                    const isStudioControlFieldValue = props.selectedElement.fields[fieldDefinitionId];
                    isStudioComponent = isStudioControlFieldValue && isStudioControlFieldValue.toLowerCase() === 'true';
                }

                return (
                    <EditorRenderer
                        publicationId={props.publicationId}
                        key={index}
                        fieldId={fieldId}
                        selectedElement={element}
                        validationErrors={formValidationErrors[fieldId]}
                        complexFieldsValidationErrors={complexFieldsValidationErrors[fieldId]}
                        isDesigner={props.isDesigner}
                        disabled={props.editableElementIds && props.editableElementIds[element.elementId] && !props.editableElementIds[element.elementId].editable}
                        getElementDefinition={props.getElementDefinition}
                        onUploadAttachment={props.onUploadAttachment}
                        onLoadAttachment={props.onLoadAttachment}
                        onRemoveAttachment={props.onRemoveAttachment}
                        templateOperations={props.templateOperations}
                        onComplexFieldChanged={(change: Domain.Publisher.ComplexFieldPatch) => {
                            props.onComplexFieldChanged(change);
                            const changedElementDefinition = extraFieldsOwner && extraFieldsOwner[fieldId]
                                ? props.getElementDefinition(extraFieldsOwner[fieldId].elementDefinitionSystemId, extraFieldsOwner[fieldId].elementDefinitionId)
                                : elementDefinition;
                            const changedFieldsIds = Object.keys(change.complexField.fields);
                            const fieldDefinitions = changedElementDefinition.complexFields.flatMap(x => x.fields);
                            let allErrors: Record<string, any[]> = {};
                            const complexFieldsValidationErrorsClone = _.cloneDeep(complexFieldsValidationErrors);
                            changedFieldsIds.forEach(changedFieldId => {
                                const fieldDefinition = fieldDefinitions.find(f => f.id === changedFieldId);
                                const value = change.complexField.fields[fieldDefinition.id];
                                let errors = [];
                                switch (fieldDefinition.dataType) {
                                    case 'String':
                                        errors = new TextValidator({
                                            required: fieldDefinition.required,
                                            stringMaxLength: fieldDefinition.stringMaxLength,
                                            stringType: fieldDefinition.stringType
                                        }).validate(value);
                                        break;
                                    default:
                                        errors = new BasicValidator({ required: fieldDefinition.required }).validate(value);
                                        break;
                                }
                                allErrors = { ...allErrors, [changedFieldId]: errors };
                            });

                            complexFieldsValidationErrorsClone[fieldId] = {
                                [change.rowIndex]: allErrors
                            };
                            setComplexFieldsValidationErrors(complexFieldsValidationErrorsClone);
                        }}
                        onSingleFieldChanged={(changes: Domain.Publisher.FieldPatch[]) => {
                            props.onSingleFieldChanged(changes);
                            const changedElementDefinition = extraFieldsOwner && extraFieldsOwner[fieldId]
                                ? props.getElementDefinition(extraFieldsOwner[fieldId].elementDefinitionSystemId, extraFieldsOwner[fieldId].elementDefinitionId)
                                : elementDefinition;

                            const formValidationErrorsClone = _.cloneDeep(formValidationErrors);

                            changes.forEach((change) => {
                                const fieldDefinition = changedElementDefinition.fields.find(field => field.id === change.fieldId);
                                let errors = [];
                                switch (fieldDefinition.dataType) {
                                    case 'String':
                                        errors = new TextValidator({
                                            required: fieldDefinition.required,
                                            stringMaxLength: fieldDefinition.stringMaxLength,
                                            stringType: fieldDefinition.stringType
                                        }).validate(change.value);
                                        break;
                                    case 'DateTimeOffset':
                                        errors = new DateValidator({
                                            required: fieldDefinition.required,
                                            dateMinValue: fieldDefinition.dateMinValue,
                                            dateMaxValue: fieldDefinition.dateMaxValue
                                        }).validate(new Date(change.value));
                                        break;
                                    case 'Integer':
                                        errors =
                                            new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.integerMinValue, maxValue: fieldDefinition.integerMaxValue })
                                                .validate(+change.value);
                                        break;
                                    case 'Decimal':
                                        errors =
                                            new NumberValidator({ required: fieldDefinition.required, minValue: fieldDefinition.decimalMinValue, maxValue: fieldDefinition.decimalMaxValue })
                                                .validate(+change.value);
                                        break;
                                    default:
                                        errors = new BasicValidator({ required: fieldDefinition.required }).validate(change.value);
                                        break;
                                }
                                formValidationErrorsClone[change.fieldId] = errors;
                            });

                            //if treeviewControl is owner and changed fields contains datasource
                            if (changedElementDefinition.systemId === SystemElementDefinitions.Pub.TreeViewControl) {
                                const dataSourceFieldDefinition = changedElementDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSource);
                                const columnSettingsFieldDefinition = changedElementDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.TableColumnSettings);
                                const dataSourceChange = changes.find(change => change.fieldId === dataSourceFieldDefinition.id);
                                if (dataSourceChange?.value) {
                                    const columnChange = changes.find(change => change.fieldId === columnSettingsFieldDefinition.id);
                                    if (columnChange?.value) {
                                        const columnData = JSON.parse(columnChange.value || '{}');
                                        const validColumns = ['ElementId', 'ParentElementId'].every(required => columnData.some(col => col.dataField === required));
                                        if (!validColumns) formValidationErrorsClone[dataSourceFieldDefinition.id] = [{ error: 'Verplichte Velden \'ElementId\' en/of \'ParentElementId\' zijn niet aanwezig in de databron.' }];
                                    }
                                }
                            }
                            // old useEffect
                            const hasErrors = Object.values(formValidationErrorsClone).some(errorArray => errorArray.length > 0);
                            props.onErrorsInSettings(hasErrors, props.selectedElement.elementId);

                            setFormValidationErrors(formValidationErrorsClone);
                        }}
                        measureMoments={props.measureMoments}
                        elementTypes={props.elementTypes}
                        hierarchies={isStudioComponent ? props.hierarchies[ActionSource.Studio] : props.hierarchies[ActionSource.Performance]}
                        hierarchyDefinitions={props.hierarchyDefinitions}
                        hierarchySource={isStudioComponent ? HierarchySource.Studio : HierarchySource.Performance}
                        loadPerformanceHierarchy={props.loadPerformanceHierarchy}
                        getDefinition={props.getDefinition}
                        getAvailableHierarchyTypes={props.getAvailableHierarchyTypes}
                        loadStudioHierarchy={props.loadStudioHierarchy}
                        sitemap={props.sitemap}
                        selectedPageId={props.selectedPageId}
                        templateName={props.templateName}
                        icons={props.icons}
                        onVariablesChanged={props.onVariablesChanged}
                        variables={props.variables}
                    />
                );
            });
    };

    const fieldEditorElementsMainTab = renderEditorRenderer(fieldIdsMainTabList, extraFieldsOwner);

    const fieldEditorElementsAdvancedSettingsTab = fieldIdAdvancedSettingsTabList.length > 0 ? renderEditorRenderer(fieldIdAdvancedSettingsTabList) : null;
    const conditionalAdvancedSettingsElementIds = [
        SystemElementDefinitions.Pub.DataTableControl,
        SystemElementDefinitions.Pub.PieChartControl,
        SystemElementDefinitions.Pub.TreeViewControl,
        SystemElementDefinitions.Pub.AccordionDsControl,
        SystemElementDefinitions.Pub.PivotTableControl
    ] as string[];

    const visibleTabsCount = 1 + (fieldEditorElementsAdvancedSettingsTab ? 1 : 0) + (props.publicationWorkflowStates ? 1 : 0);
    const elementSettings = getBaseElementSettings(props.selectedElement, elementDefinition);

    const typeInputCustomSingleValue = (props) => (
        <Styled.SingleValueWrapper>
            {IconHelper.getWorkFlowStatusIcon(props.data.label, 20)}
            <Styled.SingleValueLabel>
                {props.data.label}
            </Styled.SingleValueLabel>
        </Styled.SingleValueWrapper>
    );

    const getElementAuditTrail = (auditEvents: { content: Domain.Shared.AuditEvent[] }) => {
        const filteredContent = auditEvents?.content?.filter(c => c.elementId === props.selectedElement.elementId);
        return { content: filteredContent };
    };

    const getCaptionText = () => {
        return `${elementDefinition.label ? elementDefinition.label : elementDefinition.name}`;
    };

    const formatElementName = (): string => {
        if (elementDefinition.systemId === SystemElementDefinitions.Pub.ContainerItem) {
            const parentElement = props.getParentElement(props.selectedElement.elementId);
            if (parentElement?.elementDefinitionSystemId === SystemElementDefinitions.Pub.TabContainer) {
                return 'Tab';
            } else if (parentElement?.elementDefinitionSystemId === SystemElementDefinitions.Pub.AccordionContainer) {
                return 'Accordeon';
            } else {
                return getCaptionText();
            }
        } else {
            return getCaptionText();
        }
    };

    const toggleControlTaskStatus = (completeTask: boolean): void => {
        props.onToggleTaskStatus(props.userControlTask, completeTask);
    };

    const hasPublisherSetPublishProfileItemsAccess = props.canPerformAction && props.canPerformAction(Actions.COMPLEX_PublisherSetPublishProfileItems, ActionType.Update);
    const tabCount = visibleTabsCount + (!props.isDesigner ? 1 : 0);

    const tasksContributorsForState = (stateId: string) => {
        return props.componentWorkflowTasks ? (props.componentWorkflowTasks?.filter(cc => cc.taskStateId === stateId)?.map((cc) => ({
            enabled: true,
            id: cc.userId,
            name: cc.userName,
            email: cc.userId,
            frontIcon: { icon: cc.taskIsCompleted ? <TaskAltIcon sx={{ color: palette.green }} /> : <CircleOutlinedIcon sx={{ color: palette.grey2 }} />, color: cc.taskIsCompleted ? palette.green : palette.grey2 },
        } as Domain.Shared.RightUser)) || []) : [];
    };

    const getStateDetails = (stateId: string): StepDetailsType => {
        if (props.componentWorkflowTasks.length <= 0)
            return {
                isCurrentStep: false,
                disabled: true,
                isLastStep: false,
            };

        const currentState = props.publicationWorkflowStates.find(state => state.id === props.componentWorkflowTasks[0].currentStateId);
        const state = props.publicationWorkflowStates.find(state => state.id === stateId);
        return {
            isCurrentStep: currentState?.id === stateId,
            disabled: state?.order > currentState?.order,
            isLastStep: currentState?.category === Domain.Shared.WorkflowCategoryType.Done,
        };
    };

    const diplayChangeStateButton = !!props.userControlTask;

    const controlIsLocked = !props.editableElementIds || !props.editableElementIds[props.selectedElement.elementId] || props.editableElementIds[props.selectedElement.elementId].isLockedByOther;
    const controlHasDiscussion = !props.editableElementIds || !props.editableElementIds[props.selectedElement.elementId] || props.editableElementIds[props.selectedElement.elementId].componentHasDiscussion;

    return (
        <>
            <EdgeToolbar edge="left" open={props.isVisible} inModal onToggle={() => props.onToggleVisibility()}>
                <Styled.LeftSideBarPanel hasPadding={visibleTabsCount === 1} className="slide-panel-section">
                    {visibleTabsCount > 1 ? (
                        <EdgeTabs tabCount={tabCount}>
                            <EdgeTab title={getCaptionText()} icon={<EditIcon />} active={selectedTab === Tabs.Main} onClick={() => (setSelectedTab(Tabs.Main))} tabCount={tabCount}>
                                <Styled.EdgeToolbarContent>
                                    {!_.isEmpty(props.editableElementIds) &&
                                        props.userControlTask &&
                                        props.onToggleTaskStatus &&
                                        (
                                            <>
                                                <Label text="Taak" />
                                                <Styled.RowWrapper>
                                                    <Styled.EdgeToolbarButton btnbase="ghostbuttons"
                                                        btntype="medium_noicon"
                                                        disabled={props.userControlTask.taskIsCompleted || props.isProcessing || controlIsLocked}
                                                        onClick={() => toggleControlTaskStatus(true)}>
                                                        {props.userControlTask.taskIsCompleted ? 'Taak afgerond' : 'Taak afronden'}
                                                    </Styled.EdgeToolbarButton>
                                                    {props.userControlTask.taskIsCompleted &&
                                                        <Styled.EdgeToolbarButton
                                                            btnbase="ghostbuttons"
                                                            btntype="medium_noicon"
                                                            onClick={() => toggleControlTaskStatus(false)}
                                                            disabled={props.isProcessing || controlIsLocked}>
                                                            Taak heropenen
                                                        </Styled.EdgeToolbarButton>
                                                    }
                                                </Styled.RowWrapper>
                                            </>)
                                    }
                                    {props.publicationWorkflowStates && (
                                        <>
                                            <Label text="Status" />
                                            <Styled.RowWrapper>
                                                <Styled.StaticFieldValue>
                                                    {typeInputCustomSingleValue({ data: { label: currentStateName } })}
                                                </Styled.StaticFieldValue>
                                            </Styled.RowWrapper>
                                            {diplayChangeStateButton &&
                                                <Styled.RowWrapper>
                                                    <Styled.EdgeToolbarButton
                                                        btnbase="ghostbuttons"
                                                        btntype="small_noicon"
                                                        disabled={props.isProcessing || !previousWorkflowStateWithTasks.state || controlIsLocked}
                                                        onClick={() => setChangeWorkflowState(true)}>
                                                        STATUS WIJZIGEN
                                                    </Styled.EdgeToolbarButton>
                                                </Styled.RowWrapper>}
                                        </>
                                    )}
                                    <AutoFocus>
                                        {props.isDesigner &&
                                            <Styled.ToolbarTitle>
                                                {props.selectedElement.elementDefinitionSystemId !== SystemElementDefinitions.Pub.PageTemplate &&
                                                    <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={props.resetSelectedElement} />
                                                }
                                                {getCaptionText()}
                                            </Styled.ToolbarTitle>}
                                        {fieldEditorElementsMainTab}
                                    </AutoFocus>
                                    {hasPublisherSetPublishProfileItemsAccess && !props.isDesigner &&
                                        <MultiSelectElement
                                            id="publish-profiles"
                                            label="Publicatie profiel"
                                            editorSettings={{
                                                disabled: !props.availablePublishProfiles,
                                                validationErrors: undefined,
                                                restrictions: undefined,
                                                onChange: (selectedItems) => props.onUpdateControlPublishProfiles(selectedItems),
                                            }}
                                            searchable={false}
                                            clearable={true}
                                            optionItems={props.availablePublishProfiles ? props.availablePublishProfiles : []}
                                            value={props.selectedPublishProfiles ? props.selectedPublishProfiles : []}
                                        />
                                    }
                                </Styled.EdgeToolbarContent>
                            </EdgeTab>
                            {fieldEditorElementsAdvancedSettingsTab &&
                                <EdgeTab title="Geavanceerde instellingen"
                                    icon={<TuneIcon />}
                                    active={selectedTab === Tabs.AdvancedSettings}
                                    onClick={() => setSelectedTab(Tabs.AdvancedSettings)}
                                    disabled={!hasDataSourceValue}
                                    tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Geavanceerde instellingen</Styled.ToolbarTitle>
                                            {fieldEditorElementsAdvancedSettingsTab}
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {props.componentWorkflowTasks &&
                                <EdgeTab title="Voortgang" icon={<PersonIcon />} active={selectedTab === Tabs.Users} onClick={() => (setSelectedTab(Tabs.Users))} tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Voortgang</Styled.ToolbarTitle>
                                            {elementSettings.allowPatchContent && (<>
                                                {props.publicationWorkflowStates?.map((state) => {
                                                    const details = getStateDetails(state.id);
                                                    return <UserRightsControl
                                                        key={state.id}
                                                        users={tasksContributorsForState(state.id)}
                                                        disabled={details.disabled}
                                                        actionsEnabled={false}
                                                        showListHeading={false}
                                                        workflowTemplateStateName={state.name}
                                                        isCurrentStep={details.isCurrentStep}
                                                        isLastStep={details.isLastStep}
                                                    />;
                                                })}
                                            </>)
                                            }
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {!props.isDesigner &&
                                <EdgeTab title="Discussie" icon={controlHasDiscussion ? <MarkUnreadChatAltIcon /> : <ChatBubbleIcon />} active={selectedTab === Tabs.Comments} onClick={() => (setSelectedTab(Tabs.Comments))} tabCount={tabCount}>
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Discussie</Styled.ToolbarTitle>
                                            <CommentList
                                                publicationId={props.publicationId}
                                                pageId={props.selectedPageId}
                                                elementId={props.selectedElement.elementId}
                                                currentUserId={props.currentUserId}
                                                componentWorkflowTasks={props.componentWorkflowTasks}
                                            />
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                            {!props.isDesigner &&
                                <EdgeTab
                                    title="Historie"
                                    icon={<HistoryIcon />}
                                    active={selectedTab === Tabs.History}
                                    onClick={() => {
                                        setSelectedTab(Tabs.History);
                                        props.onAuditRefresh();
                                    }}
                                    tabCount={tabCount}
                                >
                                    <Styled.EdgeToolbarContent>
                                        <AutoFocus>
                                            <Styled.ToolbarTitle>Historie</Styled.ToolbarTitle>
                                            <AuditTrail
                                                elementDefinition={elementDefinition}
                                                auditTrail={getElementAuditTrail(props.auditEvents)}
                                            />
                                            <StatusTrail items={props.statusAuditEvents}></StatusTrail>
                                        </AutoFocus>
                                    </Styled.EdgeToolbarContent>
                                </EdgeTab>
                            }
                        </EdgeTabs>)
                        : (
                            <Styled.TabsPlaceholder>
                                <AutoFocus>
                                    {props.isDesigner && <Styled.ToolbarTitle>
                                        {props.selectedElement.elementDefinitionSystemId !== SystemElementDefinitions.Pub.PageTemplate &&
                                            <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={props.resetSelectedElement} />
                                        }
                                        {formatElementName()}</Styled.ToolbarTitle>}
                                    {fieldEditorElementsMainTab}
                                </AutoFocus>
                            </Styled.TabsPlaceholder>)
                    }
                </Styled.LeftSideBarPanel>
            </EdgeToolbar>
            {changeWorkflowState && <SetWorkflowStateDialog
                newWorkflowStateName={previousWorkflowStateWithTasks.state?.name}
                saveButtonDisabled={props.isProcessing}
                onCloseDialog={() => setChangeWorkflowState(false)}
                previousWorkflowStateUsers={previousWorkflowStateWithTasks.stateUsers}
                onChangeWorkflowState={(userIds: string[], remark: string) => {
                    props.onChangeElementState(props.selectedElement.elementId, previousWorkflowStateWithTasks.state?.id, userIds, remark).then(() => {
                        setChangeWorkflowState(false);
                    });
                }}></SetWorkflowStateDialog>}
        </>
    );
};

/**
 * Gets the base settings of an element.
 * 
 * @param element Defines the element.
 * @param elementDefinition Defines the element definition.
 */
const getBaseElementSettings = (element: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition): Domain.Publisher.BaseControlElement => {
    const elementSettings = new Domain.Publisher.BaseControlElement();
    FieldsHelper.mapObject<Domain.Publisher.BaseControlElement>(elementSettings, elementDefinition.fields, element.fields);

    return elementSettings;
};

