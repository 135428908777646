import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AnyFormData, BasicValidator, FormHelper, FormMode, TextValidator, ValidationErrorData, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import { Heading3, ListItem, MultiSelectItem, MultiSelectList, WarningWrapper, WarningLook, Text, IDataItemProps, AutoFocus } from '@liasincontrol/ui-basics';
import { MultiSelectElement, TextElement } from '@liasincontrol/ui-elements';
import { DxSelectItem, IndicatorSize, LoadIndicator, LsModal } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';

type Props = {
    moduleId: string,
    hierarchyDefinition?: Domain.Shared.HierarchyDefinition,
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    disableSaveButton: boolean,
    formMode: FormMode,
    hierarchyNames: string[],
    onSave: (changedData: { data: AnyFormData, links: Domain.Shared.HierarchyLinkDefinition[] }, formmode: FormMode) => void,
    onCancel: () => void,
};

const previewMax = {
    height: undefined,
    width: undefined,
};

/**
 * Represents a UI component that renders the form for creating or editing a HierarchyDefinition.
 */
export const HierarchyDefinitionForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<{ data: AnyFormData, links: Domain.Shared.HierarchyLinkDefinition[], linksTouched: boolean }>(initFormData(undefined, undefined));
    const fieldDefinitions = {
        [Domain.FieldDefinitions.Shared.nameFieldDefinition.id]: Domain.FieldDefinitions.Shared.nameFieldDefinition,
        [Domain.FieldDefinitions.Shared.optionFieldDefinition.id]: Domain.FieldDefinitions.Shared.optionFieldDefinition,
    };
    const validators = getValidators(fieldDefinitions, props.hierarchyNames);
    const [selectedElementDefinition, setSelectedElementDefinition] = useState<Domain.Shared.ElementDefinition>();
    const [possibleElementDefinitions, setPossibleElementDefinitions] = useState<Domain.Shared.ElementDefinition[]>();

    const hierarchyElementDefinitions = useMemo(
        () => {
            setSelectedElementDefinition(undefined);
            return getHierarchyElementDefinitions(props.elementDefinitions, (form.data.values[Domain.FieldDefinitions.Shared.optionFieldDefinition.id] as IDataItemProps<DxSelectItem>[]).map(e => e.value as string));
        },
        [props.elementDefinitions, form.data.values[Domain.FieldDefinitions.Shared.optionFieldDefinition.id]]);
    const [availableElementDefinitions, setAvailableElementDefinitions] = useState<MultiSelectItem[]>([]);
    const [hierarchyDefinitionLinksForm, setHierarchyDefinitionLinksForm] = useState<{ showDialog: boolean, isSaving: boolean }>(
        { showDialog: props.formMode === FormMode.Edit, isSaving: false });

    useEffect(() => {
        if (!props.hierarchyDefinition || !props.elementDefinitions) return;
        setForm(initFormData(props.hierarchyDefinition, props.elementDefinitions));
    }, [props.hierarchyDefinition, props.elementDefinitions]);

    useEffect(() => {
        if (!props.elementDefinitions) return;
        if (possibleElementDefinitions) return;

        const activeElementDefinitions = Object.values(props.elementDefinitions).filter(def => !def.deleted);

        if (props.formMode === FormMode.AddNew) {
            setPossibleElementDefinitions(activeElementDefinitions);
        }
        else if (props.hierarchyDefinition?.items) {
            // Add the existing used deactivated element definitions to the list of possible definitions.
            const missingUsedElementDefinitionIds = props.hierarchyDefinition.items.map((link) => link.toElementDefinitionId)
                .filter(id => !activeElementDefinitions.find(existing => existing.id === id));
            const missingElementDefinitions = Object.values(props.elementDefinitions).filter(def => missingUsedElementDefinitionIds.includes(def.id));

            setPossibleElementDefinitions([...activeElementDefinitions, ...missingElementDefinitions]);
        }
    }, [props.hierarchyDefinition, props.elementDefinitions, props.formMode]);

    useEffect(() => {
        if (!selectedElementDefinition || !hierarchyElementDefinitions) {
            setAvailableElementDefinitions([]);
            return;
        }

        const getParentsIds = (id: string, tree: Domain.Shared.HierarchyLinkDefinition[]) => tree?.filter((link) => !!link.fromElementDefinitionId && link.toElementDefinitionId === id)?.map((e) => e.fromElementDefinitionId);

        const getNodes = (nodes: string[], ids: string[], tree: Domain.Shared.HierarchyLinkDefinition[]): string[] => {
            return nodes.flatMap(currentNode => {
                const parents = getParentsIds(currentNode, tree);
                if (!parents || parents.length === 0) return [currentNode];
                return _.uniq([currentNode, ...getNodes(parents, ids, form.links)]);
            });
        };

        const ancestorIds: string[] = getNodes([selectedElementDefinition.id], [], form.links);

        const isChildren = (parentId: string, checkedId: string) =>
            form.links?.some((link) => link.fromElementDefinitionId === parentId && link.toElementDefinitionId === checkedId);
        const available = (
            hierarchyElementDefinitions
                .filter(elementDefinition => elementDefinition.id !== selectedElementDefinition.id && ancestorIds.indexOf(elementDefinition.id) < 0)
                ?.map(elementDefinition => ({
                    label: getDisplayName(elementDefinition),
                    value: isChildren(selectedElementDefinition.id, elementDefinition.id),
                    id: elementDefinition.id
                } as MultiSelectItem))
        );
        setAvailableElementDefinitions(available);
    }, [selectedElementDefinition, hierarchyElementDefinitions]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form.data, value, validators, systemId, [Domain.FieldDefinitions.Shared.nameFieldDefinition.id]);
        const newLinks = systemId === Domain.FieldDefinitions.Shared.optionFieldDefinition.id ? updateLinkDefinitions(value as IDataItemProps<DxSelectItem>[], props.elementDefinitions, form.links) : form.links;
        setForm((prev) => ({ ...prev, data: newForm, links: newLinks }));
    };

    const updateItemValue = (item: MultiSelectItem, value: boolean) => {
        const linksCopy = [...form.links];
        const link = {
            fromElementDefinitionId: selectedElementDefinition.id,
            fromElementDefinitionSystemId: selectedElementDefinition.systemId,
            toElementDefinitionId: item.id,
            toElementDefinitionSystemId: hierarchyElementDefinitions.find((entity) => entity.id === item.id).systemId,
        };
        if (value) {
            linksCopy.push(link);
        } else {
            const index = linksCopy.indexOf(link);
            linksCopy.splice(index, 1);
        }
        setForm((prev) => ({ ...prev, links: linksCopy, linksTouched: true }));
        setAvailableElementDefinitions((state) => [...state].map(existing => existing.id === item.id ? { ...existing, value } : existing));
    };

    const onNext = () => {
        setHierarchyDefinitionLinksForm({ showDialog: true, isSaving: false });
    };

    const onSave = () => {
        setHierarchyDefinitionLinksForm({ showDialog: true, isSaving: true });
        props.onSave(form, props.formMode);
    };

    const formIsUnTouched = Object.keys(form.data.touched).length === 0 && !form.linksTouched;

    // #region JSX.Elements...
    const getHierarchyElementDefinitionElements: JSX.Element = (
        <Styled.SideMenuList>
            {hierarchyElementDefinitions.map((entity) => (
                <ListItem key={entity.id} active={selectedElementDefinition && selectedElementDefinition.id === entity.id} id={entity.id} label={getDisplayName(entity)} canDelete={false} onClick={() => setSelectedElementDefinition(entity)} />
            ))}
        </Styled.SideMenuList>
    );

    if (!possibleElementDefinitions) {
        return null;
    }

    return (
        <LsModal
            id={`modal-hierarchy-definition-${props.moduleId}`}
            width={1300}
            title={props.formMode === FormMode.AddNew ? 'Hiërarchiedefinitie aanmaken' : 'Hiërarchiedefinitie bewerken'}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: hierarchyDefinitionLinksForm.showDialog ? 'Opslaan' : 'Volgende',
                onRightButtonClick: hierarchyDefinitionLinksForm.showDialog ? onSave : onNext,
                rightButtonDisabled: !form.data.isValid || hierarchyDefinitionLinksForm.isSaving || props.disableSaveButton || formIsUnTouched,
            }}
        >
            {props.hierarchyDefinition?.deleted &&
                <Styled.WarningText>
                    <Text danger={true} value='Hiërarchiedefinities is uitgeschakeld' />
                </Styled.WarningText>}
            <MuiGrid container
                spacing={{ xs: 2 }}
                columns={{ xs: 1, md: 4 }}
                justifyContent="flex-start"
                alignItems="flex-start">
                {hierarchyDefinitionLinksForm.isSaving && <LoadIndicator variant={IndicatorSize.extralarge} />}
                {!hierarchyDefinitionLinksForm.isSaving && (<>
                    <MuiGrid item xs={1} md={4}>
                        <AutoFocus>
                            <TextElement
                                key={Domain.FieldDefinitions.Shared.nameFieldDefinition.id}
                                id={Domain.FieldDefinitions.Shared.nameFieldDefinition.id}
                                label='Naam'
                                editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form.data, (val: string) => { storeFormValue(val, Domain.FieldDefinitions.Shared.nameFieldDefinition.id) }, Domain.FieldDefinitions.Shared.nameFieldDefinition.id)}
                                value={form.data.values[Domain.FieldDefinitions.Shared.nameFieldDefinition.id] as string}
                            />
                        </AutoFocus>
                    </MuiGrid>
                    {props.formMode === FormMode.Edit && (
                        <MuiGrid item xs={1} md={4}>
                            <WarningWrapper
                                look={WarningLook.warning}
                                icon={<WarningAmberIcon />}
                                className='mb-100 p-025'
                                messageText='Het wijzigen van een hiërarchiedefinitie is niet mogelijk wanneer er één of meerdere hiërarchieën gemaakt zijn op basis van de definitie.' />

                        </MuiGrid>
                    )}
                    <MuiGrid item xs={1} md={4}>
                        <MultiSelectElement
                            key={Domain.FieldDefinitions.Shared.optionFieldDefinition.id}
                            id={`select-hierarchy-def-${Domain.FieldDefinitions.Shared.optionFieldDefinition.id}`}
                            label='Elementdefinities'
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form.data, (val: IDataItemProps<DxSelectItem>[]) => {
                                setSelectedElementDefinition(undefined);
                                storeFormValue(val, Domain.FieldDefinitions.Shared.optionFieldDefinition.id);
                            }, Domain.FieldDefinitions.Shared.optionFieldDefinition.id)}
                            searchable={false}
                            clearable={true}
                            optionItems={
                                possibleElementDefinitions.map((elementDefinition) => {
                                    return {
                                        label: getDisplayName(elementDefinition),
                                        value: elementDefinition.id,
                                    } as IDataItemProps<DxSelectItem>
                                })}
                            value={form.data.values[Domain.FieldDefinitions.Shared.optionFieldDefinition.id] as IDataItemProps<DxSelectItem>[]}
                        />
                    </MuiGrid>
                </>)}
                {hierarchyDefinitionLinksForm.showDialog && !hierarchyDefinitionLinksForm.isSaving && hierarchyElementDefinitions && (<>
                    <MuiGrid key='field-name' item xs={1} md={1}>
                        <Heading3>Relaties</Heading3>
                        <Styled.Wrapper>
                            {getHierarchyElementDefinitionElements}
                        </Styled.Wrapper>
                    </MuiGrid>
                    <MuiGrid key='field-name' item xs={1} md={3}>
                        <Heading3>Toegestane onderliggende elementdefinities</Heading3>
                        <Styled.Wrapper>
                            <MultiSelectList
                                withBorder={false}
                                listItems={availableElementDefinitions}
                                previewMax={previewMax}
                                onChange={(item, value) => updateItemValue(item, value)}
                            />
                        </Styled.Wrapper>
                    </MuiGrid>
                </>)}
            </MuiGrid>
        </LsModal>
    )
};

const getHierarchyElementDefinitions = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, requestedIds: string[]): Domain.Shared.ElementDefinition[] => {
    if (!elementDefinitions) return null;
    return Object.values(elementDefinitions).filter((elementDefinition) => requestedIds.indexOf(elementDefinition.id) >= 0);
};

const updateLinkDefinitions = (items: IDataItemProps<DxSelectItem>[], elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, linkDefinitions: Domain.Shared.HierarchyLinkDefinition[]): Domain.Shared.HierarchyLinkDefinition[] => {
    const selectedElementDefinitionIds = items.map(item => item.value as string);

    // Remove from Hierarchy links all reference to deleted element definitions.
    const remainingLinkDefinitions = linkDefinitions.filter(linkDefinition =>
        selectedElementDefinitionIds.indexOf(linkDefinition.toElementDefinitionId) >= 0 &&
        (linkDefinition.fromElementDefinitionId === undefined || selectedElementDefinitionIds.indexOf(linkDefinition.fromElementDefinitionId) >= 0)
    );

    const usedElementDefinitionIds = remainingLinkDefinitions.map(linkDefinition => linkDefinition.toElementDefinitionId);

    // Represents the list of missing element definition id's (selected vs. linked).
    const newElementDefinitionIds = selectedElementDefinitionIds.filter(selected => !usedElementDefinitionIds.includes(selected));

    // Add missing definitions as unparented element definitions to the hierarchy definition.
    const newFloatingLinkDefinitions: Domain.Shared.HierarchyLinkDefinition[] = newElementDefinitionIds.map((id) => ({
        toElementDefinitionSystemId: elementDefinitions[id].systemId,
        toElementDefinitionId: id as string,
    }));

    return [...remainingLinkDefinitions, ...newFloatingLinkDefinitions];
};

const initFormData = (hierarchyDefinition?: Domain.Shared.HierarchyDefinition, elementDefinitions?: Record<string, Domain.Shared.ElementDefinition>): { data: AnyFormData, links: any, linksTouched: boolean } => {
    const usedElementIds = hierarchyDefinition?.items?.map((link) => link.toElementDefinitionId) || [];
    return {
        data: {
            values: {
                [Domain.FieldDefinitions.Shared.nameFieldDefinition.id]: hierarchyDefinition ? hierarchyDefinition.name : '',
                [Domain.FieldDefinitions.Shared.optionFieldDefinition.id]: hierarchyDefinition
                    ? getHierarchyElementDefinitions(elementDefinitions, usedElementIds)
                        ?.map((elementDefinition) => ({ label: getDisplayName(elementDefinition), value: elementDefinition.id } as IDataItemProps<DxSelectItem>))
                    : [],
            },
            touched: {},
            validationErrors: {},
            isValid: true,
        },
        links: hierarchyDefinition ? hierarchyDefinition.items : [],
        linksTouched: false,
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, hierarchyNames: string[]): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    const nameFieldDefinition = fieldDefinitions[Domain.FieldDefinitions.Shared.nameFieldDefinition.id];
    const optionFieldDefinition = fieldDefinitions[Domain.FieldDefinitions.Shared.optionFieldDefinition.id];

    return {
        [nameFieldDefinition.id]: new TextValidator({
            required: true,
            stringMaxLength: 50,
            stringMinLength: fieldDefinitions[Domain.FieldDefinitions.Shared.nameFieldDefinition.id].stringMinLength,
            stringType: fieldDefinitions[Domain.FieldDefinitions.Shared.nameFieldDefinition.id].stringType
        }, (value: string): ValidationErrorData[] => {
            if (hierarchyNames.map(name => name.toLocaleLowerCase()).includes(value.toLocaleLowerCase())) {
                return [{ error: 'Naam moet uniek zijn' }];
            }
            return [];
        }),
        [optionFieldDefinition.id]: new BasicValidator({ required: true }),
    };
};

const getDisplayName = (elementDefinition: Domain.Shared.ElementDefinition): string =>
    elementDefinition.name + (elementDefinition.deleted ? '* (Uitgeschakeld)' : '');