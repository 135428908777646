import React from 'react';
import styled from 'styled-components';
import { cssFontBold, cssFontNormal } from '../style';
import { IHelpTextProps } from './Props';
import { Button } from '../button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Popover } from 'devextreme-react/popover';
// tslint:disable:no-magic-numbers

export interface IPopoverProps {
    readonly isVisible: boolean;
}

export interface IHelpTextState {
    readonly popOver: boolean;
}

export class HelpText extends React.Component<IHelpTextProps, IHelpTextState> {
    public state = { popOver: false };

    public render() {
        const { children, id, title, type } = this.props;

        return (<>
            <AllignedButton
                id={`btn-show-info-${id}`}
                btnbase="iconbuttons"
                btntype="small_transparent"
                onClick={this.show}
                icon={type === 'info' ? <InfoOutlinedIcon /> : <HelpOutlineOutlinedIcon />
                }
            />
            <Popover
                target={`#btn-show-info-${id}`}
                // visible={this.state.popOver}
                hideOnOutsideClick={true}
                hideOnParentScroll={true}
                showEvent="click"
                position="right"
            >
                <Wrap>{title ? <Title>{title}</Title> : null}{children ? <Content>{children}</Content> : null} </Wrap>
            </Popover>
        </>
        );
    }

    public show = () => {
        this.setState({ popOver: !this.state.popOver });
    }
}

/* styling */

const Wrap = styled.div`
    padding: 0px;
    max-width: 350px;
`;

const AllignedButton = styled(Button)`
    vertical-align: middle;
`;

const Title = styled.h6`
    margin:0; padding:0 0 3px 0;
    ${cssFontBold(14, 20, 40)}
`;

const Content = styled.p`
    white-space: pre-wrap;
    margin:0; 
    padding:0 0 3px 0;
    word-wrap: break-word;
    ${cssFontNormal(14, 20, 0)}
`;
