import React, { useState } from 'react';
import { MultiSelectList, MultiSelectItem } from '@liasincontrol/ui-basics';
import { LsModal, SearchField } from '@liasincontrol/ui-devextreme';

type Props = {
    readonly onCancelled: () => void,
    readonly onConfirmed: (list: MultiSelectItem[]) => void,
    readonly listItems: MultiSelectItem[],
    readonly title: string,
    readonly disableSaveButton?: boolean,
    readonly mandatory?: boolean,
}

const previewMax = {
    height: 417,
    width: 960,
};

/**
 * Represents an UI component that displays a dialog with 1 list, allowing the selection of multiple items.
 */
export const SelectionDialog: React.FC<Props> = (props) => {
    const {
        onCancelled,
        onConfirmed,
        listItems,
        title,
        disableSaveButton = false,
        mandatory = false,
    } = props;

    const [list, setList] = useState<MultiSelectItem[]>(listItems ?? []);
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    //runtime filtered lists
    const choosingListContents = sortAlphabetically(searchValue.length > 0 ? filterList(list, searchValue) : list);
    const choosingListCount = choosingListContents.length;

    /**
     * Update List if a checkbox is checked or unchecked
     */
    const updateItemValue = (item: MultiSelectItem, value: boolean) => {
        setList(
            list.map(existing => existing.id === item.id ? { ...existing, value } : existing),
        );
    };

    const onSubmit = () => {
        setIsSubmitting(true);
        onConfirmed(list.filter(item => item.value));
    }

    return (
        <LsModal
            id='modal-search-list-dialog'
            title={title}
            width={1040}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: onCancelled,
                rightButtonText: 'Opslaan',
                onRightButtonClick: onSubmit,
                rightButtonDisabled: (mandatory && list.length === 0) || disableSaveButton || isSubmitting
            }}
        >
            <SearchField
                value={searchValue}
                id="SearchValueChoosingList"
                label="Kies uit"
                placeholder="Zoek..."
                totalResults={choosingListCount}
                onChange={setSearchValue}
            />
            <MultiSelectList
                withBorder={true}
                listItems={choosingListContents}
                previewMax={previewMax}
                onChange={(item, value) => updateItemValue(item, value)}
            />
        </LsModal>
    );
};

const filterList = (list: MultiSelectItem[], filterValue: string): MultiSelectItem[] => {
    return list.filter((item) => item.label?.toLowerCase().includes(filterValue?.toLowerCase()));
};

const sortAlphabetically = (list: MultiSelectItem[]) => {
    return [...list].sort((a, b) => (a.label > b.label) ? 1 : -1);
};
