import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { DxPivotArea, DxPivotGridDataType, ILsColumnProps, PivotGridFieldType, } from '@liasincontrol/ui-devextreme';
import { MultiSelectElement } from '@liasincontrol/ui-elements';
import { DataSourceControlsUtils } from '../../../../../../../../helpers';

type Props = {
    id?: string,
    label?: string,
    disabled?: boolean,
    key?: string,
    useDragHandle?: true,
    value: Record<string, PivotGridFieldType[]>,
    schemaFileId?: string,
    schemaFilter?: (value: ILsColumnProps, index: number, array: ILsColumnProps[]) => boolean,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onValueChange?: (data: string) => void,
}

/**
 * Represents a UI component that renders an editor.
 */
const PivotTableFieldsEditor: React.FC<Props> = (props) => {
    const [optionItems, setOptionItems] = useState<PivotGridFieldType[]>([]);
    const { schemaFileId, schemaFilter, onLoadAttachment } = props;

    useEffect(() => {
        if (schemaFileId) {
            onLoadAttachment(schemaFileId).then((blob) => blob.text())
                .then((jsonText) => {
                    const schemaFields = DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(jsonText), undefined, undefined, true);
                    const options = schemaFields.filter(schemaFilter ? schemaFilter : () => true)
                        .map((field) => ({
                            dataField: field.dataField,
                            dataType: field.dataType as DxPivotGridDataType
                        }));
                    setOptionItems(options);
                });
        } else {
            setOptionItems([]);
        }
    }, [onLoadAttachment, schemaFilter, schemaFileId]);

    const onChanged = (selectedItems: IDataItemProps<string | number>[], area: DxPivotArea): void => {
        props.value[area] = selectedItems?.map(selected => {
            const areaFields = optionItems.find(oi => oi.dataField === selected.value);
            return {
                ...areaFields,
                area
            };
        });
        props.onValueChange(JSON.stringify(_.flatMap(props.value)));
    }

    return (<>
        {availableAreas.map((area: AreaType) => {
            const areaName = area.name;
            const areaLabel = area.displayName;
            const tagLimit = area.limit;
            return <MultiSelectElement
                id={`${props.id}-${areaName}`}
                key={`${props.id}-${areaName}`}
                label={areaLabel}
                editorSettings={{
                    disabled: props.disabled,
                    validationErrors: undefined,
                    restrictions: undefined,
                    onChange: (selectedItems) => onChanged(selectedItems, areaName),
                }}
                searchable={false}
                clearable={true}
                maxTagsCount={tagLimit}
                optionItems={schemaFileId && optionItems ? optionItems.map(oi => ({ 'label': oi.dataField, 'value': oi.dataField })) : []}
                value={props.value[areaName] ? props.value[areaName].flatMap(v => ({ 'label': v.dataField, 'value': v.dataField })) : []}
            />;
        })}
    </>
    );
}

export { PivotTableFieldsEditor };

export type AreaType = { name: DxPivotArea, displayName: string, limit: number, allowSorting: boolean, allowDividing?: boolean };
export const availableAreas: AreaType[] = [
    { name: 'row', displayName: 'Rijen', limit: 3, allowSorting: true },
    { name: 'column', displayName: 'Kolommen', limit: 3, allowSorting: true },
    { name: 'data', displayName: 'Celwaarde', limit: 1, allowSorting: false, allowDividing: true },
];
