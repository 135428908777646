import React, { useEffect, useState, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { FieldsHelper, DefinitionsHelper, AnyFormData, TextValidator, ValidatorsDictionary, BasicValidator, AttachmentValidator, ApiErrorReportingHelper, FormHelper, DateValidator, ValueType, ValidationErrorData, DateUtils, AttachmentsHelper } from '@liasincontrol/core-service';
import { Bar, Button, ErrorOverlay, PageTitle, Heading1 } from '@liasincontrol/ui-basics';
import { State, ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, SiteDesignsActionCreator, WorkflowTemplateActionCreator, AttachmentsActionCreator, AjaxRequestStatus, MeasureMomentsActionCreator } from '@liasincontrol/redux-service';
import { PublicationSettings } from './Settings';
import { SendOpenTasksReminder } from './SendOpenTasksReminder';
import { PublicationContext } from '../../../../helpers/PublicationContext';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the information page of a publication.
 */
const Index: React.FC<Props> = (props) => {
    const { pathname } = useLocation();
    const isAdminPath = isAdminPathRegex.test(pathname);
    const { settings: publicationSettings, setSettings: setPublicationSettings } = useContext(PublicationContext);
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [sendEmailInfo, setSendEmailInfo] = useState<{ showDialog: boolean, actionInProgress: boolean }>({ showDialog: false, actionInProgress: false });
    const [activeRichtextEditor, setActiveRichtextEditor] = useState<string>();
    const [form, setForm] = useState<AnyFormData>({
        values: {},
        touched: {},
        validationErrors: {},
        isValid: true,
    });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    const fieldDefinitions = props.elementDefinition?.item?.fields?.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    ) as Record<string, Domain.Shared.FieldDefinition>;

    const validators = getValidators(fieldDefinitions);

    useEffect(() => {
        if (!props.elementDefinition?.item || !publicationSettings) {
            return;
        }
        setPublicationElement(undefined);

        DataAccess.Publications.getPublication(publicationSettings.publication.elementId)
            .then((response) => {
                setError(undefined);
                setPublicationSettings(response.data);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [lastRefresh]);

    useEffect(() => {
        if (props.elementDefinition && props.elementDefinition.item && publicationSettings) {
            const settings = new Domain.Publisher.PublicationElement();
            FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, props.elementDefinition.item.fields, publicationSettings.publication.fields);
            setPublicationElement(settings);
            if (settings.isClosed) {
                setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
            }
        }
    }, [publicationSettings, props.elementDefinition]);

    useEffect(() => {
        if (!publicationElement) {
            return;
        }
        const newForm = initFormData(publicationElement, form);
        setForm(newForm);
    }, [publicationElement]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.siteDesigns) {
        props.fetchSiteDesigns();
        return null;
    }

    if (!props.elementDefinition || props.elementDefinition.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    const storeFormValue = (value: string, systemId: keyof typeof validators, resetExternalErrorFieldSystemIds: string[] = []): void => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<AnyFormData>(prevForm, value, validators, systemId, resetExternalErrorFieldSystemIds));
    };

    const setExternalFieldError = (error: string, fieldSystemId: keyof typeof validators, fieldValue?: string): void => {
        setForm((prevForm) => FormHelper.setExternalFieldError(error, fieldSystemId, validators, prevForm, fieldValue));
    };

    /**
     * Represents an event handler that triggers the loading process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const loadAttachment = async (attachmentId: string): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment, AttachmentsHelper.getAttachmentNamesDictionary(publicationSettings?.publication?.attachments));
    };

    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<string> => {
        return AttachmentsHelper.uploadAttachment(file, abortSignal, props.setAttachment);
    };

    const getSettingsElement = (isEditing: boolean): JSX.Element => {
        if (!publicationSettings || _.isEmpty(form.values)) {
            return null;
        }

        return (<>
            <PublicationSettings
                activeRichtextEditor={activeRichtextEditor}
                elementDefinition={props.elementDefinition.item}
                measureMoments={props.measureMoments?.items}
                workflowTemplates={props.workflowTemplates?.items}
                siteDesigns={props.siteDesigns.items}
                isEditing={isEditing}
                form={form}
                validators={validators}
                onLoadAttachment={loadAttachment}
                onUploadAttachment={uploadAttachment}
                setActiveRichtextEditor={setActiveRichtextEditor}
                onChange={storeFormValue}
                onError={setExternalFieldError}
            />
        </>);
    };


    //#region event handlers...
    /**
     * Represents an event handler that triggers the saving process of the publication changes.
     */
    const saveChanges = (): void => {
        // Filter out only modified data to update.
        const modifiedContent = _.mapKeys(_.pick(form.values, Object.keys(form.touched)), (value, key) => fieldDefinitions[key].id) as Dictionary<string>;
        const attachments = _.pickBy(props.attachments, (value, key) => Object.keys(form.values).some((patchKey) => form.values[patchKey] === key));

        if (modifiedContent) {
            const changedAttachments: Domain.Shared.Attachment[] = Object.keys(modifiedContent).map((fieldId): Domain.Shared.Attachment => {
                const attachment = attachments[modifiedContent[fieldId]];
                if (attachment) {
                    return AttachmentsHelper.mapFileToAttachment(attachment, modifiedContent[fieldId], true);
                }
                return null;
            }).filter(item => item);

            const updatedPublicationSettings = {
                name: form.values[SystemFieldDefinitions.Pub.Name] as string,
                title: form.values[SystemFieldDefinitions.Pub.Title] as string,
                primaryColor: form.values[SystemFieldDefinitions.Pub.PrimaryColor] as string,
                primaryContrastColor: form.values[SystemFieldDefinitions.Pub.PrimaryContrastColor] as string,
                secondaryColor: form.values[SystemFieldDefinitions.Pub.SecondaryColor] as string,
                secondaryContrastColor: form.values[SystemFieldDefinitions.Pub.SecondaryContrastColor] as string,
                h1TextColor: form.values[SystemFieldDefinitions.Pub.H1TextColor] as string,
                h1TextSize: form.values[SystemFieldDefinitions.Pub.H1TextSize] as string,
                h2TextColor: form.values[SystemFieldDefinitions.Pub.H2TextColor] as string,
                h2TextSize: form.values[SystemFieldDefinitions.Pub.H2TextSize] as string,
                h3TextColor: form.values[SystemFieldDefinitions.Pub.H3TextColor] as string,
                h3TextSize: form.values[SystemFieldDefinitions.Pub.H3TextSize] as string,
                textColor: form.values[SystemFieldDefinitions.Pub.TextColor] as string,
                textSize: form.values[SystemFieldDefinitions.Pub.TextSize] as string,
                logo: form.values[SystemFieldDefinitions.Pub.Logo] ? form.values[SystemFieldDefinitions.Pub.Logo] as string : null,
                favicon: form.values[SystemFieldDefinitions.Pub.Favicon] ? form.values[SystemFieldDefinitions.Pub.Favicon] as string : null,
                siteDesignId: form.values[SystemFieldDefinitions.Pub.SiteDesignId] as string,
                measureMomentId: form.values[SystemFieldDefinitions.Pub.PublicationMeasureMomentId] as string,
                endDate: form.values[SystemFieldDefinitions.Pub.EndDate] as Date,
                reminderDate: form.values[SystemFieldDefinitions.Pub.ReminderDate] as Date,
                accessibilityStatement: form.values[SystemFieldDefinitions.Pub.AccessibilityStatement] as string,
                privacyStatement: form.values[SystemFieldDefinitions.Pub.PrivacyStatement] as string,
                portalUrl: form.values[SystemFieldDefinitions.Pub.PortalUrl] as string,
                googleAnalyticsCode: form.values[SystemFieldDefinitions.Pub.GoogleAnalyticsCode] as string,
                colorPalette: form.values[SystemFieldDefinitions.Pub.ColorPalette] as string,
                menuLevels: form.values[SystemFieldDefinitions.Pub.MenuLevels] as number,
                customColorPalette: JSON.stringify(form.values[SystemFieldDefinitions.Pub.CustomColorPaletteColors]),
                organizationName: form.values[SystemFieldDefinitions.Pub.OrganizationName] as string,
                coverImage: form.values[SystemFieldDefinitions.Pub.CoverImage] ? form.values[SystemFieldDefinitions.Pub.CoverImage] as string : null,
            };

            DataAccess.Publications.updatePublicationSettings(publicationSettings.publication.elementId, updatedPublicationSettings, changedAttachments)
                .then(() => {
                    setIsEditing(false);
                    setLastRefresh(Date.now());
                }).catch((err) => {
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate)) {
                        setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate] });
                    } else {
                        setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
                    }

                    setIsEditing(false);
                });
        }
    };

    /**
     * Represents an event handler that discards the publication changes.
     */
    const cancelChanges = (): void => {
        setForm(initFormData(publicationElement));
        setIsEditing(false);
    };

    /**
     * Represents an event handler that triggers the sending an email reminder for all users with opened tasks
     * 
     * @param to Defines the email address of the recipient.
     * @param subject Defines the subject of the reminder email.
     * @param body Defines the body of the reminder email.
     */
    const sendOpenTasksReminder = (subject: string, body: string): void => {
        setSendEmailInfo(prev => ({ ...prev, actionInProgress: true }));

        DataAccess.Publications.sendOpenTasksReminder(publicationSettings.publication.elementId, subject, body)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err);
                setError(errorInfo);
            }).finally(() => {
                setSendEmailInfo({ showDialog: false, actionInProgress: false });
            });
    };

    return (
        <>
            {publicationSettings && !publicationElement?.isClosed && <Bar look='toolbar'>
                <Bar start>
                    {!error && (
                        isAdminPath ? (
                            <>
                                <Button
                                    id={`btn-edit-${publicationSettings.publication.elementId}`}
                                    btnbase="primarybuttons" btntype="medium_transparent"
                                    disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Update)}
                                    onClick={() => setIsEditing(true)}>
                                    Bewerken
                                </Button>
                                <Button
                                    id={`btn-send-mail-${publicationSettings.publication.elementId}`}
                                    btnbase='primarybuttons'
                                    btntype='medium_transparent'
                                    disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_SendPublicationEmail, ActionType.Create)}
                                    onClick={() => setSendEmailInfo({ showDialog: true, actionInProgress: false })}>
                                    Herinnering Versturen
                                </Button>
                            </>
                        ) : (
                            <Button
                                id={`btn-refresh-${publicationSettings.publication.elementId}`}
                                btnbase='textbuttons'
                                btntype='medium_icon'
                                icon={<RefreshOutlinedIcon />}
                                onClick={() => setLastRefresh(Date.now())}>
                                Verversen
                            </Button>
                        )
                    )}
                </Bar>
            </Bar>
            }
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                {isEditing ? (
                    <LsModal
                        id={`edit-publication-${publicationSettings?.publication.elementId}`}
                        className='ls-popup-secondary'
                        toolbar={{
                            enabled: isEditing,
                            detached: true,
                            rightButtonDisabled: Object.keys(form.touched).length === 0 || !form.isValid,
                            rightButtonText: 'Opslaan',
                            onRightButtonClick: saveChanges,
                            leftButtonText: 'Sluiten',
                            onLeftButtonClick: cancelChanges,
                        }}
                    >
                        <PageTitle className='mt-250 ml-250'>
                            <Heading1>Wijzig algemene instellingen</Heading1>
                        </PageTitle>
                        {getSettingsElement(isEditing)}
                    </LsModal>
                ) : (
                    getSettingsElement(isEditing)
                )}
                {sendEmailInfo.showDialog && (
                    <SendOpenTasksReminder
                        saveButtonDisabled={sendEmailInfo.actionInProgress}
                        onCloseDialog={() => setSendEmailInfo({ showDialog: false, actionInProgress: false })}
                        onSave={sendOpenTasksReminder}
                    />
                )}
            </ErrorOverlay>
        </>
    );
};

const analyticsCodeValidationRegex = new RegExp("^G-[a-zA-Z0-9-]+$");
const isAdminPathRegex = new RegExp("(/publisher/admin(istration)?/)");

/**
 * Initialises the validators for the form (only editable values are listed).
 */
const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
        [SystemFieldDefinitions.Pub.Title]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Title].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Title].stringType
        }),
        [SystemFieldDefinitions.Pub.ReminderDate]: new DateValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.ReminderDate].required,
            dateMinValue: fieldDefinitions[SystemFieldDefinitions.Pub.ReminderDate].dateMinValue,
            dateMaxValue: fieldDefinitions[SystemFieldDefinitions.Pub.ReminderDate].dateMaxValue
        }, (value: Date, formFields: Record<string, ValueType>): ValidationErrorData[] => {
            if (
                value &&
                formFields[SystemFieldDefinitions.Pub.EndDate] &&
                value?.getTime() > (formFields[SystemFieldDefinitions.Pub.EndDate] as Date)?.getTime()
            ) {
                return [{ error: 'De herinneringsdatum mag niet na de einddatum zijn.' }];
            }
            return [];
        }
        ),
        [SystemFieldDefinitions.Pub.EndDate]: new DateValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.EndDate].required,
            dateMinValue: fieldDefinitions[SystemFieldDefinitions.Pub.EndDate].dateMinValue,
            dateMaxValue: fieldDefinitions[SystemFieldDefinitions.Pub.EndDate].dateMaxValue
        }, (value: Date, formFields: Record<string, ValueType>): ValidationErrorData[] => {
            if (
                value &&
                formFields[SystemFieldDefinitions.Pub.ReminderDate] &&
                value?.getTime() < (formFields[SystemFieldDefinitions.Pub.ReminderDate] as Date)?.getTime()
            ) {
                return [{ error: 'De einddatum mag niet voor de herinneringsdatum zijn.' }];
            }
            return [];
        }
        ),
        [SystemFieldDefinitions.Pub.TextSize]: new BasicValidator<number>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.TextSize].required }),
        [SystemFieldDefinitions.Pub.H1TextSize]: new BasicValidator<number>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.H1TextSize].required }),
        [SystemFieldDefinitions.Pub.H2TextSize]: new BasicValidator<number>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.H2TextSize].required }),
        [SystemFieldDefinitions.Pub.H3TextSize]: new BasicValidator<number>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.H3TextSize].required }),
        [SystemFieldDefinitions.Pub.MenuLevels]: new BasicValidator<number>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.MenuLevels].required }),
        [SystemFieldDefinitions.Pub.SiteDesignId]: new BasicValidator<string>({ required: fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId].required }),
        [SystemFieldDefinitions.Pub.Logo]: new AttachmentValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Logo].required,
            attachmentAllowedFileTypes: fieldDefinitions[SystemFieldDefinitions.Pub.Logo].attachmentAllowedFileTypes,
            attachmentMaxFileSize: fieldDefinitions[SystemFieldDefinitions.Pub.Logo].attachmentMaxFileSize
        }),
        [SystemFieldDefinitions.Pub.Favicon]: new AttachmentValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Favicon].required,
            attachmentAllowedFileTypes: fieldDefinitions[SystemFieldDefinitions.Pub.Favicon].attachmentAllowedFileTypes,
            attachmentMaxFileSize: fieldDefinitions[SystemFieldDefinitions.Pub.Favicon].attachmentMaxFileSize
        }),
        [SystemFieldDefinitions.Pub.AccessibilityStatement]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.AccessibilityStatement].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.AccessibilityStatement].stringMaxLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.AccessibilityStatement].stringType
        }),
        [SystemFieldDefinitions.Pub.PrivacyStatement]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.PrivacyStatement].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.PrivacyStatement].stringMaxLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.PrivacyStatement].stringType
        }),
        [SystemFieldDefinitions.Pub.PortalUrl]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.PortalUrl].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.PortalUrl].stringMaxLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.PortalUrl].stringType
        }),
        [SystemFieldDefinitions.Pub.GoogleAnalyticsCode]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.GoogleAnalyticsCode].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.GoogleAnalyticsCode].stringMaxLength ?? 15,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.GoogleAnalyticsCode].stringType
        }, (value: string, formFields: Record<string, ValueType>): ValidationErrorData[] => {
            if (
                value &&
                formFields[SystemFieldDefinitions.Pub.GoogleAnalyticsCode] &&
                !analyticsCodeValidationRegex.test(value)
            ) {
                return [{ error: 'Ongeldig Google Analytics Measurement ID.' }];
            }
            return [];
        }),
        [SystemFieldDefinitions.Pub.OrganizationName]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.OrganizationName].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.OrganizationName].stringMaxLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.OrganizationName].stringType
        }),
        [SystemFieldDefinitions.Pub.CoverImage]: new AttachmentValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.CoverImage].required,
            attachmentAllowedFileTypes: fieldDefinitions[SystemFieldDefinitions.Pub.CoverImage].attachmentAllowedFileTypes,
            attachmentMaxFileSize: fieldDefinitions[SystemFieldDefinitions.Pub.CoverImage].attachmentMaxFileSize
        })
    };
};

/**
 * Initialises the form data with publication element data.
 */
const initFormData = (initial: Domain.Publisher.PublicationElement, form?: AnyFormData): AnyFormData => {
    const formClone = { values: {}, touched: {}, validationErrors: {}, isValid: true } as AnyFormData;
    const initialClone = { ...initial };
    //TODO: initial is unmutable; what are we actually doing here
    Object.keys(initial).forEach((key) => {
        const fieldSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, initial, key);
        if (fieldSystemId === SystemFieldDefinitions.Pub.ReminderDate && initial[key]) {
            initialClone[key] = DateUtils.fromInvariantString(initial[key]);
        } else if (fieldSystemId === SystemFieldDefinitions.Pub.EndDate && initial[key]) {
            initialClone[key] = DateUtils.fromInvariantString(initial[key]);
        }

        if (!!form?.values[fieldSystemId]) {
            formClone.values[fieldSystemId] = form.values[fieldSystemId];
        } else {
            if (fieldSystemId === SystemFieldDefinitions.Pub.CustomColorPaletteColors) {
                formClone.values[fieldSystemId] = JSON.parse(initial[key] || 'null');
            } else {
                formClone.values[fieldSystemId] = initialClone[key];
            }
        }
    });

    if (!!form && Object.keys(form.touched).length > 0) {
        formClone.touched = { ...form.touched };
    }

    return formClone;
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    const hasElementDefinitions = !_.isEmpty(state.elementdefinitions?.[ActionSource.Publication]);

    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinition: {
            item: hasElementDefinitions ? DefinitionsHelper.findElementDefinition(state.elementdefinitions[ActionSource.Publication].items, SystemElementDefinitions.Pub.Publication) : undefined,
            status: hasElementDefinitions ? state.elementdefinitions[ActionSource.Publication].status : AjaxRequestStatus.NotSet,
        },
        measureMoments: state.measuremoments,
        siteDesigns: state.publisher.siteDesigns[ActionSource.Publication],
        attachments: state.attachments,
        workflowTemplates: state.workflowtemplates,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchSiteDesigns: () => {
            dispatch(SiteDesignsActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };