import React, { useState } from 'react';
import { JsonUtils } from '@liasincontrol/core-service';
import { Button, HelpText, Label, ProgressLoading } from '@liasincontrol/ui-basics';
import { MultiOptionsListsProps } from './index.props';
import { SearchListDialog, SearchListItem } from '../search-list-dialog';

/**
 * Represents a UI component that renders a viewer with a searchable list as the readonly-variant of the MultiOptionListsEditor.
 */
export const MultiOptionsListsViewer: React.FC<MultiOptionsListsProps> = (props) => {
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);
    const buttonName = 'Bekijken';

    if (!props.allItems) {
        return <ProgressLoading variant="small" />;
    }

    return (
        <>
            <Label text={props.label} />
            {props.helpText && props.helpText.text && (
                <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>
            )}
            <div style={{ marginBottom: '1.25rem' }}>
                <Button
                    btnbase="textbuttons"
                    btntype="medium_icon"
                    aria-label={buttonName}
                    onClick={() => setDialogOpened(true)}
                >
                    {buttonName}
                </Button>
            </div>
            {dialogOpened &&
                <SearchListDialog
                    title={props.title}
                    selectedItems={JsonUtils.toJson<SearchListItem[]>(props.value, [])}
                    onCancelled={() => setDialogOpened(false)}
                    label={props.labelSearchList}
                    placeholderEmptyList={props.placeholderEmptySearchList}
                />
            }
        </>
    );
};
