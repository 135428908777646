import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { DialogContainer } from './index.styled';
import { ValidationUtils } from '@liasincontrol/core-service';

import { TemplateGridSelectEditor } from '../../../../../../../_shared/Editors/TemplateGridSelectEditor';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    readonly id: string,
    readonly publicationId: string,
    readonly currentTemplateId?: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    onChange: (templateId: string, templateName: string) => void,
    onCancel: () => void,

};

/**
 * Represents a UI component that renders a dialog to choose a page template from the available templates inside of selected publication.
 */
export const PageTemplateDialog: React.FC<Props> = (props) => {
    const [templateId, setTemplateId] = useState<string>(!ValidationUtils.isEmptyGuid(props.currentTemplateId) ? props.currentTemplateId : '');

    return (
        <LsModal
            id={`modal-set-page-template-${props.id}`}
            title='Kies een sjabloon'
            width={1300}
            toolbar={{
                enabled: true,
                leftButtonText: "Sluiten",
                onLeftButtonClick: () => { setTemplateId(''); props.onCancel(); },
            }}
        >
            <DialogContainer>
                <TemplateGridSelectEditor
                    id="template-select-58ea5c13-6c4b-4738-8fe7-0918cc18ca4d"
                    publicationId={props.publicationId}
                    pageDesigns={props.pageDesigns}
                    elementDefinitions={props.elementDefinitions}
                    rootId={props.id}
                    selectedTemplateId={templateId}
                    onCancel={props.onCancel}
                    onChange={(value: Domain.Publisher.TemplateElement) => {
                        if (value && (props.currentTemplateId !== value.id || !props.currentTemplateId)) {
                            props.onChange(value.id, value.name);
                            setTemplateId('');
                        } else {
                            props.onCancel();
                        }
                    }}
                />
            </DialogContainer>
        </LsModal>
    );
};
