import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { FieldsHelper, ApiErrorReportingHelper, DefinitionsHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { guidToODataFieldId, oDataResponse, Publisher as DataAccess } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { State, ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, AjaxRequestStatus, MeasureMomentsActionCreator, HierarchyDefinitionsActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, Section, ErrorOverlay, Text } from '@liasincontrol/ui-basics';
import { ContextMenu, FieldNameOf, GridColumn, LsGrid, createSource, LsModal } from '@liasincontrol/ui-devextreme';
import Add from '@mui/icons-material/Add';
import { TemplateAdd } from './TemplateAdd';
import { TemplateEditor } from './TemplateEditor';
import { HierarchyUtils } from '../../../../../helpers';
import { usePublicationSettings } from '../../../../../helpers/PublicationContext';
import _ from 'lodash';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the list of publication templates page.
 */
const Index: React.FC<Props> = (props) => {
    const [selectedTemplate, setSelectedTemplate] = useState<Domain.Publisher.TemplateElement>();
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const [deleteTemplateData, setDeleteTemplateData] = useState<{ template: Domain.Publisher.TemplateElement, allowDelete: boolean }>(null);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [isRemoving, setIsRemoving] = useState<boolean>(false);
    const availableColumns = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Templates, ActionType.Delete)
            ? (template: Domain.Publisher.TemplateElement) => setDeleteTemplateData({ template, allowDelete: true })
            : null
    );
    const [pageDesigns, setPageDesigns] = useState<Domain.Publisher.PageDesign[]>([]);
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const publication = usePublicationSettings();

    const publicationElementDefinition = useMemo(() => Object.values(props.elementDefinitions[ActionSource.Publication]?.items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.Publication), [props.elementDefinitions[ActionSource.Publication]?.items]);
    const templateElementDefinition = useMemo(() => Object.values(props.elementDefinitions[ActionSource.Publication]?.items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.PageTemplate), [props.elementDefinitions[ActionSource.Publication]]);

    const customDataSource = useMemo(() => {
        if (!publication || (!props.elementDefinitions && !props.elementDefinitions[ActionSource.Publication] && _.isEmpty(props.elementDefinitions[ActionSource.Publication].items) && pageDesigns.length === 0)) {
            return;
        }

        if (!publication?.publication?.elementId || !templateElementDefinition || !pageDesigns) {
            return;
        }

        const selectedColumns = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Publisher.TemplateElement(), fieldsToDisplay, templateElementDefinition);

        const thenCallBack = (data: oDataResponse<Domain.Publisher.AbstractElement[]>) => {
            const templates = data.value.map((rawElement) => {
                const templateElement = new Domain.Publisher.TemplateElement();
                // fill the settings with the data from the list of element fields based on field definitions:
                FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(templateElement, templateElementDefinition.fields, rawElement);
                if (templateElement.pageDesignId) {
                    const pageDesignFieldDefinition = pageDesigns.find(page => page.id === templateElement.pageDesignId);
                    templateElement.pageDesignName = pageDesignFieldDefinition ? pageDesignFieldDefinition.name : '';
                }
                templateElement.id = rawElement.id;

                return templateElement;
            });

            return templates;
        };

        const mapToField = (columnName: keyof Domain.Publisher.TemplateElement & string) => {
            const fieldId = DefinitionsHelper.fieldNameToFieldId(new Domain.Publisher.TemplateElement(), columnName, templateElementDefinition);
            return guidToODataFieldId(fieldId || '');
        }

        return createSource<Domain.Publisher.AbstractElement>({
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns,
            dataSourcePromise: (query) => DataAccess.Publications.getTemplates(publication.publication.elementId, query),
            thenCallBack,
            columnMapper: mapToField,
        });
    }, [props.elementDefinitions, lastRefresh, publicationElement, publication?.publication?.elementId, templateElementDefinition, pageDesigns]);

    useEffect(() => {
        if (publicationElementDefinition && publication) {
            const settings = new Domain.Publisher.PublicationElement();
            FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, publicationElementDefinition.fields, publication.publication.fields);
            setPublicationElement(settings);
        }
    }, [publication, publicationElementDefinition]);

    useEffect(() => {
        if (publicationElement) {
            if (publicationElement.isClosed) {
                setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
                return;
            }
            DataAccess.SiteDesigns.getPageDesigns(publicationElement.siteDesignId).then((response) => {
                setPageDesigns(response.data);
            });
        }
    }, [publicationElement]);

    useEffect(() => {
        if (!deleteTemplateData) {
            setLastRefresh(Date.now());
        }
    }, [deleteTemplateData]);

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions[ActionSource.Publication] || props.elementDefinitions[ActionSource.Publication].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Publication, props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if (!props.elementDefinitions[ActionSource.Performance] || props.elementDefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Performance, props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    if (!props.elementDefinitions[ActionSource.Studio] || props.elementDefinitions[ActionSource.Studio].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Studio, props.modules[SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.hierarchyDefinitions || !props.hierarchyDefinitions[ActionSource.Performance] || props.hierarchyDefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Performance, props.modules[SystemModuleDefinitions.Performance], true);
        return null;
    }

    if (!props.hierarchyDefinitions || !props.hierarchyDefinitions[ActionSource.Studio] || props.hierarchyDefinitions[ActionSource.Studio].status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[SystemModuleDefinitions.Studio], true);
        return null;
    }

    const deleteTemplate = (): void => {
        if (error) setError(undefined);
        setIsRemoving(true);
        DataAccess.Templates.deletePageTemplate(publication.publication.elementId, deleteTemplateData.template.id)
            .then(() => {
                setDeleteTemplateData(null);
                setIsRemoving(false);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err);

                if (errorInfo?.details?.type && errorInfo.details.type.includes(Domain.Shared.ApiKnownErrorTypes.PageTemplateIsUsed)) {
                    setDeleteTemplateData((deleteTemplateData) => ({ template: deleteTemplateData.template, allowDelete: false }));
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
                } else {
                    setError(errorInfo);
                }
                setIsRemoving(false);
            });
    };

    const getTemplate = (templateId: string): void => {
        if (error) setError(undefined);
        DataAccess.Publications.getTemplateDetails(publication.publication.elementId, templateId).then((response) => {
            const templateElement = new Domain.Publisher.TemplateElement();
            // fill the settings with the data from the list of element fields based on field definitions:
            FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(templateElement, templateElementDefinition.fields, response.data.fields);
            templateElement.id = response.data.elementId;
            templateElement.complexFields = response.data.complexFields;
            setSelectedTemplate(templateElement);
        });

    };

    const mappedHierarchyDefinition =
        Object.keys(props.hierarchyDefinitions).reduce((collection, key) => {
            return {
                ...collection, [key]:
                    Object.values(props.hierarchyDefinitions[key].items)
                        ?.map((item: Domain.Shared.HierarchyDefinition) =>
                            HierarchyUtils.mapHierarchyDefinitionItems(item, props.elementDefinitions[HierarchyUtils.getSourceByHierarchySystemId(item.systemId)].items))
            };
        }, {});

    const mappedElementDefinition = Object.keys(props.elementDefinitions).reduce((collection, key) => {
        return {
            ...collection,
            [key]: props.elementDefinitions[key].items
        };
    }, {});

    return (
        <>
            <Bar look="toolbar">
                <Bar start>
                    {!error && <Button
                        id="btn-create"
                        btnbase="primarybuttons"
                        btntype="medium_transparent"
                        icon={<Add />}
                        disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Templates, ActionType.Create)}
                        onClick={() => setShowAddDialog(true)}>
                        Nieuw
                    </Button>}
                </Bar>
            </Bar>

            <Section look="white">
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <LsGrid
                        dataSource={customDataSource}
                        columns={availableColumns}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        searching={true}
                        enableColumnChooser={true}
                        showRowLines={true}
                        onClickRow={(item: Domain.Publisher.TemplateElement) => {
                            if (!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Templates, ActionType.Update)) {
                                return;
                            }
                            getTemplate(item.id);
                        }}
                        onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                    />

                </ErrorOverlay>
            </Section>

            {selectedTemplate &&
                <TemplateEditor
                    pageTemplate={selectedTemplate}
                    elementDefinitions={mappedElementDefinition}
                    measureMoments={props.measureMoments.items}
                    onClose={(changed: boolean) => {
                        setSelectedTemplate(undefined);
                        if (changed) setLastRefresh(Date.now());
                    }}
                    hierarchyDefinitions={mappedHierarchyDefinition}
                />
            }

            {showAddDialog &&
                <TemplateAdd
                    publicationElementDefinition={publicationElementDefinition}
                    templateElementDefinition={templateElementDefinition}
                    onSave={(template) => {
                        setLastRefresh(Date.now());
                        setSelectedTemplate(template);
                        setShowAddDialog(false);
                    }}
                    onCancel={() => setShowAddDialog(false)}
                    onError={(err) => {
                        const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err);
                        setError(errorInfo);
                    }}
                />
            }

            {deleteTemplateData &&
                <LsModal
                    id="modal-delete-template"
                    title="Sjabloon verwijderen"
                    toolbar={{
                        enabled: true,
                        leftButtonText: 'Annuleren',
                        onLeftButtonClick: () => setDeleteTemplateData(null),
                        rightButtonText: 'Verwijderen',
                        rightButtonDisabled: isRemoving || !deleteTemplateData.allowDelete,
                        onRightButtonClick: deleteTemplate,
                    }}
                >
                    <Text value={getDeleteTemplateText(deleteTemplateData.allowDelete, deleteTemplateData.template?.name)} />
                </LsModal>
            }
        </>
    );
}

const getDeleteTemplateText = (allowDelete: boolean, templateName: string) => {
    return allowDelete ? `U staat op het punt om "${templateName}" te verwijderen. Deze actie kunt u niet meer ongedaan maken. Weet u dit zeker?` :
        `Het sjabloon "${templateName}​​" is gekoppeld aan een pagina en kan niet worden verwijderd.`;
}

const getColumnConfiguration = (
    onDeleteTemplate: (template: Domain.Publisher.TemplateElement) => void)
    : GridColumn<Domain.Publisher.TemplateElement>[] => {
    return [{
        name: 'name',
        title: 'Naam',
        defaultSortOrder: 'asc',
    },
    {
        name: 'pageDesignName',
        title: 'Pagina ontwerp',
        allowSorting: false
    },
    {
        name: 'id',
        title: '',
        type: 'buttons',
        width: '5%',
        align: 'right',
        hideInColumnChooser: true,
        renderCustom: ({ data }) => onDeleteTemplate &&
            <ContextMenu<Domain.Publisher.TemplateElement>
                keyExpr='id'
                item={data}
                actions={[
                    {
                        action: onDeleteTemplate,
                        displayName: 'Verwijderen',
                        ariaLabel: "Verwijder sjablon",
                        actionName: `delete-${data.id}`
                    }
                ]}
            />
    }];
};

const fieldsToDisplay: FieldNameOf<Domain.Publisher.TemplateElement>[] = ['name', 'pageDesignId'];

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        hierarchyDefinitions: state.hierarchydefinitions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (actionSource: ActionSource, module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id, includeDeleted: actionSource === ActionSource.Studio } }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
    };
};


const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
