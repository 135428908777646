import styled from 'styled-components';
import { SelectBox } from 'devextreme-react/select-box';
import { palette } from '@liasincontrol/ui-basics';

const StyledSelectBox = styled(SelectBox)`
    background-color: ${palette.white} !important;
    padding-top: 3px !important;

    .dx-dropdowneditor-field-template-wrapper {
        padding-left: 8px !important;
    }

    &.dx-state-disabled.dx-widget {
        border: 2px ${palette.grey5} solid !important;
        background: ${palette.grey4} !important;
        color: ${palette.grey3b} !important;
    }
`;

const styledComponents = { StyledSelectBox };

export default styledComponents;
