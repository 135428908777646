import React from 'react';
import { Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    selectedMeasureMoment: Domain.Shared.MeasureMoment,
    onConfirm: (measureMomentId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to delete a measure moment.
 */
const MeasureMomentDeleteDialog: React.FC<Props> = (props) => {

    return (
        <LsModal
            id='delete-measure-moment-dialog'
            title='Verwijder Moment'
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Verwijder Moment',
                onRightButtonClick: () => props.onConfirm(props.selectedMeasureMoment.id),
            }}
        >
            <Text danger={true} value='Weet u zeker dat u het moment wilt verwijderen? Het verwijderen van een moment is permanent.' />
        </LsModal>
    );
};

export { MeasureMomentDeleteDialog };