import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Add from '@mui/icons-material/Add';
import { LsGrid, GridColumn, ContextMenu, LsModal } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { Bar, Button, ErrorOverlay, Heading1, PageTitle, Section, Text } from '@liasincontrol/ui-basics';
import { ProfileConfig } from './ProfileConfig';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity,
};

const Index: React.FC<Props> = (props) => {
    const [availablePublishProfiles, setAvailablePublishProfiles] = useState<Domain.Publisher.PublishProfile[]>([]);
    const availableColumns: GridColumn<Domain.Publisher.PublishProfile>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManagePublishProfiles, ActionType.Delete) ?
            (profile: Domain.Publisher.PublishProfile) => setDeleteProfileInfo({ profile: profile, allowDelete: true, isRemoving: false }) : null);
    const [addProfileInfo, setAddProfileInfo] = useState<{ showDialog: boolean, actionInProgress: boolean }>({ showDialog: false, actionInProgress: false });
    const [deleteProfileInfo, setDeleteProfileInfo] = useState<{ profile: Domain.Publisher.PublishProfile, allowDelete: boolean, isRemoving: boolean }>({ profile: null, allowDelete: true, isRemoving: false });
    const [updateProfileInfo, setUpdateProfileInfo] = useState<{ selectedProfile: Domain.Publisher.PublishProfile, actionInProgress: boolean }>({ selectedProfile: null, actionInProgress: false });
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    useEffect(() => {
        DataAccess.SiteMap.getAvailablePublishProfiles()
            .then((response) => {
                setAvailablePublishProfiles(response.data);
            }).catch(() => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, true));
            });
    }, [lastRefresh]);

    const openProfileConfigModal = (): void => {
        setAddProfileInfo({ showDialog: true, actionInProgress: false });
    };

    const publishProfileElementDefinition = useMemo(() => {
        return Object.values(props.elementDefinitions.items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.PublishProfile);
    }, [props.elementDefinitions]);
    const publishProfileFieldDefinitions = useMemo(() => publishProfileElementDefinition?.fields?.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    ) as Record<string, Domain.Shared.FieldDefinition>, [publishProfileElementDefinition]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    const updatePublishProfile = (profileName: string, profileId: string): void => {
        const nameFieldDefinition = publishProfileFieldDefinitions[SystemFieldDefinitions.Pub.Name];
        const fields = {
            [nameFieldDefinition.id]: profileName
        };

        const updateElement = { elementDefinitionId: publishProfileElementDefinition.id, fields: fields } as Domain.Publisher.Element;
        setUpdateProfileInfo((prev) => ({ ...prev, actionInProgress: true }));

        DataAccess.PublishProfiles.updatePublishProfile(profileId, updateElement)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                setError(errorInfo);
            }).finally(() => {
                setUpdateProfileInfo({ selectedProfile: null, actionInProgress: false });
            });
    };

    const createPublishProfile = (profileName: string): void => {
        const nameFieldDefinition = publishProfileFieldDefinitions[SystemFieldDefinitions.Pub.Name];
        const fields = {
            [nameFieldDefinition.id]: profileName
        };
        const createElement = { elementDefinitionId: publishProfileElementDefinition.id, fields: fields } as Domain.Publisher.Element;

        setAddProfileInfo(prev => ({ ...prev, actionInProgress: true }));

        DataAccess.PublishProfiles.createPublishProfile(createElement)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err);
                setError(errorInfo);
            }).finally(() => {
                setAddProfileInfo({ showDialog: false, actionInProgress: false });
            });
    };

    const deletePublishProfile = (): void => {
        setDeleteProfileInfo((prev) => ({ ...prev, isRemoving: true }));

        DataAccess.PublishProfiles.deletePublishProfile(deleteProfileInfo.profile.profileId)
            .then(() => {
                setLastRefresh(Date.now());
                setDeleteProfileInfo({ profile: null, allowDelete: true, isRemoving: false });
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err);
                if (errorInfo?.details?.type && errorInfo.details.type.includes(Domain.Shared.ApiKnownErrorTypes.PublishProfileIsUsed)) {
                    setDeleteProfileInfo((prev) => ({ ...prev, allowDelete: false }));
                } else {
                    setError(errorInfo);
                    setDeleteProfileInfo({ profile: null, allowDelete: true, isRemoving: false });
                }
            });
    };

    return (
        <>
            <PageTitle>
                <Heading1>Publicatie profielen</Heading1>
            </PageTitle>

            <Bar look="toolbar">
                <Bar start>
                    {!error &&
                        <Button
                            id="btn-create"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            icon={<Add />}
                            onClick={openProfileConfigModal}
                            disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManagePublishProfiles, ActionType.Create)}>
                            Nieuw
                        </Button>
                    }
                </Bar>
            </Bar>

            <Section look="white">
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <LsGrid
                        keyExpr="profileId"
                        dataSource={availablePublishProfiles}
                        columns={availableColumns}
                        enableColumnChooser={false}
                        showRowLines={true}
                        onClickRow={(item: Domain.Publisher.PublishProfile) => {
                            if (UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManagePublishProfiles, ActionType.Update)) {
                                setUpdateProfileInfo({ selectedProfile: availablePublishProfiles.find(x => x.profileId === item.profileId), actionInProgress: false })
                            }
                        }}
                    />
                </ErrorOverlay>
            </Section>

            {updateProfileInfo.selectedProfile && (
                <ProfileConfig
                    publishProfileFieldDefinitions={publishProfileFieldDefinitions}
                    saveButtonDisabled={updateProfileInfo.actionInProgress}
                    onCloseDialog={() => setUpdateProfileInfo({ selectedProfile: null, actionInProgress: false })}
                    onSave={updatePublishProfile}
                    profileName={updateProfileInfo.selectedProfile.profileName}
                    profileId={updateProfileInfo.selectedProfile.profileId}
                />
            )}
            {addProfileInfo.showDialog && (
                <ProfileConfig
                    publishProfileFieldDefinitions={publishProfileFieldDefinitions}
                    saveButtonDisabled={addProfileInfo.actionInProgress}
                    onCloseDialog={() => setAddProfileInfo({ showDialog: false, actionInProgress: false })}
                    onSave={createPublishProfile}
                />
            )}
            {deleteProfileInfo.profile &&
                <LsModal
                    id={`modal-delete-profile`}
                    title='Publicatie profiel verwijderen'
                    toolbar={{
                        enabled: true,
                        leftButtonText: 'Annuleren',
                        onLeftButtonClick: () => setDeleteProfileInfo({ profile: null, allowDelete: true, isRemoving: false }),
                        rightButtonText: 'Verwijderen',
                        rightButtonDisabled: !deleteProfileInfo.allowDelete,
                        onRightButtonClick: deletePublishProfile,
                    }}
                >
                    <Text value={getDeleteProfileText(deleteProfileInfo.allowDelete, deleteProfileInfo.profile?.profileName)} />
                </LsModal>
            }
        </>
    );
}

const getDeleteProfileText = (allowDelete: boolean, profileName: string): string => {
    return allowDelete ? `U staat op het punt om "${profileName}" te verwijderen. Deze actie kunt u niet meer ongedaan maken. Weet u dit zeker?` :
        `Het publicatie profiel "${profileName}​​" is in gebruik en kan niet worden verwijderd.`;
};

const getColumnConfiguration = (onDeleteProfile: (profile: Domain.Publisher.PublishProfile) => void): GridColumn<Domain.Publisher.PublishProfile>[] => {

    return [
        {
            name: 'profileName',
            title: 'Publicatie profiel'
        },
        {
            align: 'right',
            name: 'profileId',
            title: '',
            renderCustom: ({ data }) =>
                <ContextMenu<Domain.Publisher.PublishProfile>
                    keyExpr='profileId'
                    item={data}
                    actions={[
                        {
                            ariaLabel: 'Verwijder profiel',
                            actionName: `delete-${data.profileId}`,
                            displayName: 'Verwijderen',
                            action: onDeleteProfile,
                        }
                    ]}
                />
        }
    ];
};

const mapStateToProps = (state: State) => {
    return {
        attachments: state.attachments,
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
        users: state.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };