import React, { useState } from 'react';
import * as _ from 'lodash';
import { Grid as MuiGrid } from '@mui/material';
import { BasicValidator, FormHelper, TextValidator, ValidationErrorData, ValueType, FormMode } from '@liasincontrol/core-service';
import { SelectElement, TextElement, ToggleElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, Text } from '@liasincontrol/ui-basics';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    budgetJournalKind?: Domain.Finance.BudgetJournalKindItem,
    baseYears?: number[],
    workflowTemplates: Domain.Shared.WorkflowTemplateWithStates[],
    formMode: FormMode,
    onSave: (budgetJournalKind: Domain.Finance.BudgetJournalKindItem) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the modal for managing a budget journal kind item.
 */
export const BudgetJournalKindForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<{ formData: Domain.Finance.BudgetJournalKindItem, isTouched: boolean }>(initForm(props.budgetJournalKind));
    const [selectedWorkflow, setSelectedWorkflow] = useState<Domain.Shared.WorkflowTemplateWithStates>();

    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    const workflowOptions = props.workflowTemplates
        ?.filter((workflowTemplate) => workflowTemplate.isActive);

    const onChange = (value: string | number | boolean, fieldName: string) => {
        const data: Domain.Finance.BudgetJournalKindItem = { ...form.formData };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm({ formData: data, isTouched: true });

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        setValidationErrors(temporaryValidationError);
    };

    return (<>
        <LsModal
            id='modal-budget-journal-kind-form'
            title={props.formMode === FormMode.Edit ? 'Journaalsoort bewerken' : 'Nieuwe journaalsoort'}
            toolbar={{
                enabled: true,
                leftButtonText: 'Annuleren',
                onLeftButtonClick: props.onCancel,
                rightButtonText: 'Opslaan',
                onRightButtonClick: () => props.onSave(form.formData),
                rightButtonDisabled: validationErrors.hasErrors || !form.isTouched,
            }}
        >
            <MuiGrid container
                spacing={{ xs: 2 }}
                columns={{ xs: 1 }}
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid item xs={1}>
                    <AutoFocus>
                        <TextElement
                            id='name-field'
                            label='Naam'
                            editorSettings={{
                                disabled: false,
                                validationErrors: validationErrors.errors['name'],
                                restrictions: { required: true, minLength: 2, maxLength: 50 },
                                onChange: (value) => onChange(value, 'name'),
                            }}
                            value={form?.formData?.name}
                        />
                    </AutoFocus>
                </MuiGrid>
                <MuiGrid item xs={1}>
                    <TextElement
                        id='code-field'
                        label='Code'
                        editorSettings={{
                            disabled: false,
                            validationErrors: validationErrors.errors['code'],
                            restrictions: { required: true, minLength: 2, maxLength: 50 },
                            onChange: (value) => onChange(value, 'code'),
                        }}
                        value={form?.formData?.code}
                    />
                </MuiGrid>

                {props.formMode === FormMode.AddNew &&
                    <>
                        <MuiGrid item xs={1}>
                            <SelectElement<number>
                                id='select-base-year'
                                label='Basisjaar'
                                optionItems={props.baseYears || []}
                                value={form?.formData?.baseYear}
                                clearable={false}
                                searchable={false}
                                editorSettings={{
                                    disabled: false,
                                    restrictions: { required: true },
                                    validationErrors: validationErrors.errors['baseYear'],
                                    onChange: (item) => onChange(item, 'baseYear'),
                                }}
                            />
                        </MuiGrid>
                        <MuiGrid item xs={1}>
                            <SelectElement<Domain.Shared.WorkflowTemplateWithStates>
                                id='select-workflow'
                                label='Workflow'
                                displayExpr='name'
                                optionItems={workflowOptions}
                                value={workflowOptions.find(o => o.id === form?.formData?.workflowDefinitionId)}
                                clearable={false}
                                searchable={false}
                                editorSettings={{
                                    disabled: false,
                                    restrictions: { required: true },
                                    validationErrors: validationErrors.errors['workflowDefinitionId'],
                                    onChange: (item) => {
                                        if (props.formMode === FormMode.Edit) setSelectedWorkflow(item);
                                        else onChange(item?.id, 'workflowDefinitionId');
                                    },
                                }}
                            />
                        </MuiGrid>
                    </>
                }

                <MuiGrid item xs={1}>
                    <ToggleElement
                        id='isActive-field'
                        label='Actief'
                        booleanTrueLabel='Ja'
                        booleanFalseLabel='Nee'
                        editorSettings={{
                            disabled: false,
                            validationErrors: undefined,
                            restrictions: {},
                            onChange: (value) => onChange(value, 'isActive'),
                        }}
                        value={form?.formData?.isActive}
                    />
                </MuiGrid>
            </MuiGrid>
        </LsModal>
        {selectedWorkflow
            ? <LsModal
                id='confirm-workflow-change-dialog'
                title='Nieuwe workflow bevestigen'
                toolbar={{
                    enabled: true,
                    leftButtonText: 'Annuleren',
                    onLeftButtonClick: () => setSelectedWorkflow(undefined),
                    rightButtonText: 'BEVESTIGEN',
                    onRightButtonClick: () => {
                        onChange(selectedWorkflow.id, 'workflowDefinitionId');
                        setSelectedWorkflow(undefined);
                    },
                }}
            >
                <Text value='Weet u zeker dat u de workflow wilt veranderen?' />
            </LsModal>
            : null}
    </>
    );
};

const initForm = (form: Domain.Finance.BudgetJournalKindItem) => {
    return {
        formData: form || new Domain.Finance.BudgetJournalKindItem(),
        isTouched: false
    };
};

const validate = (form: Domain.Finance.BudgetJournalKindItem, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
    'baseYear': new BasicValidator({ required: true }),
    'code': new TextValidator({ required: true, requiredText: 'Het veld code is verplicht.', stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
    'workflowDefinitionId': new BasicValidator({ required: true }),
};