import React, { useState } from 'react';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { FormInfo, FormMode, ValueType } from '@liasincontrol/core-service';
import { WorkflowTemplateForm } from '../../WorkflowTemplateForm';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    workflowNames?: string[],
    onSaveChanges: (formValues: Record<string, ValueType>) => void,
    onCancelChanges: () => void,
};

/**
 * Represents a UI component that renders a dialog to add a new workflow template object.
 */
const WorkflowTemplateAdd: React.FC<Props> = (props) => {
    const [formData, setFormData] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });

    return <LsModal
        id='modal-add-new-workflowtemplate'
        title="Nieuwe workflowproces"
        toolbar={{
            enabled: true,
            onLeftButtonClick: props.onCancelChanges,
            onRightButtonClick: () => {
                props.onSaveChanges(formData?.values);
                setFormData(prev => ({ ...prev, isTouched: false }));
            },
            rightButtonDisabled: !formData?.isTouched || !formData?.isValid,
        }}
    >
        <AutoFocus>
            <WorkflowTemplateForm
                workflowNames={props.workflowNames}
                mode={FormMode.AddNew}
                isOrdering={true}
                onFormDataChanged={setFormData}
            />
        </AutoFocus>
    </LsModal>;
};

export { WorkflowTemplateAdd };
