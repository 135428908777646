import React, { useEffect, useState } from 'react';
import { Grid as MuiGrid } from '@mui/material';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import { AnyFormData, FormHelper, TextValidator, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import { LsModal } from '@liasincontrol/ui-devextreme';

type Props = {
    definitionId: string,
    definitionName?: string,
    externalError?: { id: string, message: string },
    onClose: () => void,
    onSave: (entityTypeId: string, definitionName: string) => void,
};

/**
 * Represents an UI component which is a dialog that updates a performance element definition name.
 */
export const UpdateDefinitionNameDialog: React.FC<Props> = (props) => {
    const nameFieldDefinition = Domain.FieldDefinitions.Shared.nameFieldDefinition;
    const validators = getValidators(nameFieldDefinition);
    const [form, setForm] = useState<AnyFormData>(initFormData(nameFieldDefinition, props.definitionName));

    useEffect(() => {
        if (!props.externalError) return;

        setForm((prevForm) => ({
            ...prevForm,
            isValid: false,
            validationErrors: {
                ...prevForm.validationErrors,
                [props.externalError.id]: [{ error: props.externalError.message, isExternal: true }]
            }
        }));
    }, [props.externalError]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators, resetExternalErrorFieldSystemIds: string[] = []) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId, resetExternalErrorFieldSystemIds);
        setForm(newForm);
    };

    return (
        <LsModal
            id='modal-edit-performance-definition-name'
            title='Hiërarchieën overnemen'
            toolbar={{
                enabled: true,
                leftButtonText: 'Sluiten',
                onLeftButtonClick: props.onClose,
                rightButtonText: 'Opslaan',
                onRightButtonClick: () => props.onSave(props.definitionId, form.values[nameFieldDefinition.id] as string),
                rightButtonDisabled: !form.isValid || Object.keys(form.touched).length === 0,
            }}
        >
            <MuiGrid container
                justifyContent="flex-start"
                alignItems="flex-end">
                <MuiGrid key='edit-performance-definition-name-field' item xs={12}>
                    <AutoFocus>
                        <TextElement
                            id={nameFieldDefinition.id}
                            label={nameFieldDefinition.name}
                            editorSettings={
                                ValidationUtils.getEditorSettings(true, false, validators, form, (val: string) => { storeFormValue(val, nameFieldDefinition.id, [nameFieldDefinition.id]) }, nameFieldDefinition.id
                                )}
                            value={form.values[nameFieldDefinition.id] as string}
                        />
                    </AutoFocus>
                </MuiGrid>
            </MuiGrid>
        </LsModal>
    )
};

/**
 * Initialises the validators for the form.
 */
const getValidators = (fieldDefinition: Domain.Shared.FieldDefinition): ValidatorsDictionary => {
    if (!fieldDefinition) return {};
    return {
        [fieldDefinition.id]: new TextValidator({
            required: fieldDefinition.required,
            stringMaxLength: 50,
            stringMinLength: fieldDefinition.stringMinLength,
            stringType: fieldDefinition.stringType
        }),
    };
};

/**
 * Initialises the form data with entity type data.
 */
const initFormData = (nameFieldDefinition: Domain.Shared.FieldDefinition, definitionName: string): AnyFormData => {
    return {
        values: {
            [nameFieldDefinition.id]: definitionName || '',
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};